import {
  Accordion,
  // AccordionItem,
  // AccordionItemHeading,
  // AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import styled from 'styled-components';

export const Tab = styled(Accordion)`
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #ced4da;
  padding: 10px;
  color: #656464;
  font-size: 16px;
`;

export const BodyTab = styled(AccordionItemPanel)`
  padding-top: 15px;
`;

export const Button = styled.button`
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  border: solid;
`;

export const DeleteButton = styled.button`
  /* width: 100%; */
  /* margin-top: 20px; */
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  background-color: #dc3545;
  border-color: #dc3545;
  color: #fff;
`;
