const allowedAttributes = [
  'salutation',
  'username',
  'password',
  'firstname',
  'lastname',
  'email',
  'street',
  'houseNo',
  'city',
  'zip',
  'telefon',
  // 'roles',
  'notes',
];

export default allowedAttributes;
