import { NotificationManager } from 'react-notifications';
import mapServerErrorsToClientErrors from './map-server-errors-to-client-errors';
import mutationErrorHandler from './mutation-error-handler';

const createMutationHandler = ({ mutation, mutationName, mutationReturnTypes, serverErrorMessage }) => async (
  mutationVariables
) => {
  //
  const { data, unknownError, graphQLError } = await mutation(mutationVariables) //
    .catch(() => mutationErrorHandler(serverErrorMessage));

  if (unknownError) {
    NotificationManager.error(graphQLError.message, 'Server-GraphQL-Fehler');
    return null;
  }

  const mutationReturnData = data[mutationName];
  const { __typename, message, errors } = mutationReturnData;

  if (__typename === 'InvalidData') {
    NotificationManager.error(message, 'Validierungsfehler vom Server');
    // map server errors to form errors
    // console.log("DAS IST ERRORS: ", errors)
    return mapServerErrorsToClientErrors(errors);
    // return { username: "FEHLER ALTER :P" };
  }

  if (__typename === 'Unauthorized') {
    // Unauthorized only returns message, nothing to return for final form
    NotificationManager.error(message, 'Authorizationsfehler');
    return null;
  }

  if (__typename === 'NotFound') {
    // Unauthorized only returns message, nothing to return for final form
    NotificationManager.error(message, 'NotFound-Fehler');
    return null;
  }

  if (__typename === 'AlreadyExists') {
    // AlreadyExists gets Data which I map to the client to return it to final form
    NotificationManager.error(message, 'Einzigartigkeit verletzt');
    if (errors.length > 0) {
      return errors.map((error) => ({ [error.property]: error.message }))[0];
    }
    return null;
  }

  if (__typename === 'HasDependent') {
    NotificationManager.error(message, 'Abhängigkeit vorhanden');
    return null;
  }

  const successMessage = mutationReturnTypes[__typename].successMessage(mutationReturnData);
  NotificationManager.success(successMessage, 'Update erfolgreich');

  if (mutationReturnTypes[__typename].onSuccess) {
    mutationReturnTypes[__typename].onSuccess();
  }
};

export default createMutationHandler;
