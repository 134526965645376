import currency from 'currency.js';

const EURO = (value) => currency(value, { symbol: '', decimal: ',', separator: '.' });

export default function calculatePosition(position) {
  // return EURO(position.einzelpreis) //
  //   .multiply(position.menge)
  //   .format(true);

  return ((position.einzelpreis * position.menge) / 100) //
    .toFixed(2)
    .toString()
    .replace('.', ',');
}
