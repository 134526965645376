import React from 'react';
// import { action } from '@storybook/addon-actions';
// import { Button } from '@storybook/react/demo';
import styled from 'styled-components';
// import QueryBox from './QueryBox';
import {
  QueryBox,
  Row,
  Box,
  useQueryBox,
  SearchInputText,
  SearchInputNumber,
  SearchInputDate,
  SearchInputDateRange,
  SearchInputSelect,
} from '../../../molecules/QueryBox';

const QueryBoxContainer = styled.div`
  margin: 0 auto 10px;
  /* min-width: 470px; */
  /* min-width: 385px; */
  /* min-width: 345px; */
  min-width: 290px;
  max-width: 1200px;

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
`;

const TimeTrackerQueryBox = ({ onSearch, ownersList = [], customersList = [] }) => {
  const { inputProps, toggleProps, onSubmit } = useQueryBox({
    formatter: {
      owner: (arg) => arg.value,
      customer: (arg) => arg.value,
      status: (arg) => arg.value,
    },
  });

  return (
    <div>
      <QueryBoxContainer>
        <QueryBox
          headerTitle="Erweiterte Datenbanksuche"
          formatter
          onSubmit={() => {
            onSubmit((values) => {
              onSearch(values);
            });
          }}
        >
          <Row>
            <Box label="ID" {...toggleProps({ name: 'timetrackId' })}>
              <SearchInputNumber {...inputProps({ name: 'timetrackId' })} />
            </Box>
            <Box label="Projektname" {...toggleProps({ name: 'name' })}>
              <SearchInputText {...inputProps({ name: 'name' })} />
            </Box>
          </Row>
          <Row>
            <Box label="Kunde" {...toggleProps({ name: 'customer' })}>
              <SearchInputSelect {...inputProps({ name: 'customer' })} options={customersList} />
            </Box>
            <Box label="Leistungsbringer" {...toggleProps({ name: 'owner' })}>
              <SearchInputSelect {...inputProps({ name: 'owner' })} options={ownersList} />
            </Box>
          </Row>
          <Row>
            <Box label="Projektzeitraum" {...toggleProps({ name: 'projectDate' })}>
              <SearchInputDate {...inputProps({ name: 'projectDate' })} />
            </Box>
            <Box label="Projektzeitraum - Datumsbereich" {...toggleProps({ name: 'projectDateRange' })}>
              <SearchInputDateRange {...inputProps({ name: 'projectDateRange' })} />
            </Box>
          </Row>
          <Row>
            <Box label="Erstellt am" {...toggleProps({ name: 'createdAt' })}>
              <SearchInputDate {...inputProps({ name: 'createdAt' })} />
            </Box>
            <Box label="Erstellt am - Datumsbereich" {...toggleProps({ name: 'createdAtRange' })}>
              <SearchInputDateRange {...inputProps({ name: 'createdAtRange' })} />
            </Box>
          </Row>
          <Row>
            <Box label="Geändert am" {...toggleProps({ name: 'updatedAt' })}>
              <SearchInputDate {...inputProps({ name: 'updatedAt' })} />
            </Box>
            <Box label="Geändert am - Datumsbereich" {...toggleProps({ name: 'updatedAtRange' })}>
              <SearchInputDateRange {...inputProps({ name: 'updatedAtRange' })} />
            </Box>
          </Row>

          <Row>
            <Box label="Status" {...toggleProps({ name: 'status' })}>
              <SearchInputSelect
                {...inputProps({ name: 'status' })}
                options={[
                  { label: 'Editierbar', value: { isLocked: false } },
                  { label: 'Gesperrt', value: { isLocked: true } },
                  { label: 'Sichtbar', value: { isHidden: false } },
                  { label: 'Versteckt', value: { isHidden: true } },
                  { label: 'Freelancer unterschrieben', value: { isFreelancerSigned: true } },
                  { label: 'Freelancer nicht unterschrieben', value: { isFreelancerSigned: false } },
                  { label: 'Manager unterschrieben', value: { isManagerSigned: true } },
                  { label: 'Manager nicht unterschrieben', value: { isManagerSigned: false } },
                ]}
              />
            </Box>
          </Row>
        </QueryBox>
      </QueryBoxContainer>
    </div>
  );
};

export default TimeTrackerQueryBox;
