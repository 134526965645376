import moment from 'moment';
import 'moment/locale/de';

export const calculateNettoTime = ({ startTime, endTime, breakTime }) => {
  if (!startTime && !endTime) {
    return 0;
  }

  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');
  const duration = moment.duration(end.diff(start));

  // subtract the lunch break
  duration.subtract(Number(breakTime), 'minutes');

  // +"1" => 1  + creates a nummerical representation
  const result = moment.utc(+duration).format('H:mm');
  return result === 'Invalid date' ? 0 : result;
};

export const createViewData = ({ year, month, day, timetrack = {} }) => {
  // const timeTrackerObject = timeTrackerArray.reduce(
  //   (accu, timetrack) => ({
  //     ...accu,
  //     [timetrack.day]: timetrack,
  //   }),
  //   {}
  // );

  const timeInstance = moment(`${year} ${month} ${day}`, 'YYYY MM DD');
  // const hasDay = timetrack && timetrack.active;
  const hasDay = timetrack;

  const date = timeInstance.format('DD.MM.YYYY');
  const weekDayName = timeInstance.format('dddd');
  const isWeekend = !!(weekDayName === 'Samstag' || weekDayName === 'Sonntag');
  const weekDay = weekDayName.substring(0, 2);

  // was soll in input angezeigt werden und wann soll es angezeigt werden
  const start = timetrack.start || '';
  const end = timetrack.end || '';
  const lunchBreak = timetrack.lunchBreak || '';
  const activity = timetrack.activity || '';
  const location = timetrack.location || '';

  return {
    nettoTime: hasDay
      ? calculateNettoTime({
          startTime: start,
          endTime: end,
          breakTime: lunchBreak,
        })
      : '0',
    start,
    end,
    lunchBreak,
    date,
    hasDay,
    weekDay,
    isWeekend,
    activity,
    location,
  };
};
