import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTab = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  user-select: none;
  font-size: 12px;
  font-weight: ${(props) => (props.isActive ? 'bold' : 'normal')};
  flex: inline-flex;
  padding: 10px;
  color: rgb(80, 80, 80);
  margin-right: -1.2px;
  border-radius: 0px;
  border: 1px solid #aaa;
  margin-bottom: -1px;
  background-color: ${(props) => (props.isActive ? 'white' : '#dcdcdc')};
  border-bottom: ${(props) => (props.isActive ? '0px #fff solid' : '1px #aaa solid')};
  order: ${(props) => props.order};
`;

const Tab = (props) => {
  const { isDisabled, onClick, children } = props;

  return (
    <StyledTab {...props} onClick={isDisabled ? null : onClick}>
      {children}
    </StyledTab>
  );
};

Tab.propTypes = {
  children: PropTypes.any,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Tab.defaultProps = {
  isDisabled: false,
};

export default Tab;
