import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import PropTypes from 'prop-types';

// COLUMNS
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faUserEdit, faStickyNote, faTrash } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
// import CustomerIdColumn from './Column.Id';
// import Actions from './Actions';
// import Customer from './Customer';

// import KundeKontaktColumn from '../../molecules/KundeKontakteColumn';
// import KundeAdresseColumn from '../../molecules/KundeAdresseColumn';
import DateColumn from '../../molecules/DateColumn';

import Modal from '../../molecules/Modal';

const TableStyle = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const StyledLink = styled(Link)`
  color: #212529;
  margin: 0px 0px 0px 0px;
  &:hover {
    color: #212529;
  }
`;

const EmailRow = styled.div`
  display: flex;
  padding: 10px;
  margin: 0px 0px 0px 0px;
  &:hover {
    background-color: aliceblue;
  }
`;

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'date',
    order: 'desc',
  },
];

export default function TableCustomers(props) {
  const columns = [
    {
      dataField: 'id',
      text: 'Mail',
      formatter: (cell, row) => {
        const dateString = moment(row.date).format('DD.MM.YYYY [um] HH:mm [Uhr]');

        return (
          <StyledLink to={`/projektangebote/focused/update/${row.id}`} style={{ textDecoration: 'none' }}>
            <EmailRow>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>{row.from}</div>
                  <div>{dateString}</div>
                </div>
                <div
                  style={{
                    fontWeight: 'bold',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    marginRight: '10px',
                    marginTop: '5px',
                  }}
                >
                  {row.subject}
                </div>
              </div>
            </EmailRow>
          </StyledLink>
        );
      },
      style: { width: '100%', verticalAlign: 'middle', padding: '0px' },
      sort: true,
    },
    {
      dataField: 'vorname',
      text: 'Status',
      // formatter: Actions,
      formatter: (cell, row) => (
        <>
          {row.focused ? (
            <div style={{ fontWeight: 'bold', fontSize: '12px', color: '#28a745' }}>FOKUSSIERT</div>
          ) : (
            <div style={{ fontWeight: 'bold', fontSize: '12px', color: '#dc3545' }}>UNFOKUSSIERT</div>
          )}
        </>
      ),
      style: { minWidth: '100px', maxWidth: '175px', verticalAlign: 'middle' },
      sort: true,
    },

    // HIDDEN, BUT THERE TO BE ABLE SO LAZY-SEARCH
    {
      dataField: 'subject',
      hidden: true,
    },
    {
      dataField: 'updatedAt',
      hidden: true,
    },
    {
      dataField: 'from',
      hidden: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Geändert am',
      formatter: DateColumn('updatedAt'),
      style: { minWidth: '135px', maxWidth: '135px', verticalAlign: 'middle' },
      sort: true,
      // hidden: true,
    },
  ];

  return (
    <TableStyle>
      <ToolkitProvider keyField="id" data={props.data} columns={columns} search>
        {({ searchProps, baseProps }) => (
          <div>
            <SearchBar
              {...searchProps}
              style={{ margin: '15px 0px 15px 0px', borderRadius: '0px' }}
              placeholder="Suchen..."
            />

            <BootstrapTable
              {...baseProps}
              noDataIndication="Keine Daten mit diesen Sucheigenschaften gefunden"
              defaultSorted={defaultSorted}
              bordered={false}
            />
          </div>
        )}
      </ToolkitProvider>
    </TableStyle>
  );
}

TableCustomers.defaultProps = {
  data: [],
};
