import React from 'react';

const DEFAULT_STATE = {
  sessionUser: {},
  loading: true,
};

const AppContext = React.createContext(DEFAULT_STATE);

export default AppContext;
