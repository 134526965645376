import styled from 'styled-components';
import {
  // Accordion as AccordionElement,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

export const TimeListTable = styled.table`
  /* margin: top right_left bottom; */
  margin: 0 auto 0px;
  min-width: 470px;
  max-width: 1200px;

  border-style: solid;
  border-width: 1px;
  /* width: 100%; */
  border: 1;
  font-size: 12px;
`;

export const Table = styled.table`
  border-style: solid;
  border-width: 1px;
  width: 100%;
  border: 1;
  font-size: 12px;
`;

export const Row = styled.tr`
  user-select: none;
  border-style: solid;
  border-width: 1px;
  width: 100%;
  border: 1;
`;

export const TData = styled.td`
  border-style: solid;
  border-width: 1px;
  border: 1;
  padding: 3px;
  text-align: ${({ align }) => align || ''};
  cursor: ${({ cursor }) => cursor || ''};
`;

export const Header = styled.th`
  text-align: ${({ align }) => align || ''};
  background-color: #f5f5f5;
  border-style: solid;
  border-width: 1px;
  border: 1;
  padding: 3px;
`;

export const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

export const Content = styled.div`
  margin-right: 5px;
`;

// export const AccordionTitle = styled(AccordionItemTitle)`
export const AccordionTitle = styled(AccordionItem)`
  /* padding: 10px 10px 25px 5px; */
  background-color: ${({ isweekend }) => (isweekend ? '#dfe3ee' : '')};
  opacity: ${({ hasday }) => (hasday ? '' : '0.6')};
`;

export const Accordion = styled(AccordionItem)`
  /* padding: 10px 10px 25px 5px; */
  background-color: ${({ isweekend }) => (isweekend ? '#dfe3ee' : '')};
  opacity: ${({ hasday }) => (hasday ? '' : '0.6')};
`;

export const HeadAccordion = styled(AccordionItemHeading)`
  cursor: pointer;
  width: 100%;
  border: 1px solid #dfe3ee;
  margin-top: 2px;
  /* background-color: ${({ isweekend }) => (isweekend ? '#dfe3ee' : '')}; */
  background-color: ${({ isweekend, hasday }) => (isweekend ? `rgba(223, 227, 238, ${hasday ? 1 : 0.5})` : '')};
  color: ${({ hasday }) => (hasday ? 'rgba(80, 80, 80, 1)' : 'rgba(80, 80, 80, 0.6)')};
  /* opacity: ${({ hasday }) => (hasday ? '' : '0.6')}; */
  /* background:rgba(239,239,239,0.6) */
`;

export const BodyAccordion = styled(AccordionItemPanel)`
  width: 100%;
  border: 1px solid #dfe3ee;
  margin-top: 0px;
`;

export const TimeItems = styled.div`
  font-size: 12px;
  color: #505050;
  /* background-color: red; */
`;

export const MobileLabel = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

export const MobileInput = styled.input`
  border-radius: 0px;
  font-size: 16px;
  border-style: solid;
  border-radius: 0px;
  border-width: 1px;
  border-color: #bfbfbf;
  padding: 5px;
`;
