const allowedAttributes = [
  'name',
  'salutation',
  'firstname',
  'lastname',
  'street',
  'city',
  'plz',
  'houseNo',
  'telefon',
  'fax',
  'email',
  'note',
  'employees',
];

export default allowedAttributes;
