import React from 'react';
import Switch from 'react-switch';
import { FieldArray } from 'react-final-form-arrays';
import { FaTrash } from 'react-icons/fa';
import styled from 'styled-components';
import { GoArrowUp, GoArrowDown } from 'react-icons/go';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FontAwesome from 'react-fontawesome';
import Button from '../../atoms/Button';
import FormGroup from '../../atoms/FormGroup';
import TextInput from '../../molecules/TextInput';
import SelectInput from '../../molecules/SelectInput';
import SelectField from '../../molecules/SelectField';
import EuroInput from '../../final-form-elements/EuroInput';
import Label from '../../atoms/Label';
import CalculationFooter from './Calculation.Footer';
import calculatePosition from './calculate-position';

import { PositionContainer, Row, Column, PositionColumn, DragButton } from './styles';

const getListStyle = (isDraggingOver) => ({
  display: 'flex',
  flexDirection: 'column',
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
});

const getItemStyle = (isDragging, draggableStyle) => ({
  flexWrap: 'wrap',
  borderStyle: 'solid',
  borderColor: '#cccccc',
  borderWidth: '1px',
  padding: '10px',
  userSelect: 'none',
  background: isDragging ? 'lightgreen' : 'white',
  margin: '5px',
  ...draggableStyle,
});

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #bd2130;
  width: 30px;
  height: 30px;
`;

// -------------------------- HELPERS --------------------------
const calcNetto = (brutto, ust = 0) =>
  typeof brutto !== 'number'
    ? '0,00'
    : (Number((brutto / (1 + ust / 100).toFixed(2)).toFixed(0)) / 100).toFixed(2).replace('.', ',');

// brutto - netto = USt.
const calcUSt = (brutto, ust = 0) =>
  typeof brutto !== 'number'
    ? '0,00'
    : (Number(brutto - (brutto / (1 + ust / 100).toFixed(2)).toFixed(0)) / 100).toFixed(2).replace('.', ',');

const getNewArtificalId = () => new Date().valueOf() + Math.random();
// -------------------------- HELPERS --------------------------

//
//
//
// const Positions = ({ positions = [] }) => {
const Positions = ({ positions = [] }) => {
  const onDragEnd = (fields) => (result) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const sourceIndex = source.index;
    const destinationIndex = destination.index;

    // fields.swap(sourceIndex, destinationIndex);
    fields.move(sourceIndex, destinationIndex);
  };

  // take position and put artifical id in it
  const addNewPosition = () => ({
    artificalId: getNewArtificalId(),
    beschreibung: '',
    zusatz: '',
    einheit: 'Stunden',
    menge: 1,
    einzelpreis: 0,
    ust: 7,
  });

  return (
    <FieldArray name="positions">
      {({ fields }) => (
        <div style={{ marginTop: '20px' }}>
          <h4>Positionen</h4>
          <div style={{ width: '200px' }}>
            <Button
              style={{ height: '34px', width: '300px', marginBottom: '15px' }}
              type="button"
              theme="info"
              icon="plus"
              text="Neue Position hinzufügen"
              onClick={() => fields.push(addNewPosition())}
            />
          </div>

          <DragDropContext onDragEnd={onDragEnd(fields)}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                  {positions.map((position, index) => (
                    <Draggable
                      key={position.artificalId} //
                      draggableId={`${position.artificalId}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <b>Position: {index + 1}</b>
                            <Button
                              marginTop="0px"
                              theme="danger"
                              icon="trash"
                              height="20px"
                              width="25px"
                              marginRight="5px"
                              onClick={() => fields.remove(index)}
                            />
                          </div>

                          <PositionContainer>
                            <PositionColumn flexGrow="5" flexBasis="40%">
                              <Label text="Beschreibung" />
                              <Column>
                                <TextInput margin="0px" name={`positions[${index}].beschreibung`} />
                                <TextInput placeholder="Zusatz..." margin="0px" name={`positions[${index}].zusatz`} />
                              </Column>
                            </PositionColumn>
                            <PositionColumn flexBasis="15%" minWidth="125px">
                              <Label text="Einheit" />
                              <SelectField
                                margin="0px"
                                options={[
                                  { label: 'Stunden', value: 'Stunden' },
                                  { label: 'Tage', value: 'Tage' },
                                  { label: 'Stück', value: 'Stück' },
                                  { label: 'Kilometer', value: 'Kilometer' },
                                  { label: 'Meter', value: 'Meter' },
                                  { label: 'Arbeitskräfte', value: 'Arbeitskräfte' },
                                ]}
                                name={`positions[${index}].einheit`}
                              />
                            </PositionColumn>
                            <PositionColumn flexBasis="10%" minWidth="55px">
                              <Label text="Menge" />
                              <TextInput
                                margin="0px"
                                name={`positions[${index}].menge`}
                                textalign="right"
                                type="number"
                              />
                            </PositionColumn>
                            <PositionColumn flexBasis="10%" minWidth="105px">
                              <Label text="Einzelpreis" style={{ textAlign: 'right' }} />
                              <EuroInput margin="0px" textalign="right" name={`positions[${index}].einzelpreis`} />
                            </PositionColumn>
                            <PositionColumn flexBasis="7%" minWidth="65px">
                              <Label style={{ textAlign: 'right' }} text="Ust" />
                              <SelectField
                                margin="0px"
                                options={[
                                  { label: '0%', value: '0' },
                                  { label: '7%', value: '7' },
                                  { label: '19%', value: '19' },
                                ]}
                                name={`positions[${index}].ust`}
                              />
                            </PositionColumn>
                            <PositionColumn flexBasis="10%">
                              <Label style={{ textAlign: 'right' }} text="Gesamtpreis" />
                              <div style={{ textAlign: 'right' }}>{calculatePosition(position)} €</div>
                            </PositionColumn>
                          </PositionContainer>
                          <DragButton>
                            <FontAwesome name="arrows-alt" size="2x" {...provided.dragHandleProps} />
                          </DragButton>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <CalculationFooter positions={positions} />
        </div>
      )}
    </FieldArray>
  );
};

export default Positions;
