import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TabsContext } from './Tabs';

const StyledTabBodys = styled.div`
  @media (max-width: 700px) {
    /* display: none */
  }
`;

const TabBodys = props => {
  const { activeIndex } = useContext(TabsContext);
  const { children } = props;

  const bodyChildren = children[activeIndex] || children;

  return <StyledTabBodys {...props}>{bodyChildren}</StyledTabBodys>;
};

TabBodys.propTypes = {
  children: PropTypes.any,
};

export default TabBodys;
