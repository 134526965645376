import React from 'react';
import { ImSpinner8 } from 'react-icons/im';
import styled from 'styled-components';
import moment from 'moment';

const StyledMetaInfoBox = styled.div`
  /* margin: top right_left bottom; */
  margin: 0 auto 0px;
  /* min-width: 470px; */
  /* min-width: 385px; */
  /* min-width: 345px; */
  min-width: 290px;
  max-width: 1200px;

  display: flex;
  justify-content: space-between;
  align-items: top;
  border-style: solid;
  border-width: 1px;
  border-color: #aaa;
  font-size: 12px;
  /* margin: 5px; */
  padding: 0px 10px 10px 10px;
  color: #505050;
  flex-wrap: wrap;
`;

const Column = styled.div`
  display: flex;
  padding-top: 10px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

const Labels = styled.div`
  text-align: right;
  font-weight: bold;
`;

const Value = styled.div`
  /* font-weight: bold; */
`;

const Values = styled.div`
  /* font-weight: bold; */
`;

const setValue = (row) => {
  if (row.label === 'Erstellt am' || row.label === 'Geändert am') {
    return moment(row.value).format('DD.MM.YYYY - HH:mm [Uhr]');
  }
  return row.value;
};

const MetaInfoBox = ({ columns, style }) => (
  <StyledMetaInfoBox style={style}>
    {columns.map((column, index) => (
      <Column key={index}>
        <Labels>
          {column.map((row, index) => (
            <Label key={index} style={row.style}>
              {row.label}:
            </Label>
          ))}
        </Labels>
        <Values>
          {column.map((row, index) => (
            <Value key={index} style={row.style}>
              {setValue(row)}
            </Value>
          ))}
        </Values>
      </Column>
    ))}
  </StyledMetaInfoBox>
);

export default MetaInfoBox;
