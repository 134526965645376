import styled from 'styled-components';
import { Link as LinkDom } from 'react-router-dom';

export const Link = styled(LinkDom)`
  color: #484c50;
  &:hover {
    color: #007bff;
  }
`;

export const PositionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  /* display: flex; */
  flex-direction: column;
`;

export const PositionColumn = styled.div`
  box-sizing: border-box;
  flex-basis: ${(props) => props.flexBasis};
  min-width: ${(props) => props.minWidth};
`;

export const DragButton = styled.div`
  display: flex;
  justify-content: flex-end;
  color: darkgrey;
`;
