import React from 'react';
import styled from 'styled-components';
import mapQueryStringsToMessages from './map-query-strings-to-messages';

const FetchConditionsInfoBox = styled.div`
  margin: 0 auto 10px;
  /* min-width: 470px; */
  /* min-width: 385px; */
  /* min-width: 345px; */
  min-width: 290px;
  max-width: 1200px;

  font-size: 12px;
  color: #656464;
  border-style: solid;
  border-width: 1px;
  border-color: #ced4da;
  padding: 10px;
  color: #656464;
  /* margin-bottom: 15px; */
`;

const HeaderOfFetchConditions = styled.div`
  font-size: 12px;
  color: #656464;
  font-weight: bold;
`;

// show what is fetched
const FetchConditionsInfo = ({ queryString, users, customers }) => (
  <FetchConditionsInfoBox>
    <HeaderOfFetchConditions>Alle Zeiterfassungen mit den Eigenschaften:</HeaderOfFetchConditions>
    {mapQueryStringsToMessages(
      {
        timetrackId: (id) => `Zeiterfassung-ID: ${id}`,
        updatedAt: ({ year, month }) => `Geändert am: ${year}-${month}`,
        createdAt: ({ year, month }) => `Erstellt am: ${year}-${month}`,
        projectDate: ({ year, month }) => `Projektdatum: ${year}-${month}`,
        name: (name) => `Projektname: ${name}`,
        owner: (id) => {
          const { firstname, lastname } = users.find((user) => user.userId.toString() === id.toString());
          return `Leistungsbringer: ${firstname} ${lastname}`;
        },
        customer: (id) => {
          const { typ, firmenname, name, vorname } = customers.find(
            (customer) => customer.customerId.toString() === id.toString()
          );
          return `Kunde: ${typ === 'Firma' ? firmenname : `${vorname} ${name}`}`;
        },
        projectDateRange: ({ from, to }) =>
          `Projektdatum zwischen ${from.year}-${from.month} und ${to.year}-${to.month}`,
        createdAtRange: ({ from, to }) => `Erstellt zwischen ${from.year}-${from.month} und ${to.year}-${to.month}`,
        updatedAtRange: ({ from, to }) => `Geändert zwischen ${from.year}-${from.month} und ${to.year}-${to.month}`,
        status: (status) => {
          const [key, value] = Object.entries(status)[0];

          return `Status: ${
            {
              isLocked: value ? 'Gesperrt' : 'Editierbar',
              isHidden: value ? 'Versteckt' : 'Sichtbar',
              isFreelangerSigned: value ? 'Freelancer unterschrieben' : 'Freelancer nicht unterschrieben',
              isManagerSigned: value ? 'Manager unterschrieben' : 'Manager nicht unterschrieben',
            }[key]
          }`;
        },
      },
      queryString
    )}
  </FetchConditionsInfoBox>
);

export default FetchConditionsInfo;
