import React from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import DateColumn from '../../molecules/DateColumn';

const TableStyle = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const StyledLink = styled(Link)`
  color: grey;
  margin-right: 20px;
  font-size: 20px;

  &:hover {
    color: #89cff0;
  }
`;

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'updatedAt',
    order: 'desc',
  },
];

export default function TableCustomers(props) {
  const columns = [
    {
      text: 'IBAN',
      dataField: 'id',
      formatter: (cell, row) => row.id,
      style: { minWidth: '245px' },
      sort: true,
    },
    {
      text: 'Info',
      dataField: 'id',
      formatter: (cell, row) => (row.label ? row.label : 'BESITZT KEINE BEZEICHNUNG'),
      style: { minWidth: '300px' },
      sort: true,
    },
    // {
    //   text: 'Begünstigter',
    //   dataField: 'name',
    //   formatter: (cell, row) => (
    //     <div>
    //       {row.beneficiaryType === 'Mitarbeiter' || row.beneficiaryType === 'Privatperson' ? (
    //         <>
    //           <div>{`${row.firstname} ${row.lastname}`}</div>
    //           <div style={{ fontSize: '12px' }}>{`${row.beneficiaryType}`}</div>
    //         </>
    //       ) : (
    //         <>
    //           <div>{`${row.firmenname}`}</div>
    //           <div style={{ fontSize: '12px' }}>{`${row.beneficiaryType}`}</div>
    //         </>
    //       )}
    //     </div>
    //   ),
    //   style: { minWidth: '210px' },
    //   sort: true,
    // },
    // {
    //   dataField: 'updatedAt',
    //   text: 'Geändert am',
    //   formatter: DateColumn('updatedAt'),
    //   style: { width: '130px' },
    //   sort: true,
    // },
    {
      dataField: 'vorname',
      text: 'Aktionen',
      formatter: (cell, row) => (
        <div style={{ display: 'flex', textAlign: 'center' }}>
          <StyledLink to={`/recruiters/update/${row.id}`} style={{ textDecoration: 'none' }}>
            <div data-tip="Recruiter bearbeiten" name="edit-customer-button">
              <FontAwesomeIcon size="1x" icon={faEdit} style={{ marginLeft: '20px' }} />
              <ReactTooltip place="left" type="dark" effect="float" />
            </div>
          </StyledLink>
        </div>
      ),
      style: { width: '100px' },
      sort: true,
    },
  ];

  return (
    <TableStyle>
      <ToolkitProvider keyField="id" data={props.data} columns={columns} search>
        {({ searchProps, baseProps }) => (
          <div>
            <SearchBar
              {...searchProps}
              style={{ margin: '15px 0px 15px 0px', borderRadius: '0px' }}
              placeholder="Suchen..."
            />

            <BootstrapTable
              noDataIndication="Keine Daten mit diesen Sucheigenschaften gefunden"
              defaultSorted={defaultSorted}
              bordered={false}
              {...baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </TableStyle>
  );
}

TableCustomers.defaultProps = {
  data: [],
};
