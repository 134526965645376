import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { MdAdd } from 'react-icons/md';
import { gql } from 'apollo-boost';
// import { useLazyQuery } from '@apollo/client';
import { useLazyQuery } from '@apollo/react-hooks';
import currency from 'currency.js';
import moment from 'moment';
import Label from '../../atoms/Label';

import Modal from '../../molecules/Modal2';

const StyledContainer = styled.div`
  width: 100%;
  margin: 10px;
`;

const BoxContainer = styled.div`
  display: flex;
  /* flex: 1; */
  width: 100%;
  border-color: hsl(0, 0%, 80%);
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  height: 55px;
  /* padding: 8px; */
`;

const ClearBotton = styled.div`
  cursor: pointer;
  color: #cccccc;
  /* width: 100%; */
  /* flex: 1; */
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #bee6f5;
    color: #4c4c4c;
  }
`;

const OpenModalButton = styled.div`
  color: white;
  background-color: #2861a7;
  display: flex;
  user-select: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  height: 55px;
`;

const BankTransaction = styled.div`
  display: flex;
  user-select: none;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-color: hsl(0, 0%, 80%);
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  height: 55px;
  background-color: ${(props) => (props.isSelected ? 'greenyellow' : 'white')};

  &:hover {
    background-color: greenyellow;
  }
`;

const QUERY = gql`
  query GetBankTransaction($where: BankTransactionWhereInput) {
    bankTransactions(where: $where) {
      id
      buchungstag
      betrag
      verwendungszweck
      buchungstext
    }
  }
`;

const centToEuro = (cents) => `${currency(cents, { separator: '.', decimal: ',' }).divide(100).format()} €`;

const Foo = ({ bankAccount, beneficiary, onSelectedBankTransaction }) => {
  const [bankTransactions, setBankTransactions] = useState(null);
  const [selectedBankTransaction, setSelectedBankTransaction] = useState(null);

  const [getBankTransactions, { loading, data }] = useLazyQuery(QUERY);

  const selectBankTransactionById = (id) => {
    const selectedItem = bankTransactions.find((transaction) => transaction.id === id);
    setSelectedBankTransaction(selectedItem);
    onSelectedBankTransaction(selectedItem);
  };

  useEffect(() => {
    if (data && data.bankTransactions) {
      setBankTransactions(data.bankTransactions);
    }
  }, [data]);

  if (loading) return <p>Loading ...</p>;

  //   if (data && data.bankTransactions) {
  // setBankTransactions(data.bankTransactions);
  //   }

  //   console.log('DAS IST BANKTRANSACTIONS: ', bankTransactions);

  return (
    <>
      {/* {console.log('BANKACCOUNT: ', bankAccount)} */}
      {/* <div>AAA {bankAccount.id}</div> */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <div>Bezeichnung der Bank der Banktransaktion: </div>
        <div>{bankAccount.name}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <div>IBAN der Bank der Banktransaktion: </div>
        <div>{bankAccount.id}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <div>IBAN der Bank der Banktransaktion: </div>
        <div>{beneficiary.id}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <div>Bitte wählen Sie das Jahr der Banktransaktion:</div>
        <select>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
        </select>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '10px' }}>
        <div style={{ marginRight: '20px' }}>Bitte wählen Sie den Monat der Banktransaktion:</div>
        <select>
          <option value="1">01 - Januar</option>
          <option value="2">02 - Februar</option>
          <option value="3">03 - März</option>
          <option value="4">04 - April</option>
          <option value="5">05 - Mai</option>
          <option value="6">06 - Juni</option>
          <option value="7">07 - Juli</option>
          <option value="8">08 - August</option>
          <option value="9">09 - September</option>
          <option value="10">10 - Oktober</option>
          <option value="11">11 - November</option>
          <option value="12">12 - Dezember</option>
        </select>
      </div>
      <hr />
      <div>
        {bankTransactions &&
          bankTransactions.map((transaction) => (
            <BankTransaction
              isSelected={selectedBankTransaction && transaction && selectedBankTransaction.id === transaction.id}
              key={transaction.id}
              onClick={() => selectBankTransactionById(transaction.id)}
            >
              <div>
                <div>{transaction.verwendungszweck}</div>
                <div style={{ fontSize: '10px' }}>{transaction.buchungstext}</div>
              </div>
              <div>{centToEuro(transaction.betrag)}</div>
            </BankTransaction>
          ))}
      </div>
      <button
        type="button"
        style={{
          padding: '10px',
          cursor: 'pointer',
          marginTop: '10px',
          borderWidth: 0,
          backgroundColor: '#28a745',
          color: 'white',
          width: '50%',
        }}
        onClick={() => {
          getBankTransactions({
            variables: {
              where: {
                auftragskonto: bankAccount.id,
                kontonummer: beneficiary.id,
                // auftragskonto: String
                // kontonummer: Strin
                // id: '00439bce747cafa3c0b2119fa07b177e8cd0dc679475c2d08dca7ea0703875de',
              },
            },
          });
        }}
      >
        Suchen
      </button>
    </>
  );
};

const AddTransaction = ({ bankAccount = {}, beneficiary = {}, transaction = {}, setBankTransactionInFormState }) => {
  const [selectedBankTransaction, setSelectedBankTransaction] = useState(null);

  //   {
  //     "id": "3a6e687e37b406ad97b2aa6b50413e095735cfba74b1a7eaf0e7350b63f4312d",
  //     "buchungstag": "2020-07-28T22:00:00.000Z",
  //     "betrag": -1900,
  //     "verwendungszweck": "DRP 85624902 73202795 STRATO AG ",
  //     "buchungstext": "FOLGELASTSCHRIFT",
  //     "__typename": "BankTransaction"
  // }

  return (
    <>
      <StyledContainer>
        <Label text="Banktransaktion" />
        <BoxContainer>
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '100%', padding: '8px' }}>
              {transaction.buchungstag && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: '12px' }}>{moment(transaction.buchungstag).format('DD.MM.YYYY')}</div>
                    <div style={{ fontSize: '12px' }}>{transaction.verwendungszweck}</div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ fontSize: '12px' }}>{transaction.buchungstext}</div>
                    <div>{centToEuro(transaction.betrag)}</div>
                  </div>
                </>
              )}
            </div>
            <ClearBotton>
              <FaTimes />
            </ClearBotton>
          </div>
        </BoxContainer>
      </StyledContainer>
      {/* ----------------------------------------------------- */}
      <Modal
        theme="info"
        title="Banktransaktion hinzufügen"
        content={
          <Foo
            bankAccount={bankAccount}
            beneficiary={beneficiary}
            onSelectedBankTransaction={setSelectedBankTransaction}
          />
        }
        onOkay={() => {
          setBankTransactionInFormState(selectedBankTransaction);
        }}
      >
        <OpenModalButton>
          <MdAdd size="20px" style={{ marginRight: '10px' }} />
          Banktransaktion hinzufügen
        </OpenModalButton>
      </Modal>
    </>
  );
};

export default AddTransaction;
