import React from 'react';
// import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

// import { Tab, Tabs, TabList, TabBody } from 'react-tabs';
import { FieldArray } from 'react-final-form-arrays';
import FormGroup from '../../atoms/FormGroup';
import Button from '../../atoms/Button';
import TextInput from '../../molecules/TextInput';
import TextArea from '../../molecules/TextArea';
import SelectField from '../../molecules/SelectField';

import withFinalForm from '../../HOC/with-final-form';
import allowedAttributes from './allowed-attributes-customer';
import validator from './validator';

import { Tabs, TabHeaders, TabBodys, TabBody, Tab } from '../../molecules/Tabs';

// const tabStyle = {
//   borderRadius: '0px',
//   padding: '15px',
//   color: '#505050	',
// };

// const selectedTabStyle = {
//   border: 'solid',
//   borderColor: '#aaaaaa',
//   borderWidth: '1px',
//   borderTop: 0,
//   padding: '0px 10px 20px 10px',
// };

const FormCustomer = ({ values, handleSubmit, submitButtonText }) => (
  <form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
    <Tabs>
      <TabHeaders>
        <Tab>
          <FontAwesome name="info-circle" /> &nbsp; Kundeninformationen
        </Tab>
        <Tab>
          <FontAwesome name="male" /> &nbsp; Ansprechpartner
        </Tab>
        <Tab>
          <FontAwesome name="users" /> &nbsp; Projektleiter
        </Tab>
        <Tab>
          <FontAwesome name="bank" /> &nbsp; Bankdaten
        </Tab>
        <Tab>
          <FontAwesome name="money" /> &nbsp; Steuern
        </Tab>
        <Tab>
          <FontAwesome name="sticky-note" /> &nbsp; Notizen
        </Tab>
      </TabHeaders>

      <TabBodys>
        {/* KUNDENINFORMATIONEN */}
        <TabBody>
          <FormGroup>
            <SelectField
              label="Typ"
              type="text"
              name="typ"
              options={[
                { label: 'Privatperson', value: 'Privatperson' },
                { label: 'Firma', value: 'Firma' },
              ]}
            />
          </FormGroup>
          {values.typ === 'Firma' && (
            <FormGroup>
              <TextInput label="Firmenname" type="text" name="firmenname" />
            </FormGroup>
          )}

          <hr />
          {values.typ === 'Firma' && <h4>Ansprechperson der Firma</h4>}
          <FormGroup>
            <SelectField
              label="Anrede"
              name="anrede"
              options={[
                { label: 'Herr', value: 'Herr' },
                { label: 'Frau', value: 'Frau' },
              ]}
            />
          </FormGroup>

          <FormGroup>
            <TextInput label="Vorname" type="text" name="vorname" focus />
            <TextInput label="Name" type="text" name="name" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Straße" type="text" name="strasse" />
            <TextInput label="Hausnummer" type="text" name="hausnr" />
          </FormGroup>
          <FormGroup>
            <TextInput label="PLZ" type="number" name="plz" />
            <TextInput label="Ort" type="text" name="ort" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Telefon" type="text" name="telefon" format="telefonFormat" />
            <TextInput label="Fax" type="text" name="fax" />
          </FormGroup>
          <FormGroup>
            <TextInput label="E-Mail" type="text" name="email" />
          </FormGroup>
        </TabBody>

        {/* Ansprechpartner */}
        <TabBody>
          <FormGroup>
            <TextInput label="Ansprechpartner-Vorname" type="text" name="ansprechvorname" />
            <TextInput label="Ansprechpartner-Name" type="text" name="ansprechname" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Ansprechpartner-Telefon" type="text" name="ansprechtelefon" />
            <TextInput label="Ansprechpartner-E-Mail" type="text" name="ansprechemail" />
          </FormGroup>
        </TabBody>

        {/* Projektleiter */}
        <TabBody>
          <FieldArray name="projectOfficers">
            {({ fields }) => (
              <>
                <Button
                  name="add-projectofficer-button"
                  type="button"
                  onClick={() => fields.push({ firstname: '', lastname: '', email: '', telefon: '' })}
                  style={{
                    width: '100%',
                    // marginLeft: '10px',
                    boxSizing: 'border-box',
                  }}
                  theme="success"
                  height="40px"
                  icon="plus"
                  text="Neuen Projektleiter hinzufügen"
                />

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexWrap: 'wrap',
                    boxSizing: 'border-box',
                  }}
                >
                  {fields.map((projectOfficer, index) => (
                    <div
                      name="project-officer-box"
                      key={projectOfficer}
                      style={{
                        flex: '1 0 47.5%',
                        padding: '0px 10px 10px 10px',
                        margin: '10px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: '#aaaaaa',
                        backgroundColor: '#edf1f5',
                      }}
                    >
                      <FormGroup>
                        <TextInput label="Vorname" type="text" name={`${projectOfficer}.firstname`} />
                        <TextInput label="Name" type="text" name={`${projectOfficer}.lastname`} />
                        <TextInput label="Telefon" type="text" name={`${projectOfficer}.telefon`} />
                        <TextInput label="E-Mail" type="text" name={`${projectOfficer}.emai`} />
                      </FormGroup>
                      {/* DELETE BUTTON */}
                      <Button
                        name="delete-projectofficer"
                        type="button"
                        onClick={() => fields.remove(index)}
                        style={{ marginTop: '10px' }}
                        theme="danger"
                        height="40px"
                        icon="trash"
                        text="Projektleiter löschen"
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </FieldArray>
        </TabBody>

        {/* BANKDATEN */}
        <TabBody>
          <FormGroup>
            <TextInput label="Bankname" type="text" name="bankname" />
            <TextInput label="BIC / SWIFT" type="text" name="bic" />
          </FormGroup>
          <FormGroup>
            <TextInput label="IBAN" type="text" name="iban" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Kontonummer" type="text" name="kontonr" />
            <TextInput label="BLZ" type="text" name="blz" />
          </FormGroup>
        </TabBody>

        {/* STEUERN */}
        <TabBody>
          <FormGroup>
            <TextInput label="Umsatzsteuer-Id" type="text" name="umsatzsteuerid" />
            <TextInput label="Steuernummer" type="text" name="steuernr" />
          </FormGroup>
          <FormGroup>
            <TextInput label="EU-Steuernummer" type="text" name="eusteuernr" />
            <TextInput label="Amtsgericht" type="text" name="amtsgericht" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Handlesregister" type="text" name="handelsregister" />
          </FormGroup>
        </TabBody>

        {/* NOTIZEN */}
        <TabBody>
          <FormGroup>
            <TextArea label="Notizen" type="text" name="notizen" />
          </FormGroup>
        </TabBody>
      </TabBodys>
    </Tabs>

    <Button
      name="submit-button"
      style={{ marginTop: '20px' }}
      theme="success"
      height="40px"
      icon="save"
      text={submitButtonText}
    />
  </form>
);

// FORM CONFIG
export default (props) => {
  const { data = {}, submitButtonText, mutation } = props;

  const initialValues = React.useMemo(
    () => ({
      typ: 'Privatperson',
      firmenname: '',
      anrede: 'Herr',
      name: '',
      vorname: '',
      strasse: '',
      hausnr: '',
      plz: null,
      ort: '',
      telefon: '',
      fax: '',
      email: '',
      ansprechname: '',
      ansprechvorname: '',
      ansprechtelefon: '',
      ansprechemail: '',
      umsatzsteuerid: '',
      steuernr: '',
      eusteuernr: '',
      amtsgericht: '',
      handelsregister: '',
      bankname: '',
      bic: '',
      iban: '',
      kontonr: '',
      blz: '',
      notizen: '',
      // projectOfficers: [
      //   // { projectOfficerId: 1, customerId: 1, firstname: 'Bruce', lastname: 'Wayne' },
      //   // { projectOfficerId: 2, customerId: 2, firstname: 'Peter', lastname: 'Parker' },
      // ],
      ...data,
      projectOfficers: data.projectOfficers || [],
    }),
    [data]
  );

  return withFinalForm(
    {
      initialValues,
      validator,
      submitButtonText,
      allowedAttributes,
      attributesFilter: {
        plz: (value) => !value,
      },
      valuesFormat: {
        plz: (value) => (value ? Number(value) : null),
        projectOfficers: (value) =>
          value && Array.isArray(value) && value[0]
            ? value.map(({ projectOfficerId, firstname, lastname, email, telefon }) => ({
                projectOfficerId,
                firstname,
                lastname,
                telefon,
                email,
              }))
            : [],
      },
      submitHandler: (values) => mutation(values),
    },
    FormCustomer
  );
};
