import React from 'react';
// import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { FaEnvelope, FaTrash } from 'react-icons/fa';
import styled from 'styled-components';

import { FieldArray } from 'react-final-form-arrays';

import { GoArrowUp, GoArrowDown } from 'react-icons/go';

import { Tabs, TabHeaders, TabBodys, TabBody, Tab } from '../../molecules/Tabs';

import FormGroup from '../../atoms/FormGroup';
import Button from '../../atoms/Button';
import TextInput from '../../molecules/TextInput';
import TextArea from '../../molecules/TextArea';
// import SelectField from '../../molecules/SelectField';
import SelectInput from '../../molecules/SelectInput';
import CreateSelectInput from '../../molecules/CreateSelectInput';

import EuroInput from '../../final-form-elements/EuroInput';

import ToggleButton from '../../molecules/ToggleButton';
import ToggleTab from '../../molecules/ToggleTab';

import withFinalForm from '../../HOC/with-final-form';
import allowedAttributes from './allowed-attributes';
import validator from './validator';

import DatePicker from '../../final-form-elements/DatePicker';

const FormGrid = styled.div`
  @media screen and (min-width: 760px) {
    display: grid;
    /* pro template area größe */
    grid-template-columns: 2fr 1fr;
    grid-template-areas: 'html form';
    grid-column-gap: 10px;
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'html'
    'form';
`;

const EmailHeader = styled.div`
  display: flex;
  padding: 10px 10px 5px 10px;
  justify-content: space-between;
`;

const ContentArea = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #bd2130;
  width: 30px;
  height: 30px;
`;

const ProjectElementContainer = styled.div`
  display: flex;
  background-color: #edf4fb;
  border: solid 1px #bfbfbf;
  margin-top: 10px;
  padding: 10px;
`;

const ProjectElementHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const CandidateElementContainer = styled.div`
  display: flex;
  background-color: #e0ffff;
  border: solid 1px #bfbfbf;
  margin-top: 10px;
  padding: 10px;
  flex-direction: column;
`;

const CandidateElementHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
`;
// ----------------------------- GRAPH-QL -----------------------------

//
//
//
//
// ----------------------------- GRAPH-QL -----------------------------

// ----------------------------- HELPERS -----------------------------
const getEmployeesOptionsBySelectedRecruiter = (recruiterList, selectedRecruiterId) => {
  const selectedRecruiter = recruiterList.find((recruiter) => recruiter.id === selectedRecruiterId) || {};

  const recruiterEmployees =
    selectedRecruiter.employees && selectedRecruiter.employees.length > 0 && selectedRecruiter.employees[0]
      ? selectedRecruiter.employees
      : [];

  return recruiterEmployees.map((employee) => ({
    value: employee.id,
    label: `${employee.firstname} ${employee.lastname}`,
  }));
};

const getNewArtificalId = () => new Date().valueOf() + Math.random();
// ----------------------------- HELPERS -----------------------------

const ProjectElements = ({ projectElements = [], kontenrahmenOptions }) => (
  <div style={{ margin: '5px' }}>
    <h5>Projektelemente</h5>
    {/* <div>DAS IST PROJECT ELEMENTS</div> */}

    <FieldArray name="projectElements">
      {({ fields }) => (
        <div>
          <Button
            type="button"
            style={{ marginTop: '20px', marginBottom: '10px', padding: '15px' }}
            theme="info"
            height="40px"
            icon="plus"
            text="Projektelement hinzufügen"
            onClick={() =>
              fields.push({
                artificalId: getNewArtificalId(),
                description: '',
                amount: 0,
                category: { value: {}, label: '' },
              })
            }
          />
          {projectElements.map((projectElement, index) => (
            <ProjectElementContainer key={projectElement.id || projectElement.artificalId}>
              <div style={{ width: '100%' }}>
                <ProjectElementHeader>
                  <div style={{ fontWeight: 'bold' }}>Projektelement - {`${index + 1}`}</div>
                  <div style={{ display: 'flex' }}>
                    <GoArrowUp
                      onClick={() => fields.move(index, index - 1)}
                      color="white"
                      style={{
                        cursor: 'pointer',
                        size: '25px',
                        // height: 'fit-content',
                        backgroundColor: '#379bff',
                        marginRight: '2px',
                      }}
                    />
                    <GoArrowDown
                      onClick={() => fields.move(index, index + 1)}
                      color="white"
                      style={{
                        cursor: 'pointer',
                        size: '25px',
                        // height: 'fit-content',
                        backgroundColor: '#379bff',
                        marginLeft: '2px',
                      }}
                    />
                  </div>
                  <ButtonContainer onClick={() => fields.remove(index)}>
                    <FaTrash color="white" />
                  </ButtonContainer>
                </ProjectElementHeader>
                <FormGroup>
                  <TextInput label="Beschreibung" name={`projectElements[${index}].description`} />
                </FormGroup>

                {/* <FormGroup>
                  <TextInput label="Projektort" name={`projectElements[${index}].city`} />
                  <EuroInput label="Stundenlohn" name={`projectElements[${index}].wage`} />
                </FormGroup>
                <FormGroup>
                  <DatePicker
                    label="Project-Start"
                    name={`projectElements[${index}].projectStart`}
                    data-testid="receipt-date-start"
                  />
                  <DatePicker
                    label="Project-End"
                    name={`projectElements[${index}].projectEnd`}
                    data-testid="receipt-date-end"
                  />
                </FormGroup>
                <FormGroup>
                  <SelectInput
                    isMulti
                    label="Sprachkentnisse"
                    name={`projectElements[${index}].languages`}
                    options={[
                      { label: 'Arabisch', value: 'Arabisch' },
                      { label: 'Bosnisch', value: 'Bosnisch' },
                      { label: 'Deutsch', value: 'Deutsch' },
                      { label: 'Englisch', value: 'Englisch' },
                      { label: 'Türkisch', value: 'Türkisch' },
                      { label: 'Japanisch', value: 'Japanisch' },
                    ]}
                    noOptionsMessage="Keine Sprache gefunden"
                    placeholder=""
                  />
                  <CreateSelectInput
                    label="Arbeitsform"
                    name={`projectElements[${index}].workType`}
                    options={[
                      { label: 'Firma', value: 'Firma' },
                      { label: 'Homeoffice', value: 'Homeoffice' },
                    ]}
                    noOptionsMessage="Keine Sprache gefunden"
                    placeholder=""
                  />
                </FormGroup>

                <FormGroup>
                  <SelectInput
                    label="IT-Kentnisse"
                    name={`projectElements[${index}].itKnowledge`}
                    options={[
                      { label: 'Firma', value: 'Firma' },
                      { label: 'Homeoffice', value: 'Homeoffice' },
                    ]}
                    noOptionsMessage="Keine Sprache gefunden"
                    placeholder=""
                  />
                </FormGroup> */}
                <hr />

                {/* ------------------------------ CANDIDATE LIST ------------------------------ */}
                <div style={{ fontWeight: 'bold' }}>
                  <div>Projektkandidaten</div>
                  <div>
                    <Button
                      type="button"
                      style={{ marginTop: '20px', marginBottom: '10px', padding: '15px' }}
                      theme="info"
                      height="40px"
                      icon="plus"
                      text="Kandidaten hinzufügen"
                      onClick={() =>
                        fields.push({
                          artificalId: getNewArtificalId(),
                          foo: '',
                          amount: 0,
                          category: { value: {}, label: '' },
                        })
                      }
                    />
                  </div>
                </div>

                {/* <FieldArray name={`${name}.customers`}>
                      {({ fields }) =>
                        fields.map((name, index) => ( */}
                <CandidateElementContainer>
                  <CandidateElementHeader>
                    <div style={{ fontWeight: 'bold' }}>Projektkandidat - {`${index + 1}`}</div>
                    <div style={{ display: 'flex' }}>
                      <GoArrowUp
                        onClick={() => fields.move(index, index - 1)}
                        color="white"
                        style={{
                          cursor: 'pointer',
                          size: '25px',
                          // height: 'fit-content',
                          backgroundColor: '#379bff',
                          marginRight: '2px',
                        }}
                      />
                      <GoArrowDown
                        onClick={() => fields.move(index, index + 1)}
                        color="white"
                        style={{
                          cursor: 'pointer',
                          size: '25px',
                          // height: 'fit-content',
                          backgroundColor: '#379bff',
                          marginLeft: '2px',
                        }}
                      />
                    </div>
                    <ButtonContainer onClick={() => fields.remove(index)}>
                      <FaTrash color="white" />
                    </ButtonContainer>
                  </CandidateElementHeader>
                  <FormGroup>
                    <SelectInput
                      label="Arbeiter"
                      name={`projectElements[${index}].users`}
                      options={[
                        { value: 2, label: 'Alma Amira' },
                        { value: 42, label: 'Hans Müller' },
                      ]}
                      placeholder=" "
                    />
                  </FormGroup>
                  {/* <FormGroup>
                    <TextInput label="Interview-Termin" name={`projectElements[${index}].foo`} />
                  </FormGroup>
                  <ToggleButton
                    marginTop="15px"
                    name="focused"
                    onMessage="wurde angenommen"
                    offMessage="wurde nicht angenommen"
                  /> */}
                </CandidateElementContainer>
                <hr />

                <FormGroup>
                  <TextArea label="Notizen" name={`projectElements[${index}].foo`} />
                </FormGroup>
              </div>
            </ProjectElementContainer>
          ))}
        </div>
      )}
    </FieldArray>
  </div>
);

export default ProjectElements;
