import React from 'react';
import { gql } from 'apollo-boost';
// import { Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import TableReceipt from './Receipt.Table';
// import createMutationHandler from '../../shared/create-mutationhandler';
import Loading from '../../molecules/Loading';
import ButtonBox from '../../atoms/ButtonBox';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';

const QUERY = gql`
  query Receipts($where: ReceiptWhereInput) {
    receipts(where: $where) {
      id
      name
      receiptDate
      createdAt
      updatedAt
    }
  }
`;

const ReceiptTableContainer = ({ history }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: {} },
    fetchPolicy: 'network-only',
  });

  if (queryLoading) {
    return <Loading />;
  }

  const { receipts = [] } = queryData;

  return (
    <div>
      <h1>Belege</h1>
      <hr />
      <div style={{ display: 'flex' }}>
        <ButtonBox
          name="button-create-customer"
          theme="primary"
          icon="file"
          text="Neuen Beleg"
          style={{ marginRight: '10px', marginBottom: '20px' }}
          onClick={() => history.push(`/belege/create`)}
        />
        <ButtonBox
          name="button-receipt-statistics"
          theme="primary"
          icon="bar-chart"
          text="Statistiken"
          style={{ marginRight: '10px', marginBottom: '20px' }}
          onClick={() => history.push(`/belege/statistics`)}
        />
        <ButtonBox
          name="button-receipt-supplier"
          theme="primary"
          icon="truck"
          text="Lieferanten"
          style={{ marginRight: '10px', marginBottom: '20px' }}
          onClick={() => history.push(`/receipt-suppliers`)}
        />
        <ButtonBox
          name="button-receipt-statistics"
          theme="primary"
          icon="tag"
          text="Kategorien"
          style={{ marginRight: '10px', marginBottom: '20px' }}
          onClick={() => history.push(`/receipt-categories`)}
        />
      </div>
      <div>
        <ErrorGraphQL error={queryError} />
        <TableReceipt data={receipts} />
      </div>
    </div>
  );
};

export default withRouter(ReceiptTableContainer);
