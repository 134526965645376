import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/de';
import { Default, Mobile } from '../../../molecules/Responsive';

import TimeListMobile from './TimeList.Mobile';
import TimeListDefault from './TimeList.Default';
import DynamicTimeListDefault from './Dynamic.TimeList.Default';
import DynamicTimeListMobile from './Dynamic.TimeList.Mobile';
import MetaInfoBox from '../../../molecules/MetaInfoBox';
import { ContentContainer } from '../../../shared/styles';
import { Label, Content } from './style';
import { getWorkingDays, getWorkingHours, getDynamicWorkingDays } from './time-tracker-utils';

// -------------------- HELPERS --------------------
const getNewArtificalId = () => new Date().valueOf() + Math.random();

const currentDay = new Date().getDate();

const DEFAULT_TIME_TRACK = {
  day: currentDay,
  end: '',
  start: '',
  activity: '',
  location: '',
  lunchBreak: 0,
};
// -------------------- HELPERS --------------------

const TimetrackerListDumb = ({ timetrack, tracks, submitButtonText, submitHandler }) => {
  const [dynamicTimetrack, setDynamicTimetrack] = useState(DEFAULT_TIME_TRACK);
  const [tracksState, setTimetracks] = useState([{ artificalId: getNewArtificalId(), ...DEFAULT_TIME_TRACK }]);
  const [clipboard, setClipboard] = useState(null);

  const { month, year, timetrackType } = timetrack;
  const daysOfMonth = moment(`${month} ${year}`, 'MM YYYY').endOf('month').format('DD');

  // SET TIMETRACK - CREATE TIMETRACK DATA FOR VIEW
  useEffect(() => {
    // ------------------ DYNAMIC TIMETRACK --------------------
    if (timetrackType === 'DYNAMIC') {
      if (tracks && Array.isArray(tracks) && tracks.length > 0) {
        setTimetracks(tracks.map((track) => ({ ...track, artificalId: getNewArtificalId() })));
      }
    }

    // ------------------ FULL MONTH TIMETRACK --------------------
    if (timetrackType === 'FULL_MONTH') {
      const fullMonthTracks = Array(Number(daysOfMonth))
        .fill()
        .map((_, i) => i + 1)
        .reduce((accu, day) => {
          if (tracks.some((track) => track.day.toString() === day.toString())) {
            const activeTrack = tracks.filter((track) => track.day === day)[0];
            return [...accu, { ...activeTrack, active: true }];
          }

          return [
            ...accu,
            {
              day,
              active: false,
              start: '',
              end: '',
              lunchBreak: 60,
              activity: '',
              location: '',
            },
          ];
        }, []);

      setTimetracks(fullMonthTracks);
    }
  }, [daysOfMonth, tracks]);

  // -------------------------- DYNAMIC TIMETRACK FUNCTIONS ---------------------------------
  const updateDynamicTimetrack = (key) => (value) => {
    setDynamicTimetrack((prevState) => ({ ...prevState, [key]: value }));
  };

  const updateDynamicTimetrackById = (id, key) => (value) => {
    setTimetracks((prevState) =>
      prevState.map((track) => {
        if (track.artificalId === id) {
          return { ...track, [key]: value };
        }

        return track;
      })
    );
  };

  const sortTimeTracks = () => {
    setTimetracks((prevState) => [
      ...prevState.sort((a, b) => {
        const firstElementDay = a.day;
        const secondElementDay = b.day;

        const firstElementStart = parseInt(a.start.replace(':', ''));
        const secondElementStart = parseInt(b.start.replace(':', ''));

        if (firstElementDay > secondElementDay) {
          return 1;
        }
        if (firstElementDay < secondElementDay) {
          return -1;
        }

        if (firstElementDay === secondElementDay) {
          if (firstElementStart > secondElementStart) {
            return 1;
          }

          if (firstElementStart < secondElementStart) {
            return -1;
          }

          return 0;
        }
      }),
    ]);
  };

  const addNewDynamicTimeTrack = () => {
    setTimetracks((prevState) => [...prevState, { ...dynamicTimetrack, artificalId: getNewArtificalId() }]);
    setDynamicTimetrack(DEFAULT_TIME_TRACK);
    sortTimeTracks();
  };

  const deleteDynamicDay = (index) => {
    setTimetracks((prevState) => prevState.filter((track, tracksIndex) => index !== tracksIndex));
  };
  // -------------------------- DYNAMIC TIMETRACK FUNCTIONS ---------------------------------

  // update month day / update timeTrack of month
  const updateValue = (key, day) => (value) => {
    setTimetracks((previousState) =>
      previousState.map((workingDay) => {
        if (workingDay.day.toString() === day.toString()) {
          return { ...workingDay, [key]: value };
        }
        return workingDay;
      })
    );
  };

  // ---------------- HANDLERS - COPY - PASTE - DELETE DAY ------------------
  const copyDay = (day) => {
    const copiedDay = tracksState.find((track) => track.day.toString() === day.toString());
    setClipboard(copiedDay);
  };

  const pasteDay = (day) => {
    const updatedDays = tracksState.map((track) => {
      if (track.day.toString() === day.toString() && clipboard) {
        return { ...clipboard, day };
      }
      return track;
    });

    setTimetracks(updatedDays);
  };

  const deleteDay = (day) => {
    const filteredDays = tracksState.filter((track) => track.day.toString() !== day.toString());

    filteredDays.push({
      day,
      start: '',
      end: '',
      lunchBreak: 60,
      activity: '',
      location: '',
    });

    setTimetracks(filteredDays);
  };
  // ---------------- COPY - PASTE - DELTE DAY ------------------

  const addDay = (day) => {
    setTimetracks((previousState) =>
      previousState.map((track) => {
        if (!track.active && track.day === day && track.start.length > 3 && track.end.length > 3) {
          const previousDayWithContent =
            tracksState.filter((trackDay) => trackDay.activity.trim().length > 0).reverse()[0] || {};

          return {
            ...track,
            active: true,
            activity: previousDayWithContent.activity || '',
            location: previousDayWithContent.location || '',
          };
        }
        return track;
      })
    );
  };

  // SUBMIT - WRITE IN DATABASE
  // WAS IGNORIERT WERDEN SOLL - FILTERE EINGABE, WAS RAUS SOLL WAS IN DB REIN SOLL
  const handleSubmit = () => {
    if (timetrackType === 'FULL_MONTH') {
      const filteredTracks = tracksState.reduce((accu, { active, day, start, end, activity, location, lunchBreak }) => {
        if (active && start && end) {
          return [
            ...accu,
            {
              day: parseInt(day),
              start,
              end,
              lunchBreak: lunchBreak ? parseInt(lunchBreak) : 0,
              activity,
              location,
            },
          ];
        }
        return accu;
      }, []);

      submitHandler(filteredTracks);
    }

    if (timetrackType === 'DYNAMIC') {
      const filteredDynamicDays = tracksState.reduce((accu, { day, start, end, activity, location, lunchBreak }) => {
        if (start && end) {
          return [
            ...accu,
            {
              day: parseInt(day),
              start,
              end,
              lunchBreak: lunchBreak ? parseInt(lunchBreak) : 0,
              activity,
              location,
            },
          ];
        }
        return accu;
      }, []);

      submitHandler(filteredDynamicDays);
    }
  };

  const getCustomer = () => {
    const { customer = {} } = timetrack;
    return customer.typ === 'Firma' ? `${customer.firmenname}` : `${customer.vorname} ${customer.name}`;
  };

  const getManager = () => {
    const { manager = {} } = timetrack;
    if (!manager) {
      return 'Kein Projektverantwortlicher';
    }
    return `${manager.firstname || ''} ${manager.lastname || ''}`;
  };

  return (
    <>
      <MetaInfoBox
        columns={[
          [
            { label: 'ID', value: `${timetrack.timetrackId} - ${timetrack.name}` },
            { label: 'Leistungsbringer', value: `${timetrack.owner.firstname} ${timetrack.owner.lastname}` },
            { label: 'Zeitraum', value: `${timetrack.year} - ${timetrack.month}` },
            {
              label: 'Erstellt von',
              value: `${timetrack.createdBy.firstname} ${timetrack.createdBy.lastname}`,
              style: { marginTop: '7px' },
            },
            { label: 'Erstellt am', value: timetrack.createdAt },
          ],
          [
            { label: 'Kunde', value: getCustomer() },
            { label: 'Projektverantwortlicher', value: getManager() },
            {
              label: 'Geändert von',
              value: `${timetrack.updatedBy.firstname} ${timetrack.updatedBy.lastname}`,
              style: { marginTop: '25px' },
            },
            { label: 'Geändert am', value: timetrack.updatedAt },
          ],
        ]}
      />
      <ContentContainer
        style={{
          justifyContent: 'space-between',
          fontSize: '12px',
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <div style={{ display: 'flex' }}>
          <Label>Gearbeitete Tage:</Label>
          <Content>
            {timetrack.timetrackType === 'FULL_MONTH'
              ? getWorkingDays(tracksState)
              : getDynamicWorkingDays(tracksState)}
          </Content>
        </div>
        <div style={{ display: 'flex' }}>
          <Label>Gesamt-Stunden:</Label>
          <Content>{getWorkingHours(tracksState)}</Content>
        </div>
      </ContentContainer>
      <Default>
        {timetrackType === 'FULL_MONTH' && (
          <TimeListDefault
            timetrack={timetrack}
            updateValue={updateValue}
            copyDay={copyDay}
            pasteDay={pasteDay}
            deleteDay={deleteDay}
            addDay={addDay}
            submitButtonText={submitButtonText}
            onSubmit={handleSubmit}
            year={year}
            month={month}
            tracks={tracksState}
            daysOfMonth={daysOfMonth}
          />
        )}

        {timetrackType === 'DYNAMIC' && (
          <DynamicTimeListDefault
            timetrack={timetrack}
            //
            dynamicTimetrack={dynamicTimetrack} // default single timetrack, contains default timetrack for creating a new one
            updateTimeTrack={updateDynamicTimetrack} // updates the single one if you want to add it
            addNewTimeTrack={addNewDynamicTimeTrack}
            //
            updateDynamicTimetrackById={updateDynamicTimetrackById}
            deleteDynamicDay={deleteDynamicDay}
            sortTimeTracks={sortTimeTracks}
            //
            tracks={tracksState}
            submitButtonText={submitButtonText}
            onSubmit={handleSubmit}
            year={year}
            month={month}
            daysOfMonth={daysOfMonth}
          />
        )}
      </Default>

      {/* --------------------------------- MOBILE --------------------------------- */}
      <Mobile>
        {timetrackType === 'FULL_MONTH' && (
          <TimeListMobile
            timetrack={timetrack}
            updateValue={updateValue}
            copyDay={copyDay}
            pasteDay={pasteDay}
            deleteDay={deleteDay}
            //
            addDay={addDay}
            submitButtonText={submitButtonText}
            onSubmit={handleSubmit}
            //
            year={year}
            month={month}
            tracks={tracksState}
            daysOfMonth={daysOfMonth}
          />
        )}

        {timetrackType === 'DYNAMIC' && (
          <DynamicTimeListMobile
            timetrack={timetrack}
            //
            dynamicTimetrack={dynamicTimetrack} // default single timetrack, contains default timetrack for creating a new one
            updateTimeTrack={updateDynamicTimetrack} // updates the single one if you want to add it
            addNewTimeTrack={addNewDynamicTimeTrack}
            //
            updateDynamicTimetrackById={updateDynamicTimetrackById}
            deleteDynamicDay={deleteDynamicDay}
            sortTimeTracks={sortTimeTracks}
            //
            tracks={tracksState}
            submitButtonText={submitButtonText}
            onSubmit={handleSubmit}
            year={year}
            month={month}
            daysOfMonth={daysOfMonth}
          />
        )}
      </Mobile>
    </>
  );
};

export default TimetrackerListDumb;
