import React from 'react';
import { gql } from 'apollo-boost';
import { Query } from 'react-apollo';

import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

const QUERY_RECEIPTS = gql`
  query Receipts($where: ReceiptWhereInput) {
    receipts(where: $where) {
      receiptId
      name
      receiptDate
      positions {
        amount
      }
    }
  }
`;

// ------------------------- HELPERS -------------------------
const calcSumOfPositions = (positions) =>
  !positions || !positions[0] //
    ? 0 //
    : positions.reduce((acc, position) => acc + position.amount, 0);
// ------------------------- HELPERS -------------------------

const createBarData = (receipts = []) => {
  // const amountByMonth = receipts.reduce((accu, receipt) => {
  //   const month = receipt.receiptDate ? new Date(receipt.receiptDate).getMonth() : null;
  //   const amount = month ? receipt.amount : 0;

  //   if (accu[month]) {
  //     return {
  //       ...accu,
  //       [month]: accu[month] + amount,
  //     };
  //   }
  //   return {
  //     ...accu,
  //     [month]: amount,
  //   };
  // }, {});

  // contains summ of all positions of month
  // const monthArray = Array.from({ length: 12 }, (_, i) => {
  //   if (amountByMonth[i + 1]) {
  //     return amountByMonth[i + 1];
  //   }
  //   return 0;
  // });

  // just an array with 12 elements
  const yearArray = Array.from({ length: 12 });

  const sumOfMonths = receipts.reduce((accu, receipt) => {
    const monthOfReceipt = new Date(receipt.receiptDate).getMonth() + 1;
    const sumOfPositionsOfMonth = calcSumOfPositions(receipt.positions);
    const newSummOfPositionsOfMOnth = accu[monthOfReceipt] //
      ? accu[monthOfReceipt] + calcSumOfPositions(receipt.positions)
      : sumOfPositionsOfMonth;

    return {
      ...accu,
      [monthOfReceipt]: newSummOfPositionsOfMOnth,
    };
  }, {});

  const finalSummOfYear = yearArray.map((element, index) => {
    if (!sumOfMonths[index + 1]) {
      return 0;
    }

    return sumOfMonths[index + 1];
  });

  // console.log('DAS IST ARRAY: ', finalSummOfYear);

  const barData = {
    labels: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'Septembber',
      'Oktober',
      'November',
      'Dezember',
    ],
    datasets: [
      {
        label: 'Ausgaben',
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        scaleStartValue: 0,

        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        hoverBorderColor: 'rgba(255,99,132,1)',
        // data: [65, 59, 80, 81, 56, 55, 40],
        // data: monthArra
        // data: [3000, 2534],
        data: finalSummOfYear,
      },
    ],
  };

  return barData;
};

// --------------- HELPERS ---------------
const centsToEuro = (cents) =>
  cents > 0 // undefined > 0 IS false
    ? `${(cents / 100) //
        .toFixed(2) //
        .toString() //
        .replace('.', ',')} €` //
    : '0,00 €';
// --------------- HELPERS ---------------

const ReceiptsStatistics = ({ history, match }) => (
  <>
    {/* <Query query={QUERY_RECEIPTS} variables={{ where: { receiptYear: 2018 } }}> */}
    <Query query={QUERY_RECEIPTS} variables={{ where: {} }}>
      {({ data }) => {
        const result = data || {};

        return (
          <div>
            <h1>Alle Beleg-Ausgaben {match.params.year}</h1>
            {/* <pre>{JSON.stringify(result, null, '  ')}</pre> */}
            <Bar
              // data={createBarData(data.receipts)}
              data={createBarData(result.receipts)}
              width={100}
              height={50}
              options={{
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        min: 0,
                        callback: centsToEuro,
                      },
                    },
                  ],
                },
                plugins: {
                  datalabels: {
                    display: true,
                    color: 'grey',
                    formatter: centsToEuro,
                    anchor: 'end',
                    align: 'end',
                    offset: 10,
                  },
                },
                tooltips: {
                  displayColors: false,
                  titleFontSize: 16,
                  bodyFontSize: 14,
                  xPadding: 10,
                  yPadding: 10,
                  callbacks: {
                    // label: (tooltipItem, data) => `$ ${tooltipItem.value}`,
                    label: (tooltipItem, data) => centsToEuro(tooltipItem.value),
                  },
                },
              }} // END OF OPTIONS
            />
          </div>
        );
      }}
    </Query>
  </>
);

ReceiptsStatistics.propTypes = {
  // fetchBillsOfCustomerAction: PropTypes.func,
};

ReceiptsStatistics.defaultProps = {
  // fetchBillsOfCustomerAction: () => {},
};

export default ReceiptsStatistics;
