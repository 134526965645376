import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';

export const DropArea = styled.div`
  display: flex;
  cursor: pointer;
  grid-area: picture-area;
  background-color: #dce5e9;
  padding: 10px;
  justify-content: center;
  width: 400px;
  height: 566px;
  color: grey;
  border-style: dashed;
  overflow: auto;
`;

const DragAndDropContent = ({ file, prev, isDragActive }) => {
  // SHOW FILES COMES FROM DROPZONE/Brower/that you uploaded
  if (file && file.fileBlob) {
    switch (file.fileBlob.type) {
      case 'image/jpeg':
        return (
          <img
            alt=""
            src={prev}
            style={{
              width: '400px',
            }}
          />
        );

      case 'image/jpg':
        return (
          <img
            alt=""
            src={prev}
            style={{
              width: '400px',
            }}
          />
        );

      case 'image/png':
        return (
          <img
            alt=""
            // src={preview}
            src={prev}
            style={{
              width: '400px',
            }}
          />
        );

      case 'application/pdf':
        return (
          <iframe
            title="file"
            aria-label=""
            style={{
              cursor: 'pointer',
              width: '390px',
              height: '566px',
            }}
            width="390"
            height="566"
            src={prev}
            type="application/pdf"
          />
        );

      default:
        return (
          <div>
            <b>Unbekannter Datentyp!</b>
            <div>Nur JPG, JPEG, PNG und PDF sind erlaubt</div>
          </div>
        );
    }
  }

  // SHOW FILES COMES FROM SERVER
  // das ist der fall wenn wir eine url haben, also eine datei die im server ist
  // und keine url die wir mit File und URL.createObjectUrl(File) erstellen müssen
  // wenn file.path auf eine url zeigt die nicht existiert, kommt die frontend seite rein
  // SOLUTION: check of filetype: wenn die url keine ändung hat ala jpeg, jpg, png oder pdf DANN zeige fehler
  // TODO: change file.url and file.fileBlob to preview and put that in state to prevent flickering
  if (file && file.url) {
    const typeOfFile = file.url.split('.').splice(-1)[0];

    if (typeOfFile === 'png' || typeOfFile === 'jpg' || typeOfFile === 'jpeg') {
      return <img alt="" src={file.url} style={{ width: '400px' }} />;
    }

    // WORKS
    // return <object data={file.url} width="800px" height="2100px" aria-label="" />;

    return <iframe title="pdf" src={file.url} width="390" height="566" type="application/pdf" />;
  }

  return isDragActive ? (
    <h4 style={{ marginTop: '30px' }}>Belegdatei hier loslassen ...</h4>
  ) : (
    <div>
      <p>Klicken um Belegdatei zu wählen oder Belegdatei hier rein ziehen</p>
      <p>
        <b>Erlaubte Dateien:</b> PDF, JPEG, PNG
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <FontAwesome size="5x" name="file-pdf-o" style={{ marginRight: '20px' }} />
        <FontAwesome size="5x" name="file-picture-o" />
        <FontAwesome size="5x" name="file-text-o" style={{ marginLeft: '20px' }} />
      </div>
    </div>
  );
};

const ReceiptDnDFile = ({ onChange, file = {} }) => {
  const [prev, setPrev] = React.useState();

  const onDrop = useCallback((acceptedFiles) => {
    const fileObj = acceptedFiles[0];
    onChange(fileObj);
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (file && file.fileBlob) {
      setPrev(URL.createObjectURL(file.fileBlob));
    }
  }, [file]);

  return (
    <DropArea {...getRootProps({ refKey: 'innerRef' })}>
      <input style={{ width: '100%', height: '100%' }} {...getInputProps()} />
      <div>
        <DragAndDropContent file={file} prev={prev} isDragActive={isDragActive} />
      </div>
    </DropArea>
  );
};

export default ReceiptDnDFile;
