export default (errors) => {
  // ORIGINAL ERROR SHAPE:
  // errors = [{property: '', 'message: ''}]
  // the reason why the errors have this shape from the server is because
  // we only need the InvalidData type must nust be Error = {property: String, message: String}
  // we can extend it in the server, because no matter what come in, the property is just a string
  // if we would have something like: ValiadtionError: [Error] it must be then
  // Error = {name: String, email: String, futureKey: String}
  // so when something new comes in, we always have to update that
  const clientErrors = errors.reduce(
    (accu, error) => ({
      ...accu,
      [error.property]: error.message,
    }),
    {}
  );

  return clientErrors;
};
