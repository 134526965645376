/* eslint-disable react/display-name */
import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import FormGroup from '../../atoms/FormGroup';
import FormItem from '../../atoms/FormItem';
import Form from './Form';
import ButtonBox from '../../atoms/ButtonBox';
import MetaInfoBox from '../../molecules/MetaInfoBox';

import Loading from '../../molecules/Loading';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import ErrorNoData from '../../../errors/ErrorNoData';
import createMutationHandler from '../../shared/create-mutationhandler';

import Title from '../../molecules/Title';

const QUERY = gql`
  query GetBankAccount($where: BankAccountWhereInput) {
    bankAccount(where: $where) {
      id
      name
      iban
      bankname
      salutation
      firstname
      lastname
      street
      houseNo
      plz
      bic
      city
      telefon
      fax
      email
      accountNo
      blz
      note
      createdBy {
        firstname
        lastname
      }
      updatedBy {
        firstname
        lastname
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation Update($where: BankAccountWhereUpdateInput, $data: BankAccountUpdateInput) {
    updateBankAccount(where: $where, data: $data) {
      __typename
      ... on BankAccount {
        id
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
      ... on Unauthorized {
        message
      }
      ... on AlreadyExists {
        message
        errors {
          property
          message
        }
      }
    }
  }
`;

const CustmerFormUpdate = ({ history, match }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: { id: match.params.id } },
    fetchPolicy: 'network-only',
  });
  const [updateMutation, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_MUTATION);

  const handleUpdateMuation = (mutation) => {
    const handledUpdateMuation = createMutationHandler({
      mutation,
      mutationName: 'updateBankAccount',
      mutationReturnTypes: {
        BankAccount: {
          successMessage: (data) => `Konto mit der ID ${data.id}, wurde erfolgreich verändert`,
          onSuccess: () => history.push('/bankaccounts'),
        },
      },
      serverErrorMessage: `Änderung des Kontos nicht erfolgreich`,
    });

    return (values) =>
      handledUpdateMuation({
        variables: {
          where: { id: match.params.id },
          data: values,
        },
      });
  };

  // ---------------------------- DATA AND STATUS ----------------------------
  const { bankAccount = null } = queryData;

  if (queryLoading) {
    return <Loading />;
  }

  if (!bankAccount) {
    return <ErrorNoData onHome={() => history.push('/')} onRefresh={() => history.go(0)} />;
  }

  const { createdBy = {}, updatedBy = {}, createdAt, updatedAt } = bankAccount;
  // -------------------------------------------------------------------------

  return (
    <fieldset disabled={updateLoading}>
      <FormGroup>
        <FormItem>
          <Title>Konto bearbeiten</Title>
        </FormItem>
      </FormGroup>
      <hr />
      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={updateError} />
      <ButtonBox
        theme="primary"
        icon="arrow-left"
        text="Zurück zu Bankkonten"
        style={{ margin: '0px 10px 20px 0px' }}
        onClick={() => history.goBack()}
      />
      <MetaInfoBox
        columns={[
          [{ label: 'ID', value: bankAccount.id }],
          [
            { label: 'Erstellt von', value: `${createdBy.firstname} ${createdBy.lastname}` },
            { label: 'Erstellt am', value: createdAt },
          ],
          [
            { label: 'Geändert von', value: `${updatedBy.firstname} ${updatedBy.lastname}` },
            { label: 'Geändert am', value: updatedAt },
          ],
        ]}
      />
      <Form
        data={bankAccount}
        submitButtonText="Bankänderungen speichern"
        mutation={handleUpdateMuation(updateMutation)}
      />
    </fieldset>
  );
};

CustmerFormUpdate.propTypes = {};
CustmerFormUpdate.defaultProps = {};

export default CustmerFormUpdate;

// onLockClick -> onLock
// makeMutationHandler
