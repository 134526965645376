import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import CustomerForm from './Form';
import FormGroup from '../../atoms/FormGroup';
import FormItem from '../../atoms/FormItem';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';

import Loading from '../../molecules/Loading';
import createMutationHandler from '../../shared/create-mutationhandler';

const CREATE_MUTATION = gql`
  mutation CreateRecruiter($data: RecruiterCreateInput) {
    createRecruiter(data: $data) {
      __typename
      ... on Recruiter {
        id
        name
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

const RecruiterCreateContainer = ({ match, history }) => {
  const [createMutation, { error: createError, loading: createLoading }] = useMutation(CREATE_MUTATION);

  // CREATE-MUTATION
  const handleCreateMutation = (mutation) => {
    const handledCreateMutation = createMutationHandler({
      mutation,
      mutationName: 'createRecruiter',
      mutationReturnTypes: {
        Recruiter: {
          successMessage: (data) => `Recruiter mit der ID ${data.id} erfolgreich erstellt`,
          onSuccess: () => history.push('/recruiters'),
        },
      },
      serverErrorMessage: `Erstellung des Recruiter nicht erfolgreich`,
    });

    return (values) =>
      handledCreateMutation({
        variables: {
          data: values,
        },
      });
  };

  if (createLoading) {
    return <Loading />;
  }

  return (
    <fieldset disabled={createLoading}>
      <FormGroup>
        <FormItem>
          <h2 name="form-headline">Neuen Begünstigten erstellen</h2>
        </FormItem>
      </FormGroup>

      <ErrorGraphQL error={createError} />
      <CustomerForm submitButtonText="Neuen Begünstigten erstellen" mutation={handleCreateMutation(createMutation)} />
    </fieldset>
  );
};

export default RecruiterCreateContainer;
