import React, { useState, useRef } from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import Label from '../../atoms/Label';
import withField from '../../HOC/with-field';

const StyleContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 180px;
  margin: 10px;
`;

// TODO: use styled componentsn
const errorMessageStyle = {
  display: 'flex',
  color: '#cc0000',
  marginTop: '10px',
  fontWeight: 'bold',
};

// TODO: use styled componentsn
const errorsIconStyle = {
  display: 'flex',
  color: '#cc0000',
  marginTop: '8px',
  marginRight: '3px',
};

const ErrorMessage = ({ message, name }) => (
  <div>
    {typeof message === 'object' && (
      <div style={{ display: 'flex' }}>
        <ErrorIcon style={errorsIconStyle} />
        <div name={name} style={errorMessageStyle}>
          {Object.values(message)[0]}
        </div>
      </div>
    )}

    {typeof message === 'string' && (
      <div style={{ display: 'flex' }}>
        <ErrorIcon style={errorsIconStyle} />
        <div name={name} style={errorMessageStyle}>
          {message}
        </div>
      </div>
    )}
  </div>
);

const InputField = styled.input`
  width: 100%;
  padding: 5px;
  border-style: solid;
  border-width: ${(props) => (props.hasError ? '3px' : '1px')};
  border-color: ${(props) => (props.hasError ? '#cc0000' : '#BFBFBF')};
`;

// -------------- HELPER FUNCTIONS --- START
const isCurrency = (value) => /^[0-9]+(\.*|,*)[0-9]{0,2}$/gm.test(value);

const centsToEuro = (value) => (!isCurrency(value) ? '0,00' : (Number(value) / 100).toFixed(2).replace('.', ','));

const setExportValue = (value) => (!isCurrency(value) ? 0 : Number(value.replace(',', '.')) * 100);
// -------------- HELPER FUNCTIONS --- END

//
//
//
const EuroInput = ({ input, meta, label, type, autoFocus, formatter, margin = '10px' }) => {
  const [viewData, setViewData] = useState(centsToEuro(input.value));
  const lastChange = useRef(input.value);

  const handleOnChange = (event) => {
    const textInput = event.target.value;
    setViewData(textInput);
  };

  const handleOnBlur = (event) => {
    const inputText = event.target.value;
    lastChange.current = setExportValue(inputText);
    // 1. initial value from parent is 123
    // 2. children get useState() get initial values
    // 3. input.value and lastChange.current are not equal => vieData is used for input presentation
    // CASE: input changes
    // 1. input changes => lastChange.current MUST also change
    // WHY? because if we dont change lastChange.current, input.value === lastChange.current will be always false => the last current input.value will be used on every change
    // input.onChange(setExportValue(inputText));
    // BETTER: shows the reader that lastChange.current and onChange value must be the same
    input.onChange(lastChange.current);
    setViewData(isCurrency(inputText) ? Number(inputText.replace(',', '.')).toFixed(2).replace('.', ',') : '0,00');
  };

  // DAMIT ES UPDATED WENN ELTERN STATE UPDATED
  const outputValue = input.value === lastChange.current ? viewData : centsToEuro(input.value);

  if (input.value !== lastChange.current) {
    lastChange.current = input.value;
    setViewData(centsToEuro(input.value));
    input.onChange(input.value);
  }

  const { error, submitError, touched } = meta;

  return (
    <StyleContainer style={{ margin }}>
      {label && <Label text={label} name={`label-${input.name}`} />}
      <InputField
        {...input}
        style={{ textAlign: 'right' }}
        hasError={(error && touched) || submitError}
        autoFocus={autoFocus}
        value={outputValue}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
      />
      {error && touched && <ErrorMessage message={error} name={`error-message-${input.name}`} />}
      <ErrorMessage message={submitError} />
    </StyleContainer>
  );
};

export default withField(EuroInput);
