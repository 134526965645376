import React from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import App from '../components/environment/App';

const MainRoute = () => (
  <Switch>
    <Redirect from="/login" to="/member-area" />
    <Route path="*" render={() => <App />} />
  </Switch>
);

export default withRouter(MainRoute);
