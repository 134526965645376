import React from 'react';
import PropTypes from 'prop-types';

export default function KundeKontaktColumn(column, row) {
  const kunde = row;
  return (
    <div>
      {kunde.strasse} {kunde.hausnr}
      <br />
      {kunde.plz || ''} {kunde.ort}
    </div>
  );
}

KundeKontaktColumn.propTypes = {
  strasse: PropTypes.string,
  hausNr: PropTypes.string,
  plz: PropTypes.string,
  ort: PropTypes.string,
};

KundeKontaktColumn.defaultProps = {
  strasse: '',
  hausnr: '',
  plz: '',
  ort: '',
};
