import React from 'react';

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);

    console.log('ERROR_BOUNDARY ERROR: ', error);
    console.log('ERROR_BOUNDARY INFO: ', JSON.stringify(info, null, '  '));

    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Etwas ist schiefgegangen!</h1>;
    }
    // return this.props.children;
    return this.props.children;
  }
}

export default ErrorBoundary;
