const allowedAttributes = [
  'timetrackType',
  'name',
  'year',
  'month',
  'customer',
  'manager',
  'owner',
  'wage',
  'provision',
  'notes',
  'isHidden',
  'isLocked',
  'isManagerSigned',
  'isFreelancerSigned',
  'signMailSend',
  'signMailSendTo',
];

export default allowedAttributes;
