import React from 'react';
import styled from 'styled-components';
import { FaLock } from 'react-icons/fa'

const Locked = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: center;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  background-color: red;
  color: white;
  width: 400px;
  margin-bottom: 10px;
  padding: 5px;
`;

const LockedTimeList = () => (
  <Locked>
    <FaLock style={{ marginRight: '10px' }} />
    <div>Änderungen für Leistungsbringer gesperrt</div>
  </Locked>
);

export default LockedTimeList;
