import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import ErrorIcon from '@material-ui/icons/Error';
import FontAwesome from 'react-fontawesome';
import Label from '../../atoms/Label';
import withField from '../../HOC/with-field';

const StyleContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 180px;
  /* margin: 10px; */
`;

const errorMessageStyle = {
  display: 'flex',
  color: '#cc0000',
  marginTop: '10px',
  fontWeight: 'bold',
};

const errorsIconStyle = {
  display: 'flex',
  color: '#cc0000',
  marginTop: '8px',
  marginRight: '3px',
};

const ErrorMessage = ({ message }) => (
  <div>
    {message && (
      <div style={{ display: 'flex' }}>
        <ErrorIcon style={errorsIconStyle} />
        <div style={errorMessageStyle}>{message}</div>
      </div>
    )}
  </div>
);

const SelectField = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 9px;
  font-size: 16px;
  background-color: white;
  border-radius: 0px;
  border-style: solid;
  border-width: ${(props) => (props.hasError ? '3px' : '1px')};
  border-color: ${(props) => (props.hasError ? '#cc0000' : '#BFBFBF')};
`;

const CustomInputComponent = ({ input, meta, label, options = [], 'data-testid': dataTestid, margin = '10px' }) => {
  const { error, submitError } = meta;

  return (
    <StyleContainer style={{ margin }}>
      {label && <Label text={label} name={`label-${input.name}`} />}
      <div style={{ position: 'relative' }}>
        <SelectField {...input} data-testid={dataTestid} hasError={error || submitError}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </SelectField>
        <span
          style={{
            position: 'absolute',
            pointerEvents: 'none',
            right: '10px',
            top: '7px',
            color: '#BFBFBF',
          }}
        >
          <FontAwesome name="chevron-down" />
        </span>
      </div>
      <ErrorMessage message={error} />
      <ErrorMessage message={submitError} />
    </StyleContainer>
  );
};

export default withField(CustomInputComponent);
