import React, { useContext } from 'react';
import styled from 'styled-components';
import { TabsContext } from './Tabs';
import Tab from './Tab';

const TabHeaders = props => {
  const { activeIndex, contextDispatch } = useContext(TabsContext);
  const { children } = props;

  const headerChildren = React.Children.map(children, (child, index) => {
    const baseChild = {
      activeIndex,
      isActive: index === activeIndex,
      order: index,
    };

    const tabChild = {
      ...baseChild,
      order: index,
      onClick: () => {
        contextDispatch({ type: 'SET_ACTIVE_INDEX', payload: index });
      },
    };

    const newChild = child.type !== Tab ? baseChild : tabChild;

    return React.cloneElement(child, newChild);
  });

  return <div {...props}>{headerChildren}</div>;
};

const StyledTabHeaders = styled(TabHeaders)`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 700px) {
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export default StyledTabHeaders;
