import React from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';

const Label = styled.div`
  margin: 0 auto 10px;
  /* min-width: 470px; */
  /* min-width: 345px; */
  min-width: 290px;
  max-width: 1200px;

  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #494950;
`;

const ViewTitle = ({ children }) => {
  return <Label>{children}</Label>;
};

export default ViewTitle;

// Label.propTypes = {
//   text: PropTypes.string,
// };

// Label.defaultProps = {
//   text: 'NO-TEXT-IN-LABEL',
// };
