import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import createMutationHandler from '../../shared/create-mutationhandler';
import Loading from '../../molecules/Loading';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import ReceiptForm from './Receipt.Form';
import MetaInfoBox from '../../molecules/MetaInfoBox';

const QUERY = gql`
  query GetReceipt($where: ReceiptWhereInput) {
    receipt(where: $where) {
      id
      name
      country
      receiptDate
      description
      # supplier {
      #   supplierId
      #   name
      # }
      createdBy {
        firstname
        lastname
      }

      updatedBy {
        firstname
        lastname
      }
      createdAt
      updatedAt
      payOption {
        id
        name
      }
      supplier {
        id
        label
      }
      transaction {
        id
        buchungstag
        verwendungszweck
        buchungstext
        betrag
      }
      positions {
        id
        description
        amount
        category {
          id
          description
          ust
        }
      }
      files {
        id
        order
        url
      }
    }
    # FILES FOR SELECT FIELDS
    users(where: {}) {
      userId
      firstname
      lastname
    }
    receiptCategories(where: {}) {
      id
      description
      ust
    }
    bankBeneficiaries(where: {}) {
      id
      label
    }
    bankAccounts(where: {}) {
      id
      name
      iban
    }
    # receiptSuppliers(where: {}) {
    #   supplierId
    #   name
    # }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateReceipt($where: ReceiptWhereUpdateInput, $data: ReceiptUpdateInput) {
    updateReceipt(where: $where, data: $data) {
      __typename
      ... on Receipt {
        id
        name
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
      ... on Unauthorized {
        message
      }
      ... on AlreadyExists {
        message
      }
    }
  }
`;

const ReceiptUpdate = ({ history, match }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: { id: match.params.id } },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [updateMutation, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_MUTATION);

  const handleCreateMutation = (mutation) => {
    const handledUpdateMutation = createMutationHandler({
      mutation,
      mutationName: 'updateReceipt',
      mutationReturnTypes: {
        Receipt: {
          successMessage: (data) => `Beleg mit der ID ${data.id} namens ${data.name} erfolgreich verändert`,
          onSuccess: () => history.push('/belege'),
        },
      },
      serverErrorMessage: `Veränderung des Belegs nicht erfolgreich`,
    });

    return (values) =>
      handledUpdateMutation({
        variables: {
          where: { id: match.params.id },
          data: values,
        },
      });
  };

  // --------------------------- DATA STATUS ----------------------------------
  const {
    receipt = {},
    users = [],
    receiptCategories = [],
    receiptSuppliers = [],
    bankBeneficiaries,
    bankAccounts,
  } = queryData;
  const { createdBy, updatedBy, createdAt, updatedAt } = receipt;
  // console.log('DAS IST RECEIPT: ', receipt);

  //
  //
  //
  // --------------------------------------------------------------------------

  if (queryLoading) {
    return <Loading />;
  }

  return (
    <fieldset disabled={updateLoading}>
      <h1>Beleg bearbeiten</h1>
      <hr />
      <MetaInfoBox
        columns={[
          [{ label: 'ID', value: receipt.id }],
          [
            // { label: 'Erstellt von', value: `${createdBy.firstname} ${createdBy.lastname}` },
            { label: 'Erstellt von', value: `${createdBy.firstname} ${createdBy.lastname}` },
            { label: 'Erstellt am', value: createdAt },
          ],
          [
            { label: 'Geändert von', value: `${updatedBy.firstname} ${updatedBy.lastname}` },
            { label: 'Geändert am', value: updatedAt },
          ],
        ]}
      />
      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={updateError} />
      <ReceiptForm
        data={receipt}
        userList={users}
        bankBeneficiaryList={bankBeneficiaries}
        bankAccountList={bankAccounts}
        categoryList={receiptCategories}
        submitButtonText="Beleg verändern"
        mutation={handleCreateMutation(updateMutation)}
      />
    </fieldset>
  );
};
export default withRouter(ReceiptUpdate);
