import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const InputStyle = styled.input`
  width: 55px;
  padding-right: 6px;
  text-align: right;
  font-size: 16px;
  border-style: solid;
  border-radius: 0px;
  border-width: 1px;
  border-color: #bfbfbf;
`;

const TimeInput = ({ value, onChange, onBlur = () => {}, style = {} }) => {
  const [timeValue, setTimeValue] = useState('');

  useEffect(() => {
    setTimeValue(value);
  }, [value]);

  const getFormatedTime = (inputValue) => {
    const digitsOnlyRegEx = /^(2[0-3]|1[0-9]|0[0-9]|[0-9])$/m;
    const isDigitOnly = inputValue.match(digitsOnlyRegEx);

    const digitsWithColonRegEx = /^(2[0-3]|1[0-9]||0[0-9]|[0-9]):$/m;
    const isDigitWithColon = inputValue.match(digitsWithColonRegEx);

    const digitsWithColonAndSingleDigitRegEx = /^(2[0-3]|1[0-9]|0[0-9]|[0-9]):[0-5]$/m;
    const isDigitWithColonAndSingleDigit = inputValue.match(digitsWithColonAndSingleDigitRegEx);

    const digitsWithColonAndDigitsRegEx = /^(2[0-3]|1[0-9]|0[0-9]|[0-9]):[0-5][0-9]$/m;
    const isDigitWithColonAndDigits = inputValue.match(digitsWithColonAndDigitsRegEx);

    // 3 uhr, 4 uhr .... 9 Uhr
    const threeDigitsUnitRegEx = /^([3-9][0-5]|[3-9][0-5][0-9])$/m;
    const isThreeDigitsUnit = inputValue.match(threeDigitsUnitRegEx);

    const threeDigitsTensRegEx = /^(1[0-9][0-5]|2[0-3][0-5]|1[0-9][0-5][0-9]|2[0-3][0-5][0-9])$/m;
    const isThreeDigitsTens = inputValue.match(threeDigitsTensRegEx);

    const timeFromatRegEx = /^(2[0-3]:[0-5][0-9]|1[0-9]:[0-5][0-9]|[0-9]:[0-5][0-9])$/m;
    const isTimeFormat = inputValue.match(timeFromatRegEx);

    if (isDigitOnly) {
      return `${inputValue.padStart(2, '0')}:00`;
    }
    if (isDigitWithColon) {
      return `${inputValue}00`;
    }
    if (isDigitWithColonAndSingleDigit) {
      return `${inputValue}0`;
    }
    if (isDigitWithColonAndDigits) {
      return `${inputValue}`;
    }
    if (isThreeDigitsUnit) {
      if (inputValue.length < 3) {
        return `${inputValue.slice(0, 1).padStart(2, '0')}:${inputValue.slice(1)}0`;
      }
      return `${inputValue.slice(0, 1).padStart(2, '0')}:${inputValue.slice(1)}`;
    }
    if (isThreeDigitsTens) {
      if (inputValue.length < 4) {
        return `${inputValue.slice(0, 2).padStart(2, '0')}:${inputValue.slice(2)}0`;
      }
      return `${inputValue.slice(0, 2).padStart(2, '0')}:${inputValue.slice(2)}`;
    }
    if (isTimeFormat) {
      return inputValue;
    }

    return '';
  };

  const setValue = (inputValue) => {
    // onChange(getFormatedTime(inputValue));
    setTimeValue(inputValue);

    //
    onChange(inputValue);
  };

  // const getHour = () => value.split(':')[0];
  const formatTimeOnBlur = () => {
    setValue(getFormatedTime(timeValue));
    onBlur();

    //
    onChange(getFormatedTime(timeValue));
  };

  return (
    <div>
      <InputStyle
        value={timeValue}
        onChange={(event) => setValue(event.target.value)}
        onBlur={formatTimeOnBlur}
        style={style}
      />
    </div>
  );
};

export default TimeInput;
