import * as yup from 'yup';
import { setIn } from 'final-form';

const schema = yup.object({
  firstname: yup.string().when('type', {
    is: (val) => val === 'Mitarbeiter', //
    then: yup.string().required('Name muss eingegeben werde').min(3, 'Name muss mindestens 3 Zeichen haben'),
  }),
  lastname: yup.string().when('type', {
    is: (val) => val === 'Mitarbeiter', //
    then: yup.string().required('Name muss eingegeben werde').min(3, 'Name muss mindestens 3 Zeichen haben'),
  }),
  // wenn type "Firma" ist, dann muss firmenname ein string sein und min 3 zeichen haben
  firmenname: yup.string().when('type', {
    is: (val) => val === 'Firma', //
    then: yup.string().required('Vorname muss eingegeben werde').min(3, 'Vorname muss mindestens 3 Zeichen haben'),
  }),
  iban: yup.string().required('IBAN muss eingegeben werde').min(1, 'IBAN muss mindestens 1 Zeichen haben'),
  bic: yup.string().required('BIC muss eingegeben werde').min(1, 'BIC muss mindestens 1 Zeichen haben'),
});

const validator = async (values) => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (error) {
    return error.inner.reduce((errors, error) => setIn(errors, error.path, error.message), {});
  }
};

export default validator;
