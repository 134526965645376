import React from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import createMutationHandler from '../../shared/create-mutationhandler';
import CustomersTable from './Table.Focused';
import Loading from '../../molecules/Loading';
// import ButtonBox from '../../atoms/ButtonBox';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';

const QUERY = gql`
  query GetEmailProjects($where: EmailProjectWhereInput) {
    emailProjects(where: $where) {
      id
      subject
      from
      date
      focused
      completed
    }
  }
`;

const DELETE = gql`
  mutation DeleteCustomer($where: CustomerWhereDeleteInput) {
    deleteCustomer(where: $where) {
      __typename
      ... on Customer {
        customerId
        typ
        firmenname
        anrede
        name
        vorname
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

// const date = new Date();
// const DEFAULT_PATH = `/kunden/?updatedAt=${date.getFullYear()}-${date.getMonth() + 1}`;

const Kunden = ({ history }) => {
  const { pathname } = useLocation();
  const isFocused = pathname === '/projektangebote/focused';

  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: { focused: isFocused } },
    fetchPolicy: 'network-only',
  });

  const [deleteMutation, { error: deleteError, loading: deleteLoading }] = useMutation(DELETE, {
    refetchQueries: () => [
      {
        query: QUERY,
        variables: {
          where: {},
        },
        v: Math.random(),
      },
    ],
  });

  // DELETE-MUTATION
  const handleDeleteMutation = (mutation) => {
    const handledDeleteMuation = createMutationHandler({
      mutation,
      mutationName: 'deleteCustomer',
      mutationReturnTypes: {
        EmailProject: {
          successMessage: (data) => `Kunde mit der ID ${data.id} erfolgreich gelöscht`,
          onSuccess: () => history.push('/kunden'),
        },
      },
      serverErrorMessage: `Änderung des Kunden nicht erfolgreich`,
    });

    return (values) =>
      handledDeleteMuation({
        variables: values.variables,
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  const { emailProjects = [] } = queryData;

  return (
    <fieldset disabled={deleteLoading}>
      {isFocused ? <h1>Fokussierte Projektangebote</h1> : <h1>Unfokussierte Projektangebote</h1>}
      <hr />
      {/* <ButtonBox
        name="button-create-customer"
        theme="primary"
        icon="user"
        text="Neuen Kunden"
        style={{ marginRight: '10px', marginBottom: '20px' }}
        onClick={() => history.push(`/kunden/create`)}
      /> */}
      <div>
        <ErrorGraphQL error={queryError} />
        <ErrorGraphQL error={deleteError} />
        <CustomersTable
          data={emailProjects}
          onDelete={(row) => {
            const handledDeleteMutation = handleDeleteMutation(deleteMutation);
            handledDeleteMutation({
              variables: { where: { customerId: row.customerId } },
            });
          }}
        />
      </div>
    </fieldset>
  );
};

export default withRouter(Kunden);
