import React from 'react';
import QueryBox from './QueryBox';

const QueryBoxComponent = ({ onSearch, users, customers }) => (
  <QueryBox
    onSearch={onSearch}
    ownersList={users.map(({ userId, firstname, lastname }) => ({
      value: userId,
      label: `${firstname} ${lastname}`,
    }))}
    customersList={customers.map(({ customerId, typ, firmenname, vorname, name }) => ({
      value: customerId,
      label: typ === 'Firma' ? `${firmenname}` : `${vorname} ${name}`,
    }))}
  />
);

export default QueryBoxComponent;
