import React, { useEffect } from 'react';
import { FaListUl, FaEdit } from 'react-icons/fa';
import moment from 'moment';
import styled from 'styled-components';
import currency from 'currency.js';
import ToolTip from '../../molecules/ToolTip';

import { Link } from './styles';
import {
  useTable,
  Table,
  TableHead,
  TableBody,
  Headers,
  Header,
  DataRows,
  DataRow,
  SortHeader,
  Search,
} from '../../molecules/Table';

// ------------------------------- BETRAG DISPLAY LOGIC -------------------------------
const MoneyStyle = styled.div`
  color: ${(props) => (props.cents > 0 ? 'green' : 'red')};
`;
const FormatMoney = ({ cents }) => (
  <MoneyStyle cents={cents}>{currency(cents, { separator: '.', decimal: ',' }).divide(100).format()} €</MoneyStyle>
);
// ------------------------------- BETRAG DISPLAY LOGIC -------------------------------

const TableComponent = ({ data, updateStatus, isBin, purgeUser }) => {
  // const { CAN_UPDATE_USERS } = permissions;

  const { tableData = [], updateData, sorter, fuzzySearch } = useTable(data, {
    sorting: {
      dataField: 'buchungstag',
      sortFunc: (a, b) => moment(a.buchungstag).format('DDMMYY') - moment(b.buchungstag).format('DDMMYY'), // asc
    },
  });

  useEffect(() => {
    updateData(data);
  }, [data]);

  // --------------- FUZZY SEARCH ---------------
  const handleFuzzySearch = (value) => {
    fuzzySearch(['buchungstag', 'name', 'verwendungszweck', 'buchungstext', 'betrag'])(value);
  };
  // --------------- FUZZY SEARCH ---------------

  // --------------- SORTERS ---------------
  const buchungstagSorter = (toggle) =>
    sorter((a, b) =>
      toggle
        ? -(moment(a.buchungstag).format('DDMMYY') - moment(b.buchungstag).format('DDMMYY'))
        : moment(a.buchungstag).format('DDMMYY') - moment(b.buchungstag).format('DDMMYY')
    );

  const sortByName = (key) => (toggle) => sorter((a, b) => (toggle ? -(a[key] - b[key]) : a[key] - b[key]));
  // --------------- SORTERS ---------------

  return (
    <>
      <Search onChange={(event) => handleFuzzySearch(event.target.value)} placeholder="Suchen..." />
      <Table>
        <TableHead>
          <Headers>
            <Header style={{ width: '15px', paddingLeft: '0px' }}>
              Buchungstag
              <SortHeader sortFunc={buchungstagSorter} />
            </Header>
            <Header style={{ maxWidth: '650px' }}>
              Informationen
              <SortHeader />
            </Header>
            <Header style={{ minWidth: '100px', textAlign: 'right' }}>
              Betrag
              <SortHeader sortFunc={sortByName('betrag')} />
            </Header>
            <Header style={{ width: '100px', textAlign: 'center' }}>Aktionen</Header>
          </Headers>
        </TableHead>
        <TableBody>
          {tableData[0] !== null &&
            tableData.map(({ id, buchungstag, verwendungszweck, buchungstext, betrag }) => (
              <DataRows key={id}>
                <DataRow style={{ textAlign: 'right', paddingRight: '15px' }}>
                  {moment(buchungstag).format('DD.MM.YYYY')}
                </DataRow>
                <DataRow style={{ minWidth: '300px' }}>
                  <div>{verwendungszweck}</div>
                  {buchungstext && <div style={{ fontSize: '10px' }}>{buchungstext}</div>}
                </DataRow>
                <DataRow style={{ textAlign: 'right' }}>
                  <FormatMoney cents={betrag} />
                </DataRow>
                {/* ------------------- ACTIONS------------------- */}
                <DataRow>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Link to={`bankaccounts/update/${id}`}>
                      <ToolTip title="Bankkonto editieren">
                        <FaEdit />
                      </ToolTip>
                    </Link>
                    <Link to={`bankaccounts/${id}/transactions`}>
                      <ToolTip title="Bankkonto editieren">
                        <FaListUl />
                      </ToolTip>
                    </Link>
                  </div>
                </DataRow>
                {/* ------------------- ACTIONS------------------- */}
                {/* ------------------- ACTIONS------------------- */}
              </DataRows>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

export default TableComponent; /* } */
/*
import React from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

// COLUMNS
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faUserEdit, faStickyNote, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FaListUl } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
// import CustomerIdColumn from './Column.Id';
// import Actions from './Actions';
import Customer from './Customer';

import KundeKontaktColumn from '../../molecules/KundeKontakteColumn';
import KundeAdresseColumn from '../../molecules/KundeAdresseColumn';
import DateColumn from '../../molecules/DateColumn';

import Modal from '../../molecules/Modal';

const TableStyle = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const ActionStyle = styled.div`
  color: grey;
  margin-right: 20px;
  font-size: 20px;

  &:hover {
    color: #89cff0;
  }
`;

const StyledLink = styled(Link)`
  color: grey;
  margin-right: 20px;
  font-size: 20px;

  &:hover {
    color: #89cff0;
  }
`;

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'updatedAt',
    order: 'desc',
  },
];

export default function TableCustomers(props) {
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      formatter: (cell, row) => row.id,
      style: { width: '70px' },
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      formatter: (cell, row) => row.name,
      style: { minWidth: '210px' },
      sort: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Geändert am',
      formatter: DateColumn('updatedAt'),
      // style: { minWidth: '125px', maxWidth: '125px' },
      sort: true,
    },
    // {
    //   dataField: 'telefon',
    //   text: 'Kontakte',
    //   formatter: KundeKontaktColumn,
    //   // style: { minWidth: '210px' },
    //   sort: true,
    // },
    // {
    //   dataField: 'strasse',
    //   text: 'Adresse',
    //   formatter: KundeAdresseColumn,
    //   // style: { minWidth: '135px' },
    //   sort: true,
    // },

    {
      dataField: 'helper',
      text: 'Aktionen',
      // formatter: Actions,
      formatter: (cell, row) => (
        <div>
          <div style={{ display: 'flex' }}>
            <StyledLink to={`/banks/update/${row.id}`} style={{ textDecoration: 'none' }}>
              <div data-tip="Bank bearbeiten" name="edit-customer-button">
                <FontAwesomeIcon size="1x" icon={faUserEdit} />
                <ReactTooltip place="left" type="dark" effect="float" />
              </div>
            </StyledLink>

            <StyledLink to={`/bankaccounts/transactions/${row.id}`} style={{ textDecoration: 'none' }}>
              <div data-tip="Transaktionen" name="transactions">
                <FaListUl />
                <ReactTooltip place="left" type="dark" effect="float" />
              </div>
            </StyledLink>

            <Modal
              okButtonName="ok-modal-button"
              chancelButtonName="chancel-modal-button"
              theme="danger"
              header="Löschen"
              message={`Möchten Sie Kunde ${
                row.firmenname || `${row.vorname} ${row.name}`
              } und deren Rechnungen löschen?`}
              applyFunction={() => props.onDelete(row)}
            >
              <ActionStyle>
                <div data-tip="Kunde löschen" name="delete-customer-button">
                  <FontAwesomeIcon size="1x" icon={faTrash} />
                  <ReactTooltip place="left" type="dark" effect="float" />
                </div>
              </ActionStyle>
            </Modal>
          </div>
        </div>
      ),

      // formatExtraData: { deleteById: props.deleteById },
      // formatExtraData: { deleteById: props.deleteById },
      style: { minWidth: '100px', maxWidth: '175px' },
      sort: true,
    },

    // HIDDEN, BUT THERE TO BE ABLE SO LAZY-SEARCH
    {
      dataField: 'email',
      text: 'E-Mail',
      hidden: true,
    },
    {
      dataField: 'strasse',
      text: 'Strasse',
      hidden: true,
    },
    {
      dataField: 'hausnr',
      text: 'Hausnummer',
      hidden: true,
    },
    {
      dataField: 'firmenname',
      text: 'Firmenname',
      hidden: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Geändert am',
      formatter: DateColumn('updatedAt'),
      // style: { minWidth: '125px', maxWidth: '125px' },
      sort: true,
      hidden: true,
    },
    {
      dataField: 'createdAt',
      text: 'Erstellt am',
      formatter: DateColumn('createdAt'),
      style: { minWidth: '115px', maxWidth: '115px' },
      sort: true,
      hidden: true,
    },
  ];

  return (
    <TableStyle>
      <ToolkitProvider keyField="id" data={props.data} columns={columns} search>
        {({ searchProps, baseProps }) => (
          <div>
            <SearchBar
              {...searchProps}
              style={{ margin: '15px 0px 15px 0px', borderRadius: '0px' }}
              placeholder="Suchen..."
            />

            <BootstrapTable
              noDataIndication="Keine Daten mit diesen Sucheigenschaften gefunden"
              defaultSorted={defaultSorted}
              bordered={false}
              {...baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </TableStyle>
  );
}

TableCustomers.defaultProps = {
  data: [],
};
*/

/*
import React from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import moment from 'moment';
import currency from 'currency.js';

// COLUMNS
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import UrlButton from '../../molecules/UrlButton';
// import CustomerIdColumn from './Column.Id';
// import Actions from './Actions';
// import Customer from './Customer';

import { billDocumentURL } from '../../../config';

// import KundeKontaktColumn from '../../molecules/KundeKontakteColumn';
// import KundeAdresseColumn from '../../molecules/KundeAdresseColumn';
import DateColumn from '../../molecules/DateColumn';
import Modal from '../../molecules/Modal';

const TableStyle = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const ActionStyle = styled.div`
  color: grey;
  margin-right: 20px;
  font-size: 20px;

  &:hover {
    color: #89cff0;
  }
`;

const StyledLink = styled(Link)`
  color: grey;
  margin-right: 20px;
  font-size: 20px;
  font-size: 16px;

  &:hover {
    color: #89cff0;
  }
`;

const MoneyStyle = styled.div`
  color: ${(props) => (props.cents >= 0 ? 'green' : 'red')};
  text-align: right;
`;

const FormatMoney = ({ cents = 0 }) => {
  const euro = currency(cents, { separator: '.', decimal: ',' }).divide(100).format();
  // const centsToEuro = `${(cents / 100).toFixed(2).toString().replace('.', ',')} €`;

  // return <MoneyStyle cents={cents}>{centsToEuro}</MoneyStyle>;
  return <MoneyStyle cents={cents}>{euro} €</MoneyStyle>;
};

const PayStatusStyle = styled.div`
  color: ${(props) => (props.isPaid ? 'green' : 'red')};
  font-weight: bold;
`;

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'buchungstag',
    // order: 'desc',
    order: 'asc',
  },
];

export default function TableCustomers(props) {
  const columns = [
    // {
    //   dataField: 'id',
    //   text: 'ID',
    //   formatter: (cell, row) => row.id,
    //   style: { width: '70px' },
    //   sort: true,
    // },
    {
      text: 'Buchungstag',
      dataField: 'buchungstag',
      formatter: (cell, row) => moment(row.buchungstag).format('DD.MM.YYYY'),
      style: { width: '105px' },
      sort: true,
    },
    {
      text: 'Verwendungszweck',
      dataField: 'verwendungszweck',
      formatter: (cell, row) => (
        <>
          <div>{row.verwendungszweck}</div>
          {row.buchungstext && <div style={{ fontSize: '10px' }}>{row.buchungstext}</div>}
        </>
      ),
      style: { minWidth: '210px' },
      sort: true,
    },
    // {
    //   text: 'Verwendungszweck',
    //   dataField: 'verwendungszweck',
    //   formatter: (cell, row) => (
    //     <div>
    //       <div>{moment(row.buchungstag).format('DD.MM.YYYY')}</div>
    //       <div style={{ fontWeight: '500' }}>{row.verwendungszweck}</div>
    //     </div>
    //   ),
    //   style: { minWidth: '210px' },
    //   sort: true,
    // },
    {
      text: 'Betrag',
      dataField: 'betrag',
      formatter: (cell, row) => <FormatMoney cents={row.betrag} />,
      // formatter: (cell, row) => <FormatMoney cents={88888888} />,
      headerAlign: 'right',
      style: { width: '130px' },
      sort: true,
    },
    {
      text: 'Status',
      dataField: 'hasReceipt',
      formatter: (_cell, row) => {
        const paidStatusText = row.isPaid ? 'Beleg vorhanden' : 'Beleg fehlt';
        return (
          <div>
            <PayStatusStyle isPaid={row.isPaid}>{paidStatusText}</PayStatusStyle>
            <StyledLink to={`/belege/create/${row.id}`} style={{ textDecoration: 'none' }}>
              Beleg zuweisen
            </StyledLink>
          </div>
        );
      },
      style: { width: '160px' },
      sort: true,
    },
    // {
    //   dataField: 'updatedAt',
    //   text: 'Geändert am',
    //   formatter: DateColumn('updatedAt'),
    //   style: { width: '140px' },
    //   sort: true,
    // },
    // {
    //   dataField: 'createdAt',
    //   text: 'Erstellt am',
    //   formatter: DateColumn('createdAt'),
    //   style: { width: '140px' },
    //   sort: true,
    // },
    // {
    //   dataField: 'vorname',
    //   text: 'Aktionen',
    //   // formatter: Actions,
    //   formatter: (cell, row) => (
    //     <div>
    //       <div style={{ display: 'flex' }}>
    //         <StyledLink
    //           to={`/kunden/${row.customer.customerId}/rechnungen/update/${row.billId}`}
    //           style={{ textDecoration: 'none' }}
    //         >
    //           <div data-tip="Rechnung bearbeiten" name="edit-customer-button">
    //             <FontAwesomeIcon size="1x" icon={faEdit} />
    //             <ReactTooltip place="left" type="dark" effect="float" />
    //           </div>
    //         </StyledLink>

    //         {/* <StyledLink to={`/kunden/${row.customer.customerId}`} style={{ textDecoration: 'none' }}>
    //           <div data-tip="Rechnung-PDF erstellen" name="edit-customer-button">
    //             <FontAwesomeIcon size="1x" icon={faFilePdf} />
    //             <ReactTooltip place="left" type="dark" effect="float" />
    //           </div>
            // </StyledLink> */

//         <UrlButton external icon="pdf" toolTip="PDF erstellen" url={`${billDocumentURL}/${row.billId}/pdf`} />

//         <Modal
//           okButtonName="ok-modal-button"
//           chancelButtonName="chancel-modal-button"
//           theme="danger"
//           header="Löschen"
//           message={`Möchten Sie Kunde ${
//             row.firmenname || `${row.vorname} ${row.name}`
//           } und deren Rechnungen löschen?`}
//           applyFunction={() => props.onDelete(row)}
//         >
//           <ActionStyle>
//             <div data-tip="Rechnung löschen" name="delete-customer-button">
//               <FontAwesomeIcon size="1x" icon={faTrash} />
//               <ReactTooltip place="left" type="dark" effect="float" />
//             </div>
//           </ActionStyle>
//         </Modal>
//       </div>
//     </div>
//   ),

//   // formatExtraData: { deleteById: props.deleteById },
//   // formatExtraData: { deleteById: props.deleteById },
//   style: { width: '100px' },
//   sort: true,
// },
// HIDDEN, BUT THERE TO BE ABLE SO LAZY-SEARCH
// {
//   dataField: 'email',
//   text: 'E-Mail',
//   hidden: true,
// },
// {
//   dataField: 'strasse',
//   text: 'Strasse',
//   hidden: true,
// },
// {
//   dataField: 'hausnr',
//   text: 'Hausnummer',
//   hidden: true,
// },
// {
//   dataField: 'firmenname',
//   text: 'Firmenname',
//   hidden: true,
// },
//   ];

//   return (
//     <TableStyle>
//       <ToolkitProvider keyField="id" data={props.data} columns={columns} search>
//         {({ searchProps, baseProps }) => (
//           <div>
//             <SearchBar
//               {...searchProps}
//               style={{ margin: '15px 0px 15px 0px', borderRadius: '0px' }}
//               placeholder="Suchen..."
//             />

//             <BootstrapTable
//               noDataIndication="Keine Daten mit diesen Sucheigenschaften gefunden"
//               defaultSorted={defaultSorted}
//               bordered={false}
//               {...baseProps}
//             />
//           </div>
//         )}
//       </ToolkitProvider>
//     </TableStyle>
//   );
// }

// TableCustomers.defaultProps = {
//   data: [],
// };
