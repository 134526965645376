const DOMAIN_NAME = 'app.netcorn.de';

const API_URL = `https://${DOMAIN_NAME}/api`;

// BETTER TO USE UPPERCASE
module.exports = {
  clientUrl: process.env.NODE_ENV === 'production' ? `https://app.netcorn.de` : 'http://localhost:3000',
  apiUrl: process.env.NODE_ENV === 'production' ? API_URL : 'http://localhost:3003',
  graphqlUrl: process.env.NODE_ENV === 'production' ? `${API_URL}/graphql` : 'http://localhost:3003/graphql/',
  billDocumentURL: process.env.NODE_ENV === 'production' ? `${API_URL}/bills` : 'http://localhost:3003/bills',
  timetrackDocumentURL:
    process.env.NODE_ENV === 'production' ? `${API_URL}/timetracks` : 'http://localhost:3003/timetracks',
  bankTransactionsCSVUploadURL:
    process.env.NODE_ENV === 'production'
      ? `${API_URL}/bank-transactions/upload-csv`
      : 'http://localhost:3003/bank-transactions/upload-csv',
};
