import { useState, useEffect, useRef } from 'react';
import Fuse from 'fuse.js';

export const useTable = (data = [], options = {}) => {
  const [tableData, setTableData] = useState(data);

  const updateData = (data) => {
    if (options.sorting) {
      setTableData([...data].sort(options.sorting.sortFunc));
    }
  };

  // sort mutation
  useEffect(() => {
    updateData(data);
  }, []); // This empty array represents an empty list of dependencies; run only once

  return {
    tableData,
    updateData,
    sorter: (func) => setTableData([...tableData].sort(func)),
    fuzzySearch: (keys) => (input) => {
      const fuse = new Fuse(tableData, { keys });
      const filteredData = fuse.search(input);
      if (input.length === 0) {
        if (options.sorting) {
          setTableData([...data].sort(options.sorting.sortFunc));
        } else {
          setTableData(data);
        }
      } else {
        setTableData(filteredData.map((tableRow) => tableRow.item));
      }
    },
  };
};
