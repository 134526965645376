import React, { useReducer } from 'react';
import styled from 'styled-components';

const TabsContext = React.createContext();

const Tabs = (props) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_INDEX':
        return { ...state, activeIndex: action.payload };
      case 'SET_ACCORDION_CONTENT':
        return { ...state, accordionContent: action.payload };
      default:
        return state;
    }
  };

  const initalValue = { activeIndex: 0, accordionContent: null };

  const [state, contextDispatch] = useReducer(reducer, initalValue);
  const { activeIndex, accordionContent } = state;
  const { children } = props;

  return (
    <TabsContext.Provider value={{ activeIndex, contextDispatch, accordionContent }}>
      <div {...props}>{children}</div>
    </TabsContext.Provider>
  );
};

const StyledTabs = styled(Tabs)`
  margin: 0 auto 10px;
  min-width: 290px;
  /* min-width: 470px; */
  max-width: 1200px;
`;

export { TabsContext, StyledTabs as Tabs };
