import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import ErrorIcon from '@material-ui/icons/Error';
import Label from '../../atoms/Label';
import withField from '../../HOC/with-field';

const StyleContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 180px;
  margin: 10px;
`;

// TODO: use styled componentsn
const errorMessageStyle = {
  display: 'flex',
  color: '#cc0000',
  marginTop: '10px',
  fontWeight: 'bold',
};

// TODO: use styled componentsn
const errorsIconStyle = {
  display: 'flex',
  color: '#cc0000',
  marginTop: '8px',
  marginRight: '3px',
};

const ErrorMessage = ({ message }) => (
  <div>
    {/* this case is true if we validate an object */}
    {typeof message === 'object' && (
      <div style={{ display: 'flex' }}>
        <ErrorIcon style={errorsIconStyle} />
        <div style={errorMessageStyle}>{Object.values(message)[0]}</div>
      </div>
    )}

    {typeof message === 'string' && (
      <div style={{ display: 'flex' }}>
        <ErrorIcon style={errorsIconStyle} />
        <div style={errorMessageStyle}>{message}</div>
      </div>
    )}
  </div>
);

const SelectInputComponent = (props) => {
  const {
    input,
    meta,
    label,
    options,
    placeholder,
    noOptionsMessage,
    isDisabled,
    'data-testid': dataTestid,
    onChange,
    isMulti,
    height,
  } = props;
  const { error, submitError, touched } = meta;

  return (
    <StyleContainer data-testid={dataTestid}>
      {label && <Label text={label} />}
      <Select
        {...input}
        options={options}
        styles={{
          container: (provided) => ({
            ...provided,
            width: '100%',
          }),
          //   backgroundColor: 'red',
          // },
          // control ist das was man sieht, also der input, nicht die options
          control: (provided) => ({
            ...provided,
            ...(height ? { height } : {}),
            borderRadius: 0,
            // padding: '20px',
            // width: '300px',
            // width: '100%',
          }),
          // das menu wenn man die box selected
          menu: (provided) => ({
            ...provided,
            borderRadius: 0,
            marginTop: '2px',
          }),
        }}
        isMulti={isMulti}
        isDisabled={isDisabled}
        placeholder={placeholder}
        noOptionsMessage={() => noOptionsMessage}
        onChange={(val) => {
          if (onChange) {
            input.onChange(val);
            onChange(val);
          }

          input.onChange(val);
        }}
        //
      />
      {/* <ErrorMessage message={error} /> */}
      {/* {error && (dirty || submitFailed) && touched && <ErrorMessage message={error} />} */}
      {/* <ErrorMessage message={error} /> */}
      {error && touched && <ErrorMessage message={error} />}
      <ErrorMessage message={submitError} />
    </StyleContainer>
  );
};

export default withField(SelectInputComponent);
