import React from 'react';
import FontAwesome from 'react-fontawesome';

export default function KundeKontakt(column, row) {
  const { telefon, email } = row;
  return (
    <div>
      <FontAwesome name="phone-square" />&nbsp; {telefon} <br />
      <FontAwesome name="envelope" />&nbsp; {email} <br />
    </div>
  );
}
