import React, { useState } from 'react';

const SortHeader = ({ onClick, sortFunc }) => {
  const [toggle, setToggle] = useState(false);
  const handleToggle = () => setToggle((prevState) => !prevState);

  return (
    <div
      style={{ display: 'inline', cursor: 'pointer', marginLeft: '5px' }}
      onClick={() => {
        handleToggle();

        if (sortFunc) {
          sortFunc(toggle);
        }
      }}
    >
      {toggle && '▲'}
      {!toggle && '▼'}
    </div>
  );
};

export default SortHeader;
