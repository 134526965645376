import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import FontAwesome from 'react-fontawesome';
// import { useDropzone } from 'react-dropzone';
// import currency from 'currency.js';
import Switch from 'react-switch';
import styled from 'styled-components';
// import { List } from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays';
import { GoArrowUp, GoArrowDown } from 'react-icons/go';
import { FaTrash } from 'react-icons/fa';
// import { FormLayout } from './style';
import moment from 'moment';
import allowedAttributes from './allowed-attributes-bill';
import withFinalForm from '../../HOC/with-final-form';
import validatorBill from './validator-bill';

import Button from '../../atoms/Button';
import FormGroup from '../../atoms/FormGroup';
import TextInput from '../../molecules/TextInput';
import TextArea from '../../molecules/TextArea';
import SelectField from '../../molecules/SelectField';
import DatePicker from '../../final-form-elements/DatePicker';

import ToggleButton from '../../molecules/ToggleButton';
import BillPositions from './Form.Bill.Positions';

// import ReceiptDnDFile from './Receipt.DnDFile.jsx';
// import ReceiptFileList from './Receipt.FileList.jsx';
// import positionsCalc from './positions-calc';
// import OpenFilesButton from './OpenFiles.jsx';
// import Positions from './Receipt.Positions';

export const GridLayout = styled.div`
  @media screen and (min-width: 760px) {
    display: grid;
    grid-template-columns: 430px 4fr;
    grid-template-areas: 'picture-area form-area';
    grid-column-gap: 10px;
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'picture-area'
    'form-area';
`;

const PositionContainer = styled.div`
  display: flex;
  background-color: #edf4fb;
  border: solid 1px #bfbfbf;
  margin-top: 10px;
  padding: 10px;
`;

const PositionHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #bd2130;
  width: 30px;
  height: 30px;
`;

// ------------------------ HELPERS ------------------------
const getNewArtificalId = () => new Date().valueOf() + Math.random();
// ------------------------ HELPERS ------------------------

//
//
//
const BillForm = ({ values, handleSubmit, submitButtonText, form, onSubmit }) => {
  const { positions = [] } = values;

  //
  return (
    <>
      <form data-testid="receipt-form" onSubmit={handleSubmit}>
        <FormGroup
          style={{
            flexDirection: 'row',
            marginLeft: '10px',
            marginTop: '15px',
            marginBottom: '10px',
            alignSelf: 'center',
          }}
        >
          <FormGroup style={{ flexDirection: 'column' }}>
            <ToggleButton name="isPaid" onMessage="Rechnung Bezahlt" offMessage="Rechnung nicht bezahlt" />
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <TextInput
            label="Rechnungsname"
            type="text"
            name="name"
            focus
            formatter={(value) => value.toUpperCase().trim()}
            data-testid="receipt-name"
          />
          <DatePicker label="Rechnungsdatum" name="billDate" data-testid="bill-date" />
        </FormGroup>
        <FormGroup>
          <DatePicker label="Projektbeginn" name="projectStart" data-testid="project-start" />
          <DatePicker label="Projektende" name="projectEnd" data-testid="project-end" />
        </FormGroup>
        <FormGroup>
          <SelectField
            label="Zahlungsfrist"
            name="deadlineDays"
            options={[
              { label: 'Sofort', value: 0 },
              { label: '7 Tage', value: 7 },
              { label: '14 Tage', value: 14 },
              { label: '30 Tage', value: 30 },
              { label: '40 Tage', value: 40 },
              { label: '60 Tage', value: 60 },
            ]}
            data-testid="deadlineDays"
          />
          <div style={{ width: '50%', marginTop: '20px', marginBottom: '30px', paddingLeft: '10px' }}>
            <div>Fälligkeitsdatum: </div>
            <div style={{ fontSize: '18px', marginTop: '10px' }}>
              {moment(values.billDate).add(values.deadlineDays, 'days').format('DD.MM.YYYY')}
            </div>
          </div>
        </FormGroup>

        <hr />

        {/* ************** POSITION *************** */}
        <BillPositions positions={positions} />
        {/* ************** POSITION *************** */}

        <hr />
        <FormGroup>
          <TextArea label="Notiz" type="text" name="notiz" data-testid="notiz" />
        </FormGroup>
        <Button
          type="submit"
          name="create-receipt"
          data-testid="create-receipt"
          style={{ marginTop: '20px' }}
          theme="success"
          height="40px"
          icon="save"
          text={submitButtonText}
        />
      </form>
    </>
  );
};

BillForm.propTypes = {
  values: PropTypes.object,
  submitButtonText: PropTypes.string,
};

BillForm.defaultProps = {
  values: {},
  submitButtonText: 'NO SUBMIT BUTTON TEXT',
};

// FORM CONFIG
export default (props) => {
  const { data = {}, submitButtonText, mutation } = props;

  const { positions = [] } = data;

  const initialValues = React.useMemo(
    () => ({
      // default data
      name: '',
      description: '',
      isPaid: false,
      deadlineDays: 0,
      // default data
      ...data,
      // billDate: data.billDate || new Date(),
      // billDate: data.billDate || new Date(),
      billDate: data.billDate ? new Date(data.billDate) : new Date(),
      projectStart: data.projectStart ? new Date(data.projectStart) : new Date(),
      projectEnd: data.projectEnd ? new Date(data.projectEnd) : new Date(),
      // add arttifical id for drag and drop and list working correctly
      positions:
        data.positions && data.positions[0]
          ? data.positions
              .map((position) => ({ ...position, artificalId: getNewArtificalId() }))
              .sort((a, b) => a.position - b.position)
          : [],
      // *********************** DATA FOR SELECT FIELDS ***********************
    }),
    [data]
  );

  return withFinalForm(
    {
      initialValues,
      validator: validatorBill,
      submitButtonText,
      allowedAttributes,
      // nicht nur wichtig für submit value, auch wichtig für validation
      // remove artificalKey and __typname
      // parse einzelpreis to int because EuroInput makes 3377 to 3377.000003
      // update position, which is the order of position
      valuesFormat: {
        positions: (positions) =>
          positions.map(({ __typename, artificalId, ...restPosition }, index) => ({
            ...restPosition,
            position: index,
            einzelpreis: Math.trunc(restPosition.einzelpreis),
            zusatz: restPosition.zusatz || '',
            ust: parseInt(restPosition.ust),
          })),
        deadlineDays: (deadlineDays) => parseInt(deadlineDays),
        //
      },
      submitHandler: (values) => mutation(values),
    },
    BillForm
  );
};
