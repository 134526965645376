/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import styled from 'styled-components';
// import Toggle from 'react-bootstrap-toggle';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import moment from 'moment';
import AppContext from '../../../../context/App.Context';
import getPermissions from '../../../shared/get-permissions';
import ModalButton from '../../../molecules/ModalButton';
// import DateColumn from '../../molecules/DateColumn';
import UrlButton from '../../../molecules/UrlButton';
// import { getDateData } from '../../shared/date-utils';
import { timetrackDocumentURL } from '../../../../config';
// import { AccordionItem, AccordionItemBody } from 'react-accessible-accordion';
// import FormGroup from '../../atoms/FormGroup';
// import { Group, Label, Content, Table, Row, TData, Header, Accordion, AccordionTitle } from './style';

// import { Group, Label, Content, Table, Row, TData, Header, HeadAccordion, BodyAccordion } from './style';

const TimeHeader = styled.div`
  color: #000;
  background-color: #e0e3ee;
  width: 100%;
  height: 40px;
  padding: 10px;
  :hover {
    color: #ffffff;
    background-color: #007bff;
    cursor: pointer;
  }
  margin: 0px;
`;

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'updatedAt',
    order: 'desc',
  },
];

export default function TableCustomers({ onLock, onSign, onDelete, data }) {
  const { sessionUser } = useContext(AppContext);
  const { CAN_CREATE_TIMETRACKS } = getPermissions(sessionUser.roles);

  const columns = [
    {
      dataField: 'timetrackId',
      text: 'ID',
      style: { fontSize: '12px' },
      sort: true,
      headerStyle: { display: 'none' },
      formatter: (cell, row) => (
        <Accordion allowZeroExpanded style={{ userSelect: 'none' }}>
          <AccordionItem>
            <AccordionItemHeading style={{ marginTop: '5px' }}>
              <AccordionItemButton>
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Link
                    style={{
                      textDecoration: 'none',
                      flex: 1,
                    }}
                    to={`/timetracks/list/${row.timetrackId}`}
                  >
                    <TimeHeader
                      style={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <div>
                        {row.timetrackId} - {row.name}
                      </div>
                    </TimeHeader>
                  </Link>
                </div>
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: '#e0e3ee',
                  }}
                >
                  <div>
                    {row.owner.firstname} {row.owner.lastname}{' '}
                  </div>
                  <div style={{ marginRight: '15px' }}>
                    {row.year} - {row.month}
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            {CAN_CREATE_TIMETRACKS && (
              <AccordionItemPanel
                style={{
                  borderStyle: 'solid',
                  padding: '10px',
                  borderWidth: '1px',
                  borderColor: '#e0e3ee',
                }}
              >
                <UrlButton
                  text="Editieren"
                  icon="edit"
                  toolTip="Erfassung bearbeiten"
                  url={`/timetracks/update/${row.timetrackId}`}
                />
                <UrlButton
                  text="PDF erstellen"
                  external
                  icon="pdf"
                  toolTip="PDF erstellen"
                  url={`${timetrackDocumentURL}/${row.timetrackId}/pdf`}
                />
                <ModalButton
                  text="Löschen"
                  modalHeader="Löschen"
                  modalBody={`Möchten Sie den Beleg mit der ID ${row.timetrackId} wirklich löschen?`}
                  toolTip="Erfassung löschen"
                  onOkClick={() => onDelete(row)}
                />
              </AccordionItemPanel>
            )}
          </AccordionItem>
        </Accordion>
      ),
    },
    {
      dataField: 'name',
      text: 'Projektname',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'owner.firstname',
      text: 'Leistungsbringer',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'owner.lastname',
      text: 'Leistungsbringer',
      sort: true,
      hidden: true,
    },

    // HIDDEN, BUT NEEDED TO BE ABLE SO LAZY-SEARCH THESE FIELDS
    {
      dataField: 'customer',
      text: 'Firma',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'createdAt',
      text: 'Erstellt am',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Erstellt am',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'year',
      text: 'Jahr',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'month',
      text: 'Monat',
      sort: true,
      hidden: true,
    },
  ];

  return (
    // <TableStyle>
    <ToolkitProvider
      keyField="timetrackId"
      data={data}
      columns={columns.filter(column => !(!CAN_CREATE_TIMETRACKS && column.text === 'Status'))}
      search
    >
      {({ searchProps, baseProps }) => (
        <div>
          <SearchBar
            {...searchProps}
            style={{
              margin: '0px 0px 10px 0px',
              padding: '10px',
              borderRadius: '0px',
              width: '100%',
              border: 'solid',
              borderWidth: '1px',
              borderColor: '#ced4da',
            }}
            placeholder="Suchen..."
          />

          <BootstrapTable
            noDataIndication="Keine Daten mit diesen Sucheigenschaften gefunden"
            defaultSorted={defaultSorted}
            bordered={false}
            {...baseProps}
            printable
            condensed
          />
        </div>
      )}
    </ToolkitProvider>
    // </TableStyle>
  );
}

TableCustomers.defaultProps = {
  data: [],
};
