import * as yup from 'yup';
import { setIn } from 'final-form';

const schema = yup.object({
  name: yup //
    .string()
    .required('Kontoname ist notwendig')
    .min(3, 'Kontoname muss mindestens 3 Zeichen haben'),
  bankname: yup //
    .string()
    .required('Bankname ist notwendig')
    .min(3, 'Bankname muss mindestens 3 Zeichen haben'),
  id: yup //
    .string()
    .required('IBAN ist notwendig')
    .length(22, 'Die IBAN ist in Deutschland 22 Zeichen lang'),
});

const validator = async (values) => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (error) {
    return error.inner.reduce((errors, error) => setIn(errors, error.path, error.message), {});
  }
};

export default validator;
