import React from 'react';
import Switch from 'react-switch';
import withField from '../../HOC/with-field';

const ToggleButton = ({ input, onMessage, offMessage, opposite, marginTop }) => (
  <div style={{ display: 'flex', marginTop: marginTop || '10px', marginBottom: '10px', fontSize: '12px', alignItems: "center" }}>
    <Switch
      onChange={input.onChange}
      checked={input.value}
      offColor="#dc3545"
      onColor="#28a745"
      height={20}
      width={40}
    />
    <div style={{ marginLeft: '10px', color: '#696969' }}>{input.value ? onMessage : offMessage}</div>
  </div>
);

export default withField(ToggleButton);
