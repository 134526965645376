import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledTabBody = styled.div`
  padding: 15px;
  border: 1px solid transparent;
  border-radius: 0px;
  padding: 0px;
  color: rgb(80, 80, 80);
  border-color: #aaa;
  /* margin-right: -1.2px; */
`;

const TabBody = props => {
  const { children } = props;

  return <StyledTabBody {...props}>{children}</StyledTabBody>;
};

TabBody.propTypes = {
  children: PropTypes.any,
};

export default TabBody;
