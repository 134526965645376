import * as yup from 'yup';
import { setIn } from 'final-form';

const schema = yup.object({
  // name: yup
  //   .string()
  //   .required('Name ist notwendig')
  //   .min(3, 'Name muss mindestens 3 Zeichen haben'),
  // vorname: yup
  //   .string()
  //   .required('Vorname ist notwendig')
  //   .min(3, 'Vorname muss mindestens 3 Zeichen haben'),
  // firmenname: yup.string().when('typ', {
  //   is: val => val === 'Firma',
  //   then: yup
  //     .string()
  //     .required('Firmenname muss eingegeben werde')
  //     .min(3, 'Firmenname muss mindestens 3 Zeichen haben'),
  // }),
});

const validator = async (values) => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (error) {
    return error.inner.reduce((errors, error) => setIn(errors, error.path, error.message), {});
  }
};

export default validator;
