const allowedAttributes = [
  'amtsgericht',
  'anrede',
  'ansprechemail',
  'ansprechname',
  'ansprechtelefon',
  'ansprechvorname',
  'bankname',
  'bic',
  'blz',
  'email',
  'eusteuernr',
  'fax',
  'firmenname',
  'handelsregister',
  'hausnr',
  'iban',
  'kontonr',
  'name',
  'vorname',
  'notizen',
  'ort',
  'plz',
  'steuernr',
  'strasse',
  'telefon',
  'typ',
  'umsatzsteuerid',
  'projectOfficers',
];

export default allowedAttributes;
