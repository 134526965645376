import * as yup from 'yup';
import { setIn } from 'final-form';

const schema = yup.object({
  // customer is set by customersList and formatter
  customer: yup
    .string()
    .strict() // strict: only validate the input, and skips and coercion or transformation
    .required('Kunde muss ausgewähl werden')
    .matches(/^\d+$/, 'Kunde muss gewählt werden'),
  name: yup.string().required('Projektname ist notwendig').min(3, 'Projektname muss mindestens 3 Zeichen haben'),
  // manager: yup
  //   .string()
  //   .required('Projektverantwortlicher ist notwendig')
  //   .min(3, 'Projektverantwortlicher muss mindestens 3 Zeichen haben'),
  owner: yup
    .string()
    .strict() // strict: only validate the input, and skips and coercion or transformation
    .required('Leistungsbringer muss ausgewählt werden')
    .matches(/^\d+$/, 'Leisungsbringer muss gewählt werden'),
});

const validator = async (values) => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (error) {
    return error.inner.reduce((errors, error) => setIn(errors, error.path, error.message), {});
  }
};

export default validator;
