import React from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

// COLUMNS
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faUserEdit, faStickyNote, faTrash } from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
// import CustomerIdColumn from './Column.Id';
// import Actions from './Actions';

import KundeKontaktColumn from '../../molecules/KundeKontakteColumn';
// import KundeAdresseColumn from '../../molecules/KundeAdresseColumn';
import DateColumn from '../../molecules/DateColumn';

// import Modal from '../../molecules/Modal';

const TableStyle = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const ActionStyle = styled.div`
  color: grey;
  margin-right: 20px;
  font-size: 20px;

  &:hover {
    color: #89cff0;
  }
`;

const StyledLink = styled(Link)`
  color: grey;
  margin-right: 20px;
  font-size: 20px;

  &:hover {
    color: #89cff0;
  }
`;

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'updatedAt',
    order: 'desc',
  },
];

export default function TableCustomers(props) {
  const columns = [
    {
      text: 'ID',
      dataField: 'id',
      // formatter: CustomerIdColumn,
      formatter: (cell, row) => row.id,
      style: { minWidth: '10px', maxWidth: '70px' },
      sort: true,
    },
    {
      text: 'Firma',
      dataField: 'name',
      formatter: (cell, row) => row.name,
      style: { minWidth: '210px' },
      sort: true,
    },
    {
      dataField: 'telefon',
      text: 'Kontakte',
      formatter: KundeKontaktColumn,
      // style: { minWidth: '210px' },
      sort: true,
    },
    {
      dataField: 'street',
      text: 'Adresse',
      formatter: (cell, row) => (
        <div>
          {row.street} {row.houseNo}
          <br />
          {row.plz} {row.city}
        </div>
      ),
      // style: { minWidth: '135px' },
      sort: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Geändert am',
      formatter: DateColumn('updatedAt'),
      // style: { minWidth: '125px', maxWidth: '125px' },
      sort: true,
    },
    {
      dataField: 'vorname',
      text: 'Aktionen',
      // formatter: Actions,
      formatter: (cell, row) => (
        <div style={{ display: 'flex', textAlign: 'center' }}>
          <StyledLink to={`/recruiters/update/${row.id}`} style={{ textDecoration: 'none' }}>
            <div data-tip="Recruiter bearbeiten" name="edit-customer-button">
              <FontAwesomeIcon size="1x" icon={faUserEdit} style={{ marginLeft: '20px' }} />
              <ReactTooltip place="left" type="dark" effect="float" />
            </div>
          </StyledLink>
        </div>
      ),

      // formatExtraData: { deleteById: props.deleteById },
      // formatExtraData: { deleteById: props.deleteById },
      style: { minWidth: '100px', maxWidth: '175px' },
      sort: true,
    },

    // HIDDEN, BUT THERE TO BE ABLE SO LAZY-SEARCH
    {
      dataField: 'email',
      text: 'E-Mail',
      hidden: true,
    },
    {
      dataField: 'strasse',
      text: 'Strasse',
      hidden: true,
    },
    {
      dataField: 'hausnr',
      text: 'Hausnummer',
      hidden: true,
    },
    {
      dataField: 'firmenname',
      text: 'Firmenname',
      hidden: true,
    },

    // {
    //   dataField: 'createdAt',
    //   text: 'Erstellt am',
    //   formatter: DateColumn('createdAt'),
    //   style: { minWidth: '115px', maxWidth: '115px' },
    //   sort: true,
    //   hidden: true,
    // },
  ];

  return (
    <TableStyle>
      <ToolkitProvider keyField="id" data={props.data} columns={columns} search>
        {({ searchProps, baseProps }) => (
          <div>
            <SearchBar
              {...searchProps}
              style={{ margin: '15px 0px 15px 0px', borderRadius: '0px' }}
              placeholder="Suchen..."
            />

            <BootstrapTable
              noDataIndication="Keine Daten mit diesen Sucheigenschaften gefunden"
              defaultSorted={defaultSorted}
              bordered={false}
              {...baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </TableStyle>
  );
}

TableCustomers.defaultProps = {
  data: [],
};
