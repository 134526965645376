import React from 'react';
import history from '../../app-history';

// import PropTypes from 'prop-types';
// import CustomersTable from '../../organisms/Table.Customers';
// import SearchBox from '../../molecules/SearchBox';
// import Loading from '../../molecules/Loading';
// import selectCustomerTypes from './select-customer-types';
// import parseQueryString from '../../../lib/parse-query-string';
// import ButtonBox from '../../atoms/ButtonBox';
// import SearchHeadlineCustomers from '../../molecules/Search.Headline.Customers';
//
// import { Query } from 'react-apollo';
// import { GET_CUSTOMERS } from '../../../graphQL/queries/customer';
// import searchHeadline from './search-headline';
//
// const date = new Date();
// const thisYear = date.getFullYear().toString();
// const thisMonth = (date.getMonth() + 1).toString();
// const dateStringLatest = `${date.getFullYear()}-${date.getMonth() + 1}`;
// const DEFAULT_PATH = `/kunden/?updatedAt=${dateStringLatest}`;
import styled from 'styled-components';
import AppContext from '../../context/App.Context';

// import PropTypes from 'prop-types';

const ErrorStyles = styled.div`
  padding: 2rem;
  background: white;
  margin: 2rem 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 15px solid red;
  p {
    margin: 0;
    font-weight: 100;
  }
  strong {
    margin-right: 1rem;
  }
`;

const ErrorBox = styled.div`
  padding: 2rem;
  background: white;
  margin: 2rem 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 15px solid red;
  p {
    margin: 0;
    font-weight: 100;
  }
  strong {
    margin-right: 1rem;
  }
`;

// const ErrorGraphQL = ({ error }) => {
const ErrorGraphQL = ({ error }) => {
  const context = React.useContext(AppContext);
  // wenn kein error, dann gib nichts zurück
  if (!error || !error.message) {
    return null;
  }

  if (error.message && !error.networkError) {
    return <ErrorBox>{error.message}</ErrorBox>;
  }

  // console.log('GRAPHQL ERROR: ', error);
  // console.log('GRAPHQL ERROR NETWORKERROR:  ', error.networkError);
  // console.log('GRAPHQL ERROR NETWORKERROR result:  ', error.networkError.result);

  if (error.networkError && error.networkError.result && error.networkError.result.statusCode) {
    history.push('/login');
    context.logout({ errorMessage: error.networkError.result.message });
    return null;
    // error.networkError.result.errors.forEach(error => NotificationManager.error(error.message, 'Serverfehler'));
    // return <ErrorStyles>{error.networkError.result.message}</ErrorStyles>;
    // return null;
    // return <h1>{error.message}</h1>;
  }
  /*
  GRAPHQL ERROR NETWORKERROR result:   
  Object { statusCode: 401, type: "Authentication", message: "Sie wurden vom Administrator bblockiert.", appmode: "development" }
*/
  // wenn networkError existiert, sonst bekommen wir ein undefinde error wenn wir
  // error.networkError.result aufrufen, denn dann ist ja networkError undefined
  // networkError jeder Fehler während der Verbindungsausführung oder Serverantwort
  // networkError sind fehler die nicht als Teil des Fehlerfelds im GraphQL-Ergebnis
  // I think network error is no internet or the server didn't respond with 200
  // Everything else is GraphQL error

  // console.log('DAS IST ERROR GRAPHQL: ', error);
  // console.log('DAS IST ERROR GRAPHQL NETWORK ERROR: ', error.networkError);
  // console.log('DAS IST ERROR GRAPHQL NETWORK ERROR result: ', error.networkError.result);
  // console.log('DAS IST ERROR: ', error)
  // console.log('DAS IST ERROR: ', error);

  if (
    error.networkError &&
    error.networkError.result &&
    Array.isArray(error.networkError.result.errors) &&
    error.networkError.result.errors.length
  ) {
    // error.networkError.result.errors.forEach(error => NotificationManager.error(error.message, 'Serverfehler'));
    return error.networkError.result.errors.map((error, index) => (
      <ErrorStyles key={index}>{error.message}</ErrorStyles>
    ));

    // return null;
    // return <h1>{error.message}</h1>;
  }

  // if (
  //   error.networkError &&
  //   error.networkError.result &&
  //   !Array.isArray(error.networkError.result.errors) &&
  //   error.networkError.result.errors.length
  // ) {
  //   console.log('FEHLER ALTER: ', error);
  //   return null;
  // }

  // graphQLError ist der Fehler der im graphQL response landet, also {data, error}
  return <ErrorStyles>{error.message}</ErrorStyles>;
};

export default ErrorGraphQL;
