import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import ErrorIcon from '@material-ui/icons/Error';
import Label from '../../atoms/Label';
import withField from '../../HOC/with-field';

const StyleContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 180px;
  margin: 10px;
`;

// TODO: use styled componentsn
const errorMessageStyle = {
  display: 'flex',
  color: '#cc0000',
  marginTop: '10px',
  fontWeight: 'bold',
};

// TODO: use styled componentsn
const errorsIconStyle = {
  display: 'flex',
  color: '#cc0000',
  marginTop: '8px',
  marginRight: '3px',
};

const ErrorMessage = ({ message }) => (
  <div>
    {message && (
      <div style={{ display: 'flex' }}>
        <ErrorIcon style={errorsIconStyle} />
        <div style={errorMessageStyle}>{message}</div>
      </div>
    )}
  </div>
);

const InputArea = styled.textarea`
  width: 100%;
  padding: 5px;
  border-style: solid;
  border-width: ${(props) => (props.hasError ? '3px' : '1px')};
  border-color: ${(props) => (props.hasError ? '#cc0000' : '#BFBFBF')};
`;

const CustomTextAreaComponent = ({ input, meta, label, type, 'data-testid': dataTestid }) => {
  const { error, submitError } = meta;

  return (
    <StyleContainer>
      <Label text={label} />
      <InputArea {...input} hasError={error || submitError} rows="7" data-testid={dataTestid} />
      <ErrorMessage message={error} />
      <ErrorMessage message={submitError} />
    </StyleContainer>
  );
};

export default withField(CustomTextAreaComponent);
