import React, { useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-date-picker';
import { FaCalendarAlt } from 'react-icons/fa';

// import DatePicker from 'react-date-picker/dist/entry.nostyle';

const StyleContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 180px;
`;

// TODO: use styled componentsn
const errorMessageStyle = {
  display: 'flex',
  color: '#cc0000',
  marginTop: '10px',
  fontWeight: 'bold',
};

// TODO: use styled componentsn
const errorsIconStyle = {
  display: 'flex',
  color: '#cc0000',
  marginTop: '8px',
  marginRight: '3px',
};

const InputField = styled.input`
  width: 100%;
  padding: 5px;
  border-style: solid;
  border-width: ${(props) => (props.hasError ? '3px' : '1px')};
  border-color: ${(props) => (props.hasError ? '#cc0000' : '#BFBFBF')};
`;

//
//
//
const StyledDatePicker = styled(DatePicker)`
  &&& {
    /* background-color: red; */
    width: 100%;
    border-style: solid;
    border-width: 0px;
    border-color: #bfbfbf;
    margin: 0px;
    background-color: white;
  }

  .react-date-picker__button {
    background-color: #cccccc;
    color: #525252;
  }

  .react-date-picker__inputGroup {
    padding: 5px;
    border-color: #bfbfbf;
    text-align: right;
  }

  .react-date-picker__wrapper {
    border-color: #bfbfbf;
  }

  .react-date-picker__calendar--open {
    left: unset !important;
    right: 0px !important;
    top: 100% !important;
    bottom: unset !important;
  }
`;
//
//
//
//
//
//
const DatePickerInput = ({ 'data-testid': dataTestid, ...rest }) => (
  <StyleContainer data-testid={dataTestid}>
    <StyledDatePicker {...rest} format="dd.MM.yyyy" calendarIcon={<FaCalendarAlt size="15px" />} clearIcon={null} />
  </StyleContainer>
);
export default DatePickerInput;
