/* eslint-disable react/display-name */
import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import FormGroup from '../../atoms/FormGroup';
import FormItem from '../../atoms/FormItem';
import CustomerForm from './Form';
import ButtonBox from '../../atoms/ButtonBox';
import MetaInfo from '../../molecules/MetaInfo';

import Loading from '../../molecules/Loading';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import createMutationHandler from '../../shared/create-mutationhandler';

const QUERY = gql`
  query GetEmailProject($where: EmailProjectWhereInput) {
    emailProject(where: $where) {
      id
      subject
      from
      date
      html
      focused
      completed
      recruiter {
        id
        name
      }
      employee {
        id
        firstname
        lastname
      }
      projectElements {
        id
        description
        city
        wage
        projectStart
        projectEnd
      }
    }

    # ------------------ DATA FOR OPTIONS ------------------
    recruiters(where: {}) {
      id
      name
      employees {
        id
        firstname
        lastname
        email
        telefon
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateCustomer($where: EmailProjectWhereUpdateInput, $data: EmailProjectUpdateInput) {
    updateEmailProject(where: $where, data: $data) {
      __typename
      ... on EmailProject {
        id
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
    }
  }
`;

const CustmerFormUpdate = ({ history, id }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    // id comes from prop
    variables: { where: { id } },
    fetchPolicy: 'network-only',
  });
  const [updateMutation, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_MUTATION);
  const { emailProject = {}, recruiters = [] } = queryData;
  // const { createdBy = {}, updatedBy = {} } = emailProject;

  const handleUpdateMuation = (mutation) => {
    const handledUpdateMuation = createMutationHandler({
      mutation,
      mutationName: 'updateEmailProject',
      mutationReturnTypes: {
        EmailProject: {
          successMessage: (data) => `E-Mail-Projekt mit der E-Mail-Projekt wurde erfolgreich verändert`,
          onSuccess: () => history.goBack(),
        },
      },
      serverErrorMessage: `Änderung des Kunden nicht erfolgreich`,
    });

    return (values) =>
      handledUpdateMuation({
        variables: {
          where: { id },
          data: values,
        },
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  return (
    <fieldset disabled={updateLoading}>
      {/* <FormGroup>
        <FormItem>
          <h2>Email-Projekt bearbeiten</h2>
        </FormItem>
      </FormGroup>

      <ButtonBox
        theme="primary"
        icon="arrow-left"
        text="Zurück"
        style={{ margin: '0px 10px 20px 0px' }}
        onClick={() => history.goBack()}
      /> */}

      {/* <hr /> */}
      {/* <MetaInfo>
        {[
          [['ID:', customer.customerId]],
          [
            ['Erstellt am:', customer.createdAt],
            ['Geändert am:', customer.updatedAt],
          ],
          [
            ['Erstellt von:', `${createdBy.firstname} ${createdBy.lastname}`],
            ['Geändert von:', `${updatedBy.firstname} ${updatedBy.lastname}`],
          ],
        ]}
      </MetaInfo> */}
      {/* <hr /> */}

      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={updateError} />

      <CustomerForm
        data={emailProject}
        recruiterList={recruiters}
        submitButtonText="Kundenänderungen speichern"
        mutation={handleUpdateMuation(updateMutation)}
      />
    </fieldset>
  );
};

CustmerFormUpdate.propTypes = {};
CustmerFormUpdate.defaultProps = {};

export default CustmerFormUpdate;

// onLockClick -> onLock
// makeMutationHandler
