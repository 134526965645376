import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import createMutationHandler from '../../shared/create-mutationhandler';
import TransactionsTable from './Table.Transactions';
import Loading from '../../molecules/Loading';
import ButtonBox from '../../atoms/ButtonBox';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import UploadButton from './UploadButton';
import { bankTransactionsCSVUploadURL } from '../../../config';

const QUERY = gql`
  query GetBank($where: BankAccountWhereInput) {
    bankAccount(where: $where) {
      id
      name
      iban
      # updatedAt
      transactions {
        id
        buchungstag
        verwendungszweck
        betrag
        buchungstext
        # status
      }
    }
  }
`;

// const DELETE = gql`
//   mutation DeleteCustomer($where: BankTransactionWhereDeleteInput) {
//     deleteCustomer(where: $where) {
//       __typename
//       ... on BankTransaction {
//         customerId
//         typ
//         firmenname
//         anrede
//         name
//         vorname
//       }
//       ... on Unauthorized {
//         message
//       }
//     }
//   }
// `;

// const date = new Date();
// const DEFAULT_PATH = `/kunden/?updatedAt=${date.getFullYear()}-${date.getMonth() + 1}`;

const Kunden = ({ history, match }) => {
  const [selectedCSV, setSelectedCSV] = useState();

  const { loading: queryLoading, error: queryError, data: queryData = {}, refetch } = useQuery(QUERY, {
    variables: { where: { id: match.params.id } },
    fetchPolicy: 'network-only',
  });

  // DELETE-MUTATION
  const handleDeleteMutation = (mutation) => {
    const handledDeleteMuation = createMutationHandler({
      mutation,
      mutationName: 'deleteBill',
      mutationReturnTypes: {
        BankTransaction: {
          successMessage: (data) => `Rechnung mit der ID ${data.id} erfolgreich gelöscht`,
          onSuccess: () => history.push('/kunden'),
        },
      },
      serverErrorMessage: `Änderung der Rechnung nicht erfolgreich`,
    });

    return (values) =>
      handledDeleteMuation({
        variables: values.variables,
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  const { bankAccount = {} } = queryData;
  const { transactions = [] } = bankAccount;

  // ---------------------- HANDLERS ----------------------
  const fileSelectHandler = (event) => {
    setSelectedCSV(event.target.files[0]);
  };

  const fileUploadHandler = () => {
    const formData = new FormData();
    formData.append('csv-file', selectedCSV, selectedCSV.name);
    axios
      .post(`${bankTransactionsCSVUploadURL}/${bankAccount.id}`, formData, {
        withCredentials: true,

        onUploadProgress: (progressEvent) => {
          console.log('Upload Progress: ', `${Math.round((progressEvent.loaded / progressEvent.total) * 100)}%`);
        },
      })
      .then((res) => {
        NotificationManager.success('Bank-CSV erfolgreich hochgeladen', 'Erfolg');
        refetch();
        // history.push(`/bankaccounts/transactions/${bankAccount.id}`);
        // history.push(`/bankaccounts/transactions/${bankAccount.id}`);
      })
      .catch((err) => {
        // console.log('DAS IST ERROR: ', err);
        throw new Error(err);
      });
  };

  // const displayCusttomer =
  //   BankTransaction && BankTransaction.typ === 'Firma' //
  //     ? BankTransaction.firmenname
  //     : `${BankTransaction.anrede} ${BankTransaction.vorname} ${BankTransaction.name}`;

  return (
    // <fieldset disabled={deleteLoading}>
    <fieldset>
      {/* <h3>Transaktionen der Bank - {displayCusttomer}</h3> */}
      <h3>Transaktionen des Bankkonto: </h3>
      <div style={{ fontSize: '16px' }}>{bankAccount.name}</div>
      <div style={{ fontSize: '12px' }}>{bankAccount.id}</div>
      <hr />
      <div style={{ display: 'flex' }}>
        <ButtonBox
          name="button-receipt-statistics"
          theme="primary"
          icon="bar-chart"
          text="Statistiken"
          style={{ marginRight: '10px', marginBottom: '20px' }}
          onClick={() => history.push(`/bankaccounts/transactions/statistics`)}
        />
        <UploadButton onChange={fileSelectHandler} />
        {selectedCSV && (
          <div>
            <ButtonBox
              name="button-create-bill"
              theme="primary"
              icon="arrow-up"
              noMaxWidth
              text={`${selectedCSV.name} hochladen`}
              style={{ marginLeft: '10px', marginBottom: '20px' }}
              onClick={fileUploadHandler}
            />
          </div>
        )}
      </div>
      <div>
        <ErrorGraphQL error={queryError} />
        <TransactionsTable data={transactions} />
      </div>
    </fieldset>
  );
};

export default withRouter(Kunden);
