import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import ButtonBox from '../../../atoms/ButtonBox';
import ErrorGraphQL from '../../../../errors/ErrorGraphQL';
import createMutationHandler from '../../../shared/create-mutationhandler';
import Form from './Form';
import ViewTitle from '../../../atoms/ViewTitel';
import { ContentContainer } from '../../../shared/styles';

const QUERY = gql`
  query {
    # NEEDED FOR FIELDS SELECT OPTIONS
    users(where: { isLocked: false }) {
      userId
      firstname
      lastname
    }
    customers(where: {}) {
      customerId
      typ
      firmenname
      vorname
      name
      projectOfficers {
        projectOfficerId
        firstname
        lastname
      }
    }
  }
`;

const CREATE_MUTATION = gql`
  mutation CreateTimetrack($data: TimetrackCreateInput) {
    createTimetrack(data: $data) {
      __typename
      ... on Timetrack {
        timetrackId
        name
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
    }
  }
`;

const Create = ({ match, history }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: {} },
    fetchPolicy: 'network-only',
    // which triggers loading to change even when calling refetch
    // However, it causes fetchMore to trigger loading as well
    notifyOnNetworkStatusChange: true,
  });

  const [createMutation, { error: createError, loading: createLoading }] = useMutation(CREATE_MUTATION);

  // UPDATE-MUTATION
  const handleCreateMutation = (mutation) => {
    const handledCreateMutation = createMutationHandler({
      mutation,
      mutationName: 'createTimetrack',
      mutationReturnTypes: {
        Timetrack: {
          successMessage: (data) => `Zeiterfassung mit der ID ${data.timetrackId} erfolgreich erstellt`,
          onSuccess: () => history.push('/timetracks'),
        },
      },
      serverErrorMessage: `Änderung der Zeiterfassung nicht erfolgreich`,
    });

    return (values) =>
      handledCreateMutation({
        variables: {
          data: values,
        },
      });
  };

  const { users = [], customers = [] } = queryData;

  return (
    <>
      <ViewTitle>Neue Zeiterfassung erstellen</ViewTitle>
      <ContentContainer>
        <ButtonBox theme="primary" icon="arrow-left" text="Zurück" onClick={() => history.goBack()} />
      </ContentContainer>
      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={createError} />

      <Form
        loading={[createLoading]}
        submitButtonText="Zeiterfassung erstellen"
        mutation={handleCreateMutation(createMutation)}
        usersList={users}
        customersList={customers}
      />
    </>
  );
};

export default Create;
