/* eslint-disable react/display-name */
import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import CustomerForm from './Form';
import ButtonBox from '../../atoms/ButtonBox';
import { ContentContainer } from '../../shared/styles';
// import MetaInfo from '../../molecules/MetaInfo';
import MetaDataBox from '../../molecules/MetaInfoBox';

import Loading from '../../molecules/Loading';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import createMutationHandler from '../../shared/create-mutationhandler';
import ViewTitle from '../../atoms/ViewTitel';

const QUERY = gql`
  query GetCustomer($where: CustomerWhereInput) {
    customer(where: $where) {
      customerId
      typ
      firmenname
      anrede
      name
      vorname
      strasse
      hausnr
      plz
      ort
      telefon
      email
      fax
      ansprechname
      ansprechvorname
      ansprechtelefon
      ansprechemail
      umsatzsteuerid
      steuernr
      eusteuernr
      amtsgericht
      handelsregister
      bankname
      bic
      iban
      kontonr
      blz
      notizen
      projectOfficers {
        projectOfficerId
        firstname
        lastname
        email
        telefon
      }
      createdBy {
        firstname
        lastname
      }
      updatedBy {
        firstname
        lastname
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateCustomer($where: CustomerWhereUpdateInput, $data: CustomerUpdateInput) {
    updateCustomer(where: $where, data: $data) {
      __typename
      ... on Customer {
        customerId
        firmenname
        anrede
        name
        vorname
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
    }
  }
`;

const CustmerFormUpdate = ({ history, match }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: { customerId: match.params.id } },
    fetchPolicy: 'network-only',
  });
  const [updateMutation, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_MUTATION);
  const { customer = {} } = queryData;
  const { createdBy = {}, updatedBy = {} } = customer;

  const handleUpdateMuation = (mutation) => {
    const handledUpdateMuation = createMutationHandler({
      mutation,
      mutationName: 'updateCustomer',
      mutationReturnTypes: {
        Customer: {
          successMessage: (data) => `Kunde mit der Kundennummer ${data.customerId}, wurde erfolgreich verändert`,
          onSuccess: () => history.push('/kunden'),
        },
      },
      serverErrorMessage: `Änderung des Kunden nicht erfolgreich`,
    });

    return (values) =>
      handledUpdateMuation({
        variables: {
          where: { customerId: match.params.id },
          data: values,
        },
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  return (
    <>
      <ViewTitle>Kunde bearbeiten</ViewTitle>
      <ContentContainer>
        <ButtonBox
          theme="primary"
          icon="arrow-left"
          text="Zurück"
          style={{ margin: '0px 10px 20px 0px' }}
          onClick={() => history.goBack()}
        />
      </ContentContainer>
      <MetaDataBox
        style={{ marginBottom: '25px' }}
        columns={[
          [{ label: 'ID', value: customer.customerId }],
          [
            {
              label: 'Erstellt von',
              value: `${customer.createdBy.firstname} ${customer.createdBy.lastname}`,
            },
            { label: 'Erstellt am', value: customer.createdAt },
          ],
          [
            {
              label: 'Geändert von',
              value: `${customer.updatedBy.firstname} ${customer.updatedBy.lastname}`,
            },
            { label: 'Geändert am', value: customer.updatedAt },
          ],
        ]}
      />

      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={updateError} />
      <CustomerForm
        loading={[updateLoading]}
        data={customer}
        submitButtonText="Kundenänderungen speichern"
        onSubmit={handleUpdateMuation(updateMutation)}
      />
    </>
  );
};

CustmerFormUpdate.propTypes = {};
CustmerFormUpdate.defaultProps = {};

export default CustmerFormUpdate;

// onLockClick -> onLock
// makeMutationHandler
