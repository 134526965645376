import React, { useContext } from 'react';
import styled from 'styled-components';
import { TabsContext } from './Tabs';

const AccordionBody = props => {
  const { accordionContent } = useContext(TabsContext);
  const { children } = props;
  const accordionChildren = children || accordionContent;

  return <div {...props}>{accordionChildren}</div>;
};

const StyledAccordionBody = styled(AccordionBody)`
  display: none;
  flex: 0 0 100%;
  order: ${props => props.activeIndex};

  @media (max-width: 700px) {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }
`;

export default StyledAccordionBody;
