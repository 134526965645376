import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';

const THEME_COLOR = {
  primary: '#007bff',
  success: '#28a745',
  info: '#17a2b8',
  warning: '#ffc107',
  danger: '#dc3545',
  default: '#f8f9fa',
};

const ButtonStyle = styled.button`
  margin: 0 auto 0px;
  /* min-width: 470px; */
  min-width: 290px;
  max-width: 1200px;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* keine Ahnunge wieso das alles fixt */
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 0px;
  border-width: 0px;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  border: solid;

  &:hover {
    filter: brightness(90%);
  }

  background-color: ${(props) => THEME_COLOR[props.theme] || THEME_COLOR.default};
  border-color: ${(props) => (props.theme === 'default' ? '#adadad' : THEME_COLOR[props.theme])};
  color: ${(props) => (props.theme === 'default' || props.theme === 'warning' ? '#212529' : '#fff')};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-right: ${(props) => props.marginRight};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
`;

const Button = (props) => (
  <ButtonStyle {...props}>
    {props.text ? (
      <div>
        <FontAwesome name={props.icon} /> &nbsp; {props.text}
      </div>
    ) : (
      <div>
        <FontAwesome name={props.icon} />
      </div>
    )}
  </ButtonStyle>
);

Button.propTypes = {
  theme: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.string,
};

Button.defaultProps = {
  text: '',
  icon: '',
  theme: 'default',
  width: '100%',
};

export default Button;

// const Button = props => (
//   <StyledButton
//     {...props}
//   >
//     <FontAwesome name={props.icon} /> &nbsp;
//     {props.text}
//   </StyledButton>
// );
