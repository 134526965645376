import React from 'react';
// import { Navbar, NavItem, NavbarBrand } from 'react-bootstrap';
import styled from 'styled-components';

import { FaBars, FaUser, FaPowerOff } from 'react-icons/fa';

const HeaderStyle = styled.div`
  display: flex;
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 50px;
  padding-left: ${(props) => (props.isOpen ? '215px' : '55px')};

  justify-content: space-between;
  align-items: center;

  color: #656464;
  background-color: #ebedef;
  padding-right: 10px;
  transition: all 0.5s ease;

  @media (max-width: 768px) {
    padding-left: ${(props) => (props.isOpen ? '215px' : '10px')};
  }
`;

const CollapseButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  min-width: 30px;
  margin-right: 15px;

  &:hover {
    background-color: #abd6fb;
  }
`;

const SessionInfo = styled.div`
  display: flex;
  font-size: 12px;
`;

const SessionUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogOff = styled.div`
  user-select: none;
  cursor: pointer;
  display: flex;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
`;

const Header = ({ isOpen, handleClick, onLogout, user = {} }) => (
  <HeaderStyle isOpen={isOpen}>
    <CollapseButton onClick={handleClick}>
      <FaBars />
    </CollapseButton>

    {user.firstname && (
      <SessionInfo>
        <SessionUser>
          <FaUser style={{ marginRight: '5px' }} />
          <div>
            {user.firstname} {user.lastname}
          </div>
        </SessionUser>
        <LogOff onClick={onLogout}>
          <FaPowerOff style={{ marginRight: '5px' }} />
          <div>Abmelden</div>
        </LogOff>
      </SessionInfo>
    )}
  </HeaderStyle>
);

export default Header;
