import React from 'react';
import FontAwesomeIcon from 'react-fontawesome';

const Loading = () => (
  <div style={{ margin: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <div>
      <FontAwesomeIcon name="spinner" spin size="3x" />
    </div>
    <div style={{ marginLeft: '15px', fontWeight: 'bold' }}>laden bitte warten...</div>
  </div>
);

export default Loading;
