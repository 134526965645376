import axios from 'axios';
import { apiUrl } from '../config';

export default function api({ method, path, data }) {
  const config = {
    credentials: 'same-origin',
    withCredentials: true,
    url: `${apiUrl}${path}`,
    method,
    data,
  };

  return axios(config)
    .then(response => ({ response }))
    .catch(error => {
      //
      if (error.message === 'Network Error') {
        return {
          error: {
            title: 'Netzwerk-Fehler',
            message: `
              Server kann nicht erreicht werden.
              Prüfen ihre Internetverbindung.
            `,
          },
        };
      }

      const errorCases = {
        401: {
          status: 401,
          title: 'Authentifizierungsfehler',
          message: error.response.data.message,
        },
        404: {
          status: 404,
          title: 'HTTP 404',
          message: 'Server kann nich gefunden werden',
        },
        422: {
          status: 422,
          title: 'Validierungsfehler',
          message: error.response.data.message,
          errorType: error.response.data.errorType,
          error: error.response.data.error,
        },
        409: {
          status: 409,
          title: 'Validierungsfehler',
          message: error.response.data.message,
          errorType: error.response.data.errorType,
          error: error.response.data.error,
        },
        unknownError: {
          status: 500,
          title: 'Unbekannter Server-Fehler',
          message: error.message,
        },
      };

      // console.log('+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++');
      // console.log('DAS IST API LIB: ');
      // console.log(error.response);
      // console.log('+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++');

      return {
        error: errorCases[error.response.status] || errorCases.unknownError,
      };
    });
}
