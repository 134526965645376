import React from 'react';
// import moment from 'moment';
// import 'moment/locale/de';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import styled from 'styled-components';

import { createViewData } from './dynamic-time-tracker-utils';
import { Label, Row, TData, Header, TimeListTable } from './style';
import Button from '../../../atoms/Button';
import Modal from '../../../molecules/Modal';

import TimeInput from '../../../molecules/TimeInput';
import MinuteInput from '../../../molecules/MinuteInput';

const TimeListDefault = ({
  // getWorkingDays,
  // getWorkingHours,
  dynamicTimetrack, // single time track, contains default values
  updateTimeTrack,
  addNewTimeTrack,
  //
  sortTimeTracks,
  //
  timetrack = {},
  tracks = [],
  //
  updateDynamicTimetrackById,
  deleteDynamicDay,
  addDay,
  submitButtonText,
  onSubmit,
  year,
  month,
  daysOfMonth,
}) => {
  // FOR METADATA
  const { owner = {}, customer = {}, createdBy = {}, updatedBy = {} } = timetrack;

  const getCustomer = () => {
    const { customer = {} } = timetrack;
    return customer.typ === 'Firma' ? `${customer.firmenname}` : `${customer.vorname} ${customer.name}`;
  };

  const getManager = () => {
    const { manager = {} } = timetrack;
    if (!manager) {
      return 'Kein Projektverantwortlicher';
    }
    return `${manager.firstname || ''} ${manager.lastname || ''}`;
  };

  return (
    <>
      {/* ------------------- CREATE NEW TIME TRACK ----------------------- */}
      <Modal
        minWidth="350px"
        okButtonName="ok-modal-button"
        chancelButtonName="chancel-modal-button"
        theme="info"
        header="Neue dynamische Zeit erstellen"
        message={
          <div>
            <Label>Tag der Zeiterfassung</Label>
            <div style={{ display: 'flex', fontSize: '16px', alignItems: 'baseline' }}>
              <input
                style={{
                  width: '30px',
                  fontSize: '16px',
                  borderRadius: '0px',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  textAlign: 'right',
                  marginBottom: '20px',
                }}
                value={dynamicTimetrack.day}
                onChange={(event) => updateTimeTrack('day')(event.target.value)}
              />
              .{`${timetrack.month}`.padStart(2, 0)}.{timetrack.year}
            </div>
            <Label>Uhrzeiten</Label>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '3px' }}>von</div>
              <TimeInput //
                value={dynamicTimetrack.start}
                onChange={updateTimeTrack('start')}
              />
              <div style={{ marginLeft: '10px', marginRight: '3px' }}>bis</div>
              <TimeInput //
                value={dynamicTimetrack.end}
                onChange={updateTimeTrack('end')}
              />

              <div style={{ marginLeft: '20px', marginRight: '3px' }}>Pause</div>
              <MinuteInput //
                value={dynamicTimetrack.lunchBreak}
                onChange={updateTimeTrack('lunchBreak')}
              />
            </div>

            <Label style={{ marginTop: '20px' }}>Tätigkeit</Label>
            <input
              style={{
                width: '100%',
                fontSize: '16px',
                borderRadius: '0px',
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
              value={dynamicTimetrack.activity}
              onChange={(event) => updateTimeTrack('activity')(event.target.value)}
            />

            <Label style={{ marginTop: '20px' }}>Standort</Label>
            <input
              style={{
                width: '100%',
                fontSize: '16px',
                borderRadius: '0px',
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
              value={dynamicTimetrack.location}
              onChange={(event) => updateTimeTrack('location')(event.target.value)}
            />
          </div>
        }
        // ON OKAY BUTTON PRESS
        applyFunction={addNewTimeTrack}
      >
        <Button //
          theme="primary"
          icon="plus"
          text="Neue dynamische Zeiterfassung erstellen"
          marginBottom="20px"
        />
      </Modal>

      {/* <pre>{JSON.stringify(tracksState, null, 2)}</pre> */}
      <TimeListTable>
        <thead>
          <Row>
            <Header colSpan="2">Datum</Header>
            <Header align="center">Von</Header>
            <Header align="center">Bis</Header>
            <Header align="center">Pause</Header>
            <Header>Tätigkeit</Header>
            <Header>Standort</Header>
            <Header colSpan="2" align="right">
              Gesamt
            </Header>
          </Row>
        </thead>
        <tbody>
          {tracks.map((track, index) => {
            const { day } = track;
            // just view data like Mo, Di, Mi, Fr, Sa, So, Berechnung von stunden etc.
            const {
              date,
              hasDay,
              weekDay,
              isWeekend,
              start,
              end,
              lunchBreak,
              activity,
              location,
              nettoTime,
            } = createViewData({
              year,
              month,
              day,
              timetrack: track,
            });

            return (
              <Row
                key={track.artificalId}
                style={{
                  backgroundColor: isWeekend ? '#dfe3ee' : '',
                  opacity: hasDay ? '' : '0.7',
                }}
              >
                {/* MO, DI, MI, DO, FR, SA, SO */}
                <TData width="20px">{weekDay}</TData>
                <TData width="80px">{date}</TData>

                <TData width="65px">
                  <TimeInput //
                    value={start}
                    onChange={updateDynamicTimetrackById(track.artificalId, 'start')}
                    onBlur={sortTimeTracks}
                  />
                </TData>
                <TData width="65px">
                  <TimeInput
                    value={end} //
                    onChange={updateDynamicTimetrackById(track.artificalId, 'end')}
                  />
                </TData>
                <TData width="65px">
                  <div style={{ display: 'flex' }}>
                    <MinuteInput //
                      value={lunchBreak}
                      onChange={updateDynamicTimetrackById(track.artificalId, 'lunchBreak')}
                    />
                    <div style={{ marginTop: '2px', marginLeft: '3px' }}>min</div>
                  </div>
                </TData>
                <TData>
                  <input
                    style={{
                      width: '100%',
                      fontSize: '16px',
                      borderRadius: '0px',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                    }}
                    value={activity}
                    onChange={(event) => updateDynamicTimetrackById(track.artificalId, 'activity')(event.target.value)}
                  />
                </TData>
                <TData>
                  <input
                    style={{
                      width: '100%',
                      fontSize: '16px',
                      borderRadius: '0px',
                      borderWidth: '1px',
                      borderStyle: 'solid',
                    }}
                    value={location}
                    onChange={(event) => updateDynamicTimetrackById(track.artificalId, 'location')(event.target.value)}
                  />
                </TData>
                <TData width="70px" align="right">
                  {nettoTime} h
                </TData>
                <TData width="35px" align="center" onClick={() => deleteDynamicDay(index)} cursor="pointer">
                  <FontAwesomeIcon icon={faTrash} />
                </TData>
              </Row>
            );
          })}
        </tbody>
      </TimeListTable>
      <Button theme="success" icon="save" text={submitButtonText} style={{ marginTop: '20px' }} onClick={onSubmit} />
    </>
  );
};

export default TimeListDefault;
