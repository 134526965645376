import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import Button from '../../atoms/Button';

const themeColor = {
  info: '#2e84b7',
  danger: '#CC334D',
  success: '#5CB85C',
  neutral: '#D1D5D8',
};

const ModalBackground = styled.div`
  /* min-width: 480px; */
  display: flex;
  flex-direction: column;
  transition: opacity 300ms ease-in-out;
  position: fixed;
  /* justify-content: center; */
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999999;
  padding: 10px;
`;

const ModalBox = styled.div`
  min-width: 480px;
`;

const ModalContentStyle = styled.div`
  width: 100%;
  background-color: white;
  color: #494949;
  padding: 10px;
`;

const ModalHeaderStyle = styled.div`
  display: flex;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  /* color: #494949; */
  color: white;
  position: relative;
  margin-top: 50px;
  background-color: ${(props) => themeColor[props.theme]};
  font-weight: bold;
  font-size: 120%;
  padding: 10px;
`;

const CloseButtonStyle = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
  padding: 5px;
  width: 20px;
  height: 20px;

  :hover {
    color: #494949;
    background-color: white;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: white;
  color: #494949;
  padding: 10px;
  /* padding-top: 0px; */
  /* padding: -10px 10px 10px 10px; */
`;

const HorzontalLine = styled.hr`
  padding: 0px;
  margin: 0px 0px 0px 0px;
  border-color: 494949;
`;

const Modal = ({
  children,
  title,
  content,
  theme,
  onOkay,
  showOk = true,
  showCancel = true,
  okayLabel,
  cancelLabel,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  // const toggleModal = () => {
  //   console.log("LÄUFT IS OPEN")
  //   console.log("DAS IST ISOPEN: ", isOpen)
  //   // setIsOpen((prevState) => !prevState);
  //   setIsOpen(false);
  // };

  return (
    <>
      {isOpen && (
        <ModalBackground>
          <ModalBox>
            <ModalHeaderStyle theme={theme}>
              {title}
              <CloseButtonStyle onClick={() => setIsOpen(false)}>
                <FaTimes size="10px" />
              </CloseButtonStyle>
            </ModalHeaderStyle>
            <ModalContentStyle>{content}</ModalContentStyle>
            <HorzontalLine />
            <ModalActions>
              {showOk && (
                <div style={{ marginRight: '10px' }}>
                  <Button
                    text={okayLabel || 'OK'}
                    theme="default"
                    width="150px"
                    onClick={() => {
                      if (onOkay) {
                        onOkay();
                      }
                      setIsOpen(false);
                    }}
                  />
                </div>
              )}
              {showCancel && (
                <div>
                  <Button
                    text={cancelLabel || 'Abbrechen'}
                    theme="danger"
                    width="150px"
                    onClick={() => setIsOpen(false)}
                  />
                </div>
              )}
            </ModalActions>
          </ModalBox>
        </ModalBackground>
      )}
      <div style={{ width: '100%' }} onClick={() => setIsOpen(true)}>
        {children}
      </div>
    </>
  );
};

export default Modal;
