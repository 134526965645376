import styled from 'styled-components';

export const GridLayout = styled.div`
  /*
  Dieses Media-Query wird aktiv wenn das Browserfenster mindestens 760px weit ist.
  Ist das Media-Query aktiv überschreibt es Eigenschaften oder fügt Eigenschaften
  hinzu die im Media-Query-Körper definiert sind. Die Reihenfolge ist egal
  */
  /* @media screen and (min-width: 900px) { */
  @media screen and (min-width: 760px) {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-areas: 'picture-area form-area';
    grid-column-gap: 20px;
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'picture-area'
    'form-area';
`;

export const DropArea = styled.div`
  grid-area: picture-area;
  display: flex;
  background-color: #dce5e9;
  padding: 10px;
  justify-content: center;
  width: 430px;
  height: auto;
  color: grey;
  border-style: dashed;
`;

export const FormLayout = styled.div`
  grid-area: form-area;
`;
