import React from 'react';
import { Form } from 'react-final-form';
import pick from 'lodash.pick';
import createDecorator from 'final-form-focus';
import arrayMutators from 'final-form-arrays';
import { NotificationManager } from 'react-notifications';
import formatValues from '../utils/format-values';
import ErrorFormBox from '../final-form-elements/ErrorFormBox';

const focusOnErrors = createDecorator();

const createInitialValues = ({ data, defaults = {} }) => {
  // your data has a, b and c, but you only have a, so b and c will be added
  // otherwise use data of data
  const setData = Object.entries(defaults).reduce((accu, [key, value]) => {
    if (!data[key]) {
      return { ...accu, [key]: value };
    }
    return { ...accu, [key]: data[key] };
  }, {});

  return setData;
};

const withFinalForm = (props, FormComponent) => {
  const {
    // onSubmit,
    validator,
    submitButtonText,
    submitHandler,
    // formDataItems,
    initialValues,
    allowedAttributes,
    valuesFormat,
    attributesFilter,
  } = props;

  return (
    <Form
      // to prevent React-Final-Form to reset the form values after user submits the form
      keepDirtyOnReinitialize
      decorators={[focusOnErrors]}
      mutators={{ ...arrayMutators }}
      // validateOnBlur means: validation ON BLUR ONLY, nothing more
      // validate is used for live errors of forms, like if you onBlur a form
      validate={async (values) => {
        if (valuesFormat) {
          return validator(formatValues(valuesFormat, values));
        }
        return validator(values);
      }}
      onSubmit={async (values) => {
        // console.log("DAS IST VALUES FORM ONSUBMIT: ", values);
        // 1. PICK: pick only allowed attributes and neglect the rest
        const allowedValues = pick(values, allowedAttributes);
        // const allowedOriginalValues = pick(initialValues, allowedAttributes);

        // 2. FORMAT: change the values like object to string
        const formattedValues = valuesFormat //
          ? formatValues(valuesFormat, allowedValues)
          : allowedValues;

        // const formattedOriginalValues = valuesFormat
        //   ? formatValues(valuesFormat, allowedOriginalValues)
        //   : allowedOriginalValues;

        // 3. VALIDATE: validate values
        const InvalidDatas = await validator(formattedValues);

        if (InvalidDatas) {
          NotificationManager.error('Bitte überprüfen Sie ihre Eingaben', 'Validierungsfehler');
          return InvalidDatas;
        }

        // // *******************************************************************
        // const changedFormData = diff(formattedOriginalValues, formattedValues);
        // returns array as object with index as keys { 0: x, 1: y, 2: z }
        // // *******************************************************************

        // FINAL VALUES TO SERVER
        // return submitHandler(changedFormData);
        return submitHandler(formattedValues);
      }}
      initialValues={createInitialValues(initialValues)}
      render={(formProps) => (
        <div>
          <ErrorFormBox formProps={formProps} />
          <FormComponent {...formProps} submitButtonText={submitButtonText} />
        </div>
      )}
    />
  );
};

export default withFinalForm;
