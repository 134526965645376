// import { NotificationManager } from 'react-notifications';

export default (message = 'Keine Fehlermeldung') => err => {
  // console.log('SERVER FEHLER AUS MUTATION: ', err);
  // NotificationManager.error(message, 'Server-Fehler');

  // TODO: PROPS ENV DEVELOPMENT EINFÜGEN
  // console.log('DAS IST ERROR SUBMIT: ', err);
  window.scrollTo(0, 0);

  return {
    unknownError: true,
    graphQLError: err,
  };
};
