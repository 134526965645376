import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import createMutationHandler from '../../shared/create-mutationhandler';
import Table from './Table';
import Loading from '../../molecules/Loading';
import ButtonBox from '../../atoms/ButtonBox';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';

import Title from '../../molecules/Title';

const QUERY = gql`
  query GetBank($where: BankAccountWhereInput) {
    bankAccounts(where: $where) {
      id
      name
      iban
    }
  }
`;

const DELETE = gql`
  mutation DeleteCustomer($where: CustomerWhereDeleteInput) {
    deleteCustomer(where: $where) {
      __typename
      ... on BankAccount {
        customerId
        typ
        Bankme
        anrede
        idnamevorname
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

// const date = new Date();
// const DEFAULT_PATH = `/kunden/?updatedAt=${date.getFullYear()}-${date.getMonth() + 1}`;

const Kunden = ({ history }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: {} },
    fetchPolicy: 'network-only',
  });

  const [deleteMutation, { error: deleteError, loading: deleteLoading }] = useMutation(DELETE, {
    refetchQueries: () => [
      {
        query: QUERY,
        variables: {
          where: {},
        },
        v: Math.random(),
      },
    ],
  });

  const fileSelectHandler = (event) => {
    // setSelectedCsv(event.target.files[0])
  };

  const fileUploadHandler = () => {
    // const formData = new FormData();
    // formData.append('csv', selectedCsv, selectedCsv.name);
    // axios.post(URL, formData, {
    // onUploadProgress: ProgressEvent => {
    // console.log('Upload Progress: ', Math.round(progressEvent.loaded / progressEvent.total) * 100) + '%')
    // }
    // })
    // .then(res => {
    //   console.log(res)
    // })
  };

  // DELETE-MUTATION
  const handleDeleteMutation = (mutation) => {
    const handledDeleteMuation = createMutationHandler({
      mutation,
      mutationName: 'deleteCustomer',
      mutationReturnTypes: {
        BankAccount: {
          successMessage: (data) => `Bankkonto mit der ID ${data.customerId} erfolgreich gelöscht`,
          onSuccess: () => history.push('/bankaccounts'),
        },
      },
      serverErrorMessage: `Änderung der Bank nicht erfolgreich`,
    });

    return (values) =>
      handledDeleteMuation({
        variables: values.variables,
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  const { bankAccounts = [] } = queryData;

  return (
    <fieldset disabled={deleteLoading}>
      <Title>Bankkonten</Title>
      <hr />
      <div style={{ display: 'flex' }}>
        <ButtonBox
          name="button-create-customer"
          theme="primary"
          icon="bank"
          text="Neues Bankkonto"
          style={{ marginRight: '10px', marginBottom: '20px' }}
          onClick={() => history.push(`/bankaccounts/create`)}
        />
      </div>
      <div>
        <ErrorGraphQL error={queryError} />
        <ErrorGraphQL error={deleteError} />
        <Table
          data={bankAccounts}
          onDelete={(row) => {
            const handledDeleteMutation = handleDeleteMutation(deleteMutation);
            handledDeleteMutation({
              variables: { where: { customerId: row.customerId } },
            });
          }}
        />
      </div>
    </fieldset>
  );
};

export default withRouter(Kunden);
