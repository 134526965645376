import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import AppContext from '../../../context/App.Context';
import ButtonBox from '../../atoms/ButtonBox';
import ViewTitle from '../../atoms/ViewTitel';
import { ContentContainer } from '../../shared/styles';
// import hasPermissions from '../../../lib/has-permissions';

const MemberArea = ({ history }) => {
  const { sessionUser } = useContext(AppContext);
  const { firstname, lastname } = sessionUser;

  return (
    <>
      <ViewTitle>
        <div style={{ display: 'flex' }}>
          <div>
            {firstname} {lastname}
          </div>
          <div style={{ marginLeft: '10px' }}>السلام عليكم</div>
        </div>
      </ViewTitle>
      <ContentContainer>
        <ButtonBox theme="primary" icon="clock" text="Zu meinen Zeiten" onClick={() => history.push(`/timetracks`)} />
      </ContentContainer>
    </>
  );
};

// export default MemberArea;
export default withRouter(MemberArea);
