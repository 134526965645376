import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const themeColor = {
  primary: '#007bff',
  success: '#28a745',
  info: '#17a2b8',
  warning: '#ffc107',
  danger: '#dc3545',
  default: '#f8f9fa',
};

const Box = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  border-radius: 0px;
  border: solid;
  border-width: 1px;
  max-width: ${({ width }) => width || `110px`};
  background-color: ${({ theme }) => themeColor[theme] || themeColor.default};
  border-color: ${({ theme }) => (theme === 'default' ? '#adadad' : themeColor[theme])};
`;

const ButtonBox = props => <Box {...props}>{props.children}</Box>;

ButtonBox.propTypes = {
  theme: PropTypes.string,
};

ButtonBox.defaultProps = {
  theme: 'default',
  // width: '100%',
};

export default ButtonBox;
