import * as yup from 'yup';
import { setIn } from 'final-form';

const schema = yup.object({
  // customer: yup
  //   .string()
  //   .required('Kunde ist notwendig')
  //   .min(3, 'Kunde muss mindestens 3 Zeichen haben'),
  name: yup
    .string()
    .strict() // strict sorgt dafür das keine koresion gemacht wird, wenn es int ist bleibt es int und wird nicht zu string, also strict der wert
    .required('Belegnummer / Rechnungsnummer / Bezeichnung ist notwendig')
    .min(3, 'Belegnummer / Rechnungsnummer / Bezeichnung muss mindestens 3 Zeichen haben'),
  // manager: yup
  //   .string()
  //   .required('Projektverantwortlicher ist notwendig')
  //   .min(3, 'Projektverantwortlicher muss mindestens 3 Zeichen haben'),
  supplier: yup.lazy(
    (value) =>
      !value //
        ? yup.string().nullable()
        : yup.string()
    // : yup.string().matches(/^\d+$/, 'Konto / Zahlungsmittel ist nicht gültig')
  ),
  // .required('Lieferant muss ausgewähl werden') //
  // .matches(/^\d+$/, 'Lieferant ist nicht gültig'),
  payOption: yup.lazy(
    (value) =>
      !value //
        ? yup.string().nullable()
        : yup.string()
    // : yup.string().matches(/^\d+$/, 'Konto / Zahlungsmittel ist nicht gültig')
  ),
  // yup
  // .string()
  // .required('Konto/Zahlungsmittel muss ausgewähl werden')
  // .matches(/^\d+$/, 'Konto / Zahlungsmittel ist nicht gültig'),
  positions: yup.array().of(
    yup.object().shape({
      description: yup
        .string()
        .required('Beschreibung muss eingegeben werden')
        .min(3, 'Beschreibung muss mindestens 3 Zeichen haben'),
      category: yup
        .string() //
        .required('Kategorie muss ausgewähl werden'), //
    })
  ),
});

// users: Yup.array().of(
//     Yup.object().shape({
//       userName: Yup.string().required("Required"),
//       hobbies: Yup.array().of(
//         Yup.object().shape({
//           hobby: Yup.string().required("Required")
//         })
//       )
//     })
//   )

//
//
//
//
// VALIDATOR: values => object of keys with error message
const validator = async (values) => {
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (error) {
    return error.inner.reduce((errors, error) => setIn(errors, error.path, error.message), {});
  }
};

export default validator;
