import React from 'react';
// import PropTypes from 'prop-types';
// import FontAweseome from 'react-fontawesome';

export default function FormGroup(props) {
  const formGroupStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    flexGrow: 1,
    // margin: '-10px',
    // margin: '-8px',
    margin: '0px',
    ...(props.style ? props.style : null),
  };

  return <div style={formGroupStyle}>{props.children}</div>;
}
