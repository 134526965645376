import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import createMutationHandler from '../../shared/create-mutationhandler';
import CustomersTable from './Table';
import Loading from '../../molecules/Loading';
import ButtonBox from '../../atoms/ButtonBox';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import ViewTitle from '../../atoms/ViewTitel';
import { ContentContainer } from '../../shared/styles';

const QUERY = gql`
  query GetCustomer($where: CustomerWhereInput) {
    customers(where: $where) {
      customerId
      typ
      firmenname
      anrede
      name
      vorname
      strasse
      hausnr
      plz
      ort
      telefon
      email
      fax
      ansprechname
      ansprechvorname
      ansprechtelefon
      ansprechemail
      umsatzsteuerid
      steuernr
      eusteuernr
      amtsgericht
      handelsregister
      bankname
      bic
      iban
      kontonr
      blz
      notizen
      createdAt
      updatedAt
      createdBy {
        firstname
        lastname
      }
      updatedBy {
        firstname
        lastname
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteCustomer($where: CustomerWhereDeleteInput) {
    deleteCustomer(where: $where) {
      __typename
      ... on Customer {
        customerId
        typ
        firmenname
        anrede
        name
        vorname
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

// const date = new Date();
// const DEFAULT_PATH = `/kunden/?updatedAt=${date.getFullYear()}-${date.getMonth() + 1}`;

const Kunden = ({ history }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: {} },
    fetchPolicy: 'network-only',
  });

  const [deleteMutation, { error: deleteError, loading: deleteLoading }] = useMutation(DELETE, {
    refetchQueries: () => [
      {
        query: QUERY,
        variables: {
          where: {},
        },
        v: Math.random(),
      },
    ],
  });

  // DELETE-MUTATION
  const handleDeleteMutation = (mutation) => {
    const handledDeleteMuation = createMutationHandler({
      mutation,
      mutationName: 'deleteCustomer',
      mutationReturnTypes: {
        Customer: {
          successMessage: (data) => `Kunde mit der ID ${data.customerId} erfolgreich gelöscht`,
          onSuccess: () => history.push('/kunden'),
        },
      },
      serverErrorMessage: `Änderung des Kunden nicht erfolgreich`,
    });

    return (values) =>
      handledDeleteMuation({
        variables: values.variables,
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  const { customers = [] } = queryData;

  return (
    <fieldset disabled={deleteLoading}>
      <ViewTitle>Kundenliste</ViewTitle>
      <ContentContainer>
        <ButtonBox
          name="button-create-customer"
          theme="primary"
          icon="user"
          text="Neuen Kunden"
          style={{ marginRight: '10px', marginBottom: '20px' }}
          onClick={() => history.push(`/kunden/create`)}
        />
      </ContentContainer>
      <div>
        <ErrorGraphQL error={queryError} />
        <ErrorGraphQL error={deleteError} />
        <CustomersTable
          data={customers}
          onDelete={(row) => {
            const handledDeleteMutation = handleDeleteMutation(deleteMutation);
            handledDeleteMutation({
              variables: { where: { customerId: row.customerId } },
            });
          }}
        />
      </div>
    </fieldset>
  );
};

export default withRouter(Kunden);
