import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { FaUsers, FaEuroSign, FaFile, FaClock, FaAddressCard, FaTags, FaBuilding } from 'react-icons/fa';
import styled from 'styled-components';
import hasPermissions from '../../../lib/has-permissions';
import { GroupElements } from './GroupElements';

const SideBarStyle = styled.ul`
  display: flex;
  position: absolute;

  user-select: none;
  background-color: #263238;
  /* width: ${(props) => (props.isOpen ? '250px' : '45px')}; */
  width: ${(props) => (props.isOpen ? '200px' : '45px')};
  min-height: 100vh;
  position: relative;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0px;

  overflow-x: hidden;
  overflow-y: auto;

  list-style-type: none;
  transition: all 0.5s ease;

  z-index: 1000;


  @media (max-width: 768px) {
    width: ${(props) => (props.isOpen ? '200px' : '0px')};
  }
`;

const MenuItem = styled(NavLink)`
  cursor: pointer;
  display: flex;
  margin-bottom: 0;
  align-items: center;
  line-height: 40px;
  color: #b8c7ce;
  font-size: 14px;
  width: 250px;

  &:hover {
    text-decoration: none;
    color: white;
    background-color: #1e282c;
  }

  &.active {
    text-decoration: none;
    color: white;
    background-color: #1e282c;
  }
`;

const MenuChildItem = styled(NavLink)`
  margin-bottom: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 40px;
  color: #b8c7ce;
  font-size: 14px;
  width: 200px;

  &:hover {
    text-decoration: none;
    color: white;
    background-color: #2c6a9e;
  }

  &.active {
    text-decoration: none;
    color: white;
    background-color: #2c6a9e;
  }
`;

const Label = styled.div`
  margin-left: 3px;
`;

const IconStyle = styled.div`
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Icon = ({ icon }) => <IconStyle>{icon}</IconStyle>;

const SideBar = ({ isOpen, onSidebarClose, sessionUser }) => (
  <SideBarStyle isOpen={isOpen}>
    <NavLink to="/membeer-area" style={{ textDecoration: 'none', width: '200px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px 0px 10px 0px',
          color: 'white',
          fontWeight: 'bold',
          fontSize: '16px',
        }}
      >
        <FaBuilding size="30px" style={{ paddingLeft: '13px' }} />
        <div style={{ marginLeft: '18px' }}>Office-Manager</div>
      </div>
    </NavLink>
    {hasPermissions(sessionUser.roles, ['CAN_CREATE_USERS']) && (
      <>
        <MenuItem to="/kunden" onClick={onSidebarClose}>
          <Icon icon={<FaUsers />} />
          <Label>Kunden</Label>
        </MenuItem>

        <MenuItem to="/rechnungen" onClick={onSidebarClose}>
          <Icon icon={<FaEuroSign />} />
          <Label>Rechnungen</Label>
        </MenuItem>

        <MenuItem to="/offers" onClick={onSidebarClose}>
          <Icon icon={<FaFile />} />
          <Label>Angebote</Label>
        </MenuItem>

        {/* 
        <MenuItem to="/bankaccounts">
          <Icon icon={<AiFillBank />} />
          <Label>Bankkonten</Label>
        </MenuItem>

        <MenuItem to="/beneficiaries">
          <Icon icon={<RiUserReceived2Line />} />
          <Label>Begünstigte</Label>
        </MenuItem>

        <MenuItem to="/belege">
          <Icon icon={<AiFillBank />} />
          <Label>Belege</Label>
        </MenuItem>

        <MenuItem to="/it-jobs">
          <Icon icon={<FaHammer />} />
          <Label>IT-Berufe</Label>
        </MenuItem>

        <MenuItem to="/recruiters">
          <Icon icon={<FaHandshake />} />
          <Label>Rekruter</Label>
        </MenuItem>

        <GroupElements title="Projekverwaltung" icon={<FaAddressCard maxheight="80px" />}>
          <MenuChildItem to="/projektangebote/unfocused">
            <Icon icon={<FaAddressCard />} />
            <Label>Unfokusierte</Label>
          </MenuChildItem>

          <MenuChildItem to="/projektangebote/focused">
            <Icon icon={<FaTags />} />
            <Label>Fokusierte</Label>
          </MenuChildItem>
        </GroupElements>
        */}

        <MenuItem to="/timetracks" onClick={onSidebarClose}>
          <Icon icon={<FaClock />} />
          <Label>Zeiterfassungen</Label>
        </MenuItem>
      </>
    )}
    {/* ---------------------------------------- */}

    {hasPermissions(sessionUser.roles, ['CAN_CREATE_USERS']) && (
      <GroupElements title="Benutzerverwaltung" icon={<FaAddressCard />}>
        <MenuChildItem to="/benutzer" onClick={onSidebarClose}>
          <Icon icon={<FaAddressCard />} />
          <Label>Benutzer</Label>
        </MenuChildItem>

        <MenuChildItem to="/rollen" onClick={onSidebarClose}>
          <Icon icon={<FaTags />} />
          <Label>Rollen</Label>
        </MenuChildItem>
      </GroupElements>
    )}
  </SideBarStyle>
  // </div>
);

// export default SideBar;
export default withRouter(SideBar);
