import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
// import Switch from 'react-switch';

import createMutationHandler from '../../../shared/create-mutationhandler';
import Loading from '../../../molecules/Loading';
import ErrorGraphQL from '../../../../errors/ErrorGraphQL';
import MetaDataBox from '../../../molecules/MetaInfoBox';
import ButtonBox from '../../../atoms/ButtonBox';
import Form from './Form';
import { clientUrl } from '../../../../config';
import ViewTitle from '../../../atoms/ViewTitel';
import { ContentContainer } from '../../../shared/styles';

const QUERY = gql`
  query GetTimetrack($where: TimetrackWhereInput) {
    timetrack(where: $where) {
      timetrackId
      timetrackType
      name
      createdAt
      updatedAt
      provision
      wage
      notes
      year
      month
      random
      isHidden
      isLocked
      isManagerSigned
      isFreelancerSigned
      signMailSend
      signMailSendTo
      manager {
        projectOfficerId
        firstname
        lastname
      }
      customer {
        customerId
        typ
        firmenname
        vorname
        name
      }
      owner {
        userId
        firstname
        lastname
      }
      createdBy {
        firstname
        lastname
      }
      updatedBy {
        firstname
        lastname
      }
    }
    # NEEDED FOR FIELDS SELECT OPTIONS
    users(where: { isLocked: false }) {
      userId
      firstname
      lastname
    }
    customers(where: {}) {
      customerId
      typ
      firmenname
      vorname
      name
      projectOfficers {
        projectOfficerId
        firstname
        lastname
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateTimetrack($where: TimetrackWhereUpdateInput, $data: TimetrackUpdateInput) {
    updateTimetrack(where: $where, data: $data) {
      __typename
      ... on Timetrack {
        timetrackId
        name
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

const Update = ({ match, history }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: { timetrackId: match.params.id } },
    fetchPolicy: 'network-only',
  });
  const [updateMutation, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_MUTATION);

  const { timetrack = {}, users = [], customers = [] } = queryData;
  const { createdBy = {}, updatedBy = {}, owner = {} } = timetrack;

  // console.log("DAS IST TIMETRACK: ", timetrack)

  const getCustomer = () => {
    const { customer } = timetrack;
    return customer.typ === 'Firma' ? `${customer.firmenname}` : `${customer.vorname} ${customer.name}`;
  };

  // DUPLICATED CODE; BITTE WEG MACHEN UND IN UPDATE CREATE UND ANDEREN STELLEN AUS EINER STELLE (utils) hier importieren
  const getManager = () => {
    const { manager } = timetrack;
    if (!manager) {
      return 'Kein Projektverantwortlicher';
    }

    return `${manager.firstname || ''} ${manager.lastname || ''}`;
  };

  const handleUpdateMutation = (mutation) => {
    const handledUpdateMuation = createMutationHandler({
      mutation,
      mutationName: 'updateTimetrack',
      mutationReturnTypes: {
        Timetrack: {
          successMessage: (data) => `Zeiterfassung mit der ID ${data.timetrackId} erfolgreich verändert`,
          onSuccess: () => history.push('/timetracks'),
        },
      },
      serverErrorMessage: `Änderung der Zeiterfassung nicht erfolgreich`,
    });

    return (values) =>
      handledUpdateMuation({
        variables: {
          where: { timetrackId: match.params.id },
          data: values,
        },
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  return (
    <>
      <ViewTitle>Zeiterfassung ändern</ViewTitle>
      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={updateError} />

      <ContentContainer>
        <ButtonBox theme="primary" icon="arrow-left" text="Zurück" onClick={() => history.push('/timetracks')} />
      </ContentContainer>
      <MetaDataBox
        style={{ marginBottom: '25px' }}
        columns={[
          [
            { label: 'ID', value: `${timetrack.timetrackId} - ${timetrack.name}` },
            { label: 'Leistungsbringer', value: `${timetrack.owner.firstname} ${timetrack.owner.lastname}` },
            { label: 'Unterschrift-URL', value: `${clientUrl}/signature/${timetrack.timetrackId}/${timetrack.random}` },
            {
              label: 'Erstellt von',
              value: `${timetrack.createdBy.firstname} ${timetrack.createdBy.lastname}`,
              style: { marginTop: '10px' },
            },
            { label: 'Erstellt am', value: timetrack.createdAt },
          ],
          [
            { label: 'Kunde', value: getCustomer() },
            { label: 'Projektverantwortlicher', value: getManager() },
            { label: 'Projektdatum', value: `${timetrack.month} / ${timetrack.year}` },
            {
              label: 'Geändert von',
              value: `${timetrack.updatedBy.firstname} ${timetrack.updatedBy.lastname}`,
              style: { marginTop: '10px' },
            },
            { label: 'Geändert am', value: timetrack.updatedAt },
          ],
        ]}
      />
      <Form
        loading={[updateLoading]}
        submitButtonText="Zeiterfassungänderungen speichern"
        mutation={handleUpdateMutation(updateMutation)}
        data={timetrack}
        usersList={users}
        customersList={customers}
        showToggleButtons
      />
    </>
  );
};

export default Update;
