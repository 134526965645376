export default [
  'name',
  //
  'receiptDate',
  'description',
  'supplier',
  'country',
  'payOption',
  'transaction',
  // 'filesOperations',
  // 'positionsOperations',
  'positions',
  'files',
];
