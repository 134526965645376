import moment from 'moment';
import 'moment/locale/de';

export const getDateData = ({ year, month, fullDate = '' }) => {
  const dateInstance = year && month ? moment(`${year} ${month}`, 'YYYY MM') : moment(fullDate);

  const yearValue = dateInstance.format('YYYY');
  const monthName = dateInstance.format('MMMM');

  return {
    year: yearValue,
    monthName,
  };
};
