import React, { useState, useContext } from 'react';
import Header from '../../views/Header';
import SideBar from '../../views/SideBar';
import Content from '../../views/Content';
import { AppContext } from '../../../context';

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { sessionUser, logout } = useContext(AppContext);

  const handleViewSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  const handleSidebarClose = () => {
    setSidebarOpen(false);
  };

  return (
    <>
      <SideBar isOpen={sidebarOpen} onSidebarClose={handleSidebarClose} sessionUser={sessionUser} />
      <Header user={sessionUser} handleClick={handleViewSidebar} isOpen={sidebarOpen} onLogout={logout} />
      <Content isOpen={sidebarOpen} />
    </>
  );
};

App.propTypes = {
  // logoutAction: PropTypes.func.isRequired,
};

export default App;
