import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import createMutationHandler from '../../shared/create-mutationhandler';
import ButtonBox from '../../atoms/ButtonBox';
import Table from './Table';
import Loading from '../../molecules/Loading';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import ViewTitle from '../../atoms/ViewTitel';
import { ContentContainer } from '../../shared/styles';

const QUERY = gql`
  query queryUsers($where: UserWhereInput) {
    users(where: $where) {
      userId
      username
      firstname
      lastname
      isLocked
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateUser($where: UserWhereUpdateInput, $data: UserUpdateInput) {
    updateUser(where: $where, data: $data) {
      __typename
      ... on User {
        userId
        username
      }
      ... on InvalidData {
        errors {
          message
          property
        }
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteUser($where: UserWhereDeleteInput) {
    deleteUser(where: $where) {
      __typename
      ... on User {
        userId
        username
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

const TableContainer = ({ history, match }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: {} },
    fetchPolicy: 'network-only',
  });

  const [updateMutation, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_MUTATION, {
    refetchQueries: () => [
      {
        query: QUERY,
        variables: {
          where: {},
        },
        v: Math.random(),
      },
    ],
  });

  const [deleteMutation, { error: deleteError, loading: deleteLoading }] = useMutation(DELETE, {
    refetchQueries: () => [
      {
        query: QUERY,
        variables: {
          where: {},
        },
        v: Math.random(),
      },
    ],
  });

  // UPDATE-MUTATION
  const handleUpdateMutation = (mutation) => {
    const handledUpdateMuation = createMutationHandler({
      mutation,
      mutationName: 'updateUser',
      mutationReturnTypes: {
        User: {
          successMessage: (data) => `Benutzer mit der ID ${data.userId} erfolgreich verändert`,
          onSuccess: () => history.push('/benutzer'),
        },
      },
      serverErrorMessage: `Änderung des Benutzers nicht erfolgreich`,
    });

    return (values) =>
      handledUpdateMuation({
        variables: values.variables,
      });
  };

  // DELETE-MUTATION
  const handleDeleteMutation = (mutation) => {
    const handledDeleteMuation = createMutationHandler({
      mutation,
      mutationName: 'deleteUser',
      mutationReturnTypes: {
        User: {
          successMessage: (data) => `Benutzer mit der ID ${data.userId} erfolgreich gelöscht`,
          onSuccess: () => history.push('/benutzer'),
        },
      },
      serverErrorMessage: `Änderung der Benutzer nicht erfolgreich`,
    });

    return (values) =>
      handledDeleteMuation({
        variables: values.variables,
      });
  };

  return (
    <fieldset disabled={queryLoading || updateLoading || deleteLoading}>
      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={updateError} />
      <ErrorGraphQL error={deleteError} />

      <ViewTitle>Benutzer</ViewTitle>
      <ContentContainer>
        <ButtonBox
          theme="primary"
          icon="user"
          text="Neuen Benutzer erstellen"
          onClick={() => history.push(`/benutzer/create`)}
        />
      </ContentContainer>
      <Table
        data={queryData.users}
        onLock={(row) => {
          const handledUpdateMutation = handleUpdateMutation(updateMutation);
          handledUpdateMutation({
            variables: { where: { userId: row.userId }, data: { isLocked: !row.isLocked } },
          });
        }}
        onDelete={(row) => {
          const handledDeleteMutation = handleDeleteMutation(deleteMutation);
          handledDeleteMutation({
            variables: { where: { userId: row.userId } },
          });
        }}
      />
    </fieldset>
  );
};

export default TableContainer;
