import React from 'react';
// import moment from 'moment';
// import 'moment/locale/de';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
// import styled from 'styled-components';

import { Accordion, AccordionItem, AccordionItemButton } from 'react-accessible-accordion';
import { createViewData } from './dynamic-time-tracker-utils';
import { Label, HeadAccordion, BodyAccordion } from './style';
import Button from '../../../atoms/Button';
import Modal from '../../../molecules/Modal';
import MobileFooter from './Mobile.Footer';

import TimeInput from '../../../molecules/TimeInput';
import MinuteInput from '../../../molecules/MinuteInput';
import FormGroup from '../../../atoms/FormGroup';

const TimeListDefault = ({
  dynamicTimetrack, // single time track, contains default values
  updateTimeTrack,
  addNewTimeTrack,
  //
  sortTimeTracks,
  //
  timetrack = {},
  tracks = [],
  //
  updateDynamicTimetrackById,
  deleteDynamicDay,
  addDay,
  submitButtonText,
  onSubmit,
  year,
  month,
  daysOfMonth,
}) => {
  // FOR METADATA
  // const { owner = {}, customer = {}, createdBy = {}, updatedBy = {} } = timetrack;

  const getCustomer = () => {
    const { customer = {} } = timetrack;
    return customer.typ === 'Firma' ? `${customer.firmenname}` : `${customer.vorname} ${customer.name}`;
  };

  const getManager = () => {
    const { manager = {} } = timetrack;
    if (!manager) {
      return 'Kein Projektverantwortlicher';
    }
    return `${manager.firstname || ''} ${manager.lastname || ''}`;
  };

  return (
    <>
      <div
        style={{
          zIndex: '100',
          position: 'fixed',
          bottom: '0',
          left: '0',
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          backgroundColor: '#f8f9fa',
        }}
      >
        {/* <ButtonBox style={{ width: '100%' }} icon="save" text="Speichern" onClick={onSubmit} /> */}
        {/* <Box width="100%" style={{ padding: '10px' }} onClick={onSubmit}>
          <FontAwesomeIcon icon={faSave} size="2x" />
          <b>Speichern</b>
        </Box>
        <Box width="100%" style={{ borderWidth: '1px 0px 1px 0px' }}>
          <b>Arbeitstage:</b>
          {getWorkingDays(tracks)}
        </Box>
        <Box width="100%">
          <b>Gesamtstunden:</b>
          {getWorkingHours(tracks)} h
        </Box> */}
      </div>

      <Modal
        okButtonName="ok-modal-button"
        chancelButtonName="chancel-modal-button"
        theme="info"
        header="Neue dynamische Zeit erstellen"
        message={
          <div>
            <Label>Tag der Zeiterfassung</Label>
            <div style={{ display: 'flex', fontSize: '16px', alignItems: 'baseline' }}>
              <input
                style={{
                  width: '30px',
                  fontSize: '16px',
                  borderRadius: '0px',
                  borderWidth: '1px',
                  borderStyle: 'solid',
                  textAlign: 'right',
                  marginBottom: '20px',
                }}
                value={dynamicTimetrack.day}
                onChange={(event) => updateTimeTrack('day')(event.target.value)}
              />
              .{`${timetrack.month}`.padStart(2, 0)}.{timetrack.year}
            </div>
            <Label>Uhrzeiten</Label>
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: '3px' }}>von</div>
              <TimeInput //
                value={dynamicTimetrack.start}
                onChange={updateTimeTrack('start')}
              />
              <div style={{ marginLeft: '10px', marginRight: '3px' }}>bis</div>
              <TimeInput //
                value={dynamicTimetrack.end}
                onChange={updateTimeTrack('end')}
              />

              <div style={{ marginLeft: '20px', marginRight: '3px' }}>Pause</div>
              <MinuteInput //
                value={dynamicTimetrack.lunchBreak}
                onChange={updateTimeTrack('lunchBreak')}
              />
            </div>

            <Label style={{ marginTop: '20px' }}>Tätigkeit</Label>
            <input
              style={{
                width: '100%',
                fontSize: '16px',
                borderRadius: '0px',
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
              value={dynamicTimetrack.activity}
              onChange={(event) => updateTimeTrack('activity')(event.target.value)}
            />

            <Label style={{ marginTop: '20px' }}>Standort</Label>
            <input
              style={{
                width: '100%',
                fontSize: '16px',
                borderRadius: '0px',
                borderWidth: '1px',
                borderStyle: 'solid',
              }}
              value={dynamicTimetrack.location}
              onChange={(event) => updateTimeTrack('location')(event.target.value)}
            />
          </div>
        }
        // ON OKAY BUTTON PRESS
        applyFunction={addNewTimeTrack}
      >
        <Button //
          theme="primary"
          icon="plus"
          text="Neue dynamische Zeiterfassung erstellen"
          marginBottom="20px"
        />
      </Modal>

      <Accordion
        allowZeroExpanded
        style={{ display: 'flex', flexDirection: 'column', borderWidth: '0px', marginBottom: '60px' }}
      >
        {tracks.map((track, index) => {
          // contains active/inactive logic (what time we have or not AKA hasDay or not)
          const { day } = track;
          // just view data like Mo, Di, Mi, Fr, Sa, So, Berechnung von stunden etc.
          const {
            date,
            hasDay,
            weekDay,
            isWeekend,
            start,
            end,
            lunchBreak,
            activity,
            location,
            nettoTime,
          } = createViewData({
            year,
            month,
            day,
            timetrack: track,
          });

          return (
            <AccordionItem key={track.artificalId}>
              <HeadAccordion hasday={hasDay ? 1 : 0} isweekend={isWeekend ? 1 : 0}>
                <AccordionItemButton style={{ flex: 0, padding: 0 }}>
                  <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '0px' }}
                  >
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '25px', marginLeft: '10px' }}>
                        <b>{weekDay}</b>
                      </div>
                      <div>{date}</div>
                    </div>
                    <div style={{ display: 'flex', textAlign: 'left', alignItems: 'center', marginTop: '0px' }}>
                      <div style={{ marginRight: '10px' }}>
                        <b>Stunden:</b> {nettoTime} h
                      </div>
                      <div
                        style={{
                          zIndex: '10px',
                          backgroundColor: '#ca0c2e',
                          color: 'white',
                          padding: '20px',
                        }}
                        width="35px"
                        align="center"
                        onClick={(event) => {
                          deleteDynamicDay(index);
                          event.stopPropagation();
                        }}
                      >
                        <div>
                          <FontAwesomeIcon icon={faTrash} size="1x" />
                        </div>
                      </div>
                    </div>
                  </div>
                </AccordionItemButton>
              </HeadAccordion>
              <BodyAccordion>
                <div style={{ padding: '30px' }}>
                  <FormGroup>
                    <div>
                      <h5>von:</h5>
                      <TimeInput
                        value={start}
                        onChange={updateDynamicTimetrackById(track.artificalId, 'start')}
                        onBlur={sortTimeTracks}
                      />
                    </div>

                    <div>
                      <h5>bis:</h5>
                      <TimeInput value={end} onChange={updateDynamicTimetrackById(track.artificalId, 'end')} />
                    </div>

                    <div>
                      <h5>Pause:</h5>
                      <MinuteInput //
                        value={lunchBreak}
                        onChange={updateDynamicTimetrackById(track.artificalId, 'lunchBreak')}
                      />{' '}
                      min
                    </div>
                  </FormGroup>

                  <div style={{ margin: '30px -10px -10px -10px' }}>
                    <h5>Tätigkeit:</h5>
                    <input
                      style={{ width: '100%', fontSize: '16px' }}
                      value={activity}
                      onChange={updateDynamicTimetrackById(track.artificalId, 'activity')}
                    />
                  </div>

                  <div style={{ margin: '30px -10px -10px -10px' }}>
                    <h5>Standort:</h5>
                    <input
                      style={{ width: '100%', fontSize: '16px' }}
                      value={location}
                      onChange={updateDynamicTimetrackById(track.artificalId, 'location')}
                    />
                  </div>
                </div>
              </BodyAccordion>
            </AccordionItem>
          );
        })}
      </Accordion>
      <MobileFooter tracks={tracks} onSubmit={onSubmit} type={timetrack.timetrackType} />
    </>
  );
};

export default TimeListDefault;
