import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
// import { useLazyQuery } from '@apollo/client';

import gql from 'graphql-tag';

import { useQueryParams, StringParam, NumberParam, NumericObjectParam, JsonParam } from 'use-query-params';
import styled from 'styled-components';

import Loading from '../../../molecules/Loading';
import AppContext from '../../../../context/App.Context';
import createMutationHandler from '../../../shared/create-mutationhandler';
import getPermissions from '../../../shared/get-permissions';
import ButtonBox from '../../../atoms/ButtonBox';
import Table from './Table';
import TableMobile from './Table.Mobile';
import { Default, Mobile } from '../../../molecules/Responsive';
import ErrorGraphQL from '../../../../errors/ErrorGraphQL';
import QueryBox from './QueryBox';
import mapQueryStringsToMessages from './map-query-strings-to-messages';
import ViewTitle from '../../../atoms/ViewTitel';

const HeaderOfFetchConditions = styled.div`
  font-size: 12px;
  color: #656464;
  font-weight: bold;
`;

const FetchConditionsInfoBox = styled.div`
  font-size: 12px;
  color: #656464;
  border-style: solid;
  border-width: 1px;
  border-color: #ced4da;
  padding: 10px;
  color: #656464;
  margin-bottom: 15px;
`;

const GET_TIMETRACKS = gql`
  query GET_TIMETRACKS($where: TimetrackWhereInput) {
    timetracks(where: $where) {
      timetrackId
      name
      timetrackType
      tracks {
        day
        end
        start
        activity
        location
        lunchBreak
      }
      customer {
        customerId
        typ
        firmenname
        name
        vorname
      }
      isLocked
      isHidden
      isManagerSigned
      isFreelancerSigned
      year
      month
      owner {
        firstname
        lastname
      }
      createdAt
      updatedAt
    }
  }
`;

const DELETE = gql`
  mutation DeleteTimetrack($where: TimetrackWhereDeleteInput) {
    deleteTimetrack(where: $where) {
      __typename
      ... on Timetrack {
        timetrackId
        name
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1;
const DEFAULT_SEARCH = { updatedAt: { year: currentYear, month: currentMonth } };

//
//
//
//
//
const TableContainer = ({ history, match }) => {
  const { sessionUser } = useContext(AppContext);

  const [queryString, setQueryString] = useQueryParams({
    status: JsonParam,
    timetrackId: NumberParam,
    name: StringParam,
    customer: NumberParam,
    owner: NumberParam,
    projectDate: NumericObjectParam,
    projectDateRange: JsonParam,
    createdAt: NumericObjectParam,
    createdAtRange: JsonParam,
    updatedAt: NumericObjectParam,
    updatedAtRange: JsonParam,
  });

  // all querys are done by useEffect with the refetch function
  // const { loading: queryLoading, error: queryError, data: queryData = {}, refetch } = useQuery(GET_TIMETRACKS, {
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: 'no-cache',
  // });

  const [ fetchData, { loading: queryLoading, error: queryError, data: queryData = {} } ] = useLazyQuery(GET_TIMETRACKS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [deleteMutation, { error: deleteError, loading: deleteLoading }] = useMutation(DELETE, {
    refetchQueries: () => [
      {
        query: GET_TIMETRACKS,
        variables: {
          where: {}, // put query link arguments here
        },
        v: Math.random(),
      },
    ],
  });

  const { CAN_CREATE_TIMETRACKS } = getPermissions(sessionUser.roles);

  useEffect(() => {
    const hasQueryString = Object.keys(queryString).some((key) => queryString[key]);

    if (CAN_CREATE_TIMETRACKS) {
      if (!hasQueryString) {
        setQueryString(DEFAULT_SEARCH);
        fetchData({
          variables: {
            where: { ...DEFAULT_SEARCH },
          },
        });
        return;
      }

      if (queryString.all) {
        setQueryString({ all: true }, 'replace');
        fetchData({
          variables: {
            where: {},
          },
        });
        return;
      }

      setQueryString({ ...queryString }, 'replace');
      fetchData({
        variables: {
          where: { ...queryString },
        },
      });
      return;
    }

    setQueryString({}, 'replace');
    fetchData({ where: {} });
  }, [queryString]);

  const { users = [], customers = [], timetracks = [] } = queryData;


  // DELETE-MUTATION
  const handleDeleteMutation = (mutation) => {
    const handledDeleteMuation = createMutationHandler({
      mutation,
      mutationName: 'deleteTimetrack',
      mutationReturnTypes: {
        Timetrack: {
          successMessage: (data) => `Zeiterfassung mit der ID ${data.timetrackId} erfolgreich gelöscht`,
          onSuccess: () => history.push('/timetracks'),
        },
      },
      serverErrorMessage: `Änderung der Zeiterfassung nicht erfolgreich`,
    });

    return (values) =>
      handledDeleteMuation({
        variables: values.variables,
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  return (
    <fieldset disabled={deleteLoading || queryLoading}>
      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={deleteError} />
      <Default>
        <Table
          data={timetracks}
          onDelete={(row) => {
            const mutation = handleDeleteMutation(deleteMutation);
            mutation({
              variables: { where: { timetrackId: row.timetrackId } },
            });
          }}
        />
      </Default>
      <Mobile>
        <TableMobile
          data={timetracks}
          onDelete={(row) => {
            const mutation = handleDeleteMutation(deleteMutation);
            mutation({
              variables: { where: { timetrackId: row.timetrackId } },
            });
          }}
        />
      </Mobile>
    </fieldset>
  );
};

export default TableContainer;
