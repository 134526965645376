/* eslint-disable react/display-name */
import React, { useContext, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { TableStyle } from '../../../shared/styles';
import AppContext from '../../../../context/App.Context';
import getPermissions from '../../../shared/get-permissions';
import RowsActions from './Table.Rows.Actions';
import TableColumns from './Table.Columns';
import TableContext from './Table.Context';
import ProtectComponent from '../../../molecules/ProtectComponent';

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'updatedAt',
    order: 'desc',
  },
];

// const Table = ({ onDelete, onDuplicate, onUpdate, data, users = [] }) => {
const Table = ({ data }) => {
  const { users, duplicateTimetracks, updateTimetracks, deleteTimetrack } = useContext(TableContext);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [copiedTrack, setCopiedTrack] = useState(null);

  const { sessionUser } = useContext(AppContext);
  const { CAN_CREATE_TIMETRACKS } = getPermissions(sessionUser.roles);

  const columns = TableColumns({
    CAN_CREATE_TIMETRACKS,
    deleteTimetrack,
  });

  const selectRow = {
    mode: 'checkbox',
    selected: selectedItems.map((selectedItem) => selectedItem.timetrackId),
    style: { backgroundColor: '#99f89c' },
    headerColumnStyle: { padding: '10px 10px 10px 0px' },
    selectColumnStyle: { padding: '10px 10px 10px 0px' },
    onSelect: (row, isSelect) => {
      if (isSelect) {
        setSelectedItems((prevState) => [...prevState, row]);
      } else {
        setSelectedItems((prevState) => prevState.filter((item) => item.timetrackId !== row.timetrackId));
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        setSelectedItems(rows);
      } else {
        setSelectedItems([]);
      }
    },
  };

  // --------------------- COLUM FUNCTIONS ---------------------
  const handleDuplicate = () => {
    duplicateTimetracks({
      timetrackIds: selectedItems.map((item) => item.timetrackId),
      userIds: selectedUsers,
    });
  };

  const handleHide = () => {
    updateTimetracks({
      where: { timetrackIds: selectedItems.map((item) => item.timetrackId) },
      data: { isHidden: true },
    });
  };

  const handleVisible = () => {
    updateTimetracks({
      where: { timetrackIds: selectedItems.map((item) => item.timetrackId) },
      data: { isHidden: false },
    });
  };

  const handleLock = () => {
    updateTimetracks({
      where: { timetrackIds: selectedItems.map((item) => item.timetrackId) },
      data: { isLocked: true },
    });
  };

  const handleUnlock = () => {
    updateTimetracks({
      where: { timetrackIds: selectedItems.map((item) => item.timetrackId) },
      data: { isLocked: false },
    });
  };

  const handleCopyTrack = () => {
    setCopiedTrack(selectedItems[0]);
    setSelectedItems([]);
  };

  const handlePasteTrack = () => {
    updateTimetracks({
      where: { timetrackIds: selectedItems.map((item) => item.timetrackId) },
      data: {
        timetrackType: copiedTrack.timetrackType,
        tracks: copiedTrack.tracks.map((trackItem) => {
          const { __typename, ...filteredTrack } = trackItem;
          return filteredTrack;
        }),
      },
    });
  };

  const handleUserSelect = (selected) => {
    setSelectedUsers(selected.map((selection) => selection.value));
  };
  // --------------------- COLUM FUNCTIONS ---------------------

  return (
    <TableStyle>
      {data.length === 0 ? (
        'Keine Daten Vorhanden'
      ) : (
        <ToolkitProvider
          keyField="timetrackId"
          data={data}
          columns={columns.filter((column) => !(!CAN_CREATE_TIMETRACKS && column.text === 'Status'))}
          search
        >
          {({ searchProps, baseProps }) => (
            <>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '100%' }}>
                  <SearchBar
                    {...searchProps}
                    style={{
                      margin: '0px 0px 15px 0px',
                      padding: '10px',
                      borderRadius: '0px',
                      width: '100%',
                      border: 'solid',
                      borderWidth: '1px',
                      borderColor: '#ced4da',
                    }}
                    placeholder="Suchen..."
                  />
                </div>
                <div style={{ flex: 0 }}>
                  <ProtectComponent requiredPermissions={['CAN_CREATE_TIMETRACKS']}>
                    <RowsActions
                      handleDuplicate={handleDuplicate}
                      handleLock={handleLock}
                      handleHide={handleHide}
                      handleVisible={handleVisible}
                      handleUnlock={handleUnlock}
                      handleCopyTrack={handleCopyTrack}
                      handlePasteTrack={handlePasteTrack}
                      selectedItems={selectedItems}
                      onUserSelect={handleUserSelect}
                      users={users}
                    />
                  </ProtectComponent>
                </div>
              </div>
              <BootstrapTable
                {...baseProps}
                // noDataIndication="Keine Daten gefunden"
                defaultSorted={defaultSorted}
                bordered={false}
                selectRow={selectRow}
                printable
              />
            </>
          )}
        </ToolkitProvider>
      )}
    </TableStyle>
  );
};

export default Table;
