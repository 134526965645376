import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import BillForm from './Form.Bill';
import FormGroup from '../../atoms/FormGroup';
import FormItem from '../../atoms/FormItem';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';

import Loading from '../../molecules/Loading';
import createMutationHandler from '../../shared/create-mutationhandler';

const QUERY = gql`
  query GetCustomer($where: CustomerWhereInput) {
    customer(where: $where) {
      customerId
      typ
      anrede
      name
      vorname
      firmenname
    }
  }
`;

const CREATE_MUTATION = gql`
  mutation CreateBill($data: BillCreateInput) {
    createBill(data: $data) {
      __typename
      ... on Bill {
        billId
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

const CustomerFormCreate = ({ match, history }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: { customerId: match.params.id } },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [createMutation, { error: createError, loading: createLoading }] = useMutation(CREATE_MUTATION);

  // CREATE-MUTATION
  const handleCreateMutation = (mutation) => {
    const handledCreateMutation = createMutationHandler({
      mutation,
      mutationName: 'createBill',
      mutationReturnTypes: {
        Bill: {
          successMessage: (data) => `Rechnung mit der ID ${data.billId} erfolgreich erstellt`,
          onSuccess: () => history.push(`/kunden/${match.params.id}/rechnungen`),
        },
      },
      serverErrorMessage: `Erstellung des Kunden nicht erfolgreich`,
    });

    return (values) =>
      handledCreateMutation({
        variables: {
          data: {
            ...values,
            customerId: match.params.id,
          },
        },
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  if (createLoading) {
    return <Loading />;
  }

  const { customer = {} } = queryData;
  const displayCustomer =
    customer.typ === 'Firma' //
      ? `${customer.firmenname}`
      : `${customer.anrede} ${customer.vorname} ${customer.name}`;

  return (
    <fieldset disabled={createLoading}>
      <FormGroup>
        <FormItem>
          <h2 name="form-headline">Neue Rechnung erstellen für Kunde: {displayCustomer}</h2>
        </FormItem>
      </FormGroup>

      <ErrorGraphQL error={createError} />
      <BillForm
        submitButtonText="Neuen Rechnung erstellen" // >
        mutation={handleCreateMutation(createMutation)}
      />
    </fieldset>
  );
};

export default CustomerFormCreate;
