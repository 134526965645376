import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// import glamorous from 'glamorous';
import { NotificationManager } from 'react-notifications';
import styled from 'styled-components';

const ErrorBox = styled.div`
  color: #721c24;
  background-color: #f8d7da;
  /* border-color: #f5c6cb; */
  border: 1px solid #f5c6cb;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ErrorMessage = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ErrorFormBox = ({ formProps }) => {
  const { submitFailed, hasSubmitErrors } = formProps;

  // console.log('DAS IST FORM PROPS: ', formProps);
  // console.log('DAS IST FORM PROPS: ', formProps.hasSubmitErrors);

  useEffect(() => {
    if (submitFailed) {
      NotificationManager.error(' Bitte Formulareingaben prüfen', 'Formular ist nicht Valide');
    }
  }, [submitFailed]);

  const hasClientOrServerError = formProps.submitFailed || formProps.hasSubmitErrors;

  return (
    hasClientOrServerError &&
    Object.keys(formProps.errors).length > 0 && (
      <ErrorBox>
        <b>Validierungsfehler:</b>
        {Object.keys(formProps.errors).map((errorKey, index) => (
          <div key={index}>
            {/* if error contains an array of errors */}
            {Array.isArray(formProps.errors[errorKey]) &&
              formProps.errors[errorKey].map(
                (childErrorItem, index) =>
                  childErrorItem &&
                  Object.values(childErrorItem).map((childErrorValue, childIndex) => (
                    <ErrorMessage key={childIndex}>{`- Stelle ${index + 1}: ${childErrorValue}`}</ErrorMessage>
                  ))
              )}
            {/* if error is not an array, must be like this because there is no else */}
            {!Array.isArray(formProps.errors[errorKey]) && (
              <ErrorMessage>{`- ${formProps.errors[errorKey]}`}</ErrorMessage>
            )}
          </div>
        ))}
      </ErrorBox>
    )
  );
};

export default ErrorFormBox;

// return (
//   // hasErrors &&
//   formProps.submitFailed && (
//     <ErrorBox>
//       <b>Validierungsfehler:</b>
//       {Object.keys(formProps.errors).map(errorKey => {
//         if (formProps.errors[errorKey] && formProps.touched[errorKey]) {
//           return <ErrorMessage>{formProps.errors[errorKey]}</ErrorMessage>;
//         }
//       })}
//     </ErrorBox>
//   )
// );
