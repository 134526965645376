import React from 'react';
import Switch from 'react-switch';
import { FieldArray } from 'react-final-form-arrays';
import { FaTrash } from 'react-icons/fa';
import styled from 'styled-components';
import { GoArrowUp, GoArrowDown } from 'react-icons/go';
import Button from '../../atoms/Button';
import FormGroup from '../../atoms/FormGroup';
import TextInput from '../../molecules/TextInput';
import SelectInput from '../../molecules/SelectInput';
import EuroInput from '../../final-form-elements/EuroInput';
import positionsCalc from './positions-calc';

const PositionContainer = styled.div`
  display: flex;
  background-color: #edf4fb;
  border: solid 1px #bfbfbf;
  margin-top: 10px;
  padding: 10px;
`;

const PositionHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #bd2130;
  width: 30px;
  height: 30px;
`;

// -------------------------- HELPERS --------------------------
const calcNetto = (brutto, ust = 0) =>
  typeof brutto !== 'number'
    ? '0,00'
    : (Number((brutto / (1 + ust / 100).toFixed(2)).toFixed(0)) / 100).toFixed(2).replace('.', ',');

// brutto - netto = USt.
const calcUSt = (brutto, ust = 0) =>
  typeof brutto !== 'number'
    ? '0,00'
    : (Number(brutto - (brutto / (1 + ust / 100).toFixed(2)).toFixed(0)) / 100).toFixed(2).replace('.', ',');

const getNewArtificalId = () => new Date().valueOf() + Math.random();
// -------------------------- HELPERS --------------------------

//
//
//
const Positions = ({ positions = [], kontenrahmenOptions }) => {
  const { totalNetto, totalBrutto, total7, total19 } = positionsCalc(positions);

  return (
    <FieldArray name="positions">
      {({ fields }) => (
        <div>
          <Button
            type="button"
            style={{ marginTop: '20px', marginBottom: '10px' }}
            theme="success"
            height="40px"
            icon="plus"
            text="Neue Position hinzufügen"
            onClick={() =>
              fields.push({
                artificalId: getNewArtificalId(),
                description: '',
                amount: 0,
                category: { value: {}, label: '' },
              })
            }
          />
          {positions.map((position, index) => (
            <PositionContainer key={position.positionId || position.artificalId}>
              <div style={{ width: '100%' }}>
                <PositionHeader>
                  <div style={{ fontWeight: 'bold' }}>Position - {`${index + 1}`}</div>
                  <div style={{ display: 'flex' }}>
                    <GoArrowUp
                      onClick={() => fields.move(index, index - 1)}
                      color="white"
                      style={{
                        cursor: 'pointer',
                        size: '25px',
                        // height: 'fit-content',
                        backgroundColor: '#379bff',
                        marginRight: '2px',
                      }}
                    />
                    <GoArrowDown
                      onClick={() => fields.move(index, index + 1)}
                      color="white"
                      style={{
                        cursor: 'pointer',
                        size: '25px',
                        // height: 'fit-content',
                        backgroundColor: '#379bff',
                        marginLeft: '2px',
                      }}
                    />
                  </div>
                  <ButtonContainer onClick={() => fields.remove(index)}>
                    <FaTrash color="white" />
                  </ButtonContainer>
                </PositionHeader>
                <FormGroup>
                  <TextInput label="Beschreibung" name={`positions[${index}].description`} />
                </FormGroup>
                <FormGroup>
                  <SelectInput
                    label="Kategorie"
                    name={`positions[${index}].category`}
                    options={kontenrahmenOptions}
                    placeholder=" "
                  />
                </FormGroup>
                <FormGroup>
                  <EuroInput label="Brutto-Betrag" name={`positions[${index}].amount`} />
                  <div style={{ marginTop: '20px', width: '50%', flexGrow: 1, flexShrink: 0, flexBasis: '180px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div
                        style={{
                          marginTop: '10px',
                          marginLeft: '10px',
                          textAlign: 'right',
                          marginRight: '10px',
                        }}
                      >
                        USt.
                      </div>
                      <div
                        style={{
                          marginTop: '10px',
                          marginLeft: '10px',
                          textAlign: 'right',
                          marginRight: '10px',
                        }}
                      >
                        Nettobetrag
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ margin: '10px 10px 10px 10px', textAlign: 'right' }}>
                        {calcUSt(positions[index].amount, positions[index].category.value.ust)} €
                      </div>
                      <div style={{ margin: '10px 10px 10px 10px', textAlign: 'right' }}>
                        {calcNetto(positions[index].amount, positions[index].category.value.ust)} €
                      </div>
                    </div>
                  </div>
                </FormGroup>
              </div>
            </PositionContainer>
          ))}
          <hr />
          <FormGroup>
            <div style={{ width: '100%', margin: '10px', fontSize: '16px' }}>
              <div style={{ fontSize: '20px' }}>Summen </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginTop: '10px' }}>Zwischensumme </div>
                <div id="totalNetto" style={{ marginTop: '10px' }}>
                  {totalNetto}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginTop: '10px' }}> USt. 7%</div>
                <div id="total7" style={{ marginTop: '10px' }}>
                  {total7}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginTop: '10px' }}> USt. 19%</div>
                <div id="total19" style={{ marginTop: '10px' }}>
                  {total19}
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '20px' }}> Gesamtsumme</div>
                <div id="totalBrutto" style={{ marginTop: '10px', fontWeight: 'bold', fontSize: '20px' }}>
                  {totalBrutto}
                </div>
              </div>
            </div>
          </FormGroup>
        </div>
      )}
    </FieldArray>
  );
};

export default Positions;
