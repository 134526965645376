/* eslint-disable react/display-name */
import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import FormGroup from '../../atoms/FormGroup';
import FormItem from '../../atoms/FormItem';
import Form from './Form';
import ButtonBox from '../../atoms/ButtonBox';
import MetaInfo from '../../molecules/MetaInfo';

import Loading from '../../molecules/Loading';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import createMutationHandler from '../../shared/create-mutationhandler';

const QUERY = gql`
  query GetRecruiter($where: RecruiterWhereInput) {
    recruiter(where: $where) {
      id
      salutation
      name
      firstname
      lastname
      street
      houseNo
      plz
      city
      telefon
      fax
      email
      note
      createdAt
      updatedAt
      createdBy {
        firstname
        lastname
      }
      updatedBy {
        firstname
        lastname
      }
      employees {
        id
        firstname
        lastname
        telefon
        email
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateRecruiter($where: RecruiterWhereUpdateInput, $data: RecruiterUpdateInput) {
    updateRecruiter(where: $where, data: $data) {
      __typename
      ... on Recruiter {
        id
        name
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
    }
  }
`;

const RecruiterFormUpdate = ({ history, match }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: { id: match.params.id } },
    fetchPolicy: 'network-only',
  });
  const [updateMutation, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_MUTATION);
  const { recruiter = {} } = queryData;
  const { createdBy = {}, updatedBy = {} } = recruiter;

  const handleUpdateMuation = (mutation) => {
    const handledUpdateMuation = createMutationHandler({
      mutation,
      mutationName: 'updateRecruiter',
      mutationReturnTypes: {
        Recruiter: {
          successMessage: (data) => `Recruiter mit der ID ${data.id}, wurde erfolgreich verändert`,
          onSuccess: () => history.push('/recruiters'),
        },
      },
      serverErrorMessage: `Änderung des Recruiter nicht erfolgreich`,
    });

    return (values) =>
      handledUpdateMuation({
        variables: {
          where: { id: match.params.id },
          data: values,
        },
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  return (
    <fieldset disabled={updateLoading}>
      <FormGroup>
        <FormItem>
          <h2>Recruiter bearbeiten</h2>
        </FormItem>
      </FormGroup>

      <ButtonBox
        theme="primary"
        icon="arrow-left"
        text="Zurück"
        style={{ margin: '0px 10px 20px 0px' }}
        onClick={() => history.goBack()}
      />

      <hr />
      <MetaInfo>
        {[
          [['ID:', recruiter.id]],
          [
            ['Erstellt am:', recruiter.createdAt],
            ['Geändert am:', recruiter.updatedAt],
          ],
          [
            ['Erstellt von:', `${createdBy.firstname} ${createdBy.lastname}`],
            ['Geändert von:', `${updatedBy.firstname} ${updatedBy.lastname}`],
          ],
        ]}
      </MetaInfo>
      <hr />

      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={updateError} />

      <Form
        data={recruiter}
        submitButtonText="Recruiteränderung speichern"
        mutation={handleUpdateMuation(updateMutation)}
      />
    </fieldset>
  );
};

RecruiterFormUpdate.propTypes = {};
RecruiterFormUpdate.defaultProps = {};

export default RecruiterFormUpdate;

// onLockClick -> onLock
// makeMutationHandler
