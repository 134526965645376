import React from 'react';
import { FaInfoCircle, FaStickyNote, FaUser } from 'react-icons/fa';
import { AiFillBank } from 'react-icons/ai';

import FormGroup from '../../atoms/FormGroup';
import Button from '../../atoms/Button';
import setInitalValues from '../../final-form-elements/set-inital-values';
import TextInput from '../../final-form-elements/TextInput';
import TextArea from '../../final-form-elements/TextArea';
import SelectField from '../../final-form-elements/SelectField';

import withFinalForm from '../../HOC/with-final-form';
import allowedAttributes from './allowed-attributes';
import validator from './validator';

import { Tabs, TabHeaders, TabBodys, TabBody, Tab } from '../../molecules/Tabs';

const Form = ({ values, handleSubmit, submitButtonText }) => (
  <form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
    <Tabs>
      <TabHeaders>
        <Tab>
          <FaInfoCircle /> &nbsp; Informationen
        </Tab>
        <Tab>
          <FaUser /> &nbsp; Ansprechperson
        </Tab>
        <Tab>
          <AiFillBank /> &nbsp; Bankdaten
        </Tab>
        <Tab>
          <FaStickyNote /> &nbsp; Notizen
        </Tab>
      </TabHeaders>

      <TabBodys>
        {/* INFO */}
        <TabBody>
          <FormGroup>
            <TextInput label="Kontoname" type="text" name="name" />
            <TextInput label="IBAN" type="text" name="id" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Bankname" type="text" name="bankname" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Straße" type="text" name="street" />
            <TextInput label="Hausnummer" type="text" name="houseNo" />
          </FormGroup>
          <FormGroup>
            <TextInput label="PLZ" type="number" name="plz" />
            <TextInput label="Ort" type="text" name="city" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Telefon" type="text" name="telefon" />
            <TextInput label="Fax" type="text" name="fax" />
          </FormGroup>
          <FormGroup>
            <TextInput label="E-Mail" type="text" name="email" />
          </FormGroup>
        </TabBody>

        {/* ANSPRECHPERSON */}
        <TabBody>
          <FormGroup>
            <SelectField
              label="Anrede"
              name="salutation"
              options={[
                { label: 'Herr', value: 'Herr' },
                { label: 'Frau', value: 'Frau' },
              ]}
            />
          </FormGroup>
          <FormGroup>
            <TextInput label="Vorname" type="text" name="firstname" focus />
            <TextInput label="Name" type="text" name="lastname" />
          </FormGroup>
        </TabBody>

        {/* BANKDATEN */}
        <TabBody>
          <FormGroup>
            <TextInput label="Bankname" type="text" name="bankname" />
            <TextInput label="BIC / SWIFT" type="text" name="bic" />
          </FormGroup>
          <FormGroup>
            <TextInput label="IBAN" type="text" name="id" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Kontonummer" type="text" name="accountNo" />
            <TextInput label="BLZ" type="text" name="blz" />
          </FormGroup>
        </TabBody>

        {/* NOTIZEN */}
        <TabBody>
          <FormGroup>
            <TextArea label="Notizen" type="text" name="note" />
          </FormGroup>
        </TabBody>
      </TabBodys>
    </Tabs>

    <Button
      name="submit-button"
      style={{ marginTop: '20px' }}
      theme="success"
      height="40px"
      icon="save"
      text={submitButtonText}
    />
  </form>
);

// FORM CONFIG
export default (props) => {
  const { data, submitButtonText, mutation } = props;

  const initialValues = React.useMemo(
    () =>
      setInitalValues({
        data,
        defaults: {
          id: '',
          name: '',
          salutation: 'Herr',
          firstname: '',
          lastname: '',
          street: '',
          houseNo: '',
          plz: '',
          city: '',
          bankname: '',
          bic: '',
          blz: '',
          accountNo: '',
          email: '',
          fax: '',
          telefon: '',
          note: '',
        },
      }),
    [data]
  );

  return withFinalForm(
    {
      initialValues,
      validator,
      submitButtonText,
      allowedAttributes,
      attributesFilter: {
        plz: (value) => !value,
      },
      valuesFormat: {
        // plz: (value) => (value ? Number(value) : null),
        id: (value) => value.trim(),
      },
      submitHandler: (values) => mutation(values),
    },
    Form
  );
};
