import calculateEuro from '../../../lib/calculate-euro';

export default function calculatePositions(positions) {
  const billAccu = {
    nettobetrag: 0,
    ust7: 0,
    ust19: 0,
  };

  // Zwischensumme Netto
  const zwischensummen = positions.reduce((accu, position) => {
    const nettobetrag = accu.nettobetrag + position.einzelpreis * position.menge;

    const ust7 =
      String(position.ust) === '7' //
        ? // ? accu.ust7 + position.einzelpreis * position.menge * 0.07 // MACHT FEHLER NICHT GENAU
          accu.ust7 + parseInt((position.einzelpreis * 0.07).toFixed(0))
        : accu.ust7;

    const ust19 =
      String(position.ust) === '19' //
        ? // ? accu.ust19 + position.einzelpreis * position.menge * 0.19
          accu.ust19 + parseInt((position.einzelpreis * 0.19).toFixed(0))
        : accu.ust19;

    return {
      nettobetrag,
      ust7,
      ust19,
    };
  }, billAccu);

  const rechnungsbetrag = zwischensummen.nettobetrag + zwischensummen.ust7 + zwischensummen.ust19;

  return {
    ...zwischensummen,
    rechnungsbetrag,
  };
}
