import React, { useEffect } from 'react';
import { FaListUl, FaEdit } from 'react-icons/fa';
import ToolTip from '../../molecules/ToolTip';

import { Link } from './styles';
import {
  useTable,
  Table,
  TableHead,
  TableBody,
  Headers,
  Header,
  DataRows,
  DataRow,
  SortHeader,
  Search,
} from '../../molecules/Table';

const TableComponent = ({ data, updateStatus, isBin, purgeUser }) => {
  // const { CAN_UPDATE_USERS } = permissions;

  const { tableData, updateData, sorter, fuzzySearch } = useTable(data, {
    sorting: {
      dataField: 'id',
      sortFunc: (a, b) => a.id - b.id, // asc
    },
  });

  useEffect(() => {
    updateData(data);
  }, [data]);

  // --------------- FUZZY SEARCH ---------------
  const handleFuzzySearch = (value) => {
    fuzzySearch(['id', 'name', 'iban'])(value);
  };
  // --------------- FUZZY SEARCH ---------------

  const idHeaderSorter = (toggle) => sorter((a, b) => (toggle ? -(a.id - b.id) : a.id - b.id));
  const sortByName = (key) => (toggle) =>
    sorter((a, b) => (toggle ? -a[key].localeCompare(b[key]) : a[key].localeCompare(b[key])));

  return (
    <>
      <Search onChange={(event) => handleFuzzySearch(event.target.value)} placeholder="Suchen..." />
      <Table>
        <TableHead>
          <Headers>
            <Header style={{ width: '35px', paddingLeft: '20px' }}>
              ID
              <SortHeader sortFunc={idHeaderSorter} />
            </Header>
            <Header style={{ width: '170px' }}>
              Name
              <SortHeader sortFunc={sortByName('name')} />
            </Header>
            <Header>
              IBAN
              <SortHeader sortFunc={sortByName('iban')} />
            </Header>
            <Header style={{ width: '100px', textAlign: 'center' }}>Aktionen</Header>
          </Headers>
        </TableHead>
        <TableBody>
          {tableData.map(({ id, name, iban }) => (
            <DataRows key={id}>
              <DataRow style={{ textAlign: 'right', paddingRight: '20px' }}>{id}</DataRow>
              <DataRow style={{ minWidth: '300px' }}>{name}</DataRow>
              <DataRow>{iban}</DataRow>
              {/* ------------------- ACTIONS------------------- */}
              <DataRow>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Link to={`bankaccounts/update/${id}`}>
                    <ToolTip title="Bankkonto editieren">
                      <FaEdit />
                    </ToolTip>
                  </Link>
                  <Link to={`bankaccounts/${id}/transactions`}>
                    <ToolTip title="Bankkonto editieren">
                      <FaListUl />
                    </ToolTip>
                  </Link>
                </div>
              </DataRow>
              {/* ------------------- ACTIONS------------------- */}
              {/* ------------------- ACTIONS------------------- */}
            </DataRows>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default TableComponent;
/*
import React from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

// COLUMNS
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar, faUserEdit, faStickyNote, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FaListUl } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
// import CustomerIdColumn from './Column.Id';
// import Actions from './Actions';
import Customer from './Customer';

import KundeKontaktColumn from '../../molecules/KundeKontakteColumn';
import KundeAdresseColumn from '../../molecules/KundeAdresseColumn';
import DateColumn from '../../molecules/DateColumn';

import Modal from '../../molecules/Modal';

const TableStyle = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const ActionStyle = styled.div`
  color: grey;
  margin-right: 20px;
  font-size: 20px;

  &:hover {
    color: #89cff0;
  }
`;

const StyledLink = styled(Link)`
  color: grey;
  margin-right: 20px;
  font-size: 20px;

  &:hover {
    color: #89cff0;
  }
`;

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'updatedAt',
    order: 'desc',
  },
];

export default function TableCustomers(props) {
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      formatter: (cell, row) => row.id,
      style: { width: '70px' },
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      formatter: (cell, row) => row.name,
      style: { minWidth: '210px' },
      sort: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Geändert am',
      formatter: DateColumn('updatedAt'),
      // style: { minWidth: '125px', maxWidth: '125px' },
      sort: true,
    },
    // {
    //   dataField: 'telefon',
    //   text: 'Kontakte',
    //   formatter: KundeKontaktColumn,
    //   // style: { minWidth: '210px' },
    //   sort: true,
    // },
    // {
    //   dataField: 'strasse',
    //   text: 'Adresse',
    //   formatter: KundeAdresseColumn,
    //   // style: { minWidth: '135px' },
    //   sort: true,
    // },

    {
      dataField: 'helper',
      text: 'Aktionen',
      // formatter: Actions,
      formatter: (cell, row) => (
        <div>
          <div style={{ display: 'flex' }}>
            <StyledLink to={`/banks/update/${row.id}`} style={{ textDecoration: 'none' }}>
              <div data-tip="Bank bearbeiten" name="edit-customer-button">
                <FontAwesomeIcon size="1x" icon={faUserEdit} />
                <ReactTooltip place="left" type="dark" effect="float" />
              </div>
            </StyledLink>

            <StyledLink to={`/bankaccounts/transactions/${row.id}`} style={{ textDecoration: 'none' }}>
              <div data-tip="Transaktionen" name="transactions">
                <FaListUl />
                <ReactTooltip place="left" type="dark" effect="float" />
              </div>
            </StyledLink>

            <Modal
              okButtonName="ok-modal-button"
              chancelButtonName="chancel-modal-button"
              theme="danger"
              header="Löschen"
              message={`Möchten Sie Kunde ${
                row.firmenname || `${row.vorname} ${row.name}`
              } und deren Rechnungen löschen?`}
              applyFunction={() => props.onDelete(row)}
            >
              <ActionStyle>
                <div data-tip="Kunde löschen" name="delete-customer-button">
                  <FontAwesomeIcon size="1x" icon={faTrash} />
                  <ReactTooltip place="left" type="dark" effect="float" />
                </div>
              </ActionStyle>
            </Modal>
          </div>
        </div>
      ),

      // formatExtraData: { deleteById: props.deleteById },
      // formatExtraData: { deleteById: props.deleteById },
      style: { minWidth: '100px', maxWidth: '175px' },
      sort: true,
    },

    // HIDDEN, BUT THERE TO BE ABLE SO LAZY-SEARCH
    {
      dataField: 'email',
      text: 'E-Mail',
      hidden: true,
    },
    {
      dataField: 'strasse',
      text: 'Strasse',
      hidden: true,
    },
    {
      dataField: 'hausnr',
      text: 'Hausnummer',
      hidden: true,
    },
    {
      dataField: 'firmenname',
      text: 'Firmenname',
      hidden: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Geändert am',
      formatter: DateColumn('updatedAt'),
      // style: { minWidth: '125px', maxWidth: '125px' },
      sort: true,
      hidden: true,
    },
    {
      dataField: 'createdAt',
      text: 'Erstellt am',
      formatter: DateColumn('createdAt'),
      style: { minWidth: '115px', maxWidth: '115px' },
      sort: true,
      hidden: true,
    },
  ];

  return (
    <TableStyle>
      <ToolkitProvider keyField="id" data={props.data} columns={columns} search>
        {({ searchProps, baseProps }) => (
          <div>
            <SearchBar
              {...searchProps}
              style={{ margin: '15px 0px 15px 0px', borderRadius: '0px' }}
              placeholder="Suchen..."
            />

            <BootstrapTable
              noDataIndication="Keine Daten mit diesen Sucheigenschaften gefunden"
              defaultSorted={defaultSorted}
              bordered={false}
              {...baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </TableStyle>
  );
}

TableCustomers.defaultProps = {
  data: [],
};
*/
