import React from 'react';
import { FaInfoCircle, FaLock, FaStickyNote } from 'react-icons/fa';
import allowedAttributes from './allowed-attributes';
import withFinalForm from '../../HOC/with-final-form';
import createValidator from './validation';
import Button from '../../atoms/Button';
import FormGroup from '../../atoms/FormGroup';
import TextInput from '../../molecules/TextInput';
import TextArea from '../../molecules/TextArea';
import SelectField from '../../molecules/SelectField';
import { Tabs, TabHeaders, TabBodys, TabBody, Tab } from '../../molecules/Tabs';

const CustomForm = ({ values, handleSubmit, submitButtonText, form, loading = [] }) => (
  <fieldset disabled={loading.some(bool => bool)}>
    <form onSubmit={handleSubmit}>
      <Tabs>
        <TabHeaders>
          <Tab>
            <FaInfoCircle /> &nbsp; Benutzerinformationen
          </Tab>
          <Tab>
            <FaLock /> &nbsp; Neues Passwort
          </Tab>
          <Tab>
            <FaStickyNote /> &nbsp; Notizen
          </Tab>
        </TabHeaders>

        <TabBodys>
          <TabBody>
            <FormGroup>
              <TextInput label="Benutzername" type="text" name="username" focus />
              <TextInput label="E-Mail" type="text" name="email" />
            </FormGroup>

            <FormGroup>
              <SelectField
                label="Anrede"
                name="salutation"
                options={[
                  { label: 'Herr', value: 'Herr' },
                  { label: 'Frau', value: 'Frau' },
                ]}
              />
            </FormGroup>
            <FormGroup>
              <TextInput label="Vorname" type="text" name="firstname" focus />
              <TextInput label="Name" type="text" name="lastname" />
            </FormGroup>
            <FormGroup>
              <TextInput label="Straße" type="text" name="street" />
              <TextInput label="Hausnummer" type="text" name="houseNo" />
            </FormGroup>
            <FormGroup>
              <TextInput label="PLZ" type="number" name="zip" />
              <TextInput label="Ort" type="text" name="city" />
            </FormGroup>
            <FormGroup>
              <TextInput label="Telefon" type="text" name="telefon" format="telefonFormat" />
            </FormGroup>
          </TabBody>

          <TabBody>
            <FormGroup>
              <TextInput label="Passwort" type="text" name="password" />
              <TextInput label="Passwort-Bestätigung" type="text" name="confirm" />
            </FormGroup>
          </TabBody>
          <TabBody>
            <FormGroup>
              <TextArea label="Notizen" type="text" name="notes" />
            </FormGroup>
          </TabBody>
        </TabBodys>
      </Tabs>
      <Button style={{ marginTop: '10px' }} theme="success" height="40px" icon="save" text={submitButtonText} />
    </form>
  </fieldset>
);

// FORM CONFIG
const FinalForm = ({ data, submitButtonText, onSubmit, type }) => {
  const initialValues = React.useMemo(
    () => ({
      data,
      defaults: {
        salutation: 'Herr',
        username: '',
        password: '',
        firstname: '',
        lastname: '',
        street: '',
        houseNo: '',
        plz: null,
        city: '',
        telefon: '',
        email: '',
        notes: '',
        confirm: '',
      },
    }),
    [data]
  );

  return withFinalForm(
    {
      initialValues,
      validator: createValidator({ hasRequired: type === 'CREATE' }),
      submitButtonText,
      allowedAttributes,
      // valuesFormat: {
      //   owner: (owner) => owner.value,
      //   customer: (customer) => customer.value,
      //   manager: (manager) => manager.value || null,
      //   provision: (provision) => Number(provision),
      //   month: (month) => Number(month),
      //   year: (year) => Number(year),
      //   wage: (wage) => Number(wage),
      // },
      submitHandler: (values) => onSubmit(values),
    },
    CustomForm
  );
};

export default FinalForm;
