import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tippy';
import Modal from '../Modal';

const ActionStyle = styled.div`
  color: grey;
  margin-right: 10px;
  font-size: 16px;

  &:hover {
    color: #89cff0;
  }
`;

const ModalButton = ({ onOkClick, icon = 'delete', theme = 'danger', modalHeader, modalBody, toolTip, text }) => {
  const getIcon = () => {
    switch (icon) {
      case 'delete':
        return faTrash;

      default:
        return '';
    }
  };

  return (
    <Modal theme={theme} header={modalHeader} message={modalBody} applyFunction={onOkClick}>
      <ActionStyle>
        <div style={{ display: 'flex' }}>
          <Tooltip
            // options
            arrow="true"
            title={toolTip}
            position="left"
            trigger="mouseenter"
          >
            <FontAwesomeIcon size="1x" icon={getIcon()} />
          </Tooltip>
          <div>{text && <div style={{ marginLeft: '10px', fontSize: '16px' }}>{text}</div>}</div>
        </div>
      </ActionStyle>
    </Modal>
  );
};

export default ModalButton;
