import React, { useState, useEffect } from 'react';
import { AccordionItem, AccordionItemHeading, AccordionItemButton } from 'react-accessible-accordion';
import styled, { css } from 'styled-components';
import SelectInput from 'react-select';
import { Tab, BodyTab, Button } from './style';

// MY CUSTOM HOOK
const useQueryBox = ({ formatter = {} } = {}) => {
  const [state, setState] = useState({});

  const setSearchState = (data) => {
    setState((previousState) => ({ ...previousState, ...data }));
  };

  const setStateByKey = (key, value) => {
    setState((previousState) => ({ ...previousState, [key]: value }));
  };

  return {
    queryBoxState: state,
    setSearchState,
    inputProps: ({ name, defaultValue = '' }) => {
      // GRUND:
      // A component is changing an uncontrolled input of type undefined to be controlled.
      if (!Object.keys(state).includes(name)) {
        setState((previousState) => ({ ...previousState, [name]: defaultValue }));
      }

      return {
        onClick: () => {},
        value: state[name],
        onChange: (argument) => {
          const isEvent = !!argument.target;

          if (isEvent) {
            setStateByKey(name, argument.target.value);
            return;
          }

          // if (name === 'all' && argument === true) {
          //   setState({ all: true });
          // }

          setStateByKey(name, argument);
        },
      };
    },
    // remove value on click
    // after that click, the state changes, after that the function is rerun, which adds the default
    toggleProps: ({ name }) => ({
      onClick: () => {
        const { [name]: x, ...restWithoutKeyName } = state;
        setState({ ...restWithoutKeyName });
      },
    }),
    onSubmit: (cb) => {
      const filteredState = Object.keys(state).reduce((accu, key) => {
        if (typeof state[key] === 'object') {
          return { ...accu, [key]: state[key] };
        }

        if (!state[key].trim()) {
          return { ...accu };
        }

        return { ...accu, [key]: state[key] };
      }, {});

      const formattedSate = Object.keys(filteredState).reduce((accu, key) => {
        const formattedValue = formatter[key] ? formatter[key](filteredState[key]) : filteredState[key];

        return { ...accu, [key]: formattedValue };
      }, {});

      // cb(state);
      // cb(filteredState);
      cb(formattedSate);
    },
  };
};

const SelectBoxContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`;

const BoxContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 180px;
  width: 100%;
  margin: 3px;
`;

const BoxHeader = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 5px 0px 5px 5px;
  border: 1px solid #1ea7fd;
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? '#1EA7FD' : '#dbebfb')};
  color: ${(props) => (props.isActive ? 'white' : '#656464')};
  &:hover {
    background-color: #1ea7fd;
    color: white;
  }
  font-weight: bold;
`;

const BoxBody = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 5px 5px 5px 5px;
  background-color: ${(props) => (props.isActive ? '#1EA7FD' : '#dbebfb')};
  border: 1px solid #1ea7fd;
`;

const Box = ({ label, children, onClick }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <BoxContainer>
      <BoxHeader
        isActive={isVisible}
        onClick={() => {
          setIsVisible((toggle) => !toggle);
          if (onClick) {
            onClick();
          }
        }}
      >
        {label}
      </BoxHeader>
      {isVisible && <BoxBody isActive={isVisible}>{children}</BoxBody>}
    </BoxContainer>
  );
};

const RowStyle = styled.div`
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const SelectField = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 95%;
  padding: 5px;
  background-color: white;
  border-radius: 0px;
  border-style: solid;
`;

const LabelStyle = styled.div`
  color: white;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
`;

const Label = ({ text }) => <LabelStyle>{text}</LabelStyle>;

// *********************************************************************
const Row = ({ children }) => <RowStyle>{children}</RowStyle>;
// *********************************************************************

const QueryBox = ({ children, headerTitle, onSubmit }) => (
  // const options = useQueryBox();
  <>
    <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', userSelect: 'none' }}>
      <Tab allowZeroExpanded>
        <AccordionItem>
          <AccordionItemHeading style={{ cursor: 'pointer' }}>
            <AccordionItemButton style={{ fontWeight: 'bold', fontSize: '12px' }}>{headerTitle}</AccordionItemButton>
          </AccordionItemHeading>
          {/* HIER IST NUR display: flex DAMIT ES IMMER OFFEN IST DER TAB */}
          <BodyTab style={{ width: '100%', display: 'flex', flexDirection: 'column', fontSize: '12px' }}>
            <SelectBoxContainer>{children}</SelectBoxContainer>
            <Button
              style={{ width: '100%', marginTop: '0px', cursor: 'pointer' }}
              onClick={() => {
                onSubmit();
              }}
            >
              Suchen
            </Button>
          </BodyTab>
        </AccordionItem>
      </Tab>
    </div>
  </>
);
const inputStyle = css`
  width: 100%;
  font-size: 16px;
  padding: 5px;
  box-sizing: border-box;
`;

const SearchInputText = styled.input`
  width: 100%;
  font-size: 16px;
  padding: 5px;
  box-sizing: border-box;
`;

const InputNumber = (props) => <input {...props} type="number" />;
const SearchInputNumber = styled(InputNumber)`
  ${inputStyle}
`;

const DateInput = ({ onChange, value = {} }) => {
  const [date, setDate] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });
  // const [date, setDate] = useState({});
  useEffect(() => {
    onChange(date);
    // }, [date, onChange]);
  }, [date]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        <Label text="Jahr" />
        <SelectField
          value={value.year || ''}
          onChange={(event) => {
            const inputValue = event.target.value;
            setDate((prevState) => ({ ...prevState, year: parseInt(inputValue) }));
          }}
        >
          {/* <option value="">Bitte Jahr wählen</option> */}
          <option value="2019">2019</option>
          <option value="2020">2020</option>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
          <option value="2026">2026</option>
          <option value="2027">2027</option>
          <option value="2028">2028</option>
          <option value="2029">2029</option>
          <option value="2030">2030</option>
        </SelectField>
      </div>
      <div style={{ width: '100%' }}>
        <Label text="Monat" />
        <SelectField
          value={value.month || ''}
          onChange={(event) => {
            const inputValue = event.target.value;
            setDate((prevState) => ({ ...prevState, month: parseInt(inputValue) }));
          }}
        >
          {/* <option value="">Bitte Monat wählen</option> */}
          <option value="1">01 - Januar</option>
          <option value="2">02 - Februar</option>
          <option value="3">03 - März</option>
          <option value="4">04 - April</option>
          <option value="5">05 - Mai</option>
          <option value="6">06 - Juni</option>
          <option value="7">07 - Juli</option>
          <option value="8">08 - August</option>
          <option value="9">09 - September</option>
          <option value="10">10 - Oktober</option>
          <option value="11">11 - November</option>
          <option value="12">12 - Dezember</option>
        </SelectField>
      </div>
    </div>
  );
};

const SearchInputDate = styled(DateInput)`
  ${inputStyle}
`;

const SearchInputDateRange = ({ onChange }) => {
  const [timeRange, setTimeRange] = useState({
    from: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    },
    to: {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
    },
  });

  useEffect(() => {
    onChange(timeRange);
    // }, [timeRange, onChange]);
  }, [timeRange]);

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          padding: '10px',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        Zwischen
      </div>
      <DateInput
        onChange={(date) => {
          // console.log('DAS IST VALUE FROM: ', value);
          setTimeRange((prevState) => ({ ...prevState, from: date }));
        }}
        value={timeRange.from}
      />
      <div
        style={{
          textAlign: 'center',
          padding: '10px',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        und
      </div>
      <DateInput
        onChange={(date) => {
          setTimeRange((prevState) => ({ ...prevState, to: date }));
        }}
        value={timeRange.to}
      />
    </div>
  );
};

const SearchInputSelect = ({ options, value, onChange }) => (
  // const x = 42;

  <div style={{ display: 'flex', width: '100%' }}>
    <SelectInput
      placeholder="Wählen..."
      styles={{
        container: (provided) => ({
          ...provided,
          width: '100%',
        }),
        //   backgroundColor: 'red',
        // },
        // control ist das was man sieht, also der input, nicht die options
        control: (provided) => ({
          ...provided,
          borderRadius: 0,
          // width: '300px',
          // width: '100%',
        }),
        // das menu wenn man die box selected
        menu: (provided) => ({
          ...provided,
          borderRadius: 0,
          marginTop: '2px',
        }),
      }}
      value={value}
      options={options}
      onChange={onChange}
    />
  </div>
);
// export default QueryBox;

export {
  SearchInputText,
  SearchInputNumber,
  SearchInputSelect,
  SearchInputDate,
  SearchInputDateRange,
  Row,
  QueryBox,
  useQueryBox,
  Box,
};
