import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useQueryParams, StringParam, NumberParam, NumericObjectParam, JsonParam } from 'use-query-params';
import styled from 'styled-components';
import Loading from '../../../molecules/Loading';
import AppContext from '../../../../context/App.Context';
import createMutationHandler from '../../../shared/create-mutationhandler';
import getPermissions from '../../../shared/get-permissions';
import ButtonBox from '../../../atoms/ButtonBox';
import ErrorGraphQL from '../../../../errors/ErrorGraphQL';
import ErrorNoData from '../../../../errors/ErrorNoData';
// import QueryBox from './QueryBox';
import ViewTitle from '../../../atoms/ViewTitel';
import TableFetch from './Table.Fetch';
import ProtectComponent from '../../../molecules/ProtectComponent';
import QueryBox from './Table.QueryBox';
import QueryFetchInfo from './Table.QueryBoxFetchInfo';
import TableContext from './Table.Context';
import { ContentContainer } from '../../../shared/styles';

const GET_USERS_CUSTOMERS = gql`
  query GET_USERS_CUSTOMERS {
    users(where: { isLocked: false }) {
      userId
      firstname
      lastname
    }
    customers(where: {}) {
      typ
      customerId
      firmenname
      name
      vorname
    }
  }
`;

const UPDATE_ENTITIES_MUTATION = gql`
  mutation UpdateTimetracks($where: TimetracksWhereUpdateInput, $data: TimetrackUpdateInput) {
    updateTimetracks(where: $where, data: $data) {
      __typename
      ... on Timetracks {
        ids
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteTimetrack($where: TimetrackWhereDeleteInput) {
    deleteTimetrack(where: $where) {
      __typename
      ... on Timetrack {
        timetrackId
        name
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

const DUPLICATE = gql`
  mutation DuplicateTimetrack($where: TimetrackWhereDuplicateInput) {
    duplicateTimetrack(where: $where) {
      __typename
      ... on Duplication {
        message
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

//
//

const TableContainer = ({ history, match }) => {
  const { sessionUser } = useContext(AppContext);
  // this component changes the query string
  // the component fetching the data, fetch by query string
  const [queryString, setQueryString] = useQueryParams({
    // all: BooleanParam,
    status: JsonParam,
    timetrackId: NumberParam,
    name: StringParam,
    customer: NumberParam,
    owner: NumberParam,
    projectDate: NumericObjectParam,
    projectDateRange: JsonParam,
    createdAt: NumericObjectParam,
    createdAtRange: JsonParam,
    updatedAt: NumericObjectParam,
    updatedAtRange: JsonParam,
  });

  // all querys are done by useEffect
  const { loading: queryLoading, error: queryError, data: queryData = {}, refetch } = useQuery(GET_USERS_CUSTOMERS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
  });

  const [deleteMutation, { error: deleteError, loading: deleteLoading }] = useMutation(DELETE, {
    refetchQueries: () => [
      {
        query: GET_USERS_CUSTOMERS,
        variables: {
          where: {}, // put query link arguments here
        },
        v: Math.random(),
      },
    ],
  });

  const [duplicateMutation, { error: duplicateError, loading: duplicateLoading }] = useMutation(DUPLICATE, {
    refetchQueries: () => [
      {
        query: GET_USERS_CUSTOMERS,
        variables: {
          where: {}, // put query link arguments here
        },
        v: Math.random(),
      },
    ],
  });

  const [updateEntitiesMutation, { error: updateEntitiesError, loading: updateEntitiesLoading }] = useMutation(
    UPDATE_ENTITIES_MUTATION,
    {
      refetchQueries: () => [
        {
          query: GET_USERS_CUSTOMERS,
          variables: {
            where: {}, // put query link arguments here
          },
          v: Math.random(),
        },
      ],
    }
  );

  const handleDatabaseSearchButton = (searchData) => {
    if (searchData.all) {
      setQueryString({ all: true }, 'replace');
    } else {
      setQueryString(searchData, 'replace');
    }
  };

  // ------------------------------------ MUTATION HANDLERS ------------------------------------
  const handleUpdateEntitiesMutation = (mutation) => {
    const handledDeleteMuation = createMutationHandler({
      mutation,
      mutationName: 'updateTimetracks',
      mutationReturnTypes: {
        Timetracks: {
          successMessage: (data) =>
            data.ids.length > 1
              ? `Zeiterfassungen mit den IDs ${data.ids.join(', ')} erfolgreich verändert`
              : `Zeiterfassung mit der ID ${data.ids[0]} erfolgreich verändert`,
          onSuccess: () => history.push('/timetracks'),
        },
      },
      serverErrorMessage: `Änderung der Zeiterfassung nicht erfolgreich`,
    });

    return (values) =>
      handledDeleteMuation({
        variables: values.variables,
      });
  };

  const handleDeleteMutation = (mutation) => {
    const handledDeleteMuation = createMutationHandler({
      mutation,
      mutationName: 'deleteTimetrack',
      mutationReturnTypes: {
        Timetrack: {
          successMessage: (data) => `Zeiterfassung mit der ID ${data.timetrackId} erfolgreich gelöscht`,
          onSuccess: () => history.push('/timetracks'),
        },
      },
      serverErrorMessage: `Änderung der Zeiterfassung nicht erfolgreich`,
    });

    return (values) =>
      handledDeleteMuation({
        variables: values.variables,
      });
  };

  const handleDuplicateMutation = (mutation) => {
    const handledDuplicateMuation = createMutationHandler({
      mutation,
      mutationName: 'duplicateTimetrack',
      mutationReturnTypes: {
        Duplication: {
          successMessage: (data) => data.message,
          onSuccess: () => history.push('/timetracks'),
        },
      },
      serverErrorMessage: `Duplizierung der Zeiterfassung nicht erfolgreich`,
    });

    return (values) =>
      handledDuplicateMuation({
        variables: values.variables,
      });
  };
  // ------------------------------------ MUTATION HANDLERS ------------------------------------

  // -------------------------- LOADING AND DATA CHECKING -------------------------------
  const { users = null, customers = null } = queryData;

  if (queryLoading) {
    return <Loading />;
  }

  if (!users || !customers) {
    return <ErrorNoData homeUrl="/" refreshUrl="/timetracks" />;
  }
  // ------------------------------------------------------------------------------------

  const context = {
    users,
    deleteTimetrack: (row) => {
      const mutation = handleDeleteMutation(deleteMutation);
      mutation({
        variables: { where: { timetrackId: row.timetrackId } },
      });
    },
    duplicateTimetracks: (where) => {
      const mutation = handleDuplicateMutation(duplicateMutation);
      mutation({ variables: { where } });
    },
    updateTimetracks: (variables) => {
      const mutation = handleUpdateEntitiesMutation(updateEntitiesMutation);
      mutation({ variables });
    },
  };

  return (
    <TableContext.Provider value={context}>
      <fieldset disabled={deleteLoading || queryLoading || duplicateLoading || updateEntitiesLoading}>
        <ErrorGraphQL error={queryError} />
        <ErrorGraphQL error={deleteError} />
        <ErrorGraphQL error={duplicateError} />
        <ErrorGraphQL error={updateEntitiesError} />
        <ViewTitle>Zeiterfassungen</ViewTitle>
        <ProtectComponent requiredPermissions={['CAN_CREATE_TIMETRACKS']}>
          <ContentContainer>
            <ButtonBox
              theme="primary"
              icon="table"
              text="Neue Erfassung"
              onClick={() => history.push(`/timetracks/create`)}
            />
          </ContentContainer>
          <QueryBox users={users} customers={customers} onSearch={handleDatabaseSearchButton} />
          <QueryFetchInfo users={users} customers={customers} queryString={queryString} />
        </ProtectComponent>
        <TableFetch />
      </fieldset>
    </TableContext.Provider>
  );
};

export default TableContainer;
