import React from 'react';
import moment from 'moment';
import MetaInfoBox from '../MetaInfoBox';
import { Table, Row, TData, Header } from './style';
import { createViewData, getDynamicWorkingDays, getWorkingHours } from '../../views/TimeTracker/TimeList/time-tracker-utils';
import { createViewData as createDynamicViewData } from '../../views/TimeTracker/TimeList/dynamic-time-tracker-utils';

const SignatureTable = ({ data = {}, owner = {} }) => {
  const timetrack = data;
  const { timetrackType } = timetrack;
  const timeTracks = timetrack.tracks || [];
  const { month = 1, year = 2020, customer, manager } = timetrack;
  const daysOfMonth = moment(`${month} ${year}`, 'MM YYYY').endOf('month').format('DD');

  const fullMonthTracks = Array(Number(daysOfMonth))
    .fill()
    .map((_, i) => i + 1)
    .reduce((accu, day) => {
      if (timeTracks.some((track) => track.day.toString() === day.toString())) {
        const activeTrack = timeTracks.filter((track) => track.day === day)[0];
        return [...accu, { ...activeTrack, active: true }];
      }

      return [
        ...accu,
        {
          day,
          active: false,
          start: '',
          end: '',
          lunchBreak: '',
          activity: '',
          location: '',
        },
      ];
    }, []);

  const { firstname = '', lastname = '' } = owner;

  return (
    <>
      <MetaInfoBox
        style={{ marginBottom: '10px' }}
        columns={[
          [
            { label: 'Kunde', value: customer },
            { label: 'Leistungsbringer', value: `${firstname} ${lastname}` },
            { label: 'Projektverantwortlicher', value: manager },
          ],
          [
            {
              label: 'Arbeitstage',
              value: timetrackType === 'FULL_MONTH' ? timeTracks.length : getDynamicWorkingDays(timeTracks),
            },
            {
              label: 'Gesamtstunden',
              value: getWorkingHours(timeTracks),
            },
          ],
        ]}
      />

      <Table style={{ margin: '0 auto 0px', minWidth: '470px', maxWidth: '1200px' }}>
        <thead>
          <Row>
            <Header colSpan="2">Datum</Header>
            <Header align="center">Von</Header>
            <Header align="center">Bis</Header>
            <Header align="center">Pause</Header>
            <Header>Tätigkeit</Header>
            <Header>Standort</Header>
            <Header colSpan="2" align="right">
              Gesamt
            </Header>
          </Row>
        </thead>

        {timetrackType === 'FULL_MONTH' && (
          <tbody>
            {/* {Array.from({ length: daysOfMonth }, (_, index) => { */}
            {Array.from({ length: daysOfMonth }, (_, index) => {
              // contains active/inactive logic (what time we have or not AKA hasDay or not)
              const day = index + 1;
              // just view data like Mo, Di, Mi, Fr, Sa, So, Berechnung von stunden etc.
              const {
                date,
                hasDay,
                weekDay,
                isWeekend,
                startTime,
                endTime,
                lunchBreak,
                activity,
                location,
                nettoTime,
              } = createViewData({
                year,
                month,
                day,
                timeTrackerArray: fullMonthTracks,
              });

              return (
                <Row
                  key={day}
                  style={{
                    backgroundColor: isWeekend ? '#dfe3ee' : '',
                    opacity: hasDay ? '' : '0.7',
                  }}
                >
                  <TData width="20px">{weekDay}</TData>
                  <TData width="80px">{date}</TData>
                  <TData width="65px" align="right">
                    {startTime}
                  </TData>
                  <TData width="65px" align="right">
                    {endTime}
                  </TData>
                  <TData width="65px" align="right">
                    {lunchBreak && `${lunchBreak} min`}
                  </TData>
                  <TData>{activity}</TData>
                  <TData>{location}</TData>
                  <TData width="75px" align="right">
                    {nettoTime} h
                  </TData>
                </Row>
              );
            })}
          </tbody>
        )}

        {timetrackType === 'DYNAMIC' && (
          <tbody>
            {/* {Array.from({ length: daysOfMonth }, (_, index) => { */}
            {timeTracks.map((track, index) => {
              // contains active/inactive logic (what time we have or not AKA hasDay or not)
              const { day } = track;
              // just view data like Mo, Di, Mi, Fr, Sa, So, Berechnung von stunden etc.
              const {
                date,
                hasDay,
                weekDay,
                isWeekend,
                start,
                end,
                lunchBreak,
                activity,
                location,
                nettoTime,
              } = createDynamicViewData({
                year,
                month,
                day,
                timetrack: track,
                timeTrackerArray: fullMonthTracks,
              });

              return (
                <Row
                  key={index}
                  style={{
                    backgroundColor: isWeekend ? '#dfe3ee' : '',
                    opacity: hasDay ? '' : '0.7',
                  }}
                >
                  <TData width="20px">{weekDay}</TData>
                  <TData width="80px">{date}</TData>
                  <TData width="65px" align="right">
                    {start}
                  </TData>
                  <TData width="65px" align="right">
                    {end}
                  </TData>
                  <TData width="65px" align="right">
                    {lunchBreak && `${lunchBreak} min`}
                  </TData>
                  <TData>{activity}</TData>
                  <TData>{location}</TData>
                  <TData width="75px" align="right">
                    {nettoTime} h
                  </TData>
                </Row>
              );
            })}
          </tbody>
        )}
      </Table>
    </>
  );
};

export default SignatureTable;
