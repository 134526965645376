import styled from 'styled-components';

export const BodyStyle = styled.div`
  background-color: white;
  padding: 25px;
`;

export const SignStyle = styled.div`
  background-color: white;
  /* z-index: -50; */
  /* user-select: none; */
`;

export const SignLine = styled.div`
  /* position: relative; */
  position: relative;
  border-style: solid;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-width: 1px;
  /* top: 210px; */
  top: 110px;
  width: 480px;
  margin-left: 10px;
  color: black;
  font-size: 12px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`;

export const Input = styled.input`
  margin: 0 auto 0px;
  min-width: 470px;
  max-width: 1200px;

  width: 100%;
  height: 30px;
  border-radius: 0px;
  border-width: 1px;
  border-color: gray;
  border-style: solid;
`;
