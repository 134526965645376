import React from 'react';
import styled from 'styled-components';
import { ContentRoutes } from '../../../routes';

const ContentStyle = styled.div`
  position: absolute;
  overflow: auto;

  padding-top: 60px;
  padding-bottom: 20px;
  padding-right: 15px;
  padding-left: ${(props) => (props.isOpen ? '220px' : '60px')};

  top: 0;
  right: 0;
  background-color: white;
  overflow: auto;
  width: 100%;
  height: 100%;

  transition: all 0.5s ease;

  @media (max-width: 768px) {
    padding-left: ${(props) => (props.isOpen ? '220px' : '15px')};
  }
`;

const ContentArea = ({ isOpen }) => (
  <ContentStyle isOpen={isOpen}>
    <ContentRoutes />
  </ContentStyle>
);

export default ContentArea;
