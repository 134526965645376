import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import createMutationHandler from '../../shared/create-mutationhandler';
import Table from './Table';
import Loading from '../../molecules/Loading';
import ButtonBox from '../../atoms/ButtonBox';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';

const QUERY = gql`
  query GetRecruiter($where: RecruiterWhereInput) {
    recruiters(where: $where) {
      id
      name
      telefon
      email
      street
      houseNo
      city
      updatedAt
    }
  }
`;

const DELETE = gql`
  mutation DeleteCustomer($where: RecruiterWhereDeleteInput) {
    deleteCustomer(where: $where) {
      __typename
      ... on Recruiter {
        id
        name
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

// const date = new Date();
// const DEFAULT_PATH = `/kunden/?updatedAt=${date.getFullYear()}-${date.getMonth() + 1}`;

const Recruiter = ({ history, match }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: {} },
    fetchPolicy: 'network-only',
  });

  const [deleteMutation, { error: deleteError, loading: deleteLoading }] = useMutation(DELETE, {
    refetchQueries: () => [
      {
        query: QUERY,
        variables: {
          where: {},
          // where: { customerId: match.params.id },
        },
        v: Math.random(),
      },
    ],
  });

  // DELETE-MUTATION
  const handleDeleteMutation = (mutation) => {
    const handledDeleteMuation = createMutationHandler({
      mutation,
      mutationName: 'deleteBill',
      mutationReturnTypes: {
        Recruiter: {
          successMessage: (data) => `Rechnung mit der ID ${data.billId} erfolgreich gelöscht`,
          onSuccess: () => history.push('/kunden'),
        },
      },
      serverErrorMessage: `Änderung der Rechnung nicht erfolgreich`,
    });

    return (values) =>
      handledDeleteMuation({
        variables: values.variables,
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  const { recruiters = [] } = queryData;

  return (
    <fieldset disabled={deleteLoading}>
      <h1>Rekrutierungsfirmen</h1>
      <hr />
      <ButtonBox
        name="button-create-bill"
        theme="primary"
        icon="file"
        text="Neuen Recruiter"
        style={{ marginRight: '10px', marginBottom: '20px' }}
        onClick={() => history.push(`/recruiters/create`)}
      />
      <div>
        <ErrorGraphQL error={queryError} />
        <ErrorGraphQL error={deleteError} />
        <Table data={recruiters} />
      </div>
    </fieldset>
  );
};

export default withRouter(Recruiter);
