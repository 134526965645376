import React from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemPanel,
  AccordionItemHeading,
  AccordionItemButton,
} from 'react-accessible-accordion';

// ------------------------------- STYLE -------------------------------
const ToggleTabHeader = styled(Accordion)`
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #ced4da;
  color: #656464;
  font-size: 16px;
`;

const TabItemHeading = styled(AccordionItemHeading)`
  cursor: pointer;
  background-color: aliceblue;
  &:hover {
    background-color: #dcdcdc;
  }
`;

const HorizontalLine = styled.hr`
  border: 1px solid #ced4da;
  width: 100%;
  margin: 0px 0px 0px 0px;
  box-sizing: border-box;
`;
// ------------------------------- STYLE -------------------------------

//
//
//
const ToggleTab = ({ children, header }) => (
  <ToggleTabHeader allowZeroExpanded preExpanded={['toggle-tab']}>
    <AccordionItem uuid="toggle-tab">
      <TabItemHeading>
        <AccordionItemButton style={{ fontWeight: 'bold', padding: '10px' }}>{header}</AccordionItemButton>
      </TabItemHeading>
      <AccordionItemPanel style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <HorizontalLine />
        {children}
      </AccordionItemPanel>
    </AccordionItem>
  </ToggleTabHeader>
);
export default ToggleTab;
