import React, { useState } from 'react';
import styled from 'styled-components';
import { CgImport, CgExport, CgTrash } from 'react-icons/cg';
import { createViewData } from './time-tracker-utils';
import { TimeListTable, Row, TData, Header } from './style';
import Button from '../../../atoms/Button';

import TimeInput from '../../../molecules/TimeInput';
import MinuteInput from '../../../molecules/MinuteInput';

const ActionButton = styled.div`
  cursor: pointer;
  user-select: none;
  height: 25px;
  width: 25px;
  padding-top: 2px;
  font-size: 16px;

  &:hover {
    background-color: ${(props) => props.hoverBgColor};
    color: ${(props) => props.hoverColor || 'white'};
  }
`;

const TimeListDefault = ({
  timetrack,
  tracks,
  // getWorkingDays,
  // getWorkingHours,
  updateValue,
  deleteDay,
  copyDay,
  pasteDay,
  addDay,
  submitButtonText,
  onSubmit,
  year,
  month,
  daysOfMonth,
}) => (
  <>
    <TimeListTable>
      <thead>
        <Row>
          <Header colSpan="2">Datum</Header>
          <Header align="center">Von</Header>
          <Header align="center">Bis</Header>
          <Header align="center">Pause</Header>
          <Header>Tätigkeit</Header>
          <Header>Standort</Header>
          <Header colSpan="2" align="right">
            Gesamt
          </Header>
        </Row>
      </thead>
      <tbody>
        {Array.from({ length: daysOfMonth }, (_, index) => {
          // contains active/inactive logic (what time we have or not AKA hasDay or not)
          const day = index + 1;
          // just view data like Mo, Di, Mi, Fr, Sa, So, Berechnung von stunden etc.
          const {
            date,
            hasDay,
            weekDay,
            isWeekend,
            startTime,
            endTime,
            lunchBreak,
            activity,
            location,
            nettoTime,
          } = createViewData({
            year,
            month,
            day,
            timeTrackerArray: tracks,
          });

          return (
            <Row
              key={day}
              style={{
                backgroundColor: isWeekend ? '#dfe3ee' : '',
                opacity: hasDay ? '' : '0.7',
              }}
            >
              <TData width="20px">{weekDay}</TData>
              <TData width="70px">{date}</TData>

              <TData width="65px">
                <TimeInput
                  value={startTime}
                  onChange={updateValue('start', index + 1)}
                  onBlur={() => {
                    updateValue('start', index + 1);
                    addDay(index + 1);
                  }}
                  style={{ padding: '4px' }}
                />
              </TData>
              <TData width="65px">
                <TimeInput
                  value={endTime}
                  onChange={updateValue('end', index + 1)}
                  onBlur={() => addDay(index + 1)}
                  style={{ padding: '4px' }}
                />
              </TData>
              <TData width="65px">
                <div style={{ display: 'flex' }}>
                  <MinuteInput
                    value={lunchBreak}
                    onChange={updateValue('lunchBreak', index + 1)}
                    style={{ padding: '4px' }}
                  />
                  <div style={{ marginTop: '4px', marginLeft: '3px' }}>min</div>
                </div>
              </TData>
              <TData>
                <input
                  style={{
                    padding: '5px',
                    width: '100%',
                    fontSize: '16px',
                    borderRadius: '0px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                  value={activity}
                  onChange={(event) => updateValue('activity', index + 1)(event.target.value)}
                />
              </TData>
              <TData>
                <input
                  style={{
                    padding: '5px',
                    width: '100%',
                    fontSize: '16px',
                    borderRadius: '0px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                  value={location}
                  onChange={(event) => updateValue('location', index + 1)(event.target.value)}
                />
              </TData>
              <TData width="50px" align="right">
                {nettoTime} h
              </TData>
              <TData width="75px" align="center">
                <div style={{ display: 'flex' }}>
                  <ActionButton onClick={() => pasteDay(index + 1)} hoverBgColor="green">
                    <CgImport />
                  </ActionButton>
                  <ActionButton onClick={() => copyDay(index + 1)} hoverBgColor="#fcce00" hoverColor="black">
                    <CgExport />
                  </ActionButton>
                  <ActionButton onClick={() => deleteDay(index + 1)} hoverBgColor="red">
                    <CgTrash />
                  </ActionButton>
                </div>
              </TData>
            </Row>
          );
        })}
      </tbody>
    </TimeListTable>
    <Button theme="success" icon="save" text={submitButtonText} style={{ marginTop: '20px' }} onClick={onSubmit} />
  </>
);

export default TimeListDefault;
