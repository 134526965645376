import styled from 'styled-components';

export const FormContainer = styled.div`
  /* margin: top right_left bottom; */
  margin: 0 auto 0px;
  /* min-width: 470px; */
  min-width: 290px;
  max-width: 1200px;

  border-style: solid;
  border-width: 1px;
  border-color: rgb(170, 170, 170);
  font-size: 12px;
  color: rgb(80, 80, 80);
`;

export const ContentContainer = styled.div`
  display: flex;
  /* margin: top right_left bottom; */
  margin: 0 auto 0px;
  min-width: 290px;
  /* min-width: 470px; */
  max-width: 1200px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  /* margin: top right_left bottom; */
  margin: 0 auto 0px;
  /* min-width: 470px; */
  min-width: 290px;
  max-width: 1200px;
`;

export const TableStyle = styled.div`
  font-size: 12px;
  /* width: 100%; */
  /* margin: top right_left bottom; */
  margin: 0 auto 10px;
  min-width: 700px;
  max-width: 1200px;

  background-color: white;
  padding: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;
