import React from 'react';
import { withRouter } from 'react-router-dom';
import { MainRoutes } from '../../../routes';

// import PropTypes from 'prop-types';
// import App from '../App';
// import Login from '../Login';

// class Main extends React.Component {
const Main = () => <MainRoutes />;

Main.propTypes = {
  // notifications: PropTypes.array,
};

Main.defaultProps = {
  // notifications: [],
};

// export default Main;
export default withRouter(Main);
