/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ImEye, ImEyeBlocked, ImUnlocked, ImLock } from 'react-icons/im';
import { FaUser, FaUserTie, FaEnvelope, FaRegEnvelope, FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { Tooltip } from 'react-tippy';
import ModalButton from '../../../molecules/ModalButton';
import DateColumn from '../../../molecules/DateColumn';
import UrlButton from '../../../molecules/UrlButton';
import { getDateData } from '../../../shared/date-utils';
import { timetrackDocumentURL } from '../../../../config';

const HoverText = styled.p`
  display: flex;
  align-items: center;
  color: #000;
  height: 30px;
  padding-left: 5px;

  :hover {
    color: #ffffff;
    background-color: #007bff;
    cursor: pointer;
  }
`;

const sortCaret = (order, column) => {
  if (!order) {
    return (
      <span style={{ cursor: 'pointer' }}>
        <FaCaretUp />
        <FaCaretDown />
      </span>
    );
  }
  if (order === 'asc') {
    return (
      <span style={{ cursor: 'pointer' }}>
        <FaCaretDown />
      </span>
    );
  }
  if (order === 'desc') {
    return (
      <span style={{ cursor: 'pointer' }}>
        <FaCaretUp />
      </span>
    );
  }
  return null;
};

const sortFunc = (a, b, order) => (order === 'asc' ? b - a : a - b);

const Columns = ({ CAN_CREATE_TIMETRACKS, deleteTimetrack }) => [
  {
    text: 'ID',
    dataField: 'timetrackId',
    style: { minWidth: '40px' },
    sort: true,
    sortFunc,
    sortCaret,
  },
  {
    text: 'Projektname',
    dataField: 'name',
    headerStyle: { paddingLeft: '5px' },
    style: { minWidth: '125px', width: '2000px' },
    sort: true,
    formatter: (cell, row) => (
      <div>
        <Link style={{ textDecoration: 'none' }} to={`/timetracks/list/${row.timetrackId}`}>
          <HoverText>{row.name}</HoverText>
        </Link>
      </div>
    ),
    sortCaret,
  },
  {
    text: 'Kunde',
    dataField: 'customer',
    formatter: (cell, { customer }) =>
      customer.typ === 'Firma' ? <div>{customer.firmenname}</div> : <div>{`${customer.vorname} ${customer.name}`}</div>,
    style: { minWidth: '100px', width: '2000px' },
    sort: true,
    sortCaret,
  },
  {
    text: 'Zeitraum',
    dataField: 'year',
    headerStyle: { paddingLeft: '10px' },
    style: { minWidth: '100px', width: '300px', paddingLeft: '10px' },
    sort: true,
    formatter: (cell, row) => {
      const { year, monthName } = getDateData({ year: row.year, month: row.month });
      // console.log("DAS IST MONTH: ", row.month)
      return `${year} ${monthName}`;
    },
    sortCaret,
  },
  {
    text: 'Leistungsbringer',
    dataField: 'owner.firstname',
    headerStyle: { paddingLeft: '10px' },
    style: { minWidth: '140px', width: '1000px', paddingLeft: '10px' },
    sort: true,
    formatter: (cell, { owner = {} }) => `${owner.firstname || ''} ${owner.lastname || ''}`,
    sortCaret,
  },
  {
    text: 'Leistungsbringer',
    dataField: 'owner.lastname',
    sort: true,
    hidden: true,
    FaCaretDown,
  },
  {
    text: 'Status',
    dataField: 'isLocked',
    style: { verticalAlign: 'middle', minWidth: '70px', width: '200px' },
    formatter: (value, row) => (
      <div style={{ display: 'flex', marginTop: '5px', fontSize: '15px', fontWeight: 'bold' }}>
        <div style={{ marginRight: '5px', color: row.isHidden ? 'green' : 'red' }}>
          <Tooltip
            arrow="true"
            title={row.isHidden ? 'nicht sichtbar für Leistungsbringer' : 'sichtbar für Leistungsbringer'}
            position="left"
            trigger="mouseenter"
          >
            {/* {row.isHidden ? <ImEyeBlocked /> : <ImEye />} */}
            {row.isHidden ? <ImEyeBlocked /> : <ImEye />}
          </Tooltip>
        </div>
        <div style={{ marginRight: '5px', color: row.isLocked ? 'green' : 'red' }}>
          <Tooltip
            arrow="true"
            title={row.isLocked ? 'gesperrt für Leistungsbringer' : 'frei für Leistungsbringer'}
            position="left"
            trigger="mouseenter"
          >
            {row.isLocked ? <ImLock /> : <ImUnlocked />}
          </Tooltip>
        </div>
        <div style={{ marginRight: '5px', color: row.isFreelancerSigned ? 'green' : 'red' }}>
          <Tooltip
            arrow="true"
            title={row.isFreelancerSigned ? 'Leistungsbringer unterschrieben' : 'Leistungsbringer nicht unterschrieben'}
            position="left"
            trigger="mouseenter"
          >
            {row.isHidden ? <FaUser /> : <FaUser />}
          </Tooltip>
        </div>
        <div style={{ marginRight: '5px', color: row.isManagerSigned ? 'green' : 'red' }}>
          <Tooltip
            arrow="true"
            title={row.isManagerSigned ? 'Manager unterschrieben' : 'Manager nicht unterschrieben'}
            position="left"
            trigger="mouseenter"
          >
            {row.isHidden ? <FaUserTie /> : <FaUserTie />}
          </Tooltip>
        </div>
        <div style={{ marginRight: '5px', color: row.signMailSend ? 'green' : 'red' }}>
          <Tooltip
            arrow="true"
            title={row.signMailSend ? 'Zeiterfassung gesendet' : 'Zeiterfassung nicht gesendet'}
            position="left"
            trigger="mouseenter"
          >
            {row.signMailSend ? <FaEnvelope /> : <FaRegEnvelope />}
          </Tooltip>
        </div>
      </div>
    ),
    sort: true,
  },
  {
    text: 'Erstellt am',
    dataField: 'createdAt',
    formatter: DateColumn('createdAt'),
    style: { minWidth: '20px' },
    sort: true,
    // hidden: !CAN_CREATE_TIMETRACKS,
    hidden: true,
  },
  {
    text: 'Geändert am',
    dataField: 'updatedAt',
    formatter: DateColumn('updatedAt'),
    style: { minWidth: '125px' },
    sort: true,
    hidden: true,
  },
  {
    text: 'Aktionen',
    dataField: 'month',
    headerStyle: { paddingLeft: '10px' },
    style: { minWidth: '90px', width: '100px', paddingLeft: '10px' },
    formatter: (cell, row) => (
      <div style={{ display: 'flex' }}>
        {CAN_CREATE_TIMETRACKS && (
          <>
            <UrlButton icon="edit" toolTip="Erfassung bearbeiten" url={`/timetracks/update/${row.timetrackId}`} />
            <UrlButton
              external
              icon="pdf"
              toolTip="PDF erstellen"
              url={`${timetrackDocumentURL}/${row.timetrackId}/pdf`}
            />
            <ModalButton
              modalHeader="Löschen"
              modalBody={`Möchten Sie den Beleg mit der ID ${row.timetrackId} wirklich löschen?`}
              toolTip="Erfassung löschen"
              onOkClick={() => deleteTimetrack(row)}
            />
          </>
        )}
      </div>
    ),
    sort: true,
    hidden: !CAN_CREATE_TIMETRACKS,
  },
];

export default Columns;
