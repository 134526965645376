import React from 'react';
// import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

// import { Tab, Tabs, TabList, TabBody } from 'react-tabs';
import { FieldArray } from 'react-final-form-arrays';
import FormGroup from '../../atoms/FormGroup';
import Button from '../../atoms/Button';
import TextInput from '../../molecules/TextInput';
import TextArea from '../../molecules/TextArea';
import SelectField from '../../molecules/SelectField';

import withFinalForm from '../../HOC/with-final-form';
import allowedAttributes from './allowed-attributes';
import validator from './validator';

import { Tabs, TabHeaders, TabBodys, TabBody, Tab } from '../../molecules/Tabs';

// const tabStyle = {
//   borderRadius: '0px',
//   padding: '15px',
//   color: '#505050	',
// };

// const selectedTabStyle = {
//   border: 'solid',
//   borderColor: '#aaaaaa',
//   borderWidth: '1px',
//   borderTop: 0,
//   padding: '0px 10px 20px 10px',
// };

const Form = ({ values, handleSubmit, submitButtonText }) => (
  <form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
    <Tabs>
      <TabHeaders>
        <Tab>
          <FontAwesome name="info-circle" /> &nbsp; Begünstigter
        </Tab>
        <Tab>
          <FontAwesome name="bank" /> &nbsp; Bankdaten
        </Tab>
        <Tab>
          <FontAwesome name="sticky-note" /> &nbsp; Notizen
        </Tab>
      </TabHeaders>

      <TabBodys>
        {/* KUNDENINFORMATIONEN */}
        <TabBody>
          <FormGroup>
            <SelectField
              label="Typ"
              type="text"
              name="type"
              options={[
                { label: 'Firma', value: 'Firma' },
                { label: 'Privatperson', value: 'Privatperson' },
                { label: 'Mitarbeiter', value: 'Mitarbeiter' },
                { label: 'Versicherung', value: 'Versicherung' },
                { label: 'Behörde', value: 'Behörde' },
              ]}
            />
          </FormGroup>
          {values.type === 'Firma' && (
            <FormGroup>
              <TextInput label="Firmenname" type="text" name="firmenname" />
            </FormGroup>
          )}

          <hr />
          {values.type === 'Firma' && <h4>Ansprechperson der Firma</h4>}
          <FormGroup>
            <SelectField
              label="Anrede"
              name="anrede"
              options={[
                { label: 'Herr', value: 'Herr' },
                { label: 'Frau', value: 'Frau' },
              ]}
            />
          </FormGroup>

          <FormGroup>
            <TextInput label="Vorname" type="text" name="firstname" focus />
            <TextInput label="Name" type="text" name="lastname" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Straße" type="text" name="street" />
            <TextInput label="Hausnummer" type="text" name="houseNo" />
          </FormGroup>
          <FormGroup>
            <TextInput label="PLZ" type="number" name="plz" />
            <TextInput label="Ort" type="text" name="city" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Telefon" type="text" name="telefon" format="telefonFormat" />
            <TextInput label="Fax" type="text" name="fax" />
          </FormGroup>
          <FormGroup>
            <TextInput label="E-Mail" type="text" name="email" />
          </FormGroup>
        </TabBody>

        {/* BANKDATEN */}
        <TabBody>
          <FormGroup>
            <TextInput label="Bankname" type="text" name="bankname" />
            <TextInput label="BIC / SWIFT" type="text" name="bic" />
          </FormGroup>
          <FormGroup>
            <TextInput label="IBAN" type="text" name="iban" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Kontonummer" type="text" name="kontonr" />
            <TextInput label="BLZ" type="text" name="blz" />
          </FormGroup>
        </TabBody>

        {/* NOTIZEN */}
        <TabBody>
          <FormGroup>
            <TextArea label="Notizen" type="text" name="notizen" />
          </FormGroup>
        </TabBody>
      </TabBodys>
    </Tabs>

    <Button
      name="submit-button"
      style={{ marginTop: '20px' }}
      theme="success"
      height="40px"
      icon="save"
      text={submitButtonText}
    />
  </form>
);

// FORM CONFIG
export default (props) => {
  const { data = {}, submitButtonText, mutation } = props;

  const initialValues = React.useMemo(
    () => ({
      type: 'Firma',
      firmenname: '',
      anrede: 'Herr',
      firstname: '',
      lastname: '',
      street: '',
      houseNo: '',
      plz: null,
      city: '',
      telefon: '',
      fax: '',
      email: '',
      bankname: '',
      iban: '',
      bic: '',
      kontonr: '',
      blz: '',
      notizen: '',
    }),
    [data]
  );

  return withFinalForm(
    {
      initialValues,
      validator,
      submitButtonText,
      allowedAttributes,
      attributesFilter: {
        plz: (value) => !value,
      },
      valuesFormat: {
        plz: (value) => (value ? Number(value) : null),
      },
      submitHandler: (values) => mutation(values),
    },
    Form
  );
};
