import React from 'react';
import { createGlobalStyle } from 'styled-components'
import { Router, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';
import ApolloClient from 'apollo-client';

import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloProviderHooks } from '@apollo/react-hooks';

import { createUploadLink } from 'apollo-upload-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { NotificationContainer } from 'react-notifications';
import { QueryParamProvider } from 'use-query-params';

import 'font-awesome/css/font-awesome.min.css';
// import 'bootstrap/dist/css/bootstrap.css';
import 'flexboxgrid/css/flexboxgrid.css';
import 'react-notifications/lib/notifications.css';
import 'react-tabs/style/react-tabs.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import 'react-tippy/dist/tippy.css';

import ErrorBoundary from './errors/ErrorBoundary';
import { graphqlUrl } from './config';
import { AppProvider } from './context';

import appHistory from './app-history';
import registerServiceWorker from './registerServiceWorker';

import Main from './components/environment/Main';

// --------- GLOBAL STYLE -------------
const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    display: block;
    *:focus {
      outline:none;
    }
  }

  body {
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
  }

  // wichtig für toggle-box
  [hidden] {
    display: none !important;
  }

  table {
    border-collapse: collapse !important;
    width: 100%;
  }
  /* [tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important;
  } */
  
  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  ol, ul, dl {
    margin-top: 0;
  }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
  }

  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
`;
// --------- GLOBAL STYLE -------------


const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
  },
  query: {
    fetchPolicy: 'no-cache',
  },
};

const link = createUploadLink({
  uri: graphqlUrl,
  credentials: 'include',
});

const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  defaultOptions,
});

ReactDOM.render(
  <>
    <GlobalStyle />
    <ErrorBoundary>
      <NotificationContainer />
      <Router history={appHistory}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <AppProvider>
            <ApolloProviderHooks client={apolloClient}>
              <ApolloProvider client={apolloClient}>
                {/* <Provider store={store}> */}
                <div>
                  <Main />
                </div>
                {/* </Provider> */}
              </ApolloProvider>
            </ApolloProviderHooks>
          </AppProvider>
        </QueryParamProvider>
      </Router>
    </ErrorBoundary>
  </>,
  document.getElementById('root')
);

registerServiceWorker();
