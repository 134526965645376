import React from 'react';
import PropTypes from 'prop-types';
import { FaArrowLeft, FaSignature, FaUser, FaClock } from 'react-icons/fa';
import { ImTable } from 'react-icons/im';
import styled from 'styled-components';

const THEME_COLOR = {
  primary: '#007bff',
  success: '#28a745',
  info: '#17a2b8',
  warning: '#ffc107',
  danger: '#dc3545',
  default: '#f8f9fa',
};

const ICONS = {
  'arrow-left': FaArrowLeft,
  signature: FaSignature,
  user: FaUser,
  table: ImTable,
  clock: FaClock,
};

const ButtonBoxStyle = styled.button`
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px;
  border-width: 0px;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  /* font-weight: bold; */
  /* font-weight: strong; */
  /* min-width: 50px; */
  /* width: 50px; */
  width: 85px;
  min-width: 50px;
  min-height: 85px;
  max-height: 85px;
  /* padding: 5px; */
  /* padding: 5px; */
  text-align: center;
  vertical-align: middle;

  &:hover {
    filter: brightness(90%);
  }

  background-color: ${(props) => THEME_COLOR[props.theme] || THEME_COLOR.default};
  border-color: ${(props) => (props.theme === 'default' ? '#adadad' : THEME_COLOR[props.theme])};
  color: ${(props) => (props.theme === 'default' || props.theme === 'warning' ? '#212529' : '#fff')};
  max-width: ${(props) => (props.noMaxWidth ? 'none' : '95px')};
`;

const ButtonBox = (props) => {
  const Icon = ICONS[props.icon];

  return (
    <ButtonBoxStyle {...props}>
      <Icon size="25px" />
      <div style={{ marginTop: '10px' }}>{props.text}</div>
    </ButtonBoxStyle>
  );
};

ButtonBox.propTypes = {
  theme: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.string,
};

ButtonBox.defaultProps = {
  text: '',
  icon: '',
  theme: 'default',
  // width: '100%',
};

export default ButtonBox;
