import React from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';

// import CustomerTable from '../components/ecosystems/Customers.Table';
// import CustomerFormCreate from '../components/ecosystems/Customer.Form.Create';
// import CustomerFormUpdate from '../components/ecosystems/Customer.Form.Update';
// import CustomerBillsTable from '../components/ecosystems/Customer.Bills.Table';

// BELEGE
// import ReceiptsTable from '../components/ecosystems/Receipt';
// import ReceiptFormUpdate from '../components/ecosystems/Receipt.Form.Update';

// RECHNUNGEN
// import BillFormUpdate from '../components/ecosystems/Bill.Form.Update';
// import BillFormCreate from '../components/ecosystems/Bill.Form.Create';

// ************************ FEATURE ************************
import * as Customer from '../components/views/Customer';
import * as TimeTracker from '../components/views/TimeTracker';
import * as User from '../components/views/User';
import * as ItJob from '../components/views/ItJob';
import * as Recruiter from '../components/views/Recruiter';
import * as BankAccount from '../components/views/BankAccount';
import * as BankBeneficiary from '../components/views/BankBeneficiary';
import * as Receipt from '../components/views/Receipt';
import * as ProjectOffer from '../components/views/ProjectOffer';

import ReceiptStistics from '../components/views/Receipt.Statistics';

// MEMBER AREA
import MemberArea from '../components/views/MemberArea';

import { AppContext } from '../context';
import hasRequiredPermissions from '../lib/has-permissions';

const CustomRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <AppContext.Consumer>
        {(context) =>
          context.sessionUser.username ? <Component {...props} /> : <Redirect to={{ pathname: '/login' }} />
        }
      </AppContext.Consumer>
    )}
  />
);

const AuthorizedRoute = ({ component: Component, requiredPerrmissions, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <AppContext.Consumer>
        {({ sessionUser }) => {
          const hasPermission = hasRequiredPermissions(sessionUser.roles, requiredPerrmissions);

          return hasPermission ? <Component {...props} /> : <Redirect to={{ pathname: '/kunden' }} />;
        }}
      </AppContext.Consumer>
    )}
  />
);

const Routes = () => (
  <Switch>
    {/* MEMBER AREA */}
    <CustomRoute exact path="/member-area" component={MemberArea} />

    {/* KUNDEN */}
    <CustomRoute exact path="/kunden/create" component={Customer.Create} />
    <CustomRoute exact path="/kunden/update/:id" component={Customer.Update} />
    <CustomRoute exact path="/kunden/:id/rechnungen" component={Customer.TableBills} />

    {/* KUNDEN - RECHNUNGEN  */}
    <CustomRoute exact path="/kunden/:id/rechnungen/update/:childId" component={Customer.BillUpdate} />
    <CustomRoute exact path="/kunden/:id/rechnungen/create" component={Customer.BillCreate} />

    {/* KUNDEN - Catches everything if previous route-stack did not catch route, must be last  */}
    <CustomRoute path="/kunden" component={Customer.Table} />

    {/* ANGEBOTE */}
    <CustomRoute exact path="/angebote" component={Customer.Table} />

    {/* BANKEN */}
    <CustomRoute exact path="/bankaccounts" component={BankAccount.Table} />
    <CustomRoute exact path="/bankaccounts/create" component={BankAccount.Create} />
    <CustomRoute exact path="/bankaccounts/update/:id" component={BankAccount.Update} />
    <CustomRoute exact path="/bankaccounts/transactions/statistics" component={BankAccount.Statistics} />
    <CustomRoute exact path="/bankaccounts/:id/transactions" component={BankAccount.TableTransactions} />

    {/* BEGÜNSTIGTE */}
    <CustomRoute exact path="/beneficiaries" component={BankBeneficiary.Table} />
    <CustomRoute exact path="/beneficiaries/create" component={BankBeneficiary.Create} />
    <CustomRoute exact path="/beneficiaries/update/:id" component={BankBeneficiary.Update} />

    {/* BELEGE */}
    <CustomRoute exact path="/belege" component={Receipt.Table} />
    <CustomRoute exact path="/belege/create" component={Receipt.Create} />
    <CustomRoute exact path="/belege/create/:transactionId" component={Receipt.Create} />
    <CustomRoute exact path="/belege/update/:id" component={Receipt.Update} />
    <CustomRoute exact path="/belege/statistics" component={ReceiptStistics} />

    {/* ZEITERFASSUNG */}
    <CustomRoute exact path="/timetracks" component={TimeTracker.Table} />
    <CustomRoute exact path="/timetracks/update/:id" component={TimeTracker.Update} />
    <CustomRoute exact path="/timetracks/create/" component={TimeTracker.Create} />
    <CustomRoute exact path="/timetracks/list/:id" component={TimeTracker.TimeList} />

    {/* BENUTZER */}
    <CustomRoute exact path="/benutzer/update/:id" component={User.Update} />

    {/* IT-JOBS */}
    {/* <CustomRoute exact path="/it-jobs" component={ItJob.Table} />
    <CustomRoute exact path="/it-jobs/create/" component={ItJob.Create} />
    <CustomRoute exact path="/it-jobs/update/:id" component={ItJob.Update} /> */}

    {/* RECRUITERS */}
    {/* <CustomRoute exact path="/recruiters" component={Recruiter.Table} />
    <CustomRoute exact path="/recruiters/create/" component={Recruiter.Create} />
    <CustomRoute exact path="/recruiters/update/:id" component={Recruiter.Update} /> */}

    {/* PROJEKTANGEBOT */}
    {/* <CustomRoute exact path="/projektangebote/unfocused" component={ProjectOffer.Table} />
    <CustomRoute exact path="/projektangebote/update/:id" component={ProjectOffer.Update} />
    <CustomRoute exact path="/projektangebote/focused" component={ProjectOffer.TableFocused} />
    <CustomRoute exact path="/projektangebote/focused/update/:id" component={ProjectOffer.UpdateFocused} /> */}

    {/* ADMIN ROUTES */}
    <AuthorizedRoute exact path="/benutzer" requiredPermissions={['CAN_CREATE_USERS']} component={User.Table} />
    <AuthorizedRoute exact path="/benutzer/create" requiredPermissions={['CAN_CREATE_USERS']} component={User.Create} />

    <AuthorizedRoute exact path="/rollen" requiredPermissions={['CAN_CREATE_USERS']} component={User.Table} />
    <AuthorizedRoute exact path="/einstellungen" requiredPermissions={['CAN_CREATE_USERS']} component={User.Table} />

    {/* Any other URL that is not catched above is redirected to /kunden */}
    {/* <Redirect from="*" to="/kunden" /> */}
    {/* 404 UNKNOWN COMPONENT BESSER */}
    {/* <Redirect from="*" to="/kunden" /> */}
    <Redirect from="*" to="/member-area" />
  </Switch>
);

export default withRouter(Routes);
