/* eslint-disable react/display-name */
import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import FormGroup from '../../atoms/FormGroup';
import FormItem from '../../atoms/FormItem';
import BillForm from './Form.Bill';
import ButtonBox from '../../atoms/ButtonBox';
import MetaInfo from '../../molecules/MetaInfo';

import Loading from '../../molecules/Loading';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import createMutationHandler from '../../shared/create-mutationhandler';

const QUERY = gql`
  query GetBill($where: BillWhereInput) {
    bill(where: $where) {
      billId
      name
      billDate
      deadlineDays
      projectStart
      projectEnd
      notiz
      isPaid
      positions {
        beschreibung
        zusatz
        einheit
        menge
        einzelpreis
        ust
        position
      }
      customer {
        customerId
        typ
        vorname
        name
        firmenname
      }
      createdBy {
        firstname
        lastname
      }
      updatedBy {
        firstname
        lastname
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateBill($where: BillWhereUpdateInput, $data: BillUpdateInput) {
    updateBill(where: $where, data: $data) {
      __typename
      ... on Bill {
        billId
        name
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
    }
  }
`;

const BillFormUpdate = ({ history, match }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: { billId: match.params.childId } },
    fetchPolicy: 'network-only',
  });

  const [updateMutation, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_MUTATION);

  const handleUpdateMuation = (mutation) => {
    const handledUpdateMuation = createMutationHandler({
      mutation,
      mutationName: 'updateBill',
      mutationReturnTypes: {
        Bill: {
          successMessage: (data) => `Rechnung mit der ID ${data.billId}, wurde erfolgreich verändert`,
          onSuccess: () => history.push(`/kunden/${match.params.id}/rechnungen`),
        },
      },
      serverErrorMessage: `Änderung der Rechnung nicht erfolgreich`,
    });

    return (values) =>
      handledUpdateMuation({
        variables: {
          where: { billId: match.params.childId }, // AAAHHHHHH!!! bitte ändern
          data: values,
        },
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  const { bill = {} } = queryData;
  const { customer } = bill;
  const { createdBy = {}, updatedBy = {} } = bill;

  const displayCustomer =
    customer.typ === 'Firma' //
      ? `${customer.firmenname}`
      : `${customer.anrede} ${customer.vorname} ${customer.name}`;

  // console.log('DAS IST BILL: ', bill);
  // console.log('DAS IST BILL: ', bill);

  return (
    <fieldset disabled={updateLoading}>
      <FormGroup>
        <FormItem>
          <h2>Rechnung von Kunde bearbeiten - {displayCustomer}</h2>
        </FormItem>
      </FormGroup>

      <ButtonBox
        theme="primary"
        icon="arrow-left"
        text="Zurück"
        style={{ margin: '0px 10px 20px 0px' }}
        onClick={() => history.goBack()}
      />

      <hr />
      <MetaInfo>
        {[
          [['ID:', bill.billId]],
          [
            ['Erstellt am:', bill.createdAt],
            ['Geändert am:', bill.updatedAt],
          ],
          [
            ['Erstellt von:', `${createdBy.firstname} ${createdBy.lastname}`],
            ['Geändert von:', `${updatedBy.firstname} ${updatedBy.lastname}`],
          ],
        ]}
      </MetaInfo>
      <hr />

      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={updateError} />

      <BillForm
        data={bill}
        submitButtonText="Kundenänderungen speichern"
        mutation={handleUpdateMuation(updateMutation)}
      />
    </fieldset>
  );
};

BillFormUpdate.propTypes = {};
BillFormUpdate.defaultProps = {};

export default BillFormUpdate;

// onLockClick -> onLock
// makeMutationHandler
