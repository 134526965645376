import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import CustomerForm from './Form';
import FormGroup from '../../atoms/FormGroup';
import FormItem from '../../atoms/FormItem';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';

import Loading from '../../molecules/Loading';
import createMutationHandler from '../../shared/create-mutationhandler';
import ViewTitle from '../../atoms/ViewTitel';

const CREATE_MUTATION = gql`
  mutation CreateCustomer($data: CustomerCreateInput) {
    createCustomer(data: $data) {
      __typename
      ... on Customer {
        customerId
        firmenname
        anrede
        name
        vorname
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

const CustomerFormCreate = ({ match, history }) => {
  const [createMutation, { error: createError, loading: createLoading }] = useMutation(CREATE_MUTATION);

  // CREATE-MUTATION
  const handleCreateMutation = (mutation) => {
    const handledCreateMutation = createMutationHandler({
      mutation,
      mutationName: 'createCustomer',
      mutationReturnTypes: {
        Customer: {
          successMessage: (data) => `Kunde mit der ID ${data.customerId} erfolgreich erstellt`,
          onSuccess: () => history.push('/kunden'),
        },
      },
      serverErrorMessage: `Erstellung des Kunden nicht erfolgreich`,
    });

    return (values) =>
      handledCreateMutation({
        variables: {
          data: values,
        },
      });
  };

  if (createLoading) {
    return <Loading />;
  }

  return (
    <>
      <ViewTitle>Neuen Kunden erstellen</ViewTitle>
      <ErrorGraphQL error={createError} />
      <CustomerForm
        loading={[createLoading]}
        submitButtonText="Neuen Kunden erstellen"
        onSubmit={handleCreateMutation(createMutation)}
      />
    </>
  );
};

export default CustomerFormCreate;
