import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import createMutationHandler from '../../shared/create-mutationhandler';
import BillsTable from './Table.Bills';
import Loading from '../../molecules/Loading';
import ButtonBox from '../../atoms/ButtonBox';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';

const QUERY = gql`
  query GetCustomer($where: CustomerWhereInput) {
    customers(where: $where) {
      customerId
      typ
      firmenname
      anrede
      name
      vorname
      bills {
        billId
        name
        isPaid
        createdAt
        updatedAt
        customer {
          customerId
        }
      }
    }
  }
`;

const DELETE = gql`
  mutation DeleteCustomer($where: CustomerWhereDeleteInput) {
    deleteCustomer(where: $where) {
      __typename
      ... on Customer {
        customerId
        typ
        firmenname
        anrede
        name
        vorname
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

// const date = new Date();
// const DEFAULT_PATH = `/kunden/?updatedAt=${date.getFullYear()}-${date.getMonth() + 1}`;

const Kunden = ({ history, match }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: { customerId: match.params.id } },
    fetchPolicy: 'network-only',
  });

  const [deleteMutation, { error: deleteError, loading: deleteLoading }] = useMutation(DELETE, {
    refetchQueries: () => [
      {
        query: QUERY,
        variables: {
          where: {},
          // where: { customerId: match.params.id },
        },
        v: Math.random(),
      },
    ],
  });

  // DELETE-MUTATION
  const handleDeleteMutation = (mutation) => {
    const handledDeleteMuation = createMutationHandler({
      mutation,
      mutationName: 'deleteBill',
      mutationReturnTypes: {
        Customer: {
          successMessage: (data) => `Rechnung mit der ID ${data.billId} erfolgreich gelöscht`,
          onSuccess: () => history.push('/kunden'),
        },
      },
      serverErrorMessage: `Änderung der Rechnung nicht erfolgreich`,
    });

    return (values) =>
      handledDeleteMuation({
        variables: values.variables,
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  const { customers = [] } = queryData;
  const [customer] = customers;
  const { bills = [] } = customer;

  const displayCusttomer =
    customer && customer.typ === 'Firma' //
      ? customer.firmenname
      : `${customer.anrede} ${customer.vorname} ${customer.name}`;

  return (
    <fieldset disabled={deleteLoading}>
      <h1>Rechnungen des Kunden - {displayCusttomer}</h1>
      <hr />
      <ButtonBox
        name="button-create-bill"
        theme="primary"
        icon="file"
        text="Neuen Rechnung"
        style={{ marginRight: '10px', marginBottom: '20px' }}
        onClick={() => history.push(`/kunden/${match.params.id}/rechnungen/create`)}
      />
      <div>
        <ErrorGraphQL error={queryError} />
        <ErrorGraphQL error={deleteError} />
        <BillsTable
          data={bills}
          onDelete={(row) => {
            const handledDeleteMutation = handleDeleteMutation(deleteMutation);
            handledDeleteMutation({
              variables: { where: { customerId: row.customerId } },
            });
          }}
        />
      </div>
    </fieldset>
  );
};

export default withRouter(Kunden);
