import styled from 'styled-components';

export const Table = styled.table`
  border-style: solid;
  border-width: 1px;
  width: 100%;
  border: 1;
`;

export const Row = styled.tr`
  border-style: solid;
  border-width: 1px;
  width: 100%;
  border: 1;
`;

export const TData = styled.td`
  border-style: solid;
  border-width: 1px;
  border: 1;
  padding: 3px;
  text-align: ${({ align }) => align || ''};
`;

export const Header = styled.th`
  text-align: ${({ align }) => align || ''};
  background-color: #f5f5f5;
  border-style: solid;
  border-width: 1px;
  border: 1;
  padding: 3px;
`;

export const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Label = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

export const Content = styled.div`
  margin-right: 5px;
`;

export const BoldLabel = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;
