import React from 'react';
import allowedAttributes from './allowed-attributes';
import withFinalForm from '../../../HOC/with-final-form';
import FormGroup from '../../../atoms/FormGroup';
import TextInput from '../../../molecules/TextInput';
import TextArea from '../../../molecules/TextArea';
import SelectField from '../../../molecules/SelectField';
import SelectInput from '../../../molecules/SelectInput';
import ToggleButton from '../../../molecules/ToggleButton';
import Button from '../../../atoms/Button';
import { FormContainer } from '../../../shared/styles';

import validator from './validator';

// get managers by selected company
const getManagerOptions = ({ customer = null, customersList = [] }) => {
  // null means nothing in the options
  if (!(customer && customer.value)) {
    return null;
  }

  const pickedCustomer = customersList.find(({ customerId }) => parseInt(customerId) === parseInt(customer.value));

  if (!pickedCustomer) {
    return null;
  }

  const { projectOfficers } = pickedCustomer;

  if (!Array.isArray(projectOfficers) || projectOfficers[0] === null) {
    // return null;
    return [];
  }

  return projectOfficers.map(({ projectOfficerId, firstname, lastname }) => ({
    label: `${firstname} ${lastname}`,
    value: projectOfficerId,
  }));
};

const CustomForm = ({ values, handleSubmit, submitButtonText, form, onSubmit, loading = [] }) => (
  <fieldset disabled={loading.some((bool) => bool)}>
    <form onSubmit={handleSubmit} style={{ marginBottom: '80px' }}>
      <FormGroup
        style={{
          margin: '0 auto 10px',
          minWidth: '290px',
          // minWidth: '470px',
          maxWidth: '1200px',

          justifyContent: 'space-between',
          flexDirection: 'row',
          alignSelf: 'center',
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#aaa',
          fontSize: '12px',
          padding: '0px 10px 0px 10px',
          color: '#505050',
        }}
      >
        <FormGroup style={{ flexDirection: 'column' }}>
          <ToggleButton
            name="isHidden"
            onMessage="VERSTECKT FÜR LEISTUNGSBRINGER"
            offMessage="SICHTBAR FÜR LEISTUNGSBRINGER"
            opposite
          />
          <ToggleButton
            name="isFreelancerSigned"
            onMessage="UNTERSCHREIBEN VOM ARBEITER"
            offMessage="KEINE UNTERSCHRIFT VOM ARBEITER"
          />
          <ToggleButton
            name="signMailSend"
            onMessage="UNTERSCHRIFTEN URL GESENDET"
            offMessage="UNTERSCHRIFTEN URL NICHT GESENDET"
          />
        </FormGroup>
        <FormGroup style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: '' }}>
          <ToggleButton
            name="isLocked"
            onMessage="SCHREIBGESCHÜTZT FÜR LEISTUNGSBRINGER"
            offMessage="EDITIERBAR FÜR LEISTUNGSBRINGER"
          />
          <ToggleButton
            name="isManagerSigned"
            onMessage="UNTERSCHREIBEN VOM MANAGER"
            offMessage="KEINE UNTERSCHRIFT VOM MANAGER"
          />
          {values.signMailSend && values.signMailSendTo && (
            <div style={{ display: 'flex', marginTop: '10px', marginBottom: '15px' }}>
              <div style={{ fontWeight: 'bold', marginRight: '10px' }}>URL-Mail versendet an: </div>
              <div>{values.signMailSendTo}</div>
            </div>
          )}
          {/* {!values.signMailSend && <div />} */}
        </FormGroup>
      </FormGroup>

      <FormContainer>
        <FormGroup>
          <TextInput label="Projektname" type="text" name="name" />
          <SelectField
            label="Type"
            type="text"
            name="timetrackType"
            options={[
              { label: 'Standard', value: 'FULL_MONTH' },
              { label: 'Dynamisch', value: 'DYNAMIC' },
            ]}
          />
        </FormGroup>

        <FormGroup>
          <SelectField
            label="Jahr"
            type="text"
            name="year"
            options={[
              { label: '2019', value: 2019 },
              { label: '2020', value: 2020 },
              { label: '2021', value: 2021 },
              { label: '2022', value: 2022 },
              { label: '2023', value: 2023 },
              { label: '2024', value: 2024 },
              { label: '2025', value: 2025 },
              { label: '2026', value: 2026 },
              { label: '2027', value: 2027 },
              { label: '2028', value: 2028 },
              { label: '2029', value: 2029 },
              { label: '2030', value: 2030 },
            ]}
          />
          <SelectField
            label="Monat"
            type="text"
            name="month"
            options={[
              { label: ' 1 - Januar', value: 1 },
              { label: ' 2 - Februar', value: 2 },
              { label: ' 3 - März', value: 3 },
              { label: ' 4 - April', value: 4 },
              { label: ' 5 - Mai', value: 5 },
              { label: ' 6 - Juni', value: 6 },
              { label: ' 7 - Juli', value: 7 },
              { label: ' 8 - August', value: 8 },
              { label: ' 9 - September', value: 9 },
              { label: '10 - Oktober', value: 10 },
              { label: '11 - November', value: 11 },
              { label: '12 - Dezembebr', value: 12 },
            ]}
          />
        </FormGroup>
        <FormGroup>
          <SelectInput
            label="Kunde"
            name="customer"
            options={values.customersOptions}
            placeholder="Kunden wählen..."
            noOptionsMessage="Keine Kunden gefunden"
            onChange={() => {
              form.change('manager', { value: '', label: '' });
            }}
          />
          <SelectInput
            isDisabled={!values.customer.value}
            label="Projektverantwortlicher"
            name="manager"
            options={getManagerOptions(values)}
            placeholder="Projektverantwortlichen wählen..."
            noOptionsMessage="Keine Projektverantwortlichen gefunden"
          />
        </FormGroup>

        <FormGroup>
          <SelectInput
            label="Leistungsbringer"
            name="owner"
            options={values.usersOptions}
            placeholder="Benutzer wählen..."
            noOptionsMessage="Keine Benutzer gefunden"
          />
        </FormGroup>
        <FormGroup>
          <TextInput label="Bruttogehalt pro Stunde" type="text" name="wage" />
          <TextInput label="Provision in Prozent" type="text" name="provision" />
        </FormGroup>
        <FormGroup>
          <TextArea label="Notizen" type="textfield" name="notes" />
        </FormGroup>
      </FormContainer>
      <Button marginTop="10px" theme="success" height="40px" icon="save" text={submitButtonText} />
    </form>
  </fieldset>
);

// FORM CONFIG
const FinalForm = (props) => {
  const { data = {}, usersList, customersList, submitButtonText, mutation } = props;

  // console.log("DAS IST data: ", data);

  const initialValues = React.useMemo(
    () => ({
      data: {
        ...data,
        owner: data.owner && {
          label: `${data.owner.firstname} ${data.owner.lastname}`,
          value: `${data.owner.userId}`,
        },
        customer: data.customer && {
          label:
            data.customer.typ === 'Firma'
              ? `${data.customer.firmenname}`
              : `${data.customer.vorname} ${data.customer.name}`,
          value: `${data.customer.customerId}`,
        },
        manager: data.manager && {
          label: `${data.manager.firstname} ${data.manager.lastname}`,
          value: `${data.manager.projectOfficerId}`,
        },
      },
      defaults: {
        name: '',
        notes: '',
        wage: '',
        provision: '',
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        isHidden: false,
        isLocked: false,
        isManagerSigned: false,
        isFreelancerSigned: false,
        signMailSend: false,
        signMailSendTo: '',
        timetrackType: 'FULL_MONTH',
        owner: { label: '', value: '' },
        customer: { label: '', value: '' },
        manager: { label: '', value: '' },
        // -------------------- DATA FOR SELECTION FIELD -----------------------
        usersOptions: usersList.map((user) => ({
          label: `${user.firstname} ${user.lastname}`,
          value: `${user.userId}`,
        })),
        customersOptions: customersList.map((customer) => ({
          label: customer.typ === 'Firma' ? `${customer.firmenname}` : `${customer.vorname} ${customer.name}`,
          value: `${customer.customerId}`,
        })),
        customersList,
      },
    }),
    [data, usersList, customersList]
  );

  return withFinalForm(
    {
      initialValues,
      validator,
      submitButtonText,
      allowedAttributes,
      // when to remove attributes
      // attributesFilter: {
      //   provision: (value) => (typeof value === 'string' ? !value.trim() : false),
      //   wage: (value) => (typeof value === 'string' ? !value.trim() : false),
      //   manager: (value) => !!`${value}`.trim(),
      // },
      valuesFormat: {
        owner: (owner) => owner.value,
        customer: (customer) => customer.value,
        manager: (manager) => manager.value || null,
        provision: (provision) => Number(provision),
        month: (month) => Number(month),
        year: (year) => Number(year),
        wage: (wage) => Number(wage),
      },
      submitHandler: (values) => mutation(values),
    },
    CustomForm
  );
};

export default FinalForm;
