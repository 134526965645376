import React, { useState } from 'react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import styled from 'styled-components';

//  &:hover {
//     text-decoration: none;
//     color: white;
//     background-color: #1e282c;
//   }

const MenuItem = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 40px;
  font-size: 14px;
  width: 200px;
  /* margin-left: 3px; */

  color: ${(props) => (props.isOpen ? 'white' : '#b8c7ce')};
  background-color: ${(props) => (props.isOpen ? '#1e282c' : '')};

  &:hover {
    text-decoration: none;
    color: white;
    background-color: #1e282c;
  }
`;

const Label = styled.div`
  /* margin-left: 3px; */
  padding-left: 3px;
  width: 135px;
`;

const IconStyle = styled.div`
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RotateIconStyle = styled.li`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: flex-end;
  transition: all 0.3s ease-out;
  transform: ${(props) => (props.rotate ? `rotate(-90deg)` : '')};
`;

const SlideBox = styled.ul`
  background: #223d54;
  padding: 0px;
  overflow-y: hidden;
  list-style: none;
  margin-bottom: 0px;

  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;

  /* height: 0px; */
  transition: max-height 0.5s ease-in-out;
  max-height: ${(props) => (props.slideOpen ? props.maxheight || '80px' : '0px')};
`;

export const GroupElements = ({ icon, title, children, maxheight }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleRotate = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <MenuItem onClick={toggleRotate} isOpen={isOpen}>
        <IconStyle isOpen={isOpen}>{icon}</IconStyle>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Label>{title}</Label>
          <RotateIconStyle rotate={isOpen ? 1 : 0}>
            <MdKeyboardArrowLeft />
          </RotateIconStyle>
        </div>
      </MenuItem>
      <SlideBox slideOpen={isOpen ? 1 : 0} maxheight={maxheight}>
        {children}
      </SlideBox>
    </>
  );
};
