import React from 'react';
import styled from 'styled-components';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ModalButton from '../../molecules/ModalButton';
import DateColumn from '../../molecules/DateColumn';

import UrlButton from '../../molecules/UrlButton';

const TableStyle = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'updatedAt',
    order: 'desc',
  },
];

export default function TableCustomers(props) {
  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      style: { width: '70px' },
      sort: true,
    },
    {
      dataField: 'name',
      text: 'Belegname',
      style: { minWidth: '150px' },
      sort: true,
    },
    // {
    //   dataField: 'amount',
    //   text: 'Betrag',
    //   formatter: (value) => (
    //     <div style={{ textAlign: 'right' }}>{`${(value / 10).toFixed(2).toString().replace('.', ',')} €`}</div>
    //   ),
    //   style: { width: '110px' },
    //   sort: true,
    //   headerStyle: { textAlign: 'right' },
    // },
    {
      dataField: 'receiptDate',
      text: 'Belegdatum',
      formatter: (value) => {
        const date = new Date(value);
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);

        return <div style={{ textAlign: 'right' }}>{`${day}.${month}.${year}`}</div>;
      },
      style: { width: '140px', minWidth: '120px' },
      sort: true,
      headerStyle: { textAlign: 'right' },
    },
    {
      dataField: 'createdAt',
      text: 'Erstellt am',
      formatter: DateColumn('createdAt'),
      style: { width: '140px', minWidth: '130px' },
      sort: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Geändert am',
      formatter: DateColumn('updatedAt'),
      style: { width: '140px', minWidth: '130px' },
      sort: true,
    },
    {
      dataField: 'vorname',
      text: 'Aktionen',
      formatter: (cell, row) => {
        const receipt = row;
        return (
          <div style={{ display: 'flex' }}>
            <UrlButton icon="edit" toolTip="Beleg bearbeiten" url={`/belege/update/${receipt.id}`} />
            <ModalButton
              modalHeader="Löschen"
              modalBody={`Möchten Sie den Beleg mit der ID ${row.id}`}
              toolTip="Beleg löschen"
              onOkClick={() => console.log('DAS IST MODAL BUTTON DELETE :P')}
            />
          </div>
        );
      },
      formatExtraData: { deleteById: props.deleteById },
      // style: { minWidth: '100px', maxWidth: '175px' },
      style: { width: '100px' },
      sort: true,
    },

    // HIDDEN, BUT NEEDED TO BE ABLE SO LAZY-SEARCH THESE FIELDS
    {
      dataField: 'createdAt',
      text: 'Erstellt am',
      formatter: DateColumn('createdAt'),
      style: { minWidth: '120px', maxWidth: '120px' },
      sort: true,
      hidden: true,
    },
  ];

  return (
    <TableStyle>
      <ToolkitProvider keyField="id" data={props.data} columns={columns} search>
        {({ searchProps, baseProps }) => (
          <div>
            <SearchBar
              {...searchProps}
              style={{ margin: '15px 0px 15px 0px', borderRadius: '0px' }}
              placeholder="Suchen..."
            />

            <BootstrapTable
              noDataIndication="Keine Daten mit diesen Sucheigenschaften gefunden"
              defaultSorted={defaultSorted}
              bordered={false}
              {...baseProps}
            />
          </div>
        )}
      </ToolkitProvider>
    </TableStyle>
  );
}

TableCustomers.defaultProps = {
  data: [],
};
