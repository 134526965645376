import React from 'react';
import PropTypes from 'prop-types';
import currency from 'currency.js';

export default function Label(props) {
  //   const EURO = (value) => currency(value, { symbol: '', decimal: ',', separator: '.' });
  //   const formatToEuro = () => EURO(currency(props.value)).format(true);

  const euroFormat = (parseInt(props.value) / 100) //
    .toFixed(2)
    .toString()
    .replace('.', ',');

  return <div style={props.style}>{euroFormat} €</div>;
}

Label.propTypes = {
  text: PropTypes.string,
};

Label.defaultProps = {
  text: 'NO-TEXT-IN-LABEL',
};
