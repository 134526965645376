import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import createMutationHandler from '../../shared/create-mutationhandler';
import Table from './Table';
import Loading from '../../molecules/Loading';
import ButtonBox from '../../atoms/ButtonBox';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import Title from '../../molecules/Title';

const QUERY = gql`
  query GetRecruiter($where: BankBeneficiaryWhereInput) {
    bankBeneficiaries(where: $where) {
      id
      label
      # beneficiaryType
      # firmenname
      # salutation
      # firstname
      # lastname
    }
  }
`;

const DELETE = gql`
  mutation DeleteCustomer($where: BankBeneficiaryWhereDeleteInput) {
    deleteCustomer(where: $where) {
      __typename
      ... on BankBeneficiary {
        id
        name
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

const TableContainer = ({ history, match }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: {} },
    fetchPolicy: 'network-only',
  });

  const [deleteMutation, { error: deleteError, loading: deleteLoading }] = useMutation(DELETE, {
    refetchQueries: () => [
      {
        query: QUERY,
        variables: {
          where: {},
          // where: { customerId: match.params.id },
        },
        v: Math.random(),
      },
    ],
  });

  // DELETE-MUTATION
  const handleDeleteMutation = (mutation) => {
    const handledDeleteMuation = createMutationHandler({
      mutation,
      mutationName: 'deleteBill',
      mutationReturnTypes: {
        Recruiter: {
          successMessage: (data) => `Begünstigter mit der ID ${data.billId} erfolgreich gelöscht`,
          onSuccess: () => history.push('/kunden'),
        },
      },
      serverErrorMessage: `Änderung des Begünstigten nicht erfolgreich`,
    });

    return (values) =>
      handledDeleteMuation({
        variables: values.variables,
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  const { bankBeneficiaries = [] } = queryData;

  return (
    <fieldset disabled={deleteLoading}>
      <Title>Begünstigte</Title>
      <hr />
      <ButtonBox
        name="button-create-bill"
        theme="primary"
        icon="file"
        text="Neuen Recruiter"
        style={{ marginRight: '10px', marginBottom: '20px' }}
        onClick={() => history.push(`/beneficiaries/create`)}
      />
      <div>
        <ErrorGraphQL error={queryError} />
        <ErrorGraphQL error={deleteError} />
        <Table data={bankBeneficiaries} />
      </div>
    </fieldset>
  );
};

export default withRouter(TableContainer);
