/* eslint-disable react/display-name */
import React from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';

import FormGroup from '../../atoms/FormGroup';
import FormItem from '../../atoms/FormItem';
import CustomerForm from './Form';
import ButtonBox from '../../atoms/ButtonBox';
import MetaInfo from '../../molecules/MetaInfo';

import Loading from '../../molecules/Loading';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import createMutationHandler from '../../shared/create-mutationhandler';

const QUERY = gql`
  query GetCustomer($where: CustomerWhereInput) {
    customer(where: $where) {
      customerId
      typ
      firmenname
      anrede
      name
      vorname
      strasse
      hausnr
      plz
      ort
      telefon
      email
      fax
      ansprechname
      ansprechvorname
      ansprechtelefon
      ansprechemail
      umsatzsteuerid
      steuernr
      eusteuernr
      amtsgericht
      handelsregister
      bankname
      bic
      iban
      kontonr
      blz
      notizen
      projectOfficers {
        projectOfficerId
        firstname
        lastname
        email
        telefon
      }
      createdBy {
        firstname
        lastname
      }
      updatedBy {
        firstname
        lastname
      }
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateCustomer($where: CustomerWhereUpdateInput, $data: CustomerUpdateInput) {
    updateCustomer(where: $where, data: $data) {
      __typename
      ... on Customer {
        customerId
        firmenname
        anrede
        name
        vorname
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
    }
  }
`;

const CustmerFormUpdate = ({ history, match }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: { customerId: match.params.id } },
    fetchPolicy: 'network-only',
  });
  const [updateMutation, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_MUTATION);
  const { customer = {} } = queryData;
  const { createdBy = {}, updatedBy = {} } = customer;

  const handleUpdateMuation = (mutation) => {
    const handledUpdateMuation = createMutationHandler({
      mutation,
      mutationName: 'updateCustomer',
      mutationReturnTypes: {
        Customer: {
          successMessage: (data) => `Kunde mit der Kundennummer ${data.customerId}, wurde erfolgreich verändert`,
          onSuccess: () => history.push('/kunden'),
        },
      },
      serverErrorMessage: `Änderung des Kunden nicht erfolgreich`,
    });

    return (values) =>
      handledUpdateMuation({
        variables: {
          where: { customerId: match.params.id },
          data: values,
        },
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  return (
    <fieldset disabled={updateLoading}>
      <FormGroup>
        <FormItem>
          <h2>Kunde bearbeiten</h2>
        </FormItem>
      </FormGroup>

      <ButtonBox
        theme="primary"
        icon="arrow-left"
        text="Zurück"
        style={{ margin: '0px 10px 20px 0px' }}
        onClick={() => history.goBack()}
      />

      <hr />
      <MetaInfo>
        {[
          [['ID:', customer.customerId]],
          [
            ['Erstellt am:', customer.createdAt],
            ['Geändert am:', customer.updatedAt],
          ],
          [
            ['Erstellt von:', `${createdBy.firstname} ${createdBy.lastname}`],
            ['Geändert von:', `${updatedBy.firstname} ${updatedBy.lastname}`],
          ],
        ]}
      </MetaInfo>
      <hr />

      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={updateError} />

      <CustomerForm
        data={customer}
        submitButtonText="Kundenänderungen speichern"
        mutation={handleUpdateMuation(updateMutation)}
      />
    </fieldset>
  );
};

CustmerFormUpdate.propTypes = {};
CustmerFormUpdate.defaultProps = {};

export default CustmerFormUpdate;

// onLockClick -> onLock
// makeMutationHandler
