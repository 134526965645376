import React, { useState } from 'react';
import styled from 'styled-components';
import { FaLock } from 'react-icons/fa';

const Input = styled.input`
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  margin-bottom: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`;

const SubmitButton = styled.input`
  cursor: pointer;
  width: 100%;
  border-radius: 0px;
  border: 1px solid transparent;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  margin-top: 10px;
`;

const Form = styled.div`
  height: 100vh;
  background-color: #eee;
`;

const Wrapper = styled.div`
  padding-top: 80px;
`;

const Box = styled.div`
  margin: 0px auto;
  max-width: 380px;
  padding: 15px 35px 45px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: 450;
  display: flex;
  align-items: center;
  color: #212529;
  margin-bottom: 10px;
  margin-top: 15px;
`;

const LoginForm = ({ loginUser }) => {
  const [loginData, setLoginData] = useState({ username: '', password: '' });

  const handleInputChange = (key) => (value) => {
    setLoginData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    loginUser(loginData.username, loginData.password);
  };

  return (
    <Form>
      <Wrapper>
        <Box>
          <form onSubmit={handleSubmit}>
            <Title>
              <FaLock style={{ marginRight: '5px' }} />
              <div>Bitte anmelden</div>
            </Title>
            <Input placeholder="Benutzername" onChange={event => handleInputChange('username')(event.target.value)} />
            <Input
              placeholder="Passwort"
              type="password"
              onChange={(event) => handleInputChange('password')(event.target.value)}
            />
            <SubmitButton type="submit" value="Login" />
          </form>
        </Box>
      </Wrapper>
    </Form>
  );
};


export default LoginForm;
