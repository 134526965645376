import React from 'react';
import styled from 'styled-components';
import SignatureCanvas from 'react-signature-canvas';
// import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';

// const StyleContainer = styled.div`
//   flex-grow: 1;
//   flex-shrink: 0;
//   flex-basis: 180px;
//   margin: 10px;
// `;

export const SignLine = styled.div`
  /* position: relative; */
  position: relative;
  border-style: solid;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-width: 1px;
  /* top: 210px; */
  top: 110px;
  width: 480px;
  margin-left: 10px;
  color: black;
  font-size: 12px;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`;

const SignaturePad = ({ signData }) => {
  const isVerticalScreen = useMediaQuery({ maxDeviceWidth: 375 });

  return (
    <>
      {!isVerticalScreen && (
        <div>
          <SignLine>Unterschrift</SignLine>
          <SignatureCanvas
            ref={signData}
            penColor="black"
            canvasProps={{
              width: 500,
              height: 130,
              className: 'sigCanvas',
              style: {
                zIndex: 1000,
                display: 'relative',
                marginTop: '5px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'grey',
              },
            }}
          />
        </div>
      )}

      {isVerticalScreen && (
        <div>
          <SignLine style={{ top: '80.6px', width: '310px' }}>Unterschrift</SignLine>
          <SignatureCanvas
            ref={signData}
            penColor="black"
            canvasProps={{
              width: 330,
              height: 85.8,
              className: 'sigCanvas',
              style: {
                zIndex: 1000,
                display: 'relative',
                marginTop: '5px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: 'grey',
              },
            }}
          />
        </div>
      )}
    </>
  );
};

export default SignaturePad;
