import React from 'react';
import PropTypes from 'prop-types';

export default function Label(props) {
  return (
    <div style={{ ...props.style, fontSize: '14px' }}>
      <label name={props.name}>{props.text}</label>
    </div>
  );
}

Label.propTypes = {
  text: PropTypes.string,
};

Label.defaultProps = {
  text: 'NO-TEXT-IN-LABEL',
};
