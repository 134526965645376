import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
// import PropTypes from 'prop-types';

// COLUMNS
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import styled from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFileInvoiceDollar, faUserEdit, faStickyNote, faTrash } from '@fortawesome/free-solid-svg-icons';
// import ReactTooltip from 'react-tooltip';
// import DateColumn from '../../molecules/DateColumn';
// import Modal from '../../molecules/Modal';

import FormUpdateContainer from './Form.Focused.Container';

const TableStyle = styled.div`
  font-size: 10px;
  width: 100%;
  background-color: white;
  padding: 5px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

// const StyledLink = styled(Link)`
//   color: #212529;
//   margin: 0px 0px 0px 0px;
//   &:hover {
//     color: #212529;
//   }
// `;

const EmailRow = styled.div`
  cursor: pointer;
  display: flex;
  padding: 10px;
  margin: 0px 0px 0px 0px;
  font-size: 14px;
  &:hover {
    background-color: aliceblue;
  }
`;

const EmailGrid = styled.div`
  @media screen and (min-width: 760px) {
    display: grid;
    /* pro template area größe */
    grid-template-columns: 300px 1fr;
    grid-template-areas: 'emails form';
    grid-column-gap: 10px;
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'emails'
    'form';
`;

const ContentArea = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'date',
    order: 'desc',
  },
];

export default function TableCustomers(props) {
  const [selectedId, setSelectedId] = useState('');

  const columns = [
    {
      dataField: 'id',
      text: 'Mail',
      formatter: (cell, row) => {
        const dateString = moment(row.date).format('DD.MM.YYYY [um] HH:mm [Uhr]');

        return (
          // <StyledLink to={`/projektangebote/update/${row.id}`} style={{ textDecoration: 'none' }}>
          <EmailRow
            onClick={() => {
              setSelectedId(row.id);
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div>{row.from}</div>
              <div
                style={{
                  fontWeight: 'bold',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  marginTop: '5px 10px 5px 0px',
                }}
              >
                {row.subject}
              </div>
              <div style={{ fontSize: '12px' }}>{dateString}</div>
            </div>
          </EmailRow>
          // </StyledLink>
        );
      },
      style: { width: '100%', verticalAlign: 'middle', padding: '0px' },
      sort: true,
      // kein header
      headerStyle: { display: 'none' },
    },
    // {
    //   dataField: 'vorname',
    //   text: 'Status',
    //   // formatter: Actions,
    //   formatter: (cell, row) => (
    //     <>
    //       {row.focused ? (
    //         <div style={{ fontWeight: 'bold', fontSize: '12px', color: '#28a745' }}>FOKUSSIERT</div>
    //       ) : (
    //         <div style={{ fontWeight: 'bold', fontSize: '12px', color: '#dc3545' }}>UNFOKUSSIERT</div>
    //       )}
    //     </>
    //   ),
    //   style: { minWidth: '100px', maxWidth: '175px', verticalAlign: 'middle' },
    //   sort: true,
    // },

    // HIDDEN, BUT THERE TO BE ABLE SO LAZY-SEARCH
    {
      dataField: 'subject',
      hidden: true,
    },
    {
      dataField: 'updatedAt',
      hidden: true,
    },
    {
      dataField: 'from',
      hidden: true,
    },
    // {
    //   dataField: 'updatedAt',
    //   text: 'Geändert am',
    //   formatter: DateColumn('updatedAt'),
    //   style: { minWidth: '135px', maxWidth: '135px', verticalAlign: 'middle' },
    //   sort: true,
    //   // hidden: true,
    // },
  ];

  return (
    <EmailGrid>
      <TableStyle>
        <ToolkitProvider keyField="id" data={props.data} columns={columns} search>
          {({ searchProps, baseProps }) => (
            <div>
              <SearchBar
                {...searchProps}
                style={{ margin: '15px 0px 15px 0px', borderRadius: '0px' }}
                placeholder="Suchen..."
              />

              <BootstrapTable
                {...baseProps}
                noDataIndication="Keine Daten mit diesen Sucheigenschaften gefunden"
                defaultSorted={defaultSorted}
                bordered={false}
              />
            </div>
          )}
        </ToolkitProvider>
      </TableStyle>

      {selectedId ? <FormUpdateContainer id={selectedId} /> : 'Keine E-Mail ausgewählt'}
    </EmailGrid>
  );
}

TableCustomers.defaultProps = {
  data: [],
};
