// const dataValidation = require('./data-validation');
import * as Yup from 'yup';

export default ({ hasRequired = true }) => async data => {
  const passwordSchema = Yup.string()
    .transform(value => (!value ? undefined : value)) // allow empty string // turn empty string to undefined`
    .min(10, 'Passwort muss mindestens 10 Zeichen haben')
    .max(50, 'Passwort kann maximal 50 Zeichen haben');

  const schema = Yup.object().shape({
    username: Yup.string()
      .min(5, 'Benutzername muss mindestens 5 Zeichen haben')
      .max(255, 'Benutzername kann maximal 255 Zeichen haben')
      .required('Benutzername muss eingegeben werden'),
    firstname: Yup.string()
      .min(3, 'Vorname muss mindestens 3 Zeichen haben')
      .max(255, 'Vorname kann maximal 255 Zeichen haben')
      .required('Vorname muss eingegeben werden'),
    lastname: Yup.string()
      .min(3, 'Name muss mindestens 3 Zeichen haben')
      .max(255, 'Name kann maximal 255 Zeichen haben')
      .required('Name muss eingegeben werden'),
    email: Yup.string().email('E-Mail ist nicht gültig').required('E-Mail muss eingegeben werden'),
    password: hasRequired ? passwordSchema.required('Passwort muss eingegeben werden') : passwordSchema,
  });

  return schema
    .validate(data, { abortEarly: false })
    .then(() => false)
    .catch(err =>
      err.inner.reduce(
        (accu, innerError) => ({
          ...accu,
          [innerError.path]: innerError.message,
        }),
        {}
      )
    );
};
