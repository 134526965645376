/* eslint-disable react/display-name */
import React from 'react';
import { Field } from 'react-final-form';

export default (Component, options = {}) => (props) => (
  <Field
    // EXPERIMENT
    // subscription={{ value: true, meta: true }}
    // EXPERIMENT
    {...props}
    {...options}
    // render={Component}
    render={(props) => <Component {...props} />}
    /*
      isNaN(null)  // false
      isNaN(undefined)  // true
      isNaN('')  // false
    */

    parse={(value) => {
      if (props.type === 'number') {
        const isNotANumber = isNaN(value) || value.trim() === '' || value == null;
        const parsed = isNotANumber ? null : parseInt(value, 10);
        return parsed;
      }
      return value;
    }}
    format={(value) => {
      if (props.format === 'telefonFormat') {
        const festnetzVorwahl = '^0[2-9].{3}|^0049|^\\+49';
        const handyVorwahl = '^017.{1}';
        const restDerNummer = '.{4}';
        const oder = '|';
        const telefonRegExString = `(${festnetzVorwahl}${oder}${handyVorwahl})${oder}(${restDerNummer})`;
        const telefonRegEx = new RegExp(telefonRegExString, 'gm');

        const validValue = value || '';

        return (
          validValue
            // remove this special characters
            // am anfang + eine oder keine
            // TODO: ERST WENN NEGATIVE LOOKAHEAD PUBLIC WERDEN ES2018
            .replace(/[=?!@#$%^&§()_*\-/.:#\\a-zA-ZÜÖÄüöä`´"']/gm, '')
            // remove all spaces
            .replace(/\s/gm, '')
            .replace(telefonRegEx, '$1$2 ')
            // remove space from last match
            .trim()
        );
      }
      return value;
    }}
  />
);
