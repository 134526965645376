import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Switch from 'react-switch';

import { TableStyle } from '../../shared/styles';
import DateColumn from '../../molecules/DateColumn';
import UrlButton from '../../molecules/UrlButton';
import ModalButton from '../../molecules/ModalButton';

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: 'updatedAt',
    order: 'desc',
  },
];

const TableUsers = ({ data, onLock, onDelete }) => {
  const columns = [
    {
      dataField: 'userId',
      text: 'ID',
      style: { verticalAlign: 'middle', minWidth: '30px' },
      sort: true,
    },
    {
      dataField: 'username',
      text: 'Benutzername',
      style: { verticalAlign: 'middle', minWidth: '150px', width: '1000px' },
      sort: true,
    },
    {
      dataField: 'firstname',
      text: 'Benutzer',
      style: { verticalAlign: 'middle', minWidth: '150px', width: '1000px' },
      // eslint-disable-next-line react/display-name
      formatter: (value, row) => <div>{`${row.firstname} ${row.lastname}`}</div>,
      sort: true,
    },
    {
      dataField: 'isLocked',
      text: 'Benutzer',
      style: { verticalAlign: 'middle', minWidth: '100px', width: '1000px' },
      // eslint-disable-next-line react/display-name
      formatter: (value, row) => (
        <div style={{ display: 'flex' }}>
          {/* <Toggle
            on={<div>Aktiv</div>}
            off={<div>Gesperrt</div>}
            size="normal"
            onstyle="success"
            offstyle="danger"
            active={!row.isLocked}
            onClick={() => onLock(row)}
          /> */}
          <Switch
            onChange={() => onLock(row)}
            checked={!row.isLocked}
            height={22}
            width={80}
            offColor="#dc3545"
            onColor="#28a745"
            uncheckedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 12,
                  color: 'white',
                  marginRight: 30,
                }}
              >
                Gesperrt
              </div>
            }
            checkedIcon={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  fontSize: 12,
                  color: 'white',
                  marginLeft: 10,
                }}
              >
                Aktiv
              </div>
            }
          />
        </div>
      ),
      sort: true,
    },
    {
      dataField: 'createdAt',
      text: 'Erstellt am',
      formatter: DateColumn('createdAt'),
      style: { minWidth: '100px' },
      sort: true,
    },
    {
      dataField: 'updatedAt',
      text: 'Geändert am',
      formatter: DateColumn('updatedAt'),
      style: { minWidth: '100px' },
      sort: true,
    },
    {
      dataField: 'lastname',
      text: 'Aktionen',
      formatter: (cell, row) => (
        <div style={{ display: 'flex' }}>
          <UrlButton icon="edit" toolTip="Benutzer bearbeiten" url={`/benutzer/update/${row.userId}`} />
          <ModalButton
            modalHeader="Löschen"
            modalBody={`Möchten Sie den Benutzer mit der ID ${row.userId} wirklich löschen?`}
            toolTip="Benutzer löschen"
            onOkClick={() => onDelete(row)}
          />
        </div>
      ),
      style: { minWidth: '70px', maxWidth: '170px' },
      sort: true,
    },
  ];

  return (
    <TableStyle>
      {data.length === 0 ? (
        'Keine Daten Vorhanden'
      ) : (
        <ToolkitProvider
          keyField="userId"
          data={data}
          columns={columns}
          noDataIndication="Keine Daten gefunden"
          // search
          search
        >
          {({ searchProps, baseProps }) => (
            <div>
              <SearchBar
                {...searchProps}
                style={{
                  margin: '0px 0px 15px 0px',
                  padding: '10px',
                  borderRadius: '0px',
                  width: '100%',
                  border: 'solid',
                  borderWidth: '1px',
                  borderColor: '#ced4da',
                }}
                placeholder="Suchen..."
              />

              <BootstrapTable
                {...baseProps}
                noDataIndication="Keine Daten mit diesen Sucheigenschaften gefunden"
                bordered={false}
                defaultSorted={defaultSorted}
                deleteRow
              />
            </div>
          )}
        </ToolkitProvider>
      )}
    </TableStyle>
  );
};

TableUsers.defaultProps = {
  data: [],
};
// export default withStyles(styles)(TableUsers);
export default TableUsers;
