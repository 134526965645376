import React from 'react';
import styled from 'styled-components';

const MinuteInput = styled.input`
  text-align: right;
  font-size: 16px;
  /* width: 30px; */
  width: ${(props) => (props.width ? props.width : '40px')};
  /* width: 30px; */
  padding-right: 3px;
  text-align: right;

  border-style: solid;
  border-radius: 0px;
  border-width: 1px;
  border-color: #bfbfbf;
`;

const TimeInput = ({ value, onChange, ...rest }) => {
  const filterTimeValue = (maxValue, digits) => {
    const filteredDigits = digits.replace(/\D/g, '0');

    if (Number(filteredDigits) >= maxValue) {
      return '0';
    }

    if (Number(filteredDigits) > 99) {
      return filteredDigits.slice(-3);
    }

    if (Number(filteredDigits) > 99) {
      return filteredDigits.slice(-3);
    }

    if (Number(filteredDigits) < 99) {
      return filteredDigits.slice(-2);
    }

    return filteredDigits;
  };

  const handleChange = (event) => {
    const minuteInput = event.target.value;
    const formattedMinuteInput = filterTimeValue(1000, minuteInput);
    onChange(`${formattedMinuteInput}`);
  };

  // const getMinute = () => `000${value}`.slice(-3);

  return <MinuteInput value={value} onChange={handleChange} {...rest} />;
};

export default TimeInput;
