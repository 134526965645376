import React from 'react';
import styled from 'styled-components';

const TitleStyle = styled.div`
  display: flex;
  font-size: 30px;
  font-weight: 500;
  color: #484c50;
`;

const Title = ({ children }) => <TitleStyle>{children}</TitleStyle>;

export default Title;
