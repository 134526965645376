import React from 'react';
// import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';

// import { Tab, Tabs, TabList, TabBody } from 'react-tabs';
import { FieldArray } from 'react-final-form-arrays';
import FormGroup from '../../atoms/FormGroup';
import Button from '../../atoms/Button';
import TextInput from '../../molecules/TextInput';
import TextArea from '../../molecules/TextArea';
import SelectField from '../../molecules/SelectField';

import withFinalForm from '../../HOC/with-final-form';
import allowedAttributes from './allowed-attributes';
import validator from './validator';

import { Tabs, TabHeaders, TabBodys, TabBody, Tab } from '../../molecules/Tabs';

// const tabStyle = {
//   borderRadius: '0px',
//   padding: '15px',
//   color: '#505050	',
// };

// const selectedTabStyle = {
//   border: 'solid',
//   borderColor: '#aaaaaa',
//   borderWidth: '1px',
//   borderTop: 0,
//   padding: '0px 10px 20px 10px',
// };

const FormCustomer = ({ values, handleSubmit, submitButtonText }) => (
  <form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
    <Tabs>
      <TabHeaders>
        <Tab>
          <FontAwesome name="info-circle" /> &nbsp; Firmeninformation
        </Tab>
        <Tab>
          <FontAwesome name="users" /> &nbsp; Angestellte
        </Tab>
        <Tab>
          <FontAwesome name="sticky-note" /> &nbsp; Notizen
        </Tab>
      </TabHeaders>

      <TabBodys>
        {/* KUNDENINFORMATIONEN */}
        <TabBody>
          <FormGroup>
            <TextInput label="Firmenname" type="text" name="name" />
          </FormGroup>

          <hr />
          <h4>Ansprechperson der Firma</h4>
          <FormGroup>
            <SelectField
              label="Anrede"
              name="salutation"
              options={[
                { label: 'Herr', value: 'Herr' },
                { label: 'Frau', value: 'Frau' },
              ]}
            />
          </FormGroup>

          <FormGroup>
            <TextInput label="Vorname" type="text" name="firstname" focus />
            <TextInput label="Name" type="text" name="lastname" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Straße" type="text" name="street" />
            <TextInput label="Hausnummer" type="text" name="houseNo" />
          </FormGroup>
          <FormGroup>
            <TextInput label="PLZ" type="text" name="plz" />
            <TextInput label="Ort" type="text" name="city" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Telefon" type="text" name="telefon" format="telefonFormat" />
            <TextInput label="Fax" type="text" name="fax" />
          </FormGroup>
          <FormGroup>
            <TextInput label="E-Mail" type="text" name="email" />
          </FormGroup>
        </TabBody>

        {/* Angestellte */}
        <TabBody>
          <FieldArray name="employees">
            {({ fields }) => (
              <>
                <Button
                  name="add-projectofficer-button"
                  type="button"
                  onClick={() => fields.push({ firstname: '', lastname: '', email: '', telefon: '' })}
                  style={{
                    width: '100%',
                    // marginLeft: '10px',
                    boxSizing: 'border-box',
                  }}
                  theme="success"
                  height="40px"
                  icon="plus"
                  text="Neuen Angestellten hinzufügen"
                />

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexWrap: 'wrap',
                    boxSizing: 'border-box',
                  }}
                >
                  {fields.map((employee, index) => (
                    <div
                      name="project-officer-box"
                      key={employee}
                      style={{
                        flex: '1 0 47.5%',
                        padding: '0px 10px 10px 10px',
                        margin: '10px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: '#aaaaaa',
                        backgroundColor: '#edf1f5',
                      }}
                    >
                      <FormGroup>
                        <TextInput label="Vorname" type="text" name={`${employee}.firstname`} />
                        <TextInput label="Name" type="text" name={`${employee}.lastname`} />
                        <TextInput label="Telefon" type="text" name={`${employee}.telefon`} />
                        <TextInput label="E-Mail" type="text" name={`${employee}.email`} />
                      </FormGroup>
                      {/* DELETE BUTTON */}
                      <Button
                        name="delete-projectofficer"
                        type="button"
                        onClick={() => fields.remove(index)}
                        style={{ marginTop: '10px' }}
                        theme="danger"
                        height="40px"
                        icon="trash"
                        text="Projektleiter löschen"
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </FieldArray>
        </TabBody>

        {/* NOTIZEN */}
        <TabBody>
          <FormGroup>
            <TextArea label="Notizen" type="text" name="note" />
          </FormGroup>
        </TabBody>
      </TabBodys>
    </Tabs>

    <Button
      name="submit-button"
      style={{ marginTop: '20px' }}
      theme="success"
      height="40px"
      icon="save"
      text={submitButtonText}
    />
  </form>
);

// FORM CONFIG
export default (props) => {
  const { data = {}, submitButtonText, mutation } = props;

  // const { employees } = data
  // --------------- DEFAULTING -----------------
  const {
    name = '',
    salutation = 'Herr',
    firstname = '',
    lastname = '',
    street = '',
    city = '',
    plz = '', //
    houseNo = '',
    telefon = '',
    fax = '',
    email = '',
    note = '',
    employees = [{ firstname: '', lastname: '', email: '', telefon: '' }],
  } = data;

  const initialValues = React.useMemo(
    () => ({
      name,
      salutation,
      firstname,
      lastname,
      street,
      city,
      plz,
      houseNo,
      telefon,
      fax,
      email,
      note,
      employees:
        !data.employees || !data.employees[0]
          ? [{ firstname: '', lastname: '', email: '', telefon: '' }]
          : data.employees,
      // -------------------------- SELECT OPTIONS ------------------------------
    }),
    [data]
  );

  return withFinalForm(
    {
      initialValues,
      validator,
      submitButtonText,
      allowedAttributes,
      attributesFilter: {
        plz: (value) => !value,
      },
      valuesFormat: {
        plz: (value) => parseInt(value),
        employees: (value) =>
          value && Array.isArray(value) && value[0]
            ? value.map(({ __typename, ...rest }) => ({
                ...rest,
              }))
            : [],
      },
      submitHandler: (values) => mutation(values),
    },
    FormCustomer
  );
};
