import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaSignature, FaInfo, FaEnvelope } from 'react-icons/fa';
import Modal from '../../molecules/Modal';

const THEME_COLOR = {
  primary: '#007bff',
  success: '#28a745',
  info: '#17a2b8',
  warning: '#ffc107',
  danger: '#dc3545',
  default: '#f8f9fa',
};

const ICONS = {
  signature: FaSignature,
  info: FaInfo,
  envelope: FaEnvelope,
};

const ButtonBoxStyle = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px;
  border-width: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  /* font-weight: bold; */
  width: 85px;
  min-width: 50px;

  min-height: 85px;
  max-height: 85px;
  padding: 5px;
  text-align: center;
  vertical-align: middle;

  &:hover {
    filter: brightness(90%);
  }

  background-color: ${(props) => THEME_COLOR[props.theme] || THEME_COLOR.default};
  border-color: ${(props) => (props.theme === 'default' ? '#adadad' : THEME_COLOR[props.theme])};
  color: ${(props) => (props.theme === 'default' || props.theme === 'warning' ? '#212529' : '#fff')};
  max-width: ${(props) => (props.noMaxWidth ? 'none' : '95px')};
  margin-left: ${(props) => props.marginLeft};
`;

const ButtonBoxModal = ({
  text,
  icon,
  applyFunction,
  theme,
  header,
  okButtonText,
  hideApplyButton,
  message,
  maxWidth,
  marginLeft,
}) => {
  const Icon = ICONS[icon];

  return (
    <Modal
      theme={theme}
      maxWidth={maxWidth}
      header={header}
      okButtonText={okButtonText}
      // BETTER NAMING
      // onOkButtonClick, onSubmit
      applyFunction={applyFunction}
      hideApplyButton={hideApplyButton}
      message={message}
    >
      <ButtonBoxStyle theme={theme} marginLeft={marginLeft}>
        <Icon size="30px" />
        <div style={{ marginTop: '10px' }}>{text}</div>
      </ButtonBoxStyle>
    </Modal>
  );
};

ButtonBoxModal.propTypes = {
  theme: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  width: PropTypes.string,
};

ButtonBoxModal.defaultProps = {
  text: '',
  icon: '',
  theme: 'default',
  // width: '100%',
};

export default ButtonBoxModal;
