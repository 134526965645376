import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import createMutationHandler from '../../shared/create-mutationhandler';
// import MetaInfo from '../../molecules/MetaInfo';
import Loading from '../../molecules/Loading';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import Form from './Form';
import ViewTitle from '../../atoms/ViewTitel';
import MetaDataBox from '../../molecules/MetaInfoBox'

const QUERY = gql`
  query GetUser($where: UserWhereInput) {
    user(where: $where) {
      userId
      username
      email
      firstname
      lastname
      salutation
      zip
      city
      street
      houseNo
      telefon
      notes
      createdAt
      updatedAt
      createdBy {
        firstname
        lastname
      }
      updatedBy {
        firstname
        lastname
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateUser($where: UserWhereUpdateInput, $data: UserUpdateInput) {
    updateUser(where: $where, data: $data) {
      __typename
      ... on User {
        userId
        username
      }
      ... on InvalidData {
        errors {
          message
          property
        }
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

const Update = ({ match, history }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: { userId: match.params.id } },
    fetchPolicy: 'network-only',
  });

  const [updateMutation, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_MUTATION, {
    refetchQueries: () => [
      {
        query: QUERY,
        variables: { where: { userId: match.params.id } },
        v: Math.random(),
      },
    ],
  });

  // UPDATE-MUTATION
  const handleUpdateMutation = (mutation) => {
    const handledUpdateMuation = createMutationHandler({
      mutation,
      mutationName: 'updateUser',
      mutationReturnTypes: {
        User: {
          successMessage: (data) => `Benutzer mit der ID ${data.userId} erfolgreich verändert`,
          onSuccess: () => history.push('/benutzer'),
        },
      },
      serverErrorMessage: `Änderung des Benutzers nicht erfolgreich`,
    });

    return (values) =>
      handledUpdateMuation({
        variables: {
          where: { userId: match.params.id },
          data: values,
        },
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  const { user = {} } = queryData;

  return (
    <>
      <ViewTitle>Benutzer ändern</ViewTitle>
      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={updateError} />
      <MetaDataBox
        style={{ marginBottom: '10px' }}
        columns={[
          [{ label: 'ID', value: user.userId }],
          [
            { label: 'Erstellt von', value: `${user.createdBy.firstname} ${user.createdBy.lastname}` },
            { label: 'Erstellt am', value: user.createdAt },
          ],
          [
            { label: 'Geändert von', value: `${user.updatedBy.firstname} ${user.updatedBy.lastname}` },
            { label: 'Geändert am', value: user.updatedAt},
          ],
        ]}
      />
      <Form
        data={user}
        submitButtonText="Benutzeränderungen speichern"
        onSubmit={handleUpdateMutation(updateMutation)}
        loading={[updateLoading]}
        // roles={user.roles || []}
        // rolesOfUser={user.roles || []}
      />
    </>
  );
};

export default Update;
