import React from 'react';
import { NotificationManager } from 'react-notifications';
import { withRouter } from 'react-router-dom';

import AppContext from './App.Context';
import api from '../lib/api';
import Loading from '../components/molecules/Loading';
import Login from '../components/environment/Login';
import Signature from '../components/environment/Signature';

class AppProvider extends React.Component {
  state = {
    sessionUser: {},
    loading: true,
    isPublicUrl: false,
  };

  componentDidMount() {
    const publicURLRegEx = /\/signature\/\d+\/.*/g;

    // if (this.props.location.pathname === '/signature/*/*') {
    if (publicURLRegEx.test(this.props.location.pathname)) {
      this.setState(() => ({
        loading: false,
        isPublicUrl: true,
      }));

      return null;
    }

    api({
      method: 'GET',
      path: '/login/user',
    })
      .then(({ response, error }) => {
        if (error) {
          // all das push('/login') habe ich nur verwendet damit ich nicht
          // users oder /kunden sehe und dann die Login-Seite sehen

          // redirect to /login if path is /
          if (this.props.location.pathname === '/') {
            // return this.props.history.push('/login');
            return this.setState(
              () => ({ loading: false }),
              () => {
                this.props.history.push('/login');
              }
            );
          }

          // just redirect to /login but no error
          if (this.props.location.pathname === '/login') {
            this.setState(
              () => ({ loading: false }),
              () => {
                this.props.history.push('/login');
              }
            );
          } else {
            // redirect to /login and show error if pathname is not / or /login
            // could land here if you enter URL directly and hit enter
            // console.log('Du hast was eingegeben was existiert, jedoch nicht login ist');
            this.setState(
              () => ({ loading: false }),
              () => {
                NotificationManager.error(error.message, 'Fehler');
                this.props.history.push('/login');
              }
            );
          }
        }

        if (response) {
          this.setState({
            loading: false,
            sessionUser: response.data,
          });
        }
      })
      .catch((error) => {
        NotificationManager.error(error.message, 'Unbekanter API-Fehler');
        this.props.history.push('/login');
      });
  } // componentDidMount() END

  loginUser = (username, password) => {
    api({
      method: 'POST',
      path: '/login',
      data: { username, password },
    })
      .then(({ response, error }) => {
        if (error) {
          NotificationManager.error(error.message, 'Fehler');
        } else {
          this.setState(
            (prevState) => ({ sessionUser: response.data }),
            () => this.props.history.push('/member-area')
          );
          NotificationManager.success('Sie wurden erfolgreich angemeldet', 'Anmeldung erfolgreich');
        }
      })
      .catch((error) => NotificationManager.error(error.message, 'Unbekanter Fehler'));
  };

  logout = (args = {}) => {
    api({
      method: 'GET',
      path: '/logout',
    })
      .then(({ response, error }) => {
        if (error) {
          NotificationManager.error(error.message, 'Fehler');
        } else {
          this.setState({ sessionUser: {} }, () => {
            this.props.history.push('/login');

            if (args.errorMessage) {
              return NotificationManager.error(args.errorMessage, 'Fehler');
            }

            NotificationManager.success('Sie wurden erfolgreich abgemeldet', 'Erfolg');
          });
        }
      })
      .catch((error) => NotificationManager.error(error.message, 'Unbekanter Fehler'));
  };

  setSessionUser = (sessionUser) => {
    this.setState((prevState) => ({ sessionUser }));
  };

  /*
  WHY LOADING?
  only render a component if you have the sate that it needs
  we only render a component if we have the state that it need to not get
  behavior that is unknown, we just render after the state is set
  meanwile we show a spinner in the <Loading /> component
  */
  render() {
    const { sessionUser, loading, isPublicUrl } = this.state;
    // const {} = this.props;
    if (loading) {
      return <Loading />;
    }

    if (isPublicUrl) {
      return <Signature />;
    }

    if (!sessionUser.username) {
      return <Login loginUser={this.loginUser} />;
    }

    return (
      <div>
        <AppContext.Provider
          value={{
            ...this.state,
            // loginUser: this.loginUser,
            logout: this.logout,
            setSessionUser: this.setSessionUser,
          }}
        >
          {this.props.children}
        </AppContext.Provider>
      </div>
    );
  }
}

// withRouter() damit wir this.props.history bekommen
export default withRouter(AppProvider);
