import React, { useEffect } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// ICONS
import { GiHamburgerMenu } from 'react-icons/gi';
import { GoArrowUp, GoArrowDown } from 'react-icons/go';
import { FaTrash } from 'react-icons/fa';
// ICONS
import { FieldArray } from 'react-final-form-arrays';
import ReceiptDnDFile from './Receipt.DnDFile.jsx';
import Button from '../../atoms/Button';

const DroppableContainer = styled.div`
  border: 1px solid #6c757d;
  background-color: #e4e4e4;
  padding: 10px;
  height: fit-content;
`;

const DraggableItem = styled.div`
  width: 405px;
  margin-bottom: 10px;
`;

const DragHeader = styled.div`
  width: 400px;
  display: flex;
  justify-content: space-between;
  border: ${({ isDragging }) => (isDragging ? '#0087ff' : '#6c757d')};
  background-color: ${({ isDragging }) => (isDragging ? '#0087ff' : '#6c757d')};
  padding: 5px;
`;

const DragHeaderButton = styled.div`
  cursor: pointer;
  padding: 5px;
`;

// -------------------------------- HELPERS ----------------------------------
const getNewArtificalId = () => new Date().valueOf() + Math.random();
// -------------------------------- HELPERS ----------------------------------

const ReceiptFileList = ({ files = [], updateFiles, form }) => {
  const onDragEnd = (fields) => (result) => {
    // const { destination, source, draggableId } = result;
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const sourceIndex = source.index;
    const destinationIndex = destination.index;
    // const pickedItem = files[sourceIndex];

    // files.splice(sourceIndex, 1);
    // files.splice(destinationIndex, 0, pickedItem);

    // // change order
    // const newOrderFiles = files.map((file, index) => ({ ...file, order: index }));
    // // setFiles([...newOrderFiles]);
    // updateFiles(newOrderFiles);

    // fields.swap(sourceIndex, destinationIndex);
    fields.move(sourceIndex, destinationIndex);
  };

  return (
    <FieldArray name="files">
      {({ fields }) => (
        <div style={{ width: '420px' }}>
          <Button
            // STANDARD: jeder button triggers ein submit, um dies zu verhindern
            // muss der button vom type "button" sein
            type="button" // wichtig weil sonst wird ein submit ausgelöst
            text="Neu Belegedatei hinzufügen"
            icon="plus"
            theme="success"
            height="40px"
            marginTop="15px"
            marginBottom="10px"
            onClick={() => {
              // add new file
              fields.push({
                artificalId: getNewArtificalId(),
              });
            }}
          />
          <DragDropContext onDragEnd={onDragEnd(fields)}>
            <Droppable droppableId="droppable-1">
              {(provided) => (
                <DroppableContainer {...provided.droppableProps} ref={provided.innerRef}>
                  {files.map((fileItem, index) => (
                    <Draggable
                      key={fileItem.id || fileItem.artificalId}
                      draggableId={`${fileItem.id || fileItem.artificalId}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <DraggableItem {...provided.draggableProps} ref={provided.innerRef}>
                          <div>
                            <DragHeader isDragging={snapshot.isDragging}>
                              <DragHeaderButton onClick={() => fields.remove(index)}>
                                <FaTrash size="25px" color="white" />
                              </DragHeaderButton>
                              <div style={{ display: 'flex' }}>
                                <DragHeaderButton onClick={() => fields.move(index, index - 1)}>
                                  <GoArrowUp size="25px" color="white" />
                                </DragHeaderButton>
                                <DragHeaderButton onClick={() => fields.move(index, index + 1)}>
                                  <GoArrowDown size="25px" color="white" />
                                </DragHeaderButton>
                              </div>
                              <DragHeaderButton {...provided.dragHandleProps}>
                                <GiHamburgerMenu size="25px" color="white" />
                              </DragHeaderButton>
                            </DragHeader>
                            <ReceiptDnDFile
                              file={fileItem}
                              // needed for dropzone to update a file on a specific index
                              // if you want to repick
                              onChange={(file) => {
                                fields.update(index, { ...fileItem, fileBlob: file });
                              }}
                            />
                          </div>
                        </DraggableItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </DroppableContainer>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
    </FieldArray>
  );
};

export default ReceiptFileList;
