import React from 'react';
import { Tooltip } from 'react-tippy';

const ToolTip = ({ title, children }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <Tooltip
      // options
      arrow="true"
      title={title}
      position="left"
      trigger="mouseenter"
    >
      {children}
    </Tooltip>
  </div>
);

export default ToolTip;
