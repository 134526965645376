import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import createMutationHandler from '../../shared/create-mutationhandler';
import Loading from '../../molecules/Loading';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import Form from './Form';
import ViewTitle from '../../atoms/ViewTitel';

const CREATE_MUTATION = gql`
  mutation UserTimetrack($data: UserCreateInput) {
    createUser(data: $data) {
      __typename
      ... on User {
        userId
        username
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
      ... on Unauthorized {
        message
      }
      ... on AlreadyExists {
        message
      }
    }
  }
`;

const Create = ({ match, history }) => {
  const [createMutation, { error: createError, loading: createLoading }] = useMutation(CREATE_MUTATION, {});
  const data = React.useRef({});

  // UPDATE-MUTATION
  const handleCreateMutation = (mutation) => {
    const handledCreateMutation = createMutationHandler({
      mutation,
      mutationName: 'createUser',
      mutationReturnTypes: {
        User: {
          successMessage: (data) => `Benutzer mit der ID ${data.userId} erfolgreich erstellt`,
          onSuccess: () => history.push('/benutzer'),
        },
        InvalidData: {
          // rename successMessage to message
          successMessage: (data) => `Benutzerdaten vom Server nicht akzeptiert`,
          // onSuccess: () => history.push('/benutzer'),
        },
      },
      serverErrorMessage: `Änderung der Benutzer nicht erfolgreich`,
    });

    return (values) =>
      handledCreateMutation({
        variables: {
          data: values,
        },
      });
  };

  // if (createLoading) {
  //   return <Loading />;
  // }

  return (
    <>
      <ViewTitle>Neuen Benutzer erstellen</ViewTitle>
      <ErrorGraphQL error={createError} />
      <Form
        type="CREATE"
        data={data.current}
        submitButtonText="Benutzer erstellen"
        onSubmit={handleCreateMutation(createMutation)}
        loading={[true]}
      />
    </>
  );
};

export default Create;
