import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import createMutationHandler from '../../shared/create-mutationhandler';
import Loading from '../../molecules/Loading';
import ErrorGraphQL from '../../../errors/ErrorGraphQL';
import ReceiptForm from './Receipt.Form';

const QUERY = gql`
  query GetData($transactionId: ID) {
    users(where: {}) {
      userId
      firstname
      lastname
    }
    receiptCategories(where: {}) {
      id
      description
      ust
    }
    bankBeneficiaries(where: {}) {
      id
      label
    }
    bankAccounts(where: {}) {
      id
      name
      iban
    }
    bankTransaction(where: { id: $transactionId }) {
      verwendungszweck
      iban
      bank {
        name
      }
    }
  }
`;

const CREATE_MUTATION = gql`
  mutation CreateReceipt($data: ReceiptCreateInput) {
    createReceipt(data: $data) {
      __typename
      ... on Receipt {
        id
        name
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
      ... on Unauthorized {
        message
      }
      ... on AlreadyExists {
        message
      }
    }
  }
`;

const ReceiptCreate = ({ history, location, match }) => {
  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: {
      where: {},
      ...(match.params.transactionId ? { transactionId: match.params.transactionId } : {}),
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  // console.log('DAS IST LOCATION: ', location);
  // console.log('DAS IST MATCH: ', match);
  // console.log('DAS IST MATCH transactionId: ', match.params.transactionId);

  const [createMutation, { error: createError, loading: createLoading }] = useMutation(CREATE_MUTATION);

  const { users = [], receiptCategories = [], bankBeneficiaries = [], bankTransaction = {}, bankAccounts } = queryData;
  // console.log('DAS IST BT: ', bankTransaction);
  const { bank = {} } = bankTransaction ?? {};

  const handleCreateMutation = (mutation) => {
    const handledCreateMutation = createMutationHandler({
      mutation,
      mutationName: 'createReceipt',
      mutationReturnTypes: {
        Receipt: {
          successMessage: (data) => `Beleg mit der ID ${data.receiptId} namens ${data.name} erfolgreich erstellt`,
          onSuccess: () => history.push('/belege'),
        },
      },
      serverErrorMessage: `Erstellung des Belegs nicht erfolgreich`,
    });

    return (values) =>
      handledCreateMutation({
        variables: {
          data: values,
        },
      });
  };

  if (queryLoading) {
    return <Loading />;
  }

  const [beneficiaryOfTransaction] = bankBeneficiaries.filter(
    (beneficiary) => beneficiary.iban === bankTransaction.iban
  );

  return (
    <fieldset disabled={createLoading}>
      <h1>Neuen Beleg erstellen</h1>
      <hr />
      {/* ------- SHOW TRANSACTION ON CREATE IF /receipts/create/:transactionId --------  */}
      {match.params.transactionId && (
        <>
          <div>
            <div style={{ marginBottom: '15px' }}>
              <div style={{ fontWeight: 'bold' }}>Zahlungsmittel:</div>
              <div>{bank.name}</div>
            </div>

            <div style={{ marginBottom: '15px' }}>
              <div style={{ fontWeight: 'bold' }}>Für den Verwendungszweck:</div>
              <div>{bankTransaction.verwendungszweck}</div>
            </div>

            <div style={{ marginBottom: '15px' }}>
              <div style={{ fontWeight: 'bold' }}>Begünstigter:</div>
              <div>
                {beneficiaryOfTransaction ? (
                  beneficiaryOfTransaction.firmenname
                ) : (
                  <div>
                    <div>
                      Begünstigter mit der IBAN {bankTransaction.iban} ist im System nicht bekannt. Bitte erstellen sie
                      diesen
                    </div>
                    <button>Begünstigten erstellen</button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
      {/* ------- SHOW TRANSACTION ON CREATE IF /receipts/create/:transactionId --------  */}

      <hr />
      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={createError} />
      <ReceiptForm
        location={location}
        userList={users}
        categoryList={receiptCategories}
        bankBeneficiaryList={bankBeneficiaries}
        bankAccountList={bankAccounts}
        bankTransaction={bankTransaction}
        submitButtonText="Neuen Beleg erstellen"
        transactionId={match.params.transactionId}
        mutation={handleCreateMutation(createMutation)}
      />
    </fieldset>
  );
};
export default withRouter(ReceiptCreate);
