import React, { useContext, createContext, useState } from 'react';
import styled from 'styled-components';

const DropDownContext = React.createContext(null);

const DropDownContainer = styled.div`
  color: #484c50;
`;

const DropDownToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  height: 30px;
  width: 30px;

  &:hover {
    background-color: #a0d2f0;
  }
`;

const DropDownContentContainer = styled.div`
  position: relative;
`;

const DropDownContentStyle = styled.div`
  cursor: pointer;
  user-select: none;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};

  position: absolute;
  background-color: #f1f1f1;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  right: 0;
  /* padding: 5px; */
`;

export const DropDownContent = ({ children }) => {
  const { toggleMenu, isOpen } = useContext(DropDownContext);

  return (
    <DropDownContentContainer
      onClick={(event) => {
        toggleMenu();
        event.stopPropagation();
      }}
    >
      <DropDownContentStyle isOpen={isOpen}>{children}</DropDownContentStyle>
    </DropDownContentContainer>
  );
};

export const DropDownToggle = ({ children }) => {
  const { toggleMenu } = useContext(DropDownContext);

  return (
    <DropDownToggleContainer
      onClick={(event) => {
        event.stopPropagation();
        toggleMenu();
      }}
    >
      {children}
    </DropDownToggleContainer>
  );
};

export const DropDownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  &:hover {
    background-color: #a0d2f0;
  }
`;

const DropDown = ({ children }) => {
  const [isOpen, setToggleDropDown] = useState(false);

  const toggleMenu = () => {
    setToggleDropDown((prevState) => !prevState);
  };

  return (
    <DropDownContext.Provider value={{ isOpen, toggleMenu }}>
      <DropDownContainer>{children}</DropDownContainer>
    </DropDownContext.Provider>
  );
};

export default DropDown;
