const hasPermissions = (roles = [], requiredPermissions = []) => {
  const userPermissions = roles.reduce((accu, role) => {
    if (Array.isArray(role.permissions)) {
      return [...accu, ...role.permissions.map(permission => permission.name)];
    }
    return accu;
  }, []);

  const result = requiredPermissions.every(requiredPermission => userPermissions.includes(requiredPermission));

  return result;
};

export default hasPermissions;
