import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BiX } from 'react-icons/bi';

const PRIMARY_COLOR = '#2e84b7';
const DANGER_COLOR = '#CC334D';
const SUCCESS_COLOR = '#5CB85C';
const DARK_GREY_COLOR = '#2D2D2D';
const LIGHT_GREY_COLOR = '#D1D5D8';

const getThemeColor = (props) => {
  switch (props.theme) {
    case 'danger': {
      return { backgroundColor: DANGER_COLOR, color: 'white' };
    }
    case 'primary': {
      return { backgroundColor: PRIMARY_COLOR, color: 'white' };
    }
    case 'success': {
      return { backgroundColor: SUCCESS_COLOR, color: 'white' };
    }
    case 'neutral': {
      return { color: DARK_GREY_COLOR, backgroundColor: LIGHT_GREY_COLOR };
    }
    default:
      return {};
  }
};

// import { Button, Modal } from 'react-bootstrap';
const modalStyle = {
  display: 'inline',
  // transform: 'translate(-50%, -50%)',
  transition: 'opacity 300ms ease-in-out',

  position: 'fixed',
  justifyContent: 'center',
  alignItems: 'flex-start',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 999,
  padding: '10px',
};

const contentContainerStyle = {
  backgroundColor: 'white',
  // width: '50vw',
  // padding: '2rem',
  position: 'relative',
};

const sharedModalStyle = {
  padding: '10px',
};

const closeTextStyle = {
  position: 'relative',
  fontSize: '150%',
  backgroundColor: 'transparent',
  color: 'white',
  cursor: 'pointer',
  ':hover': {
    color: 'gray',
  },
  border: 0,
  top: '-0.84rem',
  right: '0.1rem',
  width: '20px',
  height: '20px',
  textAlign: 'center',
};


const modalFooterStyle = {
  textAlign: 'right',
};

const lineStyle = {
  marginLeft: '10',
  marginRight: '10',
  // width: '90%',
  height: '1px',
  background: '#D1D5D8',
};

const buttonStyle = {
  cursor: 'pointer',
  border: 'none',
  color: 'white',
  backgroundColor: '#D1D5D8',
  ':hover': { filter: 'brightness(110%)' },
  marginLeft: '5px',
  padding: '7px',
  minWidth: '88px',
};

const ModalContainer = styled.div`
  /* display: $inline; */
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  transition: opacity 300ms ease-in-out;
  position: fixed;

  justify-content: center;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.6);

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  padding: 10px;
`;

// const CloseText = styled.div(closeTextStyle);
const Container = styled.div(contentContainerStyle);
const CloseButton = styled.div`
  cursor: pointer;
  z-index: 999;
  width: 20px;
  height: 20px;
  margin-top: 2px;

  background-color: transparent;
  border: 0;

  &:hover {
    background-color: red;
  }

  border-width: 1px;
  text-align: center;
`;

const ModalHeader = styled.div`
  /* position: relative; */
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  padding: 10px;
  color: ${(props) => (props.theme ? getThemeColor(props).color : 'white')};
  background-color: ${(props) => (props.theme ? getThemeColor(props).backgroundColor : 'white')};
`;

// whitespace: normal ist standard, jedoch packe ich das ein, da einige
// eltern elemente nicht normal hat
const ModalContent = styled.div(sharedModalStyle, { whiteSpace: 'normal' });
const ModalFooter = styled.div(sharedModalStyle, modalFooterStyle);
const Line = styled.div(lineStyle);
// const ModalHeader = styled.div(sharedModalStyle, modalHeaderStyle, getThemeColor);
const Button = styled.button(buttonStyle, getThemeColor);

//
//
//
//
//
const Modal = ({
  children,
  okButtonName,
  chancelButtonName,
  okButtonText = 'OK',
  theme,
  header,
  message,
  applyFunction,
  minWidth = '520px',
  maxWidth,
  hideApplyButton,
  disabled
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div style={{ cursor: 'pointer', pointerEvents: disabled ? 'none' : 'auto' }}>
      <div>
        {isOpen && (
          <div style={{ display: 'inline' }}>
            <ModalContainer isOpen={isOpen}>
              <Container>
                <ModalHeader theme={theme || 'neutral'}>
                  <div>{header}</div>
                  <CloseButton onClick={toggleModal}>
                    <BiX />
                  </CloseButton>
                </ModalHeader>
                <ModalContent style={{ minWidth, ...(maxWidth ? { maxWidth } : {}) }}>{message}</ModalContent>
                <Line />
                <ModalFooter>
                  {!(typeof hideApplyButton === 'function' && hideApplyButton()) && (
                    <Button
                      name={okButtonName}
                      theme={theme || 'neutral'}
                      onClick={() => {
                        toggleModal();
                        applyFunction();
                      }}
                    >
                      {okButtonText}
                    </Button>
                  )}
                  <Button theme="neutral" onClick={toggleModal} name={chancelButtonName}>
                    Abbrechen
                  </Button>
                </ModalFooter>
              </Container>
            </ModalContainer>
          </div>
        )}
      </div>

      <div style={{ display: 'inline' }} onClick={toggleModal} role="presentation">
        {children}
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  applyFunction: PropTypes.func,
  header: PropTypes.string,
  // message: PropTypes.string,
  // children: PropTypes.string,
};

Modal.defaultProps = {
  applyFunction: () => {},
  header: 'No Header',
  // message: 'No Message',
  // children: 'NO MODAL CHILD IS GIVEN',
};
