const allowedAttributes = [
  'name',
  'billDate',
  'projectStart',
  'projectEnd',
  'deadlineDays',
  'positions', //
  'isPaid',
  'notiz',
];

export default allowedAttributes;
