// formatters is an object of functions
export default (formatters, values) => {
  // all values with configs/formatters
  const formatedValues = Object.keys(values).reduce((accu, valueKey) => {
    // if it has a value, use the value
    if (formatters[valueKey]) {
      return {
        ...accu,
        [valueKey]: formatters[valueKey](values[valueKey]),
      };
    }

    // otherwise use current unformated value
    return {
      ...accu,
      [valueKey]: values[valueKey],
    };
  }, {});

  return formatedValues;
};
