const allowedAttributes = [
  'focused',
  'completed',
  'recruiter',
  'employee',
  //
  //
];

export default allowedAttributes;
