import React from 'react';
import { CgImport, CgExport, CgTrash } from 'react-icons/cg';
import { Accordion, AccordionItem, AccordionItemButton } from 'react-accessible-accordion';
import { HiDotsVertical } from 'react-icons/hi';
import FormGroup from '../../../atoms/FormGroup';
import DropDownMenu, { DropDownToggle, DropDownContent, DropDownItem } from '../../../molecules/DropDownMenu';
import { createViewData } from './time-tracker-utils';
import { HeadAccordion, BodyAccordion, TimeItems, MobileLabel, MobileInput } from './style';

import TimeInput from '../../../molecules/TimeInput';
import MinuteInput from '../../../molecules/MinuteInput';
import MobileFooter from './Mobile.Footer';

const TimeListMobile = ({
  timetrack,
  tracks,
  year,
  month,
  daysOfMonth,
  updateValue,
  addDay,
  deleteDay,
  copyDay,
  pasteDay,
  onSubmit,
}) => (
  <>
    <Accordion
      allowZeroExpanded
      style={{
        display: 'flex',
        flexDirection: 'column',
        borderWidth: '0px',
        marginTop: '10px',
        marginBottom: '50px',
      }}
    >
      {Array.from({ length: daysOfMonth }, (_, index) => {
        // contains active/inactive logic (what time we have or not AKA hasDay or not)
        const day = index + 1;
        // just view data like Mo, Di, Mi, Fr, Sa, So, Berechnung von stunden etc.
        const {
          date,
          hasDay,
          weekDay,
          isWeekend,
          startTime,
          endTime,
          lunchBreak,
          activity,
          location,
          nettoTime,
        } = createViewData({
          year,
          month,
          day,
          timeTrackerArray: tracks,
        });

        return (
          <TimeItems key={index}>
            <AccordionItem key={date}>
              <HeadAccordion hasday={hasDay ? 1 : 0} isweekend={isWeekend ? 1 : 0}>
                <AccordionItemButton style={{ flex: 0, padding: 0 }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginTop: '0px',
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '25px', marginLeft: '10px' }}>
                        <b>{weekDay}</b>
                      </div>
                      <div>{date}</div>
                    </div>

                    <div style={{ display: 'flex', textAlign: 'left', alignItems: 'center', marginTop: '0px' }}>
                      <div style={{ marginRight: '10px' }}>
                        <b>Stunden:</b> {nettoTime} h
                      </div>
                      <DropDownMenu>
                        <DropDownToggle>
                          <HiDotsVertical size="20px" />
                        </DropDownToggle>
                        <DropDownContent>
                          <DropDownItem
                            onClick={() => {
                              pasteDay(index + 1);
                            }}
                          >
                            <CgImport />
                            <div style={{ marginLeft: '10px' }}>Einfügen</div>
                          </DropDownItem>
                          <DropDownItem
                            onClick={() => {
                              copyDay(index + 1);
                            }}
                          >
                            <CgExport />
                            <div style={{ marginLeft: '10px' }}>Kopieren</div>
                          </DropDownItem>
                          <DropDownItem
                            onClick={() => {
                              deleteDay(index + 1);
                            }}
                          >
                            <CgTrash />
                            <div style={{ marginLeft: '10px' }}>Löschen</div>
                          </DropDownItem>
                        </DropDownContent>
                      </DropDownMenu>
                    </div>
                  </div>
                </AccordionItemButton>
              </HeadAccordion>
              <BodyAccordion>
                <div style={{ padding: '10px' }}>
                  <FormGroup>
                    <div>
                      <MobileLabel>von:</MobileLabel>
                      <TimeInput
                        value={startTime}
                        onChange={updateValue('start', index + 1)}
                        onBlur={() => addDay(index + 1)}
                      />
                    </div>

                    <div>
                      <MobileLabel>bis:</MobileLabel>
                      <TimeInput
                        value={endTime}
                        onChange={updateValue('end', index + 1)}
                        onBlur={() => addDay(index + 1)}
                      />
                    </div>

                    <div>
                      <MobileLabel>Pause:</MobileLabel>
                      <MinuteInput value={lunchBreak} onChange={updateValue('lunchBreak', index + 1)} /> min
                    </div>
                  </FormGroup>

                  <div>
                    <MobileLabel style={{ marginTop: '15px' }}>Tätigkeit:</MobileLabel>
                    <MobileInput
                      style={{ width: '100%' }}
                      value={activity}
                      onChange={(event) => updateValue('activity', index + 1)(event.target.value)}
                    />
                  </div>

                  <div>
                    <MobileLabel>Standort:</MobileLabel>
                    <MobileInput
                      style={{ width: '100%' }}
                      value={location}
                      onChange={(event) => updateValue('location', index + 1)(event.target.value)}
                    />
                  </div>
                </div>
              </BodyAccordion>
            </AccordionItem>
          </TimeItems>
        );
      })}
    </Accordion>
    <MobileFooter tracks={tracks} onSubmit={onSubmit} type={timetrack.timetrackType} />
  </>
);

export default TimeListMobile;
