import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import FontAwesome from 'react-fontawesome';
// import { useDropzone } from 'react-dropzone';
// import currency from 'currency.js';
import Switch from 'react-switch';
import styled from 'styled-components';
// import { List } from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays';
import { GoArrowUp, GoArrowDown } from 'react-icons/go';
import { FaTrash } from 'react-icons/fa';
import { FormLayout } from './style';
import allowedAttributes from './allowed-attributes';
import withFinalForm from '../../HOC/with-final-form';
import validator from './validator';

import Button from '../../atoms/Button';
import FormGroup from '../../atoms/FormGroup';
import TextInput from '../../molecules/TextInput';
import TextArea from '../../molecules/TextArea';
import SelectField from '../../molecules/SelectField';
import SelectInput from '../../molecules/SelectInput';
import DatePicker from '../../final-form-elements/DatePicker';
import AddTransaction from './AddTransaction';

// import ReceiptDnDFile from './Receipt.DnDFile.jsx';
import ReceiptFileList from './Receipt.FileList.jsx';
import positionsCalc from './positions-calc';
import OpenFilesButton from './OpenFiles.jsx';
import Positions from './Receipt.Positions';

export const GridLayout = styled.div`
  @media screen and (min-width: 760px) {
    display: grid;
    grid-template-columns: 430px 4fr;
    grid-template-areas: 'picture-area form-area';
    grid-column-gap: 10px;
  }

  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'picture-area'
    'form-area';
`;

const PositionContainer = styled.div`
  display: flex;
  background-color: #edf4fb;
  border: solid 1px #bfbfbf;
  margin-top: 10px;
  padding: 10px;
`;

const PositionHeader = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #bd2130;
  width: 30px;
  height: 30px;
`;

const RecheiptForm = ({ values, handleSubmit, submitButtonText, form, onSubmit, transactionId, ...rest }) => {
  const { positions = [], files = [], kontenrahmenOptions = [] } = values;

  // console.log('DAS IST TID: ', transactionId);
  // console.log('DAS IST REST: ', rest);

  const handleImportFiles = (event) => {
    const getNewArtificalId = () => new Date().valueOf() + Math.random();
    const pickedFiles = Array.from(event.target.files);

    form.change('files', [
      ...values.files,
      ...pickedFiles.map((file, index) => ({
        artificalId: getNewArtificalId() + index,
        fileBlob: file,
      })),
    ]);
  };

  const setBankTransaction = (transaction) => {
    form.change('transaction', transaction);
  };

  //
  return (
    <>
      <form data-testid="receipt-form" onSubmit={handleSubmit}>
        {/* <div>DAS IST STATE: {location.state.bank}</div> */}
        <OpenFilesButton onChange={handleImportFiles} />
        <GridLayout>
          {/* ************** FILES *************** */}
          <ReceiptFileList files={files} form={form} />
          {/* ************** FILES *************** */}
          {/* INPUT ELEMENTT */}
          <FormLayout>
            <FormGroup>
              <TextInput
                label="Belegnummer / Rechnungsnummer / Bezeichnung"
                type="text"
                name="name"
                focus
                data-testid="receipt-name"
              />
            </FormGroup>
            <FormGroup>
              <DatePicker label="Belegdataum" name="receiptDate" data-testid="receipt-date" />
              <SelectField
                label="Land"
                name="country"
                options={[
                  { label: 'Deutschland', value: 'de' },
                  { label: 'USA', value: 'usa' },
                  { label: 'China', value: 'cn' },
                  { label: 'Niederlande', value: 'nl' },
                  { label: 'Frankreich', value: 'fr' },
                  { label: 'England', value: 'gb' },
                ]}
                data-testid="country"
              />
            </FormGroup>
            <FormGroup>
              <SelectInput
                height="55px"
                placeholder=""
                label="Konto / Zahlungsmittel"
                name="payOption"
                options={values.bankAccountOptions}
                data-testid="pay-options"
                isDisabled={!!values.transactionId}
                onChange={(selected) => {
                  form.change(
                    'rawPayOption',
                    values.bankAccountOptionsRaw.find((bankAccountRaw) => bankAccountRaw.id === selected.value)
                  );
                }}
              />
            </FormGroup>
            <FormGroup>
              <SelectInput
                height="55px"
                placeholder=""
                label="Begünstigter / Lieferant"
                name="supplier"
                // options={{ value: 1, label: 'TEST' }}
                options={values.beneficiaryOptions}
                data-testid="beneficiary"
                isDisabled={!!values.transactionId}
                onChange={(selected) => {
                  form.change(
                    'rawSupplier',
                    values.beneficiaryOptionsRaw.find((beneficiaryRaw) => beneficiaryRaw.id === selected.value)
                  );
                }}
              />
            </FormGroup>
            <FormGroup>
              {/* BANKTRANSAKTION */}
              <AddTransaction
                bankAccount={values.rawPayOption}
                beneficiary={values.rawSupplier}
                transaction={values.transaction}
                setBankTransactionInFormState={setBankTransaction}
              />
            </FormGroup>

            {/* ************** POSITION *************** */}
            <Positions positions={positions} kontenrahmenOptions={kontenrahmenOptions} />
            {/* ************** POSITION *************** */}

            <hr />
            <FormGroup>
              <TextArea label="Beschreibung" type="text" name="description" data-testid="description" />
            </FormGroup>
          </FormLayout>
        </GridLayout>
        <Button
          type="submit"
          name="create-receipt"
          data-testid="create-receipt"
          style={{ marginTop: '20px' }}
          theme="success"
          height="40px"
          icon="save"
          text={submitButtonText}
        />
      </form>
    </>
  );
};

RecheiptForm.propTypes = {
  values: PropTypes.object,
  submitButtonText: PropTypes.string,
};

RecheiptForm.defaultProps = {
  values: {},
  submitButtonText: 'NO SUBMIT BUTTON TEXT',
};

// FORM CONFIG
export default (props) => {
  const {
    data = {},
    submitButtonText,
    mutation,
    // data for select fields
    userList,
    transactionId,
    // have default => ARE OPTIONAL
    categoryList = [],
    bankBeneficiaryList = [],
    bankAccountList = [],
  } = props;

  const { positions = [], files = [], beneficiary } = data;

  const initialValues = React.useMemo(
    () => ({
      // default data
      transactionId,
      name: '',
      description: '',
      country: 'de',
      ...data,
      // custom data if data props are not empty
      rawPayOption: data.payOption,
      payOption: !data.payOption //
        ? { value: '', label: '' }
        : {
            // raw: data.payOption,
            value: data.payOption.id,
            label: (
              <>
                <div style={{ fontSize: '12px' }}>{data.payOption.id}</div>
                <div>{data.payOption.name}</div>
              </>
            ),
          },
      rawSupplier: data.supplier,
      supplier: !data.supplier //
        ? { value: '', label: '' }
        : {
            value: data.supplier.id,
            label: (
              <>
                <div style={{ fontSize: '12px' }}>{data.supplier.id}</div>
                <div>{data.supplier.label}</div>
              </>
            ),
          },
      receiptDate: data.receiptDate ? new Date(data.receiptDate) : new Date(),
      positions:
        positions.length === 0 || !positions[0] // wenn [0] falsy value ist
          ? []
          : data.positions
              .map((position) => ({
                ...position,
                // nimm die liste aller categorien vom server und gehe diese durch
                // nimm die category mit "id vom server = id von der liste"
                category: {
                  value: { categoryId: position.category.categoryId, ust: position.category.ust },
                  label: `${position.category.categoryId} - ${position.category.description} - ${position.category.ust}%`,
                },
              }))
              .sort((a, b) => a.order - b.order),
      files:
        files.length === 0 || !files[0] // wenn [0] falsy value ist
          ? []
          : data.files.sort((a, b) => a.order - b.order),

      // *********************** DATA FOR SELECT FIELDS ***********************
      // bankOptions: [
      //   { label: 'Konto - Firma', value: 1 },
      //   { label: 'Konto - 527', value: 2 },
      //   { label: 'Konto - 656', value: 3 },
      //   { label: 'Konto - Nedzad', value: 4 },
      //   { label: 'Konto - Amira', value: 5 },
      //   { label: 'Bar - Firma', value: 6 },
      //   { label: 'Bar - Nedzad', value: 7 },
      // ],
      bankAccountOptionsRaw: bankAccountList,
      bankAccountOptions: bankAccountList.map(({ id, name }) => ({
        value: id,
        label: (
          <>
            <div style={{ fontSize: '12px' }}>{id}</div>
            <div>{name}</div>
          </>
        ),
      })),
      beneficiaryOptionsRaw: bankBeneficiaryList,
      beneficiaryOptions: bankBeneficiaryList.map(({ id, label }) => ({
        value: id,
        label: (
          <>
            <div style={{ fontSize: '12px' }}>{id}</div>
            <div>{label || 'BESITZT KEIN LABEL'}</div>
          </>
        ),
      })),
      kontenrahmenOptions: categoryList.map((kategorie) => ({
        value: { categoryId: kategorie.categoryId, ust: kategorie.ust },
        label: `${kategorie.categoryId} - ${kategorie.description} - ${kategorie.ust}%`,
      })),
      categoryOptions: categoryList,
      userOptions: userList,
      // *********************** DATA FOR SELECT FIELDS ***********************
    }),
    [data, categoryList, bankBeneficiaryList, files]
  );

  return withFinalForm(
    {
      initialValues,
      validator,
      submitButtonText,
      allowedAttributes,
      // nicht nur wichtig für submit value, auch wichtig für validation
      valuesFormat: {
        name: (name) => name.trim(),
        supplier: (pickedSupplier) =>
          pickedSupplier.value //
            ? pickedSupplier.value
            : null,
        payOption: (payOption) =>
          payOption.value //
            ? payOption.value
            : null,
        transaction: (transaction) => transaction.id,
        // remove __typename and artificalId
        positions: (positionsList) =>
          positionsList.map(({ __typename, artificalId, category, amount, ...filteredPosition }, index) => ({
            ...filteredPosition,
            order: index,
            category: category.value.categoryId,
            amount: Math.trunc(amount),
          })),
        // remove files with artificalId but no data
        // remove __typename and artifialId
        // remove url (aka the path), because url comes from the server and is not updated by us, its metadata
        files: (filesList) =>
          filesList
            // .filter((file) => file.artificalId && !file.fileBlob)
            .map(({ __typename, artificalId, url, id, ...filteredFiles }, index) => ({
              ...filteredFiles,
              // if id exists
              ...(id ? { id: parseInt(id) } : {}),
              order: index,
            })),
      },
      submitHandler: (values) => mutation(values),
    },
    RecheiptForm
  );
};
