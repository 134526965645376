import React from 'react';
// import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { FaEnvelope } from 'react-icons/fa';
import styled from 'styled-components';

import { FieldArray } from 'react-final-form-arrays';

import { Tabs, TabHeaders, TabBodys, TabBody, Tab } from '../../molecules/Tabs';

import FormGroup from '../../atoms/FormGroup';
import Button from '../../atoms/Button';
import TextInput from '../../molecules/TextInput';
import TextArea from '../../molecules/TextArea';
import SelectField from '../../molecules/SelectField';

import ToggleButton from '../../molecules/ToggleButton';
import ToggleTab from '../../molecules/ToggleTab';

import withFinalForm from '../../HOC/with-final-form';
import allowedAttributes from './allowed-attributes';
import validator from './validator';

const PROJEKTELEMENT = [
  { description: 'Linux Admin' },
  { description: 'Windows Support' },
  { description: 'Mac Admin' },
];

const FormCustomer = ({ values, handleSubmit, submitButtonText }) => (
  <form style={{ marginTop: '20px' }} onSubmit={handleSubmit}>
    <hr />
    <FormGroup style={{ flexDirection: 'row', marginLeft: '10px', alignSelf: 'center' }}>
      <FormGroup style={{ flexDirection: 'column' }}>
        <ToggleButton name="focused" onMessage="Projekt fokussiert" offMessage="Projekt nicht fokussiert" />
      </FormGroup>
      <FormGroup style={{ flexDirection: 'column', alignItems: 'flex-start', justifyContent: '' }}>
        <ToggleButton
          name="completed"
          onMessage="Projekt abgeschlossen"
          offMessage="Projekt nicht abgeschlossen"
          opposite
        />
      </FormGroup>
    </FormGroup>
    <hr />

    <Tabs>
      <TabHeaders>
        <Tab>
          <FaEnvelope /> &nbsp; E-Mail
        </Tab>
        {PROJEKTELEMENT.map((projectElement) => (
          <Tab width="100%">
            <FontAwesome name="male" /> &nbsp; {projectElement.description} - Log
          </Tab>
        ))}
        {/* <Tab>
          <FontAwesome name="male" /> &nbsp; Ansprechpartner
        </Tab> */}
        {/* <Tab>
          <FontAwesome name="users" /> &nbsp; Projektleiter
        </Tab> */}
        {/* <Tab>
          <FontAwesome name="bank" /> &nbsp; Bankdaten
        </Tab> */}
        {/* <Tab>
          <FontAwesome name="money" /> &nbsp; Steuern
        </Tab> */}
        <Tab>
          <FontAwesome name="sticky-note" /> &nbsp; Notizen
        </Tab>
      </TabHeaders>

      <TabBodys>
        {/* E-MAIL */}
        <TabBody>
          <FormGroup>
            <ToggleTab header="E-mail">
              <div
                style={{ marginTop: '20px', marginLeft: '10px', width: '100%' }}
                dangerouslySetInnerHTML={{ __html: values.html }}
              />
            </ToggleTab>
          </FormGroup>
          <FormGroup>
            <TextInput label="Recruting-Firma" type="text" name="ansprechvorname" />
            <TextInput label="Recruiter" type="text" name="ansprechname" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Projektort" type="text" name="ansprechvorname" />
            <TextInput label="Art der Arbeit" type="text" name="ansprechname" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Projektstart" type="text" name="ansprechvorname" />
            <TextInput label="Projektend" type="text" name="ansprechname" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Sprachkentnisse" type="text" name="ansprechvorname" />
            <TextInput label="IT-Kentnisse" type="text" name="ansprechname" />
          </FormGroup>
        </TabBody>

        {/* Ansprechpartner */}
        <TabBody>
          <FormGroup>
            <TextInput label="Ansprechpartner-Vorname" type="text" name="ansprechvorname" />
            <TextInput label="Ansprechpartner-Name" type="text" name="ansprechname" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Ansprechpartner-Telefon" type="text" name="ansprechtelefon" />
            <TextInput label="Ansprechpartner-E-Mail" type="text" name="ansprechemail" />
          </FormGroup>
        </TabBody>

        {/* Projektleiter */}
        <TabBody>
          <FieldArray name="projectOfficers">
            {({ fields }) => (
              <>
                <Button
                  name="add-projectofficer-button"
                  type="button"
                  onClick={() => fields.push({ firstname: '', lastname: '', email: '', telefon: '' })}
                  style={{
                    width: '100%',
                    // marginLeft: '10px',
                    boxSizing: 'border-box',
                  }}
                  theme="success"
                  height="40px"
                  icon="plus"
                  text="Neuen Projektleiter hinzufügen"
                />

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexWrap: 'wrap',
                    boxSizing: 'border-box',
                  }}
                >
                  {fields.map((projectOfficer, index) => (
                    <div
                      name="project-officer-box"
                      key={projectOfficer}
                      style={{
                        flex: '1 0 47.5%',
                        padding: '0px 10px 10px 10px',
                        margin: '10px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: '#aaaaaa',
                        backgroundColor: '#edf1f5',
                      }}
                    >
                      <FormGroup>
                        <TextInput label="Vorname" type="text" name={`${projectOfficer}.firstname`} />
                        <TextInput label="Name" type="text" name={`${projectOfficer}.lastname`} />
                        <TextInput label="Telefon" type="text" name={`${projectOfficer}.telefon`} />
                        <TextInput label="E-Mail" type="text" name={`${projectOfficer}.emai`} />
                      </FormGroup>
                      {/* DELETE BUTTON */}
                      <Button
                        name="delete-projectofficer"
                        type="button"
                        onClick={() => fields.remove(index)}
                        style={{ marginTop: '10px' }}
                        theme="danger"
                        height="40px"
                        icon="trash"
                        text="Projektleiter löschen"
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </FieldArray>
        </TabBody>

        {/* BANKDATEN */}
        <TabBody>
          <FormGroup>
            <TextInput label="Bankname" type="text" name="bankname" />
            <TextInput label="BIC / SWIFT" type="text" name="bic" />
          </FormGroup>
          <FormGroup>
            <TextInput label="IBAN" type="text" name="iban" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Kontonummer" type="text" name="kontonr" />
            <TextInput label="BLZ" type="text" name="blz" />
          </FormGroup>
        </TabBody>

        {/* STEUERN */}
        <TabBody>
          <FormGroup>
            <TextInput label="Umsatzsteuer-Id" type="text" name="umsatzsteuerid" />
            <TextInput label="Steuernummer" type="text" name="steuernr" />
          </FormGroup>
          <FormGroup>
            <TextInput label="EU-Steuernummer" type="text" name="eusteuernr" />
            <TextInput label="Amtsgericht" type="text" name="amtsgericht" />
          </FormGroup>
          <FormGroup>
            <TextInput label="Handlesregister" type="text" name="handelsregister" />
          </FormGroup>
        </TabBody>

        {/* NOTIZEN */}
        <TabBody>
          <FormGroup>
            <TextArea label="Notizen" type="text" name="notizen" />
          </FormGroup>
        </TabBody>
      </TabBodys>
    </Tabs>

    <Button
      name="submit-button"
      style={{ marginTop: '20px' }}
      theme="success"
      height="40px"
      icon="save"
      text={submitButtonText}
    />
  </form>
);

// FORM CONFIG
export default (props) => {
  const { data, submitButtonText, mutation } = props;
  const { html, focused, completed, date } = data;

  const initialValues = React.useMemo(
    () => ({
      html,
      focused,
      completed,
      date,
    }),
    [data]
  );

  return withFinalForm(
    {
      initialValues,
      validator,
      submitButtonText,
      allowedAttributes,
      // attributesFilter: {
      //   plz: (value) => !value,
      // },
      // valuesFormat: {
      //   plz: (value) => Number(value),
      //   projectOfficers: (value) =>
      //     value.map(({ firstname, lastname, email, telefon }) => ({
      //       firstname,
      //       lastname,
      //       telefon,
      //       email,
      //     })),
      // },
      submitHandler: (values) => mutation(values),
    },
    FormCustomer
  );
};
