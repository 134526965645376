import React, { useState, useContext, useEffect } from 'react';
import gql from 'graphql-tag';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { NotificationManager } from 'react-notifications';
import { FaLock } from 'react-icons/fa';
import styled from 'styled-components';
import axios from 'axios';

import ErrorGraphQL from '../../../../errors/ErrorGraphQL';
import ButtonBox from '../../../atoms/ButtonBox';
import Button from '../../../atoms/Button';
import createMutationHandler from '../../../shared/create-mutationhandler';
import getPermissions from '../../../shared/get-permissions';
import AppContext from '../../../../context/App.Context';
import { clientUrl, timetrackDocumentURL } from '../../../../config';

import { ContentContainer, OptionsContainer } from '../../../shared/styles';
import ButtonBoxURL from '../../../atoms/ButtonBoxURL';
import ButtonBoxModal from '../../../atoms/ButtonBoxModal';
import TimeList from './TimeList.Types';
import SignaturePad from '../../../molecules/SignaturePad';
import ViewTitle from '../../../atoms/ViewTitel';
import Loading from '../../../molecules/Loading';
import LockedTimeList from './LockedTimeList';

const QUERY = gql`
  query GetTimetrack($where: TimetrackWhereInput) {
    timetrack(where: $where) {
      timetrackId
      timetrackType
      name
      year
      month
      random
      isLocked
      isHidden
      isManagerSigned
      isFreelancerSigned
      signMailSend
      signMailSendTo
      tracks {
        day
        start
        end
        lunchBreak
        activity
        location
      }
      createdAt
      updatedAt
      provision
      wage
      notes
      manager {
        projectOfficerId
        firstname
        lastname
      }
      customer {
        customerId
        typ
        firmenname
        vorname
        name
        email
      }
      owner {
        userId
        firstname
        lastname
      }
      createdBy {
        firstname
        lastname
      }
      updatedBy {
        firstname
        lastname
      }
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateTimetrack($where: TimetrackWhereUpdateInput, $data: TimetrackUpdateInput) {
    updateTimetrack(where: $where, data: $data) {
      __typename
      ... on Timetrack {
        timetrackId
        name
      }
      ... on InvalidData {
        message
        errors {
          property
          message
        }
      }
      ... on Unauthorized {
        message
      }
    }
  }
`;

const Label = styled.div`
  font-weight: bold;
  margin-right: 5px;
`;

const TimetrackerList = ({ match, history }) => {
  const { sessionUser } = useContext(AppContext);
  const [email, setEmail] = useState({
    to: '',
    subject: '',
    emailBody: '',
  });

  const { loading: queryLoading, error: queryError, data: queryData = {} } = useQuery(QUERY, {
    variables: { where: { timetrackId: match.params.id } },
    fetchPolicy: 'network-only',
  });

  const [updateMutation, { error: updateError, loading: updateLoading }] = useMutation(UPDATE_MUTATION, {
    updateQuery: UPDATE_MUTATION,
    refetchQueries: () => [
      {
        query: QUERY,
        variables: {
          where: { timetrackId: match.params.id },
        },
        v: Math.random(),
      },
    ],
  });

  const { CAN_CREATE_TIMETRACKS } = getPermissions(sessionUser.roles);

  const updateEmailByKey = (key) => (value) => {
    setEmail((prevState) => ({ ...prevState, [key]: value }));
  };

  let signaturePadData = {};
  const { timetrack = {} } = queryData;
  const { customer = {}, owner = {}, signMailSend } = timetrack;
  const { tracks = [] } = timetrack;

  useEffect(() => {
    setEmail({
      to: customer.email,
      subject: `Netcorn - Zeiterfassung - ${timetrack.year}-${String(timetrack.month).padStart(2, '0')} - ${
        owner.firstname
      } ${owner.lastname}`,
      emailBody: `Hallo!

Bitte besuchen Sie den folgenden Link, um die Zeiten von
${owner.firstname} ${owner.lastname} für den Zeitraum ${timetrack.year}-${String(timetrack.month).padStart(
        2,
        '0'
      )} zu unterschreiben:

${clientUrl}/signature/${timetrack.timetrackId}/${timetrack.random}

`,
    });
  }, [customer]);

  const updateMutationHandler = (mutation) => {
    const mutationUpdate = createMutationHandler({
      mutation,
      mutationName: 'updateTimetrack',
      mutationReturnTypes: {
        Timetrack: {
          successMessage: (data) => `Zeiterfassung mit der ID ${data.timetrackId} erfolgreich verändert`,
          onSuccess: () => history.push('/timetracks'),
        },
      },
      serverErrorMessage: 'Änderung der Zeiterfassung nicht erfolgreich',
    });

    return (values) =>
      mutationUpdate({
        variables: {
          where: { timetrackId: match.params.id },
          data: {
            tracks: values,
          },
        },
      });
  };

  // ----------------------------------------- DB - GRAPHQL - API -----------------------------------------
  const handleSignOkButtoModalClick = () => {
    const base64Signature = signaturePadData.toDataURL('image/png');
    const signatureSize = base64Signature.length;
    // first condition if small field is empty
    // second condition after or, if signature canvas is bigger
    if (signatureSize === 1134 || signatureSize === 1986) {
      NotificationManager.error('Unterschriftfeld darf nicht leer sein');
      return;
    }

    updateMutation({
      variables: {
        where: { timetrackId: match.params.id },
        data: {
          freelancerSignature: base64Signature,
        },
      },
    })
      .then(() => NotificationManager.success('Unterschrift erfolgreich gespeichert'))
      .catch((err) => NotificationManager.error('Unterschrift nicht erfolgreich'));
  };

  const handleSignDeleteButton = () => {
    signaturePadData.clear();
  };

  const handleEmailSend = () => {
    // url={`${timetrackDocumentURL}/${row.timetrackId}/pdf`}
    axios({
      url: `${timetrackDocumentURL}/${timetrack.timetrackId}/url-email`,
      method: 'POST',
      data: email,
      // to send cookie for authentication
      withCredentials: true,
    })
      .then(({ status, data }) => {
        if (status === 200) {
          NotificationManager.success(data.message, 'Erfolg');
        }
      })
      .catch((error) => {
        NotificationManager.error('Bitte wenden Sie sich an ihren Administrator', 'Server Fehler');
      });
  };
  // ----------------------------------------- DB - GRAPHQL - API -----------------------------------------

  // muss, denn sonst gibt es undefined error weil beim ersten render ist dann vieles undefined
  // also warten bis daten von DB da sind
  if (queryLoading) {
    return <Loading />;
  }

  return (
    <fieldset disabled={updateLoading}>
      <ViewTitle>Zeiterfassung</ViewTitle>
      {/* ------------------------------ SCHREIBGESCHÜTZT ------------------------------ */}
      {timetrack.isLocked && (
        <ContentContainer>
          <LockedTimeList />
        </ContentContainer>
      )}
      {/* ------------------------------ SCHREIBGESCHÜTZT ------------------------------ */}
      <OptionsContainer>
        <ButtonBox theme="primary" icon="arrow-left" text="Zurück" onClick={() => history.goBack()} />

        {/* ZEITERFASSUNGS-MANAGER-UNTERSCHREIBEN-BUTTON */}

        {!timetrack.isManagerSigned && (
          <ButtonBoxURL
            marginLeft="5px"
            theme="primary"
            icon="signature"
            text="Manager Unterschrift"
            href={`${clientUrl}/signature/${timetrack.timetrackId}/${timetrack.random}`}
          />
        )}
        {/* ZEITERFASSUNGS-MANAGER-UNTERSCHREIBEN-BUTTON */}

        {!timetrack.isFreelancerSigned && (
          <ButtonBoxModal
            marginLeft="5px"
            theme="primary"
            icon="signature"
            text="Zeiterfassung unterschreiben"
            header="Zeiterfassung unterschreiben"
            message={
              <div>
                <SignaturePad signData={(ref) => (signaturePadData = ref)} />
                <Button theme="danger" text="Unterschrift löschen" onClick={handleSignDeleteButton} />
              </div>
            }
            applyFunction={handleSignOkButtoModalClick}
          />
        )}
        {CAN_CREATE_TIMETRACKS && !signMailSend && (
          <ButtonBoxModal
            marginLeft="5px"
            maxWidth="300px"
            theme="primary"
            icon="envelope"
            text="Zeiterfassung-Unterschrift-URL senden"
            header="Zeiterfassung-Unterschrift-URL senden"
            hideApplyButton={() => email.to.trim().length < 1 || !email.to.includes('@') || !email.to.includes('.')}
            applyFunction={handleEmailSend}
            message={
              <div>
                <Label style={{ marginTop: '20px' }}>An:</Label>
                <input
                  style={{
                    width: '100%',
                    fontSize: '16px',
                    borderRadius: '0px',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                  }}
                  value={email.to}
                  onChange={(event) => updateEmailByKey('to')(event.target.value)}
                />
                <Label style={{ marginTop: '20px' }}>Betreff</Label>
                {email.subject}
                <Label style={{ marginTop: '20px' }}>Nachricht</Label>
                <pre
                  style={{
                    fontFamily:
                      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                    fontSize: '16px',
                  }}
                >
                  {email.emailBody}
                </pre>
              </div>
            }
          />
        )}
      </OptionsContainer>
      {/* ZEITERFASSUNGS-UNTERSCHREIBEN-BUTTON */}

      <ErrorGraphQL error={queryError} />
      <ErrorGraphQL error={updateError} />
      <TimeList
        submitButtonText="Zeiterfassung ändern"
        timetrack={timetrack}
        tracks={tracks}
        submitHandler={updateMutationHandler(updateMutation)}
      />
    </fieldset>
  );
};

export default TimetrackerList;
