import React from 'react';

export default function KundeModal(cell, row) {
  const customer = row;
  return (
    <div name="column-customer-name">
      {customer.typ === 'Firma' ? (
        <div>
          <b>{`${customer.firmenname}`}</b> <br />
          {`${customer.anrede} ${customer.vorname} ${customer.name}`} <br />
        </div>
      ) : (
        <div>
          {`${customer.anrede} ${customer.vorname} ${customer.name}`} <br />
        </div>
      )}
    </div>
  );
}
