import moment from 'moment';
import 'moment/locale/de';

export const calculateNettoTime = ({ startTime, endTime, breakTime }) => {
  if (!startTime && !endTime) {
    return 0;
  }

  const start = moment(startTime, 'HH:mm');
  const end = moment(endTime, 'HH:mm');
  const duration = moment.duration(end.diff(start));

  // subtract the lunch break
  duration.subtract(Number(breakTime), 'minutes');

  // +"1" => 1  + creates a nummerical representation
  const result = moment.utc(+duration).format('H:mm');
  return result === 'Invalid date' ? 0 : result;
};

export const createViewData = ({ year, month, day, timeTrackerArray = [] }) => {
  const timeTrackerObject = timeTrackerArray.reduce(
    (accu, timeTrack) => ({
      ...accu,
      [timeTrack.day]: timeTrack,
    }),
    {}
  );

  const timeInstance = moment(`${year} ${month} ${day}`, 'YYYY MM DD');
  const hasDay = timeTrackerObject[day] && timeTrackerObject[day].active;

  const date = timeInstance.format('DD.MM.YYYY');
  const weekDayName = timeInstance.format('dddd');
  const isWeekend = !!(weekDayName === 'Samstag' || weekDayName === 'Sonntag');
  const weekDay = weekDayName.substring(0, 2);

  // was soll in input angezeigt werden und wann soll es angezeigt werden
  const startTime = hasDay ? timeTrackerObject[day].start : '';
  const endTime = hasDay ? timeTrackerObject[day].end : '';
  const breakTime = hasDay ? timeTrackerObject[day].lunchBreak : '';
  const activityName = hasDay ? timeTrackerObject[day].activity : '';
  const locationName = hasDay ? timeTrackerObject[day].location : '';

  return {
    nettoTime: hasDay
      ? calculateNettoTime({
          startTime,
          endTime: timeTrackerObject[day].end,
          breakTime: timeTrackerObject[day].lunchBreak,
        })
      : '0',
    startTime,
    endTime,
    breakTime,
    date,
    hasDay,
    weekDay,
    isWeekend,
    lunchBreak: breakTime,
    activity: activityName,
    location: locationName,
  };
};

// hat potential aktivität je nach wunsch zu definieren
// ---------------------------------- FULL MONTH ----------------------------------
export const getWorkingDays = (tracks) => Object.keys(tracks.filter((track) => track.active)).length;

export const getWorkingHours = (tracks) => {
  // Minutes Array
  const workedMinutesOfDays = Object.values(tracks).map((day) => {
    const { start, end, lunchBreak } = day;
    const nettoTimeOfDay = calculateNettoTime({
      startTime: start,
      endTime: end,
      breakTime: lunchBreak,
    });
    const workedMinute = moment.duration(moment(nettoTimeOfDay, 'HH:mm').format('H:mm')).asMinutes();
    return workedMinute;
  });
  const workedMinutes = workedMinutesOfDays.reduce((accu, minute) => accu + Number(minute), 0);
  return (workedMinutes / 60).toFixed(2).replace('.', ',');
};

// ---------------------------------- DYNAMIC MONTH ----------------------------------
export const getDynamicWorkingDays = (tracks) => {
  const workingDays = tracks.map((track) => track.day);
  const daysSet = new Set(workingDays);

  return daysSet.size;
};