/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';
import { FaLock, FaUnlock } from 'react-icons/fa';
import { ImEyeBlocked, ImEye, ImClock, ImClock2 } from 'react-icons/im';
import { CgDuplicate } from 'react-icons/cg';
import { Tooltip as Tippy } from 'react-tippy';
import Select from 'react-select';
import Modal from '../../../molecules/Modal';

const ActionsStyle = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  /* pointer-events: none; */
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  color: ${(props) => (props.disabled ? 'grey' : '#185992')};
  background-color: ${(props) => (props.disabled ? 'lightgrey' : '#a0d1ff')};

  user-select: none;
  margin: 5px;
  height: 30px;
  width: 30px;
  font-size: ${(props) => props.fontSize || '15px'};

  &:hover {
    background-color: #3f8ae0;
    color: white;
  }
`;

const ToolTipp = ({ children, title }) => (
  <Tippy arrow="true" title={title} position="right" trigger="mouseenter">
    {children}
  </Tippy>
);

const RowsActions = ({
  onLock,
  onSign,
  onDelete,
  handleDuplicate,
  handleHide,
  handleVisible,
  handleLock,
  handleUnlock,
  handleCopyTrack,
  handlePasteTrack,
  selectedItems,
  onUserSelect,
  users,
  data,
}) => (
  <ActionsStyle>
    <ToolTipp title="Verstecken vor Leistungsbringer">
      <Action fontSize="20px" onClick={handleHide} disabled={selectedItems.length === 0}>
        <ImEyeBlocked />
      </Action>
    </ToolTipp>
    <ToolTipp title="Anzeigen für Leistungsbringer">
      <Action fontSize="20px" onClick={handleVisible} disabled={selectedItems.length === 0}>
        <ImEye />
      </Action>
    </ToolTipp>
    <ToolTipp title="Sperren für Leistungsbringer">
      <Action fontSize="20px" onClick={handleLock} disabled={selectedItems.length === 0}>
        <FaLock />
      </Action>
    </ToolTipp>
    <ToolTipp title="Freigeben für Leistungsbringer">
      <Action fontSize="20px" onClick={handleUnlock} disabled={selectedItems.length === 0}>
        <FaUnlock />
      </Action>
    </ToolTipp>
    <ToolTipp title="Zeit kopieren">
      <Action fontSize="20px" onClick={handleCopyTrack} disabled={!(selectedItems.length === 1)}>
        <ImClock2 />
      </Action>
    </ToolTipp>
    <ToolTipp title="Zeit einfügen">
      <Action fontSize="20px" onClick={handlePasteTrack} disabled={selectedItems.length === 0}>
        <ImClock />
      </Action>
    </ToolTipp>
    <Modal
      disabled={selectedItems.length === 0}
      theme="info"
      header="Zeiterfassung duplizieren für..."
      message={
        <div>
          <div>Bitte wähle alle Leistungsbringer für Zeiterfassungsduplikation</div>
          <Select
            isMulti
            options={users.map((user) => ({
              value: user.userId,
              label: `${user.firstname} ${user.lastname}`,
            }))}
            styles={{
              container: (provided) => ({
                ...provided,
                // width: '100%',
                width: '500px',
              }),
              control: (provided) => ({
                ...provided,
                borderRadius: 0,
              }),
              menu: (provided) => ({
                ...provided,
                borderRadius: 0,
                marginTop: '2px',
              }),
            }}
            onChange={(val) => {
              onUserSelect(val);
            }}
          />
        </div>
      }
      applyFunction={handleDuplicate}
    >
      <ToolTipp title="Zeiterfassung duplizieren">
        <Action fontSize="20px" disabled={selectedItems.length === 0}>
          <CgDuplicate />
        </Action>
      </ToolTipp>
    </Modal>
  </ActionsStyle>
);

export default RowsActions;
