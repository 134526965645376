import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

const FormItem = styled.div`
  flex-grow: ${({ flexGrow }) => flexGrow || '1'};
  flex-shrink: 0;
  flex-basis: ${({ flexBasis }) => flexBasis || '180px'};

  margin: 10px;
`;

export default props => <FormItem {...props}>{props.children}</FormItem>;
