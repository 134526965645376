import React from 'react';
import moment from 'moment';
import FontAwesome from 'react-fontawesome';

const DateColumn = (key) => (cell, row) => {
  const date = row[key];
  return (
    <div>
      <FontAwesome name="calendar" />
      &nbsp; {moment(date).format('DD.MM.YYYY')} <br />
      <FontAwesome name="clock-o" />
      &nbsp; {moment(date).format('HH:mm')} Uhr <br />
    </div>
  );
};

export default DateColumn;
