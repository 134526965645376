import React from 'react';
import { TiWarning, TiRefresh, TiHome } from 'react-icons/ti'
// import history from '../../app-history';

// import PropTypes from 'prop-types';
// import CustomersTable from '../../organisms/Table.Customers';
// import SearchBox from '../../molecules/SearchBox';
// import Loading from '../../molecules/Loading';
// import selectCustomerTypes from './select-customer-types';
// import parseQueryString from '../../../lib/parse-query-string';
// import ButtonBox from '../../atoms/ButtonBox';
// import SearchHeadlineCustomers from '../../molecules/Search.Headline.Customers';
//
// import { Query } from 'react-apollo';
// import { GET_CUSTOMERS } from '../../../graphQL/queries/customer';
// import searchHeadline from './search-headline';
//
// const date = new Date();
// const thisYear = date.getFullYear().toString();
// const thisMonth = (date.getMonth() + 1).toString();
// const dateStringLatest = `${date.getFullYear()}-${date.getMonth() + 1}`;
// const DEFAULT_PATH = `/kunden/?updatedAt=${dateStringLatest}`;
import styled from 'styled-components';
// import AppContext from '../../context/App.Context';

// import PropTypes from 'prop-types';

const ErrorStyles = styled.div`
  padding: 2rem;
  background: white;
  margin: 2rem 0;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 15px solid red;
  p {
    margin: 0;
    font-weight: 100;
  }
  strong {
    margin-right: 1rem;
  }
`;

const ErrorStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: red;
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
`;

const StyledRedirect = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c5e6fd;
  padding: 10px;

  :hover {
    background-color: lightblue;
  }
`

// const ErrorGraphQL = ({ error }) => {
const ErrorNoData = ({ refreshUrl, homeUrl }) => {
 
  return (
    <>
      <ErrorStyle>
      <TiWarning style={{ marginRight: '5px' }} />
      <div> Daten konnten nicht geladen werden</div>
      </ErrorStyle>
      <div style={{
        marginTop: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}>
        <StyledRedirect onClick={() => window.location.href = refreshUrl}>
          <TiRefresh size="25px" style={{ marginRight: '5px' }} />
          <div>Nochmals versuchen</div>
        </StyledRedirect>

        <StyledRedirect onClick={() => window.location.href = homeUrl}>
          <TiHome size="20px" style={{ marginRight: '5px' }} />
          <div>Zur Startseite</div>
        </StyledRedirect>
      </div>
    </>
  );
};

export default ErrorNoData;
