import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const MetaInfos = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Labels = styled.div`
  text-align: right;
`;

const Label = styled.div`
  font-weight: bold;
  margin-right: 7px;
  width: auto;
`;

const Group = styled.div`
  display: flex;
`;

const Values = styled.div``;

const Value = styled.div`
  margin-right: 7px;
`;

const MetaInfo = ({ children = [] }) => (
  <>
    <MetaInfos>
      {children.map((items, index) => (
        <Group key={index}>
          <Labels>
            {items.map((row, index) => (
              <Label key={index}>{row[0]}</Label>
            ))}
          </Labels>
          <Values>
            {items.map((row, index) => (
              <Value key={index}>
                {row[0].trim() === 'Erstellt am:' || row[0].trim() === 'Geändert am:'
                  ? moment(row[1]) //
                      .format('DD.MM.YYYY [um] HH:mm [Uhr]')
                  : row[1] || 'Keine Angabe'}
              </Value>
            ))}
          </Values>
        </Group>
      ))}
    </MetaInfos>
  </>
);

export default MetaInfo;
