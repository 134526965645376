import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faTable, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
// import Tooltip from 'rc-tooltip';
// import {} from 'react-icons';

import { Tooltip } from 'react-tippy';

const StyledLink = styled(Link)`
  color: grey;
  margin-right: 10px;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    color: #89cff0;
  }
`;

const StyledExternalLink = styled.a`
  color: grey;
  margin-right: 10px;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    color: #89cff0;
  }
`;

const UrlButton = ({ icon, url = '', toolTip, external = false, text }) => {
  const iconSvg = () => {
    switch (icon) {
      case 'edit':
        return faEdit;

      case 'delete':
        return faTrash;

      case 'table':
        return faTable;

      case 'pdf':
        return faFilePdf;

      default:
        break;
    }
  };

  // {/* <a href={`${billDocumentURL}/${billId}/pdf`}

  // { external
  //           ?
  //           :
  //         } */}

  return (
    <>
      {external ? (
        <StyledExternalLink href={url}>
          <div style={{ display: 'flex' }}>
            <Tooltip
              // options
              arrow="true"
              title={toolTip}
              position="left"
              trigger="mouseenter"
            >
              <FontAwesomeIcon size="1x" icon={iconSvg()} />
            </Tooltip>
            {text && <div style={{ marginLeft: '5px' }}>{text}</div>}
          </div>
        </StyledExternalLink>
      ) : (
        <StyledLink to={url}>
          <div style={{ display: 'flex' }}>
            <Tooltip
              // options
              arrow="true"
              title={toolTip}
              position="left"
              trigger="mouseenter"
            >
              <FontAwesomeIcon size="1x" icon={iconSvg()} />
            </Tooltip>
            {text && <div style={{ marginLeft: '10px' }}>{text}</div>}
          </div>
        </StyledLink>
      )}
    </>
  );
};

export default UrlButton;
