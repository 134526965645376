import React from 'react';
import styled from 'styled-components';

const FetchConditions = styled.div`
  color: #656464;
  font-style: italic;
`;

const TextStyle = styled.div`
  color: #656464;
  margin: 0px 10px 0px 10px;
`;

const mapQueryStringsToMessage = (mapping, queryStrings = {}) => (
  <div style={{ display: 'flex' }}>
    {Object.entries(queryStrings)
      .reduce((accu, [key, value], index) => {
        if (value) {
          return [
            ...accu,
            <FetchConditions key={index}>{mapping[key](value)}</FetchConditions>,
            <TextStyle key={index + Math.random()}>und</TextStyle>,
          ];
        }

        return accu;
      }, [])
      .slice(0, -1)}
  </div>
);

export default mapQueryStringsToMessage;
