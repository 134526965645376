import styled from 'styled-components';

export const Search = styled.input`
  font-size: 16px;
  border-radius: 0px;
  border-style: solid;
  border-color: #c5c5c5;
  border-width: 1px;
  padding: 5px;
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const TableHead = styled.thead``;

export const TableBody = styled.tbody``;

export const Headers = styled.tr`
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
`;

export const Header = styled.th`
  user-select: none;
  display: table-cell;
  /* padding: 16px; */
  padding: 10px;
  font-size: 14px;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;

  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.5rem;
`;

export const DataRows = styled.tr`
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
`;

export const DataRow = styled.td`
  display: table-cell;
  /* padding: 5px; */
  padding: 10px;
  font-size: 14px;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.4;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
  color: rgba(0, 0, 0, 0.87);
  border-collapse: collapse;
`;
