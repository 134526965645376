import React from 'react';
// import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { FaEnvelope } from 'react-icons/fa';
import styled from 'styled-components';

import { FieldArray } from 'react-final-form-arrays';

import { Tabs, TabHeaders, TabBodys, TabBody, Tab } from '../../molecules/Tabs';

import FormGroup from '../../atoms/FormGroup';
import Button from '../../atoms/Button';
import TextInput from '../../molecules/TextInput';
import TextArea from '../../molecules/TextArea';
// import SelectField from '../../molecules/SelectField';
import SelectInput from '../../molecules/SelectInput';

import ToggleButton from '../../molecules/ToggleButton';
import ToggleTab from '../../molecules/ToggleTab';

import ProjectElements from './ProjectElements.Form';

import withFinalForm from '../../HOC/with-final-form';
import allowedAttributes from './allowed-attributes';
import validator from './validator';

const FormGrid = styled.div`
  @media screen and (min-width: 760px) {
    display: grid;
    /* pro template area größe */
    /* grid-template-columns: 2fr 1fr; */
    grid-template-columns: 1fr 550px;
    grid-template-areas: 'html form';
    grid-column-gap: 10px;
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'html'
    'form';
`;

const EmailHeader = styled.div`
  display: flex;
  padding: 10px 10px 5px 10px;
  justify-content: space-between;
`;

const ContentArea = styled.div`
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.12);
`;

// ----------------------------- GRAPH-QL -----------------------------

//
//
//
//
// ----------------------------- GRAPH-QL -----------------------------

// ----------------------------- HELPERS -----------------------------
const getEmployeesOptionsBySelectedRecruiter = (recruiterList, selectedRecruiterId) => {
  const selectedRecruiter = recruiterList.find((recruiter) => recruiter.id === selectedRecruiterId) || {};

  const recruiterEmployees =
    selectedRecruiter.employees && selectedRecruiter.employees.length > 0 && selectedRecruiter.employees[0]
      ? selectedRecruiter.employees
      : [];

  return recruiterEmployees.map((employee) => ({
    value: employee.id,
    label: `${employee.firstname} ${employee.lastname}`,
  }));
};

const getNewArtificalId = () => new Date().valueOf() + Math.random();

// ----------------------------- HELPERS -----------------------------

const FormCustomer = ({ values, handleSubmit, submitButtonText, dirty, form }) => (
  <form onSubmit={handleSubmit}>
    {/* <pre>DOES FORM CHANGE/ is dirty?: {JSON.stringify(dirty, null, '  ')}</pre> */}
    {/* <pre>{JSON.stringify(values, null, '  ')}</pre> */}
    <FormGrid>
      <ContentArea>
        <EmailHeader>
          <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right', flex: 0 }}>
            <div style={{ fontSize: '20px', fontWeight: 'bold', marginRight: '10px', marginBottom: '10px' }}>Von:</div>
            <div style={{ fontSize: '20px', fontWeight: 'bold', marginRight: '10px' }}>Betreff:</div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div style={{ fontSize: '20px', marginRight: '10px', marginBottom: '10px' }}>{values.from}</div>
            <div style={{ fontSize: '20px', marginRight: '10px' }}>{values.subject}</div>
          </div>
          {/* <div style={{ display: 'flex' }}>
            <div style={{ fontSize: '20px', fontWeight: 'bold', marginRight: '10px' }}>Betreff:</div>
            <div style={{ fontSize: '20px' }}>info@gulp.de</div>
          </div> */}
        </EmailHeader>
        <hr />
        <div
          style={{
            marginTop: '20px',
            marginLeft: '10px',
            width: '100%',
            // zoom: '0.5'
          }}
          dangerouslySetInnerHTML={{ __html: values.html }}
        />
      </ContentArea>

      <ContentArea>
        <Button
          name="submit-button"
          style={{ marginTop: '20px' }}
          theme="success"
          height="40px"
          icon="save"
          text="speichern"
        />
        <hr />
        <FormGroup style={{ flexDirection: 'column', margin: '0px' }}>
          <ToggleButton name="focused" onMessage="Projekt fokussiert" offMessage="Projekt nicht fokussiert" />
        </FormGroup>
        <hr />
        <FormGroup>
          <SelectInput
            label="Recruting-Firma"
            name="recruiter"
            options={values.recruiterOptions}
            // placeholder="Recruiter wählen..."
            noOptionsMessage="Keine Recruiter gefunden"
            onChange={() => form.change('employee', { value: '', label: '' })}
          />
          <SelectInput
            // isDisabled={(values.recruiter && !values.recruiter.value) || !values.recruiter.employees}
            label="Recruting-Angestellter"
            name="employee"
            options={getEmployeesOptionsBySelectedRecruiter(values.recruiterList, values.recruiter.value)}
            placeholder=""
            noOptionsMessage="Keine Recruiter gefunden"
          />
        </FormGroup>

        <hr />

        <ProjectElements projectElements={values.projectElements} />
      </ContentArea>
    </FormGrid>
  </form>
);

// FORM CONFIG
export default (props) => {
  const { data = {}, submitButtonText, mutation, recruiterList = [] } = props;
  const { html, focused, completed, date, from, subject, projectElements = [] } = data;
  const recruiter = data.recruiter || {};
  const employee = data.employee || {};

  const initialValues = React.useMemo(
    () => ({
      html,
      focused,
      completed,
      date,
      from,
      subject,
      recruiter: recruiter.name
        ? {
            label: recruiter.name,
            value: recruiter.id,
          }
        : {
            label: '',
            value: '',
          },
      employee:
        employee.firstname || employee.lastname
          ? {
              label: `${employee.firstname} ${employee.lastname}`,
              value: employee.id,
            }
          : {
              label: '',
              value: '',
            },
      projectElements: !projectElements[0] //
        ? [{ artificalId: getNewArtificalId(), description: '' }]
        : projectElements.map((projectElement) => ({
            ...projectElement,
            // projectStart: projectElements.projectStart ? new Date(projectElements.projectStart) : new Date(),
            // projectEnd: projectElements.projectEnd ? new Date(projectElements.projectEnd) : '',
          })),
      // -------------------- OPTIONS --------------------
      recruiterList, // needed for relational select to be able to select child if parent is selected
      recruiterOptions: recruiterList.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
      // [
      //   { label: 'FOO', value: 1 },
      //   { label: 'Bar', value: 2 },
      //   { label: 'Zap', value: 3 },
      // ],
      // recruterEmployees: employees.map((employee) => ({
      //   label: `${employee.firstname} ${employee.lastname}`,
      //   value: employee.firstname,
      // })),
    }),
    [data]
  );

  return withFinalForm(
    {
      initialValues,
      validator,
      submitButtonText,
      allowedAttributes,
      // attributesFilter: {
      //   plz: (value) => !value,
      // },
      valuesFormat: {
        recruiter: ({ value }) => value,
        employee: ({ value }) => value,
        //   plz: (value) => Number(value),
        //   projectOfficers: (value) =>
        //     value.map(({ firstname, lastname, email, telefon }) => ({
        //       firstname,
        //       lastname,
        //       telefon,
        //       email,
        //     })),
      },
      submitHandler: (values) => mutation(values),
    },
    FormCustomer
  );
};
