import React from 'react';
// import PropTypes from 'prop-types';
import calculatePositions from './calculate-positions';
import CentToEuro from '../../atoms/CentToEuro';

const titleNettoStyle = {
  fontWeight: 'bold',
};

const titleBruttoStyle = {
  fontSize: '17px',
  fontWeight: 'bold',
};

const contentNettoStyle = {
  textAlign: 'right',
  marginRight: '10px',
  width: '120px',
};

const contentBruttoStyle = {
  fontSize: '17px',
  textAlign: 'right',
  marginRight: '10px',
  width: '120px',
};

const CalculationFooter = ({ positions }) => {
  const { nettobetrag, ust7, ust19, rechnungsbetrag } = calculatePositions(positions);

  return (
    <div style={{ marginTop: '15px' }}>
      {/* UNTERE RECHNUNGSBOX */}
      <ul
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          listStyleType: 'none',
          // marginRight: '5px',
        }}
      >
        <li style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={titleNettoStyle}>Nettobetrag</span>
          <div style={contentNettoStyle}>
            <CentToEuro value={nettobetrag} />
          </div>
        </li>

        <li style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={titleNettoStyle}>+ 7% Umsatzsteuer</span>
          <div style={contentNettoStyle}>
            <CentToEuro value={ust7} />
          </div>
        </li>

        <li style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={titleNettoStyle}>+ 19% Umsatzsteuer</span>
          <div style={contentNettoStyle}>
            <CentToEuro value={ust19} />
          </div>
        </li>

        <li>
          <hr
            style={{
              height: '2px',
              backgroundColor: 'black',
              width: '280px',
              marginTop: '3px',
              marginBottom: '3px',
            }}
          />
        </li>

        <li style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={titleBruttoStyle}>Rechnungsbetrag</span>
          <div style={contentBruttoStyle}>
            <CentToEuro value={rechnungsbetrag} />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default CalculationFooter;
