import React from 'react';
import styled from 'styled-components';

import { FaFileCsv } from 'react-icons/fa';

const UploadFileStyle = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  border-radius: 0px;
  min-width: 90px;
  max-width: 90px;
  min-height: 90px;
  max-height: 90px;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  padding-top: 10px;
  cursor: pointer;
  &:hover {
    filter: brightness(90%);
  }
`;

const OpenFilesButton = ({ onChange }) => (
  <>
    <UploadFileStyle htmlFor="select-files">
      <div>
        <FaFileCsv size="30px" />
      </div>
      <div>Transaktionen importieren</div>
    </UploadFileStyle>
    <input
      type="file"
      multiple
      accept="text/comma-separated-values"
      id="select-files"
      onChange={onChange}
      style={{ display: 'none' }}
    />
  </>
);

export default OpenFilesButton;
