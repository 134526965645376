const createInitialValues = ({ data, defaults = {} }) => {
  const nonExistingAttributesToAdd = Object.entries(defaults).reduce((accu, [key, value]) => {
    if (!data[key]) {
      return { ...accu, [key]: value };
    }
    return accu;
  }, {});

  return {
    ...nonExistingAttributesToAdd,
    ...Object.entries(data).reduce(
      (accu, [key, value]) => ({
        ...accu,
        [key]: value || defaults[key] || '',
      }),
      {}
    ),
  };
};

export default createInitialValues;
