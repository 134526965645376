const allowedAttributes = [
  'id',
  'name',
  'salutation',
  'firstname',
  'lastname',
  'street',
  'houseNo',
  'plz',
  'city',
  'bankname',
  'bic',
  'blz',
  'accountNo',
  'email',
  'fax',
  'telefon',
  'note',
];

export default allowedAttributes;
