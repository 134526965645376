import React, { useContext } from 'react';
import styled from 'styled-components';
import AppContext from '../../../context/App.Context';

const TitleStyle = styled.div`
  display: flex;
  font-size: 30px;
  font-weight: 500;
  color: #484c50;
`;

const ProtectComponent = ({ children, requiredPermissions }) => {
  const { sessionUser } = useContext(AppContext);

  const userPermissions = sessionUser.roles.reduce((accu, role) => {
    if (!role.permissions) {
      return accu;
    }
    return [...accu, ...role.permissions.map((permission) => permission.name)];
  }, []);

  const hasAllRequiredPermissions = requiredPermissions.every((requiredPermission) =>
    userPermissions.some((userPermission) => userPermission === requiredPermission)
  );

  return hasAllRequiredPermissions && children;
};

export default ProtectComponent;
