import React from 'react';
import styled from 'styled-components';
import { FaSave } from 'react-icons/fa';
import { getDynamicWorkingDays, getDynamicWorkingHours, getWorkingDays, getWorkingHours } from './time-tracker-utils';
// import { getWorkingDays as getDynamicWorkingDays } from './dynamic-time-tracker-utils';
import Box from '../../../atoms/Box';

const Footer = styled.div`
  cursor: pointer;
  user-select: none;
  z-index: 100;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: #f8f9fa;
`;

const MobileFooter = ({ tracks, onSubmit, type }) => (
  <>
    <Footer>
      <Box width="100%" style={{ padding: '10px' }} onClick={onSubmit}>
        <FaSave size="20px" />
        <b>Speichern</b>
      </Box>
      <Box width="100%" style={{ borderWidth: '1px 0px 1px 0px' }}>
        <b>Arbeitstage:</b>
        {type === 'FULL_MONTH' ? getWorkingDays(tracks) : getDynamicWorkingDays(tracks)}
      </Box>
      <Box width="100%">
        <b>Gesamtstunden:</b>
        {getWorkingHours(tracks)}
      </Box>
    </Footer>
  </>
);

export default MobileFooter;
