import React, { useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';

import { apiUrl } from '../../../config.js';
import { BodyStyle, SignLine, Input } from './style';
import Button from '../../atoms/Button';
import Loading from '../../molecules/Loading';
import SignatureTable from '../../molecules/SignatureTabel';
import ViewTitle from '../../atoms/ViewTitel/index.js';
import { ContentContainer } from '../../shared/styles.js';

const Signature = ({ location }) => {
  const [timetrack, setTimetrack] = useState({});
  const [loading, setLoading] = useState(true);
  const [sigPad, setSigPad] = useState({});
  const [signCity, setSignCity] = useState('');
  const [formIsNotValid, setFormIsNotValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [urlCorrect, setUrlCorrect] = useState(false);

  const isVerticalScreen = useMediaQuery({ maxDeviceWidth: 375 });

  const id = location.pathname.split('/')[2];
  const random = location.pathname.split('/')[3];

  const { timetrack: timetrackData, owner } = timetrack;

  // location.pathname:
  // /signature/1/22asdasd

  useEffect(() => {
    axios
      .get(`${apiUrl}/signatures/${id}/${random}`)
      .then((response) => {
        if (response.status === 202) {
          setLoading(false);
          return setUrlCorrect(false);
        }
        setLoading(false);
        setUrlCorrect(true);
        setTimetrack(response.data);
        // setTimetrack(response.data.timetrack);
      })
      .catch((error) => {
        setLoading(false);
        setUrlCorrect(false);
      });
  }, [id, random]);

  const handleSignaturClick = () => {
    if (signCity.trim().length === 0 || sigPad.toDataURL('image/png').length < 2277) {
      setFormIsNotValid(true);
      return null;
    }

    const config = {
      credentials: 'same-origin',
      withCredentials: true,
      url: `${apiUrl}/signatures/${id}`,
      method: 'post',
      data: {
        base64String: sigPad.toDataURL('image/png'),
        signCity,
      },
    };

    // send signature
    axios(config)
      .then((response) => {
        setSuccess(true);
      })
      .catch((error) => {
        alert('Es ist ein Fehler eingetretetn, bitte versuchen Sie es später wieder');
      });
  };

  const handleClear = () => {
    sigPad.clear();
  };

  if (loading) {
    return <Loading />;
  }

  if (!urlCorrect) {
    return <h1 style={{ color: 'red', margin: '20px' }}> URL nicht gültig</h1>;
  }

  if (success) {
    return <h2 style={{ margin: '20px' }}>Ihre Unterschrift wurde erfolgreich gesendet</h2>;
  }

  return (
    <BodyStyle>
      <ViewTitle>Bitte unterschreiben Sie die Zeiterfassung</ViewTitle>

      <SignatureTable data={timetrackData} owner={owner} />

      {!isVerticalScreen && (
        <ContentContainer>
          <div>
            <SignLine>Unterschrift</SignLine>
            <SignatureCanvas
              ref={(ref) => setSigPad(ref)}
              penColor="black"
              canvasProps={{
                width: 500,
                height: 130,
                className: 'sigCanvas',
                style: {
                  zIndex: 1000,
                  display: 'relative',
                  marginTop: '5px',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: 'grey',
                },
              }}
            />
          </div>
        </ContentContainer>
      )}

      {isVerticalScreen && (
        <ContentContainer>
          <div>
            <SignLine style={{ top: '80.6px', width: '310px' }}>Unterschrift</SignLine>
            <SignatureCanvas
              ref={(ref) => setSigPad(ref)}
              penColor="black"
              canvasProps={{
                width: 330,
                height: 85.8,
                className: 'sigCanvas',
                style: {
                  zIndex: 1000,
                  display: 'relative',
                  marginTop: '5px',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: 'grey',
                },
              }}
            />
          </div>
        </ContentContainer>
      )}

      <ViewTitle>Ort der Unterschrift</ViewTitle>
      <ContentContainer>
        <Input
          value={signCity}
          onChange={(event) => setSignCity(event.target.value)}
          placeholder=" Unterschriftsort eingeben..."
        />
      </ContentContainer>
      <Button
        text="Unterschrift löschen"
        style={{ marginTop: '10px', marginBottom: '20px' }}
        theme="danger"
        height="50px"
        icon="trash"
        onClick={handleClear}
      />
      {formIsNotValid && (
        <ContentContainer>
          <h4 style={{ color: 'red' }}>Bitte geben Sie den Ort der Unterschrift und die Unterschrift ein</h4>
        </ContentContainer>
      )}
      <Button
        text="Zeiterfassung mit Signatur bestätigen"
        style={{ marginTop: '10px' }}
        theme="success"
        height="50px"
        icon="save"
        onClick={handleSignaturClick}
      />
    </BodyStyle>
  );
};

export default withRouter(Signature);
