const getPermissions = (roles = []) => {
  const permissions = roles.reduce((accu, role) => {
    // wenn roles empty object ist
    if (!role.permissions) {
      return accu;
    }

    return [...accu, ...role.permissions.map(permission => permission.name)];
  }, []);

  const userPermissions = permissions.reduce((accu, permission) => ({ ...accu, [permission]: true }), {});

  return userPermissions;
};

export default getPermissions;
