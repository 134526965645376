import React from 'react';
import styled from 'styled-components';
// import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import Label from '../../atoms/Label';
import withField from '../../HOC/with-field';

const StyleContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 180px;
  /* margin: 10px; */
`;

// TODO: use styled componentsn
const errorMessageStyle = {
  display: 'flex',
  color: '#cc0000',
  marginTop: '10px',
  fontWeight: 'bold',
};

// TODO: use styled componentsn
const errorsIconStyle = {
  display: 'flex',
  color: '#cc0000',
  marginTop: '8px',
  marginRight: '3px',
};

const ErrorMessage = ({ message, 'data-testid': dataTestid }) => (
  <div>
    {typeof message === 'object' && (
      <div style={{ display: 'flex' }}>
        <ErrorIcon style={errorsIconStyle} />
        <div data-testid={`error-message-${dataTestid}`} style={errorMessageStyle}>
          {Object.values(message)[0]}
        </div>
      </div>
    )}

    {typeof message === 'string' && (
      <div style={{ display: 'flex' }}>
        <ErrorIcon style={errorsIconStyle} />
        <div data-testid={dataTestid} style={errorMessageStyle}>
          {message}
        </div>
      </div>
    )}
  </div>
);

const InputField = styled.input`
  width: 100%;
  padding: 9px;
  border-style: solid;
  font-size: 16px;
  border-width: ${(props) => (props.hasError ? '3px' : '1px')};
  border-color: ${(props) => (props.hasError ? '#cc0000' : '#BFBFBF')};
`;

const CustomInputComponent = ({
  input,
  meta,
  label,
  type,
  autoFocus,
  formatter,
  'data-testid': dataTestid,
  margin = '10px',
  placeholder = '',
}) => {
  // dirty: true when the value of the field is not equal to the initial value
  const {
    error,
    submitError,
    touched,
    // validating
    // dirty,
    // submitFailed,
  } = meta;

  return (
    <StyleContainer style={{ margin }}>
      {label && <Label text={label} name={`label-${input.name}`} />}
      <InputField
        {...input}
        placeholder={placeholder}
        data-testid={dataTestid}
        hasError={(error && touched) || submitError}
        autoFocus={autoFocus}
        onChange={(event) => {
          if (formatter) {
            return input.onChange(formatter(event.target.value));
          }
          input.onChange(event.target.value);
        }}
      />
      {error && touched && <ErrorMessage message={error} data-testid={`error-message-${dataTestid}`} />}
      <ErrorMessage message={submitError} />
      {/* {meta.validating && <Spinner />} */}
      {/* {validating && "CHECKING..."} */}
    </StyleContainer>
  );
};

export default withField(CustomInputComponent);
