const floatToEuro = (value) => `${(value / 100).toFixed(2).replace('.', ',')} €`;
// const floatToEuro = (value) => `${value / 100}`;

const calcTotal = (positions) => {
  const { total7, total19, totalNetto, totalBrutto } = positions.reduce(
    (accu, position) => {
      // erst wenn dass alles existiert mach was sonst 0
      // wenn eines davon nicht existiert würde es fehler geben
      if (position && position.category && position.category.value && position.amount) {
        const bruttoPos = position.amount;
        // berechne alles wenn 0%
        if (position.category.value.ust === 0) {
          return {
            ...accu,
            totalNetto: accu.totalNetto + bruttoPos,
            totalBrutto: accu.totalBrutto + bruttoPos,
          };
        }

        // berechne alles wenn 7%
        if (position.category.value.ust === 7) {
          const netto7 = Math.trunc((position.amount / 1.07).toFixed(0));
          const ust7Amount = bruttoPos - netto7;

          return {
            ...accu,
            total7: accu.total7 + ust7Amount,
            totalNetto: accu.totalNetto + netto7,
            totalBrutto: accu.totalBrutto + bruttoPos,
          };
        }

        // berechne alles wenn 19%
        if (position.category.value.ust === 19) {
          // const netto19 = position.amount / (1 + 19 / 100);
          // const netto19 = Number((position.amount / 1.19).toFixed(0));
          const netto19 = Math.trunc((position.amount / 1.19).toFixed(0));
          // const netto19 = position.amount / 1.19;
          const ustAmount = bruttoPos - netto19;

          return {
            ...accu,
            total19: accu.total19 + ustAmount,
            totalNetto: accu.totalNetto + netto19,
            totalBrutto: accu.totalBrutto + bruttoPos,
          };
        }
      } else {
        return accu;
      }

      return accu;
    },
    {
      total7: 0,
      total19: 0,
      totalNetto: 0,
      totalBrutto: 0,
    }
  );

  return {
    total7: floatToEuro(total7),
    total19: floatToEuro(total19),
    totalNetto: floatToEuro(totalNetto),
    totalBrutto: floatToEuro(totalBrutto),
  };
};

export default calcTotal;
